import Vue from 'vue';
import VueRouter from 'vue-router';

import tablasmaestras from './modules/tablasmaestras';
import declaraciones from './modules/declaraciones';

import applogindisplayarea from '@/components/general/applogindisplayarea';
Vue.component(applogindisplayarea.name, applogindisplayarea);

Vue.use(VueRouter);

// GEN_REGISTER_COMPONENT_START

Vue.component('sdsmmessageelement-grid', () => import('@/components/sdsmmessageelement/SdsmmessageelementGrid'));
Vue.component('sdsmmessageaudit-grid', () => import('@/components/sdsmmessageaudit/SdsmmessageauditGrid'));
Vue.component('sdsmmessageerror-grid', () => import('@/components/sdsmmessageerror/SdsmmessageerrorGrid'));
// GEN_REGISTER_COMPONENT_END

const puiRoutes = [
	{
		path: '/',
		redirect: 'home'
	},
	{
		path: 'home',
		component: () => import('@/components/general/AppWelcomePanel.vue')
	},
	{
		path: 'usersettings',
		component: () => import('pui9-admin/src/components/admin/puiuser/PuiUserSettingsForm.vue')
	},
	{
		path: 'puiaudit',
		component: () => import('pui9-admin/src/components/config/puiaudit/PuiAuditGrid.vue')
	},
	{
		path: 'session',
		component: () => import('pui9-admin/src/components/config/puisession/PuiSessionGrid.vue')
	},
	{
		path: 'puiuser',
		component: () => import('pui9-admin/src/components/admin/puiuser/PuiUserGrid.vue')
	},
	{
		path: '(.*/)?puiuser/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/admin/puiuser/PuiUserForm.vue')
	},
	{
		path: 'puiprofile',
		component: () => import('pui9-admin/src/components/admin/puiprofile/PuiProfileGrid.vue')
	},
	{
		path: '(.*/)?puiprofile/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/admin/puiprofile/PuiProfileForm.vue')
	},
	{
		path: 'puifunctionality',
		component: () => import('pui9-admin/src/components/admin/puifunctionality/PuiFunctionalityGrid.vue')
	},
	{
		path: 'puivariable',
		component: () => import('pui9-admin/src/components/config/puivariable/PuiVariableGrid.vue')
	},
	{
		path: '(.*/)?puivariable/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/config/puivariable/PuiVariableForm.vue')
	},
	{ path: 'puilanguage', component: () => import('pui9-admin/src/components/config/puilanguage/PuiLanguageGrid.vue') },
	{
		path: '(.*/)?puilanguage/:method/:pk',
		props: true,
		component: () => import('pui9-admin/src/components/config/puilanguage/PuiLanguageForm.vue')
	},
	{
		path: 'puidocument',
		component: () => import('pui9-documents/src/components/PuiDocumentGrid.vue')
	},
	{
		path: '(.*/)?puidocument/:method/:pk',
		props: true,
		component: () => import('pui9-documents/src/components/PuiDocumentForm.vue')
	},
	{
		path: 'puidocgentemplate',
		component: () => import('pui9-docgen/src/components/puidocgen/PuiDocgenTemplateGrid.vue')
	},
	{
		path: '(.*/)?puidocgentemplate/:method/:pk',
		props: true,
		component: () => import('pui9-docgen/src/components/puidocgen/PuiDocgenTemplateForm')
	}
];

const appRoutes = [
	// GEN_ROUTES_START
	// GEN_ROUTES_END
];
appRoutes.push(...tablasmaestras.routes);
appRoutes.push(...declaraciones.routes);

const puiNotFound = [{ path: '*', component: () => import('pui9-base/src/components/PuiNotFound') }];

function setAuthRequired(route) {
	return { ...route, meta: { ...route.meta, requiresAuth: true } };
}

const mergedRouter = {
	mode: 'history',
	hash: false,
	base: '/pcsum',
	routes: [
		{
			path: '/login',
			component: () => import('pui9-base/src/components/PuiIntroLayout')
		},
		{
			path: '/keycloaklogin',
			component: () => import('pui9-login/src/components/PuiKeycloakLogin')
		},
		{
			path: `/`,
			component: () => import('pui9-base/src/components/PuiBaseLayout'),
			children: puiRoutes.concat(appRoutes).concat(puiNotFound).map(setAuthRequired)
		}
	]
};

export default new VueRouter(mergedRouter);
