const CopyAction = {
	id: 'idEquipmentCopyAction',
	selectionType: 'single',
	label: 'action.sdsmuti.copyAction.title',
	functionality: 'COPY_UTI',
	showInForm: true,
	checkAvailability: function () {
		return true;
		// if (typeof reg[0] === 'undefined') {
		// 	return true;
		// }
		// return reg[0].cargooperatorid == this.$store.state.session.properties.GESTAUT_OPERATOR_ID;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		row.utiid = null;
		row.utiidentification = null;
		row.fullfilliso = '0';
		this.$puiEvents.$emit('pui-modalDialogForm-idEquipmentCopyAction-' + model.name + '-show', row);
	}
};

const MassiveUpdate = {
	id: 'idEquipmentMassiveUpdateAction',
	selectionType: 'multiple',
	label: 'action.sdsmuti.massiveEditAction.title',
	description: 'action.sdsmuti.massiveEditAction.description',
	functionality: 'MAS_UPD_UTI',
	showInForm: true,
	checkAvailability: function (registries) {
		return registries.cargooperatorid == this.$store.state.session.properties.GESTAUT_OPERATOR_ID;
	},
	runAction: function (action, model, registries) {
		const data = {
			utis: registries,
			isAction: true
		};
		this.$puiEvents.$emit('pui-modalDialogForm-idEquipmentMassiveUpdateAction-' + model.name + '-show', data);
	}
};

export default {
	gridactions: [MassiveUpdate, CopyAction],
	formactions: [CopyAction]
};
