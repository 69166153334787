<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<mdock-modals :modelName="modelName"></mdock-modals>
	</div>
</template>

<script>
import mdockActions from './MdockActions';
import mdockModals from './MdockModals.vue';

export default {
	name: 'mdock-grid',
	components: {
		'mdock-modals': mdockModals
	},
	data() {
		return {
			modelName: 'mdock',
			actions: mdockActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
