<template>
	<div>
		<div class="divHeader">
			<pcsum-header :data="DocumentHeaderData" :params="{ entity: 'sdsmdocument' }"></pcsum-header>

			<v-card class="PcsumStatsHeaderWrapper" elevation="0">
				<v-expand-transition>
					<v-row dense v-if="showStats" class="mt-1">
						<v-col cols="3">
							<pcsum-stats-header
								:label="$t('header.sdsmdocument.billofloadings')"
								:data="BLData"
								:showTotal="true"
							></pcsum-stats-header>
						</v-col>

						<v-col cols="3">
							<pcsum-stats-header :label="$t('header.sdsmdocument.goods')" :data="GoodsData"></pcsum-stats-header>
						</v-col>

						<v-col cols="6">
							<pcsum-stats-header
								:label="$t('header.sdsmdocument.utils')"
								:data="EqsData"
								:data2="EqsData2"
								:showTotal="true"
							></pcsum-stats-header>
						</v-col>
					</v-row>
				</v-expand-transition>
				<v-chip @click="toggleStats()" class="mt-2">
					{{ $t('general.stats') }}
					<i class="far fa-chevron-down" v-if="!showStats"></i>
					<i class="far fa-chevron-up" v-if="showStats"></i>
				</v-chip>
			</v-card>
		</div>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';
import PcsumHeader from '../general/PcsumHeader';
import PcsumStatsHeader from '../general/PcsumStatsHeader';
import SdsmDocumentUtils from './SdsmdocumentUtils';
export default {
	name: 'sdsmdocument-form-header',
	mixins: [PuiFormHeaderMixin],
	components: {
		'pcsum-stats-header': PcsumStatsHeader,
		'pcsum-header': PcsumHeader
	},
	props: {
		data: { type: Object, default: () => {} },
		params: { type: Object, default: () => {} },
		alerts: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			bls: null,
			goods: null,
			eqs: null,
			eqsState: null,
			preloadStats: false,
			showStats: false,
			modelName: 'sdsmdocument'
		};
	},
	methods: {
		async toggleStats() {
			this.showStats = !this.showStats;
			if (this.showStats && !this.eqs) {
				this.bls = await SdsmDocumentUtils.getBLsStats(this, this.data);
				this.goods = await SdsmDocumentUtils.getGoodsStats(this, this.data);
				this.eqs = await SdsmDocumentUtils.getUtisStats(this, this.data);
				this.eqsState = await SdsmDocumentUtils.getUtisByBL(this, this.data);
			}
		}
	},
	computed: {
		BLData() {
			/* eslint-disable no-mixed-spaces-and-tabs */
			return this.bls
				? [
						{ label: this.$t('header.sdsmdocument.blempty'), value: this.bls[0]?.vacios || 0 },
						{ label: this.$t('header.sdsmdocument.blpass'), value: this.bls[0]?.pasaje || 0 },
						{ label: this.$t('header.sdsmdocument.blcommo'), value: this.bls[0]?.mercaderias || 0 }
				  ]
				: null;
		},
		GoodsData() {
			return this.goods
				? [
						{ label: this.$t('header.sdsmdocument.goodnumber'), value: this.goods.length },
						{ label: this.$t('header.sdsmdocument.goodkg'), value: this.goods.reduce((acc, curr) => acc + curr.weight, 0) },
						{ label: this.$t('header.sdsmdocument.passengers'), value: this.calculatePassengers },
						{ label: this.$t('header.sdsmdocument.vehicles'), value: this.calculateVehicles }
				  ]
				: null;
		},
		DocumentHeaderData() {
			return this.model && this.data
				? [
						{ label: 'header.sdsmdocument.documentnumber', value: this.data.documentnumber },
						{ label: 'header.sdsmdocument.type', value: this.model.documenttype },
						{ label: 'header.sdsmdocument.operator', value: this.model.operator, showId: true, id: this.model.operatorid },
						{ label: 'header.sdsmdocument.callnumber', value: this.model.callnumber, pill: true },
						{ label: 'header.sdsmdocument.shipname', value: this.model.shipname },
						{ label: 'header.sdsmdocument.state', value: this.model.state, pill: true, statusId: this.model.documentstateid },
						{ label: 'header.sdsmdocument.alert', alert: this.alerts, pill: true }
				  ]
				: null;
		},
		EqsData2() {
			return this.eqs
				? [
						{ label: this.$t('header.sdsmdocument.uticont'), value: this.calculateCont },
						{ label: this.$t('header.sdsmdocument.utiroro'), value: this.calculateRoros },
						{ label: this.$t('header.sdsmdocument.utiothers'), value: this.calculateOtros }
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  ]
				: null;
		},
		EqsData() {
			return this.eqsState
				? [
						{ label: this.$t('header.sdsmdocument.utiempty'), value: this.eqsState[0] ? this.calculateEmpty : 0 },
						{ label: this.$t('header.sdsmdocument.utifull'), value: this.eqsState[0] ? this.calculateFull : 0 }
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  ]
				: null;
		},
		calculateRoros() {
			const roros = this.eqs.filter((item) => item.eqtypegroupid == 2);
			let n = 0;
			if (roros?.[0]) {
				roros.forEach((element) => {
					n = n + element.suma;
				});
			}
			return n;
		},
		calculateCont() {
			const cont = this.eqs.filter((item) => item.eqtypegroupid == 3);
			let n = 0;
			if (cont?.[0]) {
				cont.forEach((element) => {
					n = n + element.suma;
				});
			}
			return n;
		},

		calculateOtros() {
			const others = this.eqs.filter((item) => item.eqtypegroupid == 1);
			let n = 0;
			if (others?.[0]) {
				others.forEach((element) => {
					n = n + element.suma;
				});
			}
			return n;
		},
		calculateEmpty() {
			let n = 0;
			let v = 0;
			this.eqsState.forEach((element) => {
				if (element.utistatusid != 1) {
					if (element.units == null) {
						n = n + 1;
					} else {
						n = n + element.units;
					}
				} else if (element.units == null) {
					v = v + 1;
				} else {
					v = v + element.units;
				}
			});

			return v;
		},
		calculateFull() {
			let n = 0;
			let v = 0;
			this.eqsState.forEach((element) => {
				if (element.utistatusid != 1) {
					if (element.units == null) {
						n = n + 1;
					} else {
						n = n + element.units;
					}
				} else if (element.units == null) {
					v = v + 1;
				} else {
					v = v + element.units;
				}
			});
			return n;
		},
		calculateVehicles() {
			let vehiculos = this.goods.filter((item) => item.tariccod.startsWith('000') && !item.tariccod.startsWith('0001'));
			let suma = vehiculos.reduce((total, item) => total + item.packagesnumber, 0);
			return suma;
		},
		calculatePassengers() {
			let pass = this.goods.filter((item) => item.tariccod.startsWith('0001'));

			let suma = pass.reduce((total, item) => total + item.packagesnumber, 0);

			return suma;
		},
		calculateTotal() {
			return this.eqs[0].s1 + this.eqs[0].s2 - this.roros[0].container - this.calculateRoros;
		}
	},
	created() {
		this.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'puivariable',
				filter: {
					rules: [{ field: 'variable', op: 'eq', data: 'DOCUMENT_STATISTICS_AUTOLOAD' }]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					if (response.data.data[0].value === 'true') {
						this.toggleStats();
					}
				}
			},
			() => {}
		);
	}
};
</script>
