<template>
	<div class="pui-form">
		<template v-if="model">
			<v-form class="mb-4 pb-4" ref="form" lazy-validation @submit.prevent>
				<v-row class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('modal.sdsmuti.massiveedit.utis')">
							<v-chip-group column>
								<template v-for="(U, index) in utis">
									<template v-if="U.utiid">
										<v-tooltip bottom :key="'UTI-' + index">
											<template v-slot:activator="{ on, attrs }">
												<v-chip label outlined :color="getStatus(U).color" v-bind="attrs" v-on="on">
													<span>{{ U.utiidentification || $t('modal.sdsmuti.massiveedit.noidentification') }}</span>
													<template v-if="U.status">
														<v-icon small class="pl-3" :color="getStatus(U).color">{{ getStatus(U).icon }} </v-icon>
													</template>
												</v-chip>
											</template>
											<span class="massiveToolTip">
												<div class="content">
													<div>{{ $t('sdsmdocument.documentnumber') }}: {{ U.documentnumber }}</div>
													<div>{{ $t('sdsmbillofloading.blnumber') }}: {{ U.blordernumber }}</div>
													<div>{{ $t('sdsmuti.eqsizeid') }}: {{ U.equipmentsize }}</div>
													<div>{{ $t('sdsmuti.utistatusid') }}: {{ U.utistatus }}</div>
													<div>{{ $t('sdsmuti.tare') }}: {{ U.tare }}</div>
												</div>
												<div v-if="U.error" class="messages">
													<i class="fas fa-exclamation-triangle"></i> <span>{{ U.error }}</span>
												</div>
											</span>
										</v-tooltip>
									</template>
								</template>
							</v-chip-group>
						</pui-field-set>
					</v-col>
					<v-col cols="12">
						<pui-field-set :title="$t('modal.sdsmuti.massiveedit.newvalues')">
							<v-row dense>
								<v-col class="12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<pui-select
										id="`eqtypeid-sdsmuti`"
										attach="eqtypeid-sdsmuti"
										:label="$t('sdsmuti.eqtypeid')"
										toplabel
										clearable
										v-model="model"
										modelName="mequipmenttype"
										:modelFormMapping="{ eqtypeid: 'eqtypeid' }"
										:itemsToSelect="eqtypeidItemsToSelect"
										:itemValue="'eqtypeid'"
										:queryFields="['edicode', 'description']"
										:itemText="(item) => `${item.edicode} - ${item.description}`"
										:order="{ description: 'asc' }"
										detailComponentName="sdsmuti-form"
										:fixedFilter="DateExpirationFilter"
										singleItemAutoselect
									></pui-select>
								</v-col>

								<!-- UTISTATUSID -->
								<v-col class="12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<pui-select
										id="`utistatusid-sdsmuti`"
										attach="utistatusid-sdsmuti"
										:label="$t('sdsmuti.utistatusid')"
										toplabel
										clearable
										v-model="model"
										modelName="mutistatus"
										:modelFormMapping="{ utistatusid: 'utistatusid' }"
										:itemsToSelect="utistatusidItemsToSelect"
										singleItemAutoselect
										:itemValue="'utistatusid'"
										:queryFields="['description']"
										:itemText="(item) => `${item.description}`"
										:order="{ description: 'asc' }"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col class="12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<pui-select
										id="`eqsizeid-sdsmuti`"
										attach="eqsizeid-sdsmuti"
										:label="$t('sdsmuti.eqsizeid')"
										toplabel
										clearable
										v-model="model"
										modelName="mequipmentsize"
										:modelFormMapping="{ eqsizeid: 'eqsizeid' }"
										:itemsToSelect="eqsizeidItemsToSelect"
										:itemValue="'eqsizeid'"
										:queryFields="['isocode', 'description']"
										:itemText="(item) => `${item.isocode} - ${item.description}`"
										:order="{ description: 'asc' }"
										:fixedFilter="DateExpirationFilter"
										detailComponentName="sdsmuti-form"
										singleItemAutoselect
									></pui-select>
								</v-col>
								<v-col class="12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
									<pui-text-field :id="`tare-sdsmuti`" v-model="model.tare" :label="$t('sdsmuti.tare')" toplabel></pui-text-field>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-else>
			<v-progress-linear color="primary" indeterminate></v-progress-linear>
		</template>
	</div>
</template>

<script>
import SdsmUtiUtils from '../SdsmutiUtils';
import MassiveUtils from '../../../utils/massive';
export default {
	name: 'sdsmuti-form-for-massive-update',
	props: {
		utis: { type: Array, default: () => [] }
	},
	data() {
		return {
			model: null
		};
	},
	methods: {
		getStatus(uti) {
			return MassiveUtils.getStatus(uti);
		},
		async SendUtiMassiveEdit() {
			let everythingupdated = true;
			for (const u in this.utis) {
				if (this.utis[u].utiid) {
					this.utis[u].status = 1;
					let response = await SdsmUtiUtils.massiveupdate(this, this.model, this.utis[u].utiid);
					if (response.updated) {
						this.utis[u].status = 2;
						this.utis[u].error = false;
					} else {
						this.utis[u].status = 3;
						this.utis[u].error = response.error;
						everythingupdated = false;
					}
				}
			}
			if (everythingupdated) {
				this.$puiNotify.success(this.$t('modal.sdsmuti.massiveedit.notifySuccess'));
			}
			return everythingupdated;
		}
	},
	computed: {
		eqsizeidItemsToSelect() {
			return [{ eqsizeid: this.model.eqsizeid }];
		},
		eqtypeidItemsToSelect() {
			return [{ eqtypeid: this.model.eqtypeid }];
		},
		utistatusidItemsToSelect() {
			return [{ utistatusid: this.model.utistatusid }];
		},
		DateExpirationFilter() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'dateexpiration', op: 'nu' }]
					}
				],
				groupOp: 'and',
				rules: []
			};
		}
	},
	async created() {
		for (const u in this.utis) {
			if (this.utis[u].utiid) {
				this.$set(this.utis[u], 'status', null);
				this.$set(this.utis[u], 'error', null);
			}
		}
		this.model = await SdsmUtiUtils.newUTI(this);
	}
};
</script>
