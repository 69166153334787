<template>
	<div class="Alerts">
		<div class="Alerts-parent" v-for="record in alerts" :key="record.pk">
			<div
				class="Alert"
				v-on:click="record.operator == $store.state.session.properties.GESTAUT_OPERATOR_ID ? handleClick(record.pk, record.entity) : null"
			>
				<v-alert
					style="font-size: 1em; cursor: pointer"
					dense
					text
					type="warning"
					:color="record.operator !== $store.state.session.properties.GESTAUT_OPERATOR_ID ? 'grey' : 'orange'"
				>
					<span v-if="record.entity === 'sdsmdocument'">
						{{ $t('modal.alertmodal.document') + ' ' + record.pk }}
					</span>
					<span v-if="record.entity === 'sdsmjointservice'">
						{{ $t('modal.alertmodal.jointservice') + ' ' + record.pk }}
					</span>
					<span v-if="record.entity === 'sdsmbillofloading'">
						{{ $t('modal.alertmodal.billofloading') + ' ' + record.pk }}
					</span>
					<span v-if="record.entity === 'sdsmgood'">
						{{ $t('modal.alertmodal.good') + ' ' + record.pk }}
					</span>
					<i class="far fa-chevron-right" style="margin: 0px 10px; opacity: 0.5"></i>
					<span>{{ $t(traduccionMsg(record.msg)) }}</span>
				</v-alert>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'modalWarningAlerts',
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		alerts: {
			type: Array,
			default: () => []
		}
	},

	methods: {
		closeModal() {
			this.$emit('update:showModal', false);
			this.showModal = false;
		},
		traduccionMsg(msg) {
			switch (msg) {
				case 'NO ENVIADO':
					return 'modal.alertmodal.notsend';

				case 'En espera de respuesta':
					return 'modal.alertmodal.w8customs';

				case 'Rechazado por aduanas':
					return 'modal.alertmodal.rejected';
				case 'N Paquetes':
					return 'modal.alertmodal.npakage';

				default:
					return 'Alert error';
			}
		},
		handleClick(pk, entity) {
			// Aquí puedes llamar a cualquier método que necesites con el pk y el entity
			const query = {};
			let btoaString;
			switch (entity) {
				case 'sdsmdocument':
					btoaString = { documentid: pk };
					break;
				case 'sdsmjointservice':
					btoaString = { jointserviceid: pk };
					break;

				case 'sdsmbillofloading':
					btoaString = { blid: pk };
					break;
				case 'sdsmgood':
					btoaString = { goodid: pk };
					break;
				default:
					btoaString = null;
					break;
			}

			let urlPk = btoa(JSON.stringify(btoaString));
			const url = '/' + entity + '/update/' + urlPk + '/';
			const params = {
				router: this.$router,
				url: url,
				params: null,
				query: query
			};
			this.$store.dispatch('puiRouterGoToUrl', params);
		}
	}
};
</script>

<style>
.Alert .v-alert .v-alert__wrapper .v-icon {
	font-size: 1.25em !important;
}
</style>
