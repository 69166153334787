<template>
	<div class="pui-form">
		<template v-if="model">
			<v-form class="mb-4 pb-4" ref="form" lazy-validation @submit.prevent>
				<v-row class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('modal.sdsmbillofloading.massiveupd.title')">
							<v-chip-group column>
								<template v-for="(U, index) in billofloadings">
									<template v-if="U.blid">
										<v-tooltip bottom :key="'billofloading-' + index">
											<template v-slot:activator="{ on, attrs }">
												<v-chip label outlined :color="getStatus(U).color" v-bind="attrs" v-on="on">
													<span>{{
														$t('sdsmbillofloading.blnumber') +
														': ' +
														(U.blnumber || $t('modal.sdsmuti.massiveedit.noidentification')) +
														' / ' +
														$t('sdsmbillofloading.ordernumber') +
														': ' +
														(U.ordernumber || $t('modal.sdsmuti.massiveedit.noidentification'))
													}}</span>
													<template v-if="U.status">
														<v-icon small class="pl-3" :color="getStatus(U).color">{{ getStatus(U).icon }} </v-icon>
													</template>
												</v-chip>
											</template>
											<span class="massiveToolTip">
												<div class="content">
													<div>{{ $t('sdsmdocument.documentnumber') }}: {{ U.documentnumber }}</div>
													<div>{{ $t('sdsmjointservice.jointservicenumber') }}: {{ U.jointservicenumber }}</div>
													<div>{{ $t('sdsmjointservice.state') }}: {{ U.state }}</div>
													<div>{{ $t('sdsmjointservice.ordernumber') }}: {{ U.ordernumber }}</div>
												</div>
												<div v-if="U.error" class="messages">
													<i class="fas fa-exclamation-triangle"></i> <span>{{ U.error }}</span>
												</div>
											</span>
										</v-tooltip>
									</template>
								</template>
							</v-chip-group>
						</pui-field-set>
					</v-col>
					<v-col cols="12">
						<pui-field-set :title="$t('modal.sdsmuti.massiveedit.newvalues')">
							<v-row dense>
								<v-col class="12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> </v-col>
							</v-row>

							<v-row dense>
								<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pl-2 pr-2">
									<label class="customLabel v-label--">{{ $t('sdsmbillofloading.origindes') }}</label>
									<v-autocomplete
										class="customSelector"
										outlined
										dense
										v-model="model.origincode"
										ref="origincodeRef"
										:loading="loading"
										:items="locinfo"
										clearable
										:readonly="originSelected"
										item-value="locode"
										:item-text="item => `${item.locode} - ${item.descripcion}`"
										@keyup="selectIfOnlyOneOptionorigincode"
									>
										<template v-slot:selection="{ props, item }">
											<div class="BasicSlotOption" v-bind="props">
												<span> {{ item?.locode }} </span>
												<span> {{ item?.descripcion }} </span>
											</div>
										</template>
										<template v-slot:item="{ props, item }">
											<div class="BasicSlotOption" v-bind="props">
												<span> {{ item?.locode }} </span>
												<span> {{ item?.descripcion }} </span>
											</div>
										</template>
									</v-autocomplete>
								</v-col>
								<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pl-2 pr-2">
									<label class="customLabel v-label--">{{ $t('sdsmbillofloading.loaddes') }}</label>
									<v-autocomplete
										class="customSelector"
										outlined
										dense
										validate-on-blur
										v-model="model.loadcode"
										ref="loadcodeRef"
										:loading="loading"
										:items="locinfo"
										:readonly="loadcodeSelected"
										clearable
										item-value="locode"
										:item-text="model.loadcode ? `''` : item => `${item.locode} - ${item.descripcion}`"
										@keyup="selectIfOnlyOneOptionloadcode"
									>
										<template v-slot:selection="{ props, item }">
											<div class="BasicSlotOption" v-bind="props">
												<span> {{ item?.locode }} </span>
												<span> {{ item?.descripcion }} </span>
											</div>
										</template>
										<template v-slot:item="{ props, item }">
											<div class="BasicSlotOption" v-bind="props">
												<span> {{ item?.locode }} </span>
												<span> {{ item?.descripcion }} </span>
											</div>
										</template>
									</v-autocomplete>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pl-2 pr-2">
									<label class="customLabel">{{ $t('sdsmbillofloading.transhipmentdes') }}</label>
									<v-autocomplete
										class="customSelector"
										outlined
										dense
										v-model="model.transhipmentcode"
										:loading="loading"
										:items="locinfo"
										:readonly="transhipmentcodeSelected"
										clearable
										validate-on-blur
										item-value="locode"
										:item-text="model.transhipmentcode ? `''` : item => `${item.locode} - ${item.descripcion}`"
										ref="transhipmentcode"
										@keyup="selectIfOnlyOneOptiontranshipmentcode"
									>
										<template v-slot:selection="{ props, item }">
											<div class="BasicSlotOption" v-bind="props">
												<span> {{ item?.locode }} </span>
												<span> {{ item?.descripcion }} </span>
											</div>
										</template>
										<template v-slot:item="{ props, item }">
											<div class="BasicSlotOption" v-bind="props">
												<span> {{ item?.locode }} </span>
												<span> {{ item?.descripcion }} </span>
											</div>
										</template>
									</v-autocomplete>
								</v-col>
								<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pl-2 pr-2">
									<label class="customLabel v-label--">{{ $t('sdsmbillofloading.destinationdes') }}</label>
									<v-autocomplete
										class="customSelector"
										outlined
										dense
										v-model="model.destinationcode"
										:loading="loading"
										:items="locinfo"
										clearable
										:readonly="destinationcodeSelected"
										item-value="locode"
										:item-text="item => `${item.locode} - ${item.descripcion}`"
										ref="destinationcode"
										@keyup="selectIfOnlyOneOptiondestinationcode"
									>
										<template v-slot:selection="{ props, item }">
											<div class="BasicSlotOption" v-bind="props">
												<span> {{ item?.locode }} </span>
												<span> {{ item?.descripcion }} </span>
											</div>
										</template>
										<template v-slot:item="{ props, item }">
											<div class="BasicSlotOption" v-bind="props">
												<span> {{ item?.locode }} </span>
												<span> {{ item?.descripcion }} </span>
											</div>
										</template>
									</v-autocomplete>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
									<pui-select
										id="`bltypeid-sdsmbillofloading`"
										attach="bltypeid-sdsmbillofloading"
										:label="$t('sdsmbillofloading.bltypeid')"
										toplabel
										clearable
										v-model="model"
										modelName="vutbillofloadingtype"
										:modelFormMapping="{ bltypeid: 'bltypeid' }"
										:itemsToSelect="billtypeItemsToSelect"
										:itemValue="'bltypeid'"
										:itemText="item => `${item.description}`"
										:order="{ documentid: 'asc' }"
										detailComponentName="vutbillofloadingtype-form"
										singleItemAutoselect
									></pui-select>
								</v-col>
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
									<pui-select
										id="`goodtypeid-vutgoodtype`"
										attach="goodtypeid-sdsmbillofloading"
										:label="$t('sdsmbillofloading.goodtypeid')"
										toplabel
										clearable
										v-model="model"
										modelName="vutgoodtype"
										:modelFormMapping="{ goodtypeid: 'goodtypeid' }"
										:itemsToSelect="goodtypeItemsToSelect"
										:itemValue="'goodtypeid'"
										:itemText="item => `${item.description}`"
										:order="{ documentid: 'asc' }"
										detailComponentName="vutgoodtype-form"
										singleItemAutoselect
									></pui-select>
								</v-col>
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
									<pui-select
										id="`frontreartransid-mfrontreartransport`"
										attach="frontreartransid-sdsmbillofloading"
										:label="$t('sdsmbillofloading.frontreartransid')"
										toplabel
										clearable
										v-model="model"
										modelName="vutmfrontreartransporttra"
										:modelFormMapping="{ frontreartransid: 'frontreartransid' }"
										:itemsToSelect="frontreartransidItemsToSelect"
										:itemValue="'frontreartransid'"
										:itemText="item => `${item.description}`"
										:order="{ documentid: 'asc' }"
										detailComponentName="mfrontreartransport-form"
										:fixedFilter="FilterTransport"
										singleItemAutoselect
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
									<pui-select
										id="`stevedoreid-sdsmstevedore`"
										attach="stevedoreid-sdsmbillofloading"
										:label="$t('sdsmbillofloading.stevedoreid')"
										toplabel
										clearable
										v-model="model"
										modelName="sdsmstevedore"
										:modelFormMapping="{ stevedoreid: 'stevedoreid' }"
										:itemsToSelect="stevedoreItemsToSelect"
										:itemValue="'stevedoreid'"
										:itemText="item => `${item.description}`"
										:order="{ documentid: 'asc' }"
										detailComponentName="sdsmstevedore-form"
										:fixedFilter="FilterStevedoreid"
									></pui-select>
								</v-col>
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
									<pui-select
										id="`dockid-sdsmjointservice`"
										attach="dockid-sdsmjointservice"
										:label="$t('sdsmbillofloading.dockid')"
										toplabel
										clearable
										v-model="model"
										modelName="mdock"
										:modelFormMapping="{ dockid: 'dockid' }"
										:itemsToSelect="dockItemsToSelect"
										:itemValue="'dockid'"
										:itemText="item => `${item.description}`"
										:order="{ dockid: 'asc' }"
										:fixedFilter="FilterDockid"
									></pui-select>
								</v-col>
								<!-- COMCODE -->
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
									<pui-select
										id="`docklocationid-sdsmjointservice`"
										attach="docklocationid-sdsmjointservice"
										:label="$t('sdsmbillofloading.docklocationid')"
										toplabel
										clearable
										:required="dockSelected"
										v-model="model"
										modelName="mdockdocklocation"
										:modelFormMapping="{ docklocationid: 'docklocationid' }"
										:itemsToSelect="docklocationItemsToSelect"
										:itemValue="['docklocationid']"
										:itemText="item => `${item.docklocation}`"
										:order="{ docklocationid: 'asc' }"
										:fixedFilter="fixedFilterDockLocation"
										singleItemAutoselect
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
									<pui-text-field
										:id="`goodservice-sdsmbillofloading`"
										v-model="model.goodservice"
										:label="$t('sdsmbillofloading.goodservice')"
										toplabel
										maxlength="35"
									></pui-text-field>
								</v-col>
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
									<pui-text-field
										:id="`comcode-sdsmbillofloading`"
										v-model="model.comcode"
										:label="$t('sdsmbillofloading.comcode')"
										toplabel
										maxlength="35"
									></pui-text-field>
								</v-col>
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
									<pui-text-field
										:id="`shipimo-sdsmbillofloading`"
										v-model="model.shipimo"
										:label="$t('sdsmbillofloading.shipimo')"
										toplabel
										maxlength="35"
									></pui-text-field>
								</v-col>
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
									<pui-text-field
										:id="`manipulationterminal-sdsmbillofloading`"
										v-model="model.manipulationterminal"
										:label="$t('sdsmbillofloading.manipulationterminal')"
										toplabel
										maxlength="35"
									></pui-text-field>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-else>
			<v-progress-linear color="primary" indeterminate></v-progress-linear>
		</template>
	</div>
</template>

<script>
import MassiveUtils from '../../../utils/massive'
import formUtils from '@/utils/form'
import SdsmbillofloadingUtils from '../SdsmbillofloadingUtils'
import { mapState } from 'vuex'
import filters from '@/utils/filters.js'
export default {
	name: 'sdsmbillofloading-masupd',
	props: {
		billofloadings: { type: Array, default: () => [] }
	},
	data () {
		return {
			codartRules: [v => this.checkOperator(v) || this.$t('sdsmjointservice.orderinuse')],
			model: null,
			loading: false,
			originSelected: false,
			loadcodeSelected: false,
			transhipmentcodeSelected: false,
			destinationcodeSelected: false,
			dockSelected: false,
			issimpregBool: false,
			simpregooperatorBool: true,
			lastSelectedOption: null,
			jsnumber: [],
			filters: {
				dockid: '',
				docklocationid: '',
				stateid: '',
				operatorid: ''
			}
		}
	},
	async created () {
		this.loading = true

		for (const u in this.billofloadings) {
			if (this.billofloadings[u].blid) {
				this.$set(this.billofloadings[u], 'status', null)
				this.$set(this.billofloadings[u], 'error', null)
			}
		}
		this.model = await SdsmbillofloadingUtils.newBillOfLoading(this)
		formUtils.updateMiniAudit(this.model)
		await SdsmbillofloadingUtils.getLocInfo(this)
		this.$watch('model.origincode', this.WatchOriginCode)
		this.$watch('model.loadcode', this.WatchLoadCode)
		this.$watch('model.transhipmentcode', this.WatchTranshipmentCode)
		this.$watch('model.destinationcode', this.WatchDestinationCode)
		this.loading = false
	},
	methods: {
		async SendBLMassiveEdit () {
			let everythingupdated = true
			for (const u in this.billofloadings) {
				if (this.billofloadings[u].blid) {
					this.billofloadings[u].status = 1
					let response = await SdsmbillofloadingUtils.massiveupdate(this, this.model, this.billofloadings[u].blid)
					if (response.updated) {
						this.billofloadings[u].status = 2
						this.billofloadings[u].error = false
					} else {
						this.billofloadings[u].status = 3
						this.billofloadings[u].error = response.error
						everythingupdated = false
					}
				}
			}
			if (everythingupdated) {
				this.$puiNotify.success(this.$t('modal.sdsmbillofloading.massiveupd.notifySuccess'))
			}
			return everythingupdated
		},
		selectIfOnlyOneOptionorigincode (event) {
			if (event.key == 'Backspace' && this.model.origincode != null) {
				this.model.origincode = null
			}

			let text = event.target.value
			let resultadosFiltrados = this.locinfo.filter(item => {
				return item.locode.toLowerCase().includes(text.toLowerCase())
			})
			if (resultadosFiltrados.length == 1) {
				this.model.origincode = resultadosFiltrados[0].locode

				this.$refs.origincodeRef.isMenuActive = false
			}
		},
		selectIfOnlyOneOptionloadcode (event) {
			if (event.key == 'Backspace' && this.model.loadcode != null) {
				this.model.loadcode = null
			}

			let text = event.target.value
			let resultadosFiltrados = this.locinfo.filter(item => {
				return item.locode.toLowerCase().includes(text.toLowerCase())
			})
			if (resultadosFiltrados.length == 1) {
				this.model.loadcode = resultadosFiltrados[0].locode

				this.$refs.loadcodeRef.isMenuActive = false
			}
		},
		selectIfOnlyOneOptiontranshipmentcode (event) {
			if (event.key == 'Backspace' && this.model.transhipmentcode != null) {
				this.model.transhipmentcode = null
			}

			let text = event.target.value
			let resultadosFiltrados = this.locinfo.filter(item => {
				return item.locode.toLowerCase().includes(text.toLowerCase())
			})
			if (resultadosFiltrados.length == 1) {
				this.model.transhipmentcode = resultadosFiltrados[0].locode

				this.$refs.transhipmentcode.isMenuActive = false
			}
		},
		selectIfOnlyOneOptiondestinationcode (event) {
			if (event.key == 'Backspace' && this.model.destinationcode != null) {
				this.model.destinationcode = null
			}

			let text = event.target.value
			let resultadosFiltrados = this.locinfo.filter(item => {
				return item.locode.toLowerCase().includes(text.toLowerCase())
			})
			if (resultadosFiltrados.length == 1) {
				this.model.destinationcode = resultadosFiltrados[0].locode

				this.$refs.destinationcode.isMenuActive = false
			}
		},
		WatchOriginCode (newValue) {
			this.model.origindes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo)
		},
		WatchTranshipmentCode (newValue) {
			this.model.transhipmentdes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo)
		},
		WatchLoadCode (newValue) {
			this.model.loaddes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo)
		},
		WatchDestinationCode (newValue) {
			this.model.destinationdes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo)
		},
		getStatus (billofloading) {
			//get iconos v x !
			return MassiveUtils.getStatus(billofloading)
		}
	},

	computed: {
		...mapState({
			locinfo: state => state.PCS.locinfo
		}),
		fixedFilterDockLocation () {
			return filters.getDockDockLockFilter(this.model.dockid)
		},
		FilterStevedoreid () {
			return filters.expirationDatePlus(this.filters.stevedoreid, 'stevedoreid')
		},
		FilterDockid () {
			return filters.expirationDatePlus(this.filters.dockid, 'dockid')
		},
		jointserviceItemsToSelect () {
			return [{ jointserviceid: this.model.jointserviceid }]
		},
		origincodeItemsToSelect () {
			return [{ origincode: this.model.origincode }]
		},
		stevedoreItemsToSelect () {
			return [{ stevedoreid: this.model.stevedoreid }]
		},
		billtypeItemsToSelect () {
			return [{ bltypeid: this.model.bltypeid }]
		},
		goodtypeItemsToSelect () {
			return [{ goodtypeid: this.model.goodtypeid }]
		},
		frontreartransidItemsToSelect () {
			return [{ frontreartransid: this.model.frontreartransid }]
		},
		dockItemsToSelect () {
			return [{ dockid: this.model.dockid }]
		},
		docklocationItemsToSelect () {
			return [{ docklocationid: this.model.docklocationid }]
		},
		fixedFilterDock () {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'dockid', op: 'eq', data: this.model.dockid },
							{ field: 'dateexpiration', op: 'nu' }
						]
					}
				],
				groupOp: 'and',
				rules: []
			}
		},
		FilterTransport () {
			const filter = {
				groups: [
					{
						rules: [{ field: 'edicode', op: 'eq', data: 'ZZ1' }]
					},
					{
						rules: [{ field: 'edicode', op: 'eq', data: 'ZZ2' }]
					},
					{
						rules: [{ field: 'edicode', op: 'eq', data: 'ZZ6' }]
					}
				],
				groupOp: 'or',
				rules: []
			}
			return this.isPassengers ? filter : null
		},
		isCargo () {
			return this.model.bltypeid === 1
		},
		isPassengers () {
			return this.model.bltypeid === 2
		},
		isEmpty () {
			return this.model.bltypeid === 3
		}
	},
	watch: {
		'model.dockid' (newQuestion) {
			if (newQuestion != null) {
				this.dockSelected = true
			} else {
				this.dockSelected = false
				this.model.docklocationid = null
			}
		},
		'model.origincode' (newQuestion) {
			if (newQuestion != null) {
				this.originSelected = true
			} else {
				this.originSelected = false
			}
		},
		'model.loadcode' (newQuestion) {
			if (newQuestion != null) {
				this.loadcodeSelected = true
			} else {
				this.loadcodeSelected = false
			}
		},
		'model.transhipmentcode' (newQuestion) {
			if (newQuestion != null) {
				this.transhipmentcodeSelected = true
			} else {
				this.transhipmentcodeSelected = false
			}
		},
		'model.destinationcode' (newQuestion) {
			if (newQuestion != null) {
				this.destinationcodeSelected = true
			} else {
				this.destinationcodeSelected = false
			}
		}
	}
}
</script>
