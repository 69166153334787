<template>
	<div>
		<!-- Import CSV -->
		<v-dialog v-if="JSImportCSV.jointservice" v-model="JSImportCSV.open" persistent scrollable>
			<sdsmjointservice-import-csv
				:key="JSImportCSV.jointservice.jointserviceid"
				:jointservice="JSImportCSV.jointservice"
				:modelName="this.modelName"
				:isCreatingElement="true"
			></sdsmjointservice-import-csv>
		</v-dialog>

		<!-- Edit Bill of lading in a Modal from Joint Service screen -->
		<pui-modal-dialog-form
			scrollable
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.sdsmjointservice.editbillofloading.title')"
			:modelName="modelName"
			:dialogName="sdsmjointserviceEditBOLInModal.name"
			:okText="sdsmjointserviceEditBOLInModal.okText"
			:onOk="onOkEditBOLModal"
			:onShow="onShowEditBOLModal"
		>
			<template slot="message" slot-scope="data">
				<sdsmjointservice-form-for-quickedit
					:ref="sdsmjointserviceEditBOLInModal.refForm"
					:model="data.modalData"
				></sdsmjointservice-form-for-quickedit>
			</template>
		</pui-modal-dialog-form>

		<!-- Modal for BOL Massive Create -->
		<v-dialog v-if="massiveCreateModal.jointservice" v-model="massiveCreateModal.open" persistent scrollable>
			<sdsmbillofloading-massive-create
				:key="massiveCreateModal.jointservice.jointserviceid"
				:jointservice="massiveCreateModal.jointservice"
				:billofloading="massiveCreateModal.billofloading"
				:modelName="this.modelName"
				:isCreatingElement="true"
			></sdsmbillofloading-massive-create>
		</v-dialog>

		<!-- MassiveUpdate Action -->
		<pui-modal-dialog-form
			scrollable
			widthDialog="75%"
			:dialogName="sdsmJointserviceMassiveUpdateModal.name"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.sdsmjointservice.massiveedit.title')"
			:modelName="modelName"
			:onOk="onOkJointserviceMassiveUpdateModal"
			:okText="sdsmJointserviceMassiveUpdateModal.okText"
			:onShow="onShowJointserviceMassiveUpdateModal"
		>
			<template slot="message" slot-scope="data">
				<sdsmjointservice-form-for-massive-update
					:ref="sdsmJointserviceMassiveUpdateModal.refForm"
					:jointservices="data.modalData.jointservices"
				></sdsmjointservice-form-for-massive-update>
			</template>
		</pui-modal-dialog-form>

		<!-- Warnings modal -->
		<pui-modal-dialog-form
			scrollable
			widthDialog="30%"
			:dialogName="alertsWarningModal.name"
			modelName="sdsmjointservice"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.alertmodal.warnings')"
			:okText="alertsWarningModal.okText"
			:onShow="() => {}"
			:disableCancel="true"
		>
			<template slot="message" slot-scope="data">
				<sdsmjointservice-warnings :ref="alertsWarningModal.refForm" :alerts="data.modalData.alerts"></sdsmjointservice-warnings>
			</template>
		</pui-modal-dialog-form>

		<!-- Errors modal -->
		<pui-modal-dialog-form
			scrollable
			widthDialog="30%"
			:dialogName="alertsErrorModal.name"
			modelName="sdsmjointservice"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.alertmodal.errors')"
			:okText="alertsErrorModal.okText"
			:onShow="() => {}"
			:disableCancel="true"
		>
			<template slot="message" slot-scope="data">
				<sdsmjointservice-errors :ref="alertsErrorModal.refForm" :alerts="data.modalData.alerts"></sdsmjointservice-errors>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
import SdsmbillofloadingMassiveCreate from './../sdsmbillofloading/modalForms/SdsmbillofloadingMassiveCreate.vue';
import SdsmbillofloadingFormForQuickEditVue from '../sdsmbillofloading/modalForms/SdsmbillofloadingFormForQuickEdit.vue';
import { mapState } from 'vuex';
import SdsmjointserviceFormForMassiveUpdate from './modalForms/SdsmjointserviceFormForMassiveUpdate.vue';
import alertsWarningModal from '../general/PcsumHeader/alertWarningModal_new.vue';
import alertsErrorModal from '../general/PcsumHeader/alertErrorModal_new.vue';
import SdsmjointserviceImportCSV from './modalForms/SdsmjointserviceImportCSV.vue';
export default {
	name: 'sdsmjointservice-modals',
	components: {
		'sdsmjointservice-import-csv': SdsmjointserviceImportCSV,
		'sdsmjointservice-form-for-quickedit': SdsmbillofloadingFormForQuickEditVue,
		'sdsmbillofloading-massive-create': SdsmbillofloadingMassiveCreate,
		'sdsmjointservice-form-for-massive-update': SdsmjointserviceFormForMassiveUpdate,
		'sdsmjointservice-warnings': alertsWarningModal,
		'sdsmjointservice-errors': alertsErrorModal
	},
	computed: {
		...mapState({
			massiveCreateModal: (state) => state.modal.massiveCreate,
			JSImportCSV: (state) => state.modal.JSImportCSV
		})
	},
	data() {
		return {
			sdsmjointserviceEditBOLInModal: {
				refForm: 'refBOLForm',
				name: 'sdsmjointserviceEditBOLInModalAction',
				okText: this.$t('general.saveandfinish')
			},
			sdsmJointserviceMassiveUpdateModal: {
				okText: null,
				refForm: 'jointserviceFormMassiveUpdate',
				name: 'idJointserviceMassiveUpdateAction'
			},
			alertsWarningModal: {
				okText: this.$t('modal.alertmodal.close'),
				refForm: 'modalWarningAlerts',
				name: 'modalWarningAlerts'
			},
			alertsErrorModal: {
				okText: this.$t('modal.alertmodal.close'),
				refForm: 'modalErrorAlerts',
				name: 'modalErrorAlerts'
			}
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		/*************************/
		/* Edit Bill of lading */
		/***********************/
		async onOkEditBOLModal() {
			const billofloading = await this.$refs[this.sdsmjointserviceEditBOLInModal.refForm].Save();
			if (billofloading) {
				return new Promise((resolve) => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					// Refreshing List after updated the row -> reaching Parent
					setTimeout(() => {
						if (this.$parent.$refs.BOLS) {
							this.$parent.$refs.BOLS.EmitRefresh();
						}
					}, 1000);
					resolve(true);
				});
			}
		},
		onShowEditBOLModal() {},

		/*************************/
		/* Massive Update       */
		/***********************/

		async onOkJointserviceMassiveUpdateModal() {
			const completed = await this.$refs[this.sdsmJointserviceMassiveUpdateModal.refForm].SendJointserviceMassiveEdit();

			if (completed) {
				return new Promise((resolve) => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					resolve(true);
				});
			}
		},
		onShowJointserviceMassiveUpdateModal() {}
	}
};
</script>
