<template>
  <div class="PcsumCopyWizard">
	<!-- SETTING NUMBER OF COPIES -->
	<template v-if="wizard.step === 1">
		<pui-text-field
			v-model="wizard.copies"
			label="Nº de copias"
			toplabel
			maxlength="4"
			type="number"
			@keydown.enter="nextStep(null)"
		></pui-text-field>
	</template>

	<!-- FORM -->
	<template v-else-if="wizard.step === 2">
		<v-alert
			color="orange"
			dense
			text
			icon="far fa-copy"
			type="info"
			>
			{{ $t('general.copynumber') }} {{ wizard.copy }} <span class="totalCopies">/ {{ wizard.copies || 1 }}</span>
		</v-alert>
		<slot></slot>
	</template>

	<!-- ALL COPIES CREATED -->
	<template v-else>
		<div class="CopyWizardSuccess p-7">
			<v-icon
				x-large
				color="primary"
			>
				fas fa-check
			</v-icon>
			<div class="mt-7">{{ $t('general.copiesdone') }}</div>
		</div>
	</template>
  </div>
</template>

<script>
export default {
	name: 'copy-wizard',
	data() {
		return {
			wizard: null
		}
	},
	methods: {
		initWizard () {
			this.wizard = { step: 1, copy: 1, copies: 1, completed: false }
		},
		finishWizard () {
			this.wizard.completed = true
		},
		increaseCopy () {
			this.wizard.copy++
		},
		setStep(step) {
			this.wizard.step = step
		},
		async nextStep (saveMethod) {
			let item = null
			switch(this.wizard.step) {
				case 1:
					this.wizard.copies = this.wizard.copies <= 0 || isNaN(this.wizard.copies) ? 1 : this.wizard.copies
					this.setStep(2)
					break
				case 2:

					if (saveMethod) {
						item = await saveMethod()
						if (item) {
							if (this.wizard.copy < this.wizard.copies) {
								this.increaseCopy()
							} else {
								this.setStep(3)
							}
						}
					}
					break
				case 3:
					this.finishWizard()
			}
			this.setOkText()
			return this.wizard.completed
		},
		setOkText () {
			let text = null;
			const copies = parseInt(this.wizard.copies)
			switch (true) {
				case this.wizard.step === 1:
					text = this.$t('general.start');
					break;
				case this.wizard.step === 2 && this.wizard.copy < copies:
					text = this.$t('general.saveandnext');
					break;
				case this.wizard.step === 2 && this.wizard.copy === copies:
					text = this.$t('general.saveandfinish');
					break
				case this.wizard.step === 3:
				default:
					text = this.$t('general.finish');
					break;
			}
			this.$emit("EmitSetOkText", text)
		},
	},
	created () {
		this.initWizard()
		this.setOkText()
	}
}
</script>

<style>
.PcsumFormInModal .v-card__text {
	padding: 0px!important;
}
.PcsumFormInModal .divHeader {
	margin: 0px 0px 5px 0px!important;
}
.PcsumFormInModal .row.pui-form-layout {
	padding: 0px!important;
}
.PcsumFormInModal .row.pui-form-layout fieldset {
	margin-left: 0px!important;
	margin-right: 0px!important;
}
.PcsumFormInModal .v-card__actions {
	padding: 20px 24px 14px 20px!important;
}
.PcsumFormInModal .CopyWizardSuccess {
	padding: 20px;
	text-align: center;
}
.PcsumFormInModal .totalCopies {
	opacity: 0.5;
}

</style>
