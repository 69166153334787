const SdsmGoodUtils = {
	async getDDGG(ctx) {
		let ddgg = [];
		const params = {
			criterio: ' '
		};
		await ctx.$puiRequests.getRequest(
			'/sdsmgood/getDDGG',
			params,
			(response) => {
				ddgg = response.data;
			},
			() => {}
		);
		return ddgg;
	},
	async getTarCodes(ctx) {
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'mtaric',
				rows: null
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					ctx.$store.commit('M__TARIC', response.data.data);
				}
			},
			() => {}
		);
	},

	async filterCustomsituation(ctx) {
		let pack = [];

		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'mcustomssituation',
				rows: null
			},
			(response) => {
				pack = response.data.data;
			},
			() => {}
		);
		return pack;
	},
	async filterPackagetypeid(ctx) {
		let pack = [];

		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'mpackagetype',
				rows: null
			},
			(response) => {
				pack = response.data.data;
			},
			() => {}
		);
		return pack;
	},
	async getGoodByBlid(ctx, blid) {
		let eqs = [];
		if (blid) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmbillofloading',
					rows: null,
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'blid', op: 'eq', data: blid }]
					}
				},
				(response) => {
					eqs = response.data.data;
				},
				() => {}
			);
		}
		return eqs;
	},
	async getDocumentByid(ctx, documentid) {
		let doc = [];
		if (documentid) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmdocument',
					rows: null,
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'documentid', op: 'eq', data: documentid }]
					}
				},
				(response) => {
					doc = response.data.data;
				},
				() => {}
			);
		}
		return doc;
	},
	async getBLByJsid(ctx, jsid) {
		let doc = [];
		if (jsid) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmbillofloading',
					rows: null,
					filter: {
						groups: [
							{
								rules: [
									{ field: 'jointserviceid', op: 'eq', data: jsid },
									{ field: 'bltypeid', op: 'eq', data: 1 }
								]
							},
							{
								rules: [
									{ field: 'jointserviceid', op: 'eq', data: jsid },
									{ field: 'bltypeid', op: 'eq', data: 2 }
								]
							}
						],
						groupOp: 'or',
						rules: []
					}
				},
				(response) => {
					doc = response.data.data;
				},
				() => {}
			);
		}
		return doc;
	},
	compararfechas(fechainicio, fechafin) {
		const date1 = new Date(fechainicio);
		const date2 = new Date(fechafin);
		const fechaactual = new Date();
		let resultado = true;

		if (fechainicio && fechafin) {
			resultado = fechaactual >= date1 && fechaactual <= date2;
		}
		return resultado;
	},
	filterTarCodes(tarcodes, ispassengers, isCreating, reset) {
		let filtered = [];

		if (tarcodes && !isCreating) {
			if (!reset) {
				filtered = tarcodes.filter((t) => parseInt(t.ispassenger) === (ispassengers ? 1 : 0));
			} else {
				filtered = tarcodes.filter(
					(t) => parseInt(t.ispassenger) === (ispassengers ? 1 : 0) && this.compararfechas(t.effectivestartdate, t.effectiveenddate)
				);
			}
		} else if (tarcodes && isCreating) {
			//aqui tengo que filtrar por las fechas

			filtered = tarcodes.filter(
				(t) => parseInt(t.ispassenger) === (ispassengers ? 1 : 0) && this.compararfechas(t.effectivestartdate, t.effectiveenddate)
			);
		}

		return filtered;
	},
	checkVehBrandRequired(tariccod) {
		const codes = ['8702A', '8702B', '8703', '8704A']; // Use PUI_VARIABLE
		return codes.includes(tariccod);
	},
	async generateNextGoodOrderNumber(ctx, jointserviceid) {
		let ordernumber = null;
		await ctx.$puiRequests.getRequest(
			'/sdsmgood/generateNextGoodOrderNumber',
			{ jointserviceid },
			(response) => {
				ordernumber = response.data;
			},
			() => {}
		);
		return ordernumber;
	},
	async checkBeforeSaving(ctx, good, eqs, docs) {
		// Check form fields
		if (ctx.$refs.form?.validate && !ctx.$refs.form.validate()) {
			ctx.$puiNotify.error(ctx.$t('pui9.error.form_errors'));
			ctx.resetAfterSaveActions ? ctx.resetAfterSaveActions() : null;
			return false;
		}
		let valid = true;

		// Checking packages number
		if (good.packagesnumber === null || good.packagesnumber === '') {
			valid = false;
			ctx.$puiNotify.error(ctx.$t('errors.packagenumber'));
		}

		// Checking Equipments
		let packages = 0;
		for (let i = 0; i < eqs.length; i++) {
			// Checking duplicated UTI Identification
			if (eqs[i].utiidentification) {
				const exist = eqs.filter((e) => e.utiidentification === eqs[i].utiidentification);
				if (exist.length > 1) {
					valid = false;
					ctx.$puiNotify.error(ctx.$t('errors.eqduplicated'));
				}
			}
			// Calculating total packages number
			packages += parseInt(eqs[i].packagenumber);

			// Checking UTI Identification
			if (!eqs[i].utiidentification && eqs[i].fullfilliso === '1') {
				valid = false;
				ctx.$puiNotify.error(ctx.$t('errors.eqidentification'));
			}

			// Checking where packagenumber should be 0 (or not)
			if (
				(eqs[i].utistatusid == 4 && parseInt(eqs[i].packagenumber !== 0)) ||
				(eqs[i].utistatusid != 4 && parseInt(eqs[i].packagenumber) == 0)
			) {
				valid = false;
				ctx.$puiNotify.error(ctx.$t('errors.eqpackagenumberforstatus'));
			}

			if (!eqs[i].eqsizeid || !eqs[i].eqtypeid || !eqs[i].tare || !eqs[i].utistatusid) {
				valid = false;
				ctx.$puiNotify.error(ctx.$t('errors.eqfield'));
			}
		}
		// Checking total packages number
		if (packages > good.packagesnumber) {
			valid = false;
			ctx.$puiNotify.error(ctx.$t('errors.eqpackagenumber'));
		}

		// Documents
		for (let i = 0; i < docs.length; i++) {
			if (!docs[i].documentcstypeid || !docs[i].documentcsstatusid || !docs[i].documentnumber || !docs[i].documentdate) {
				valid = false;
				ctx.$puiNotify.error(ctx.$t('errors.docfield'));
			}
		}
		return valid;
	},

	async getGoodDocTypes(ctx) {
		let types = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'mdocumentcstype',
				rows: null,
				filter: {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: 'langtra', op: 'eq', data: ctx.$store.getters.getUserLanguage }]
						}
					],
					groupOp: 'and',
					rules: []
				}
			},
			(response) => {
				types = response.data.data;
			},
			() => {}
		);
		return types;
	},
	async getGoodDocStatus(ctx) {
		let status = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'mdocumentcsstatus',
				rows: null,
				filter: {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: 'langtra', op: 'eq', data: ctx.$store.getters.getUserLanguage }]
						}
					],
					groupOp: 'and',
					rules: []
				}
			},
			(response) => {
				status = response.data.data;
			},
			() => {}
		);
		return status;
	},
	/**************/
	/* Save Good */
	/************/
	async save(ctx, good, utis, docs, isCreating) {
		let data = null;
		let metodo = isCreating ? '/sdsmgood/save' : '/sdsmgood/saveUpd';
		good.utis = utis;
		good.docs = docs;
		await ctx.$puiRequests.postRequest(
			metodo,
			good,
			(response) => {
				data = response.data;
				ctx.$puiNotify.success(ctx.$t('general.success'));
			},
			(e) => {
				ctx.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		return data;
	},
	/*****************************/
	/* Update Good from Massive */
	/***************************/
	async massiveupdate(ctx, data, goodid) {
		let updated = false;
		let error = null;
		await ctx.$puiRequests.postRequest(
			'/sdsmgood/massiveupdate',
			{ data, goodid },
			() => {
				updated = true;
			},
			(e) => {
				error = e.response.data.message;
			},
			null,
			null
		);
		return { updated, error };
	},
	/*************/
	/* New Good */
	/***********/
	async newGood(ctx) {
		let good = null;
		await ctx.$puiRequests.getRequest(
			'/sdsmgood/template',
			null,
			(response) => {
				good = response.data;
			},
			() => {},
			null,
			null
		);
		return good;
	},
	/*****************/
	/* New Good Doc */
	/***************/
	async newDOC(ctx, data) {
		let doc = null;
		await ctx.$puiRequests.getRequest(
			'/sdsmdocumentcustoms/template',
			data,
			(response) => {
				doc = response.data;
			},
			() => {},
			null,
			null
		);
		return doc;
	},
	/************************/
	/* Delete Good <-> Uti */
	/**********************/
	async deleteGOODUTI(ctx, data) {
		let deleted = true;
		if (data.goodutiid) {
			deleted = false;
			const params = {
				goodutiid: data.goodutiid
			};
			await ctx.$puiRequests.deleteRequest(
				'/sdsmgooduti/delete',
				null,
				() => {
					deleted = true;
					ctx.$puiNotify.success(ctx.$t('general.utiremovefromgood'));
				},
				(e) => {
					ctx.$puiNotify.error(e.response.data.message);
				},
				params,
				null
			);
		}
		return deleted;
	},
	/********************/
	/* Delete Good Doc */
	/******************/
	async deleteDOC(ctx, data) {
		let deleted = true;
		if (data.documentcsid) {
			deleted = false;
			const params = {
				documentcsid: data.documentcsid
			};
			await ctx.$puiRequests.deleteRequest(
				'/sdsmdocumentcustoms/delete',
				null,
				() => {
					deleted = true;
					ctx.$puiNotify.success(ctx.$t('general.docremovefromgood'));
				},
				(e) => {
					ctx.$puiNotify.error(e.response.data.message);
				},
				params,
				null
			);
		}
		return deleted;
	},

	async getAlerts(ctx, pk) {
		let alerts = [];
		await ctx.$puiRequests.getRequest(
			'/sdsmgood/getAlerts',
			{ pk },
			(response) => {
				alerts = response.data;
			},
			() => {},
			null,
			null
		);
		return alerts;
	}
};

export default SdsmGoodUtils;
