<template>
	<div class="pui-form">
		<sdsmbillofloading-form-header
			v-if="headerData"
			:data="headerData"
			:goods="GoodsFromDoc"
			:eqs="EQsFromDoc"
			:roros="ROROsFromDoc"
			:params="{ isCreatingElement }"
		></sdsmbillofloading-form-header>
		<v-form class="mb-4 pb-4" ref="form" lazy-validation @submit.prevent>
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :title="$t('form.sdsmbillofloading.formtitle')">
						<v-row dense>
							<v-col cols="12 col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 pl-2 pr-2">
								<pui-select
									:id="`jointserviceid-billofloading`"
									:attach="`blid-sdsmgood`"
									:label="$t('sdsmbillofloading.jointservicenumber')"
									:disabled="true"
									toplabel
									modelName="sdsmjointservice"
									v-model="model"
									reactive
									clearable
									:itemsToSelect="[{ jointserviceid: this.model.jointserviceid }]"
									:modelFormMapping="{ jointserviceid: 'jointserviceid' }"
									:itemValue="['jointserviceid']"
									:itemTemplate="true"
									:itemText="''"
									:selectTemplate="true"
								>
									<template v-slot:selectionTemplate="{ props, item }">
										<div class="SlotOptionWithDetails" v-bind="props">
											{{ $t('sdsmbillofloading.jointserviceid') }} <span class="separator">:</span
											><span class="value">{{ item.jointserviceid || '0' }}</span
											><span class="separator">/</span>

											{{ $t('sdsmjointservice.document') }}<span class="separator">:</span>
											<span class="value">{{ item.documentnumber || '0' }}</span
											><span class="separator">/</span> {{ $t('sdsmbillofloading.jointservicenumber')
											}}<span class="separator">:</span> <span class="value">{{ item.jointservicenumber || '0' }} </span
											><span class="separator">/</span> {{ $t('sdsmbillofloading.cargooperator')
											}}<span class="separator">:</span> <span class="value">{{ item.operator || '0' }}</span>
											<span class="separator">/</span> {{ $t('sdsmbillofloading.callcode') }}<span class="separator">:</span>
											<span class="value">{{ item.callcode || '0' }} </span>
										</div>
									</template>
									<template v-slot:theTemplate="{ props, item }">
										<div class="SlotOptionWithDetails" v-bind="props">
											{{ $t('sdsmbillofloading.jointserviceid') }} <span class="separator">:</span
											><span class="value">{{ item.jointserviceid || '0' }}</span
											><span class="separator">/</span>

											{{ $t('sdsmjointservice.document') }}<span class="separator">:</span>
											<span class="value">{{ item.documentnumber || '0' }}</span
											><span class="separator">/</span> {{ $t('sdsmbillofloading.jointservicenumber')
											}}<span class="separator">:</span> <span class="value">{{ item.jointservicenumber || '0' }} </span
											><span class="separator">/</span> {{ $t('sdsmbillofloading.cargooperator')
											}}<span class="separator">:</span> <span class="value">{{ item.operator || '0' }}</span>
											<span class="separator">/</span> {{ $t('sdsmbillofloading.callcode') }}<span class="separator">:</span>
											<span class="value">{{ item.callcode || '0' }} </span>
										</div>
									</template>
								</pui-select>
							</v-col>
							<v-col class="12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
								<pui-text-field
									:id="`blnumber-sdsmbillofloading`"
									v-model="model.blnumber"
									:label="$t('sdsmbillofloading.blnumber')"
									:disabled="formDisabled"
									toplabel
									:required="isCargo"
								></pui-text-field>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pl-2 pr-2">
								<label class="customLabel v-label--required">{{ $t('sdsmbillofloading.origindes') }}</label>
								<v-autocomplete
									class="customSelector"
									outlined
									dense
									v-model="model.origincode"
									ref="origincodeRef"
									:loading="loading"
									:items="locinfo"
									:disabled="formDisabled"
									clearable
									:readonly="originSelected"
									:rules="[(v) => !!v || $t('requiredMessage')]"
									item-value="locode"
									:item-text="(item) => `${item.locode} - ${item.descripcion}`"
									@keyup="selectIfOnlyOneOptionorigincode"
								>
									<template v-slot:selection="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
									<template v-slot:item="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pl-2 pr-2">
								<label class="customLabel v-label--required">{{ $t('sdsmbillofloading.loaddes') }}</label>
								<v-autocomplete
									class="customSelector"
									outlined
									dense
									validate-on-blur
									v-model="model.loadcode"
									ref="loadcodeRef"
									:loading="loading"
									:items="locinfo"
									:disabled="formDisabled"
									:readonly="loadcodeSelected"
									clearable
									:rules="[(v) => !!v || $t('requiredMessage')]"
									item-value="locode"
									:item-text="model.loadcode ? `''` : (item) => `${item.locode} - ${item.descripcion}`"
									@keyup="selectIfOnlyOneOptionloadcode"
								>
									<template v-slot:selection="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
									<template v-slot:item="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pl-2 pr-2">
								<label class="customLabel">{{ $t('sdsmbillofloading.transhipmentdes') }}</label>
								<v-autocomplete
									class="customSelector"
									outlined
									dense
									v-model="model.transhipmentcode"
									:loading="loading"
									:items="locinfo"
									:disabled="formDisabled"
									:readonly="transhipmentcodeSelected"
									clearable
									validate-on-blur
									item-value="locode"
									:item-text="model.transhipmentcode ? `''` : (item) => `${item.locode} - ${item.descripcion}`"
									ref="transhipmentcode"
									@keyup="selectIfOnlyOneOptiontranshipmentcode"
								>
									<template v-slot:selection="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
									<template v-slot:item="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pl-2 pr-2">
								<label class="customLabel v-label--required">{{ $t('sdsmbillofloading.destinationdes') }}</label>
								<v-autocomplete
									class="customSelector"
									outlined
									dense
									v-model="model.destinationcode"
									:loading="loading"
									:items="locinfo"
									:disabled="formDisabled"
									clearable
									:readonly="destinationcodeSelected"
									:rules="[(v) => !!v || $t('requiredMessage')]"
									item-value="locode"
									:item-text="(item) => `${item.locode} - ${item.descripcion}`"
									ref="destinationcode"
									@keyup="selectIfOnlyOneOptiondestinationcode"
								>
									<template v-slot:selection="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
									<template v-slot:item="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
								<pui-select
									id="`bltypeid-sdsmbillofloading`"
									attach="bltypeid-sdsmbillofloading"
									:label="$t('sdsmbillofloading.bltypeid')"
									toplabel
									clearable
									required
									:disabled="true"
									v-model="model"
									modelName="vutbillofloadingtype"
									:modelFormMapping="{ bltypeid: 'bltypeid' }"
									:itemsToSelect="billtypeItemsToSelect"
									:itemValue="'bltypeid'"
									:itemText="(item) => `${item.description}`"
									:order="{ documentid: 'asc' }"
									detailComponentName="vutbillofloadingtype-form"
									singleItemAutoselect
								></pui-select>
							</v-col>
							<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
								<pui-select
									id="`goodtypeid-vutgoodtype`"
									attach="goodtypeid-sdsmbillofloading"
									:label="$t('sdsmbillofloading.goodtypeid')"
									toplabel
									clearable
									required
									v-model="model"
									:disabled="formDisabled"
									modelName="vutgoodtype"
									:modelFormMapping="{ goodtypeid: 'goodtypeid' }"
									:itemsToSelect="goodtypeItemsToSelect"
									:itemValue="'goodtypeid'"
									:itemText="(item) => `${item.description}`"
									:order="{ documentid: 'asc' }"
									detailComponentName="vutgoodtype-form"
									singleItemAutoselect
								></pui-select>
							</v-col>
							<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
								<pui-select
									id="`frontreartransid-mfrontreartransport`"
									attach="frontreartransid-sdsmbillofloading"
									:label="$t('sdsmbillofloading.frontreartransid')"
									toplabel
									clearable
									required
									v-model="model"
									:disabled="formDisabled"
									modelName="vutmfrontreartransporttra"
									:modelFormMapping="{ frontreartransid: 'frontreartransid' }"
									:itemsToSelect="frontreartransidItemsToSelect"
									:itemValue="'frontreartransid'"
									:itemText="(item) => `${item.description}`"
									:order="{ documentid: 'asc' }"
									detailComponentName="mfrontreartransport-form"
									:fixedFilter="FilterTransport"
									singleItemAutoselect
								></pui-select>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
								<pui-select
									id="`stevedoreid-sdsmstevedore`"
									attach="stevedoreid-sdsmbillofloading"
									:label="$t('sdsmbillofloading.stevedoreid')"
									toplabel
									clearable
									v-model="model"
									:disabled="formDisabled"
									modelName="sdsmstevedore"
									:modelFormMapping="{ stevedoreid: 'stevedoreid' }"
									:itemsToSelect="stevedoreItemsToSelect"
									:itemValue="'stevedoreid'"
									:itemText="(item) => `${item.description}`"
									:order="{ documentid: 'asc' }"
									detailComponentName="sdsmstevedore-form"
								></pui-select>
							</v-col>
							<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
								<pui-select
									id="`dockid-sdsmjointservice`"
									attach="dockid-sdsmjointservice"
									:label="$t('sdsmbillofloading.dockid')"
									toplabel
									clearable
									:disabled="formDisabled"
									v-model="model"
									modelName="mdock"
									:modelFormMapping="{ dockid: 'dockid' }"
									:itemsToSelect="dockItemsToSelect"
									:itemValue="'dockid'"
									:itemText="(item) => `${item.description}`"
									:order="{ dockid: 'asc' }"
								></pui-select>
							</v-col>
							<!-- COMCODE -->
							<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
								<pui-select
									id="`docklocationid-sdsmjointservice`"
									attach="docklocationid-sdsmjointservice"
									:label="$t('sdsmbillofloading.docklocationid')"
									toplabel
									clearable
									:disabled="formDisabled"
									v-model="model"
									modelName="mdockdocklocation"
									:modelFormMapping="{ docklocationid: 'docklocationid' }"
									:itemsToSelect="docklocationItemsToSelect"
									:itemValue="['docklocationid']"
									:itemText="(item) => `${item.docklocation}`"
									:order="{ docklocationid: 'asc' }"
									:fixedFilter="fixedFilterDock"
									singleItemAutoselect
								></pui-select>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
								<pui-text-field
									:id="`goodservice-sdsmbillofloading`"
									v-model="model.goodservice"
									:label="$t('sdsmbillofloading.goodservice')"
									:disabled="formDisabled"
									toplabel
									maxlength="35"
								></pui-text-field>
							</v-col>
							<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
								<pui-text-field
									:id="`comcode-sdsmbillofloading`"
									v-model="model.comcode"
									:label="$t('sdsmbillofloading.comcode')"
									:disabled="formDisabled"
									toplabel
									maxlength="35"
								></pui-text-field>
							</v-col>
							<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
								<pui-text-field
									:id="`shipimo-sdsmbillofloading`"
									v-model="model.shipimo"
									:label="$t('sdsmbillofloading.shipimo')"
									:disabled="formDisabled"
									toplabel
									maxlength="35"
								></pui-text-field>
							</v-col>
							<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
								<pui-text-field
									:id="`manipulationterminal-sdsmbillofloading`"
									v-model="model.manipulationterminal"
									:label="$t('sdsmbillofloading.manipulationterminal')"
									:disabled="formDisabled"
									toplabel
									maxlength="35"
								></pui-text-field>
							</v-col>
						</v-row>
					</pui-field-set>
					<pui-field-set :title="$t('sdsmbillofloading.table')">
						<v-row dense>
							<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
								<pui-text-field
									:id="`dsmcfrontrear-sdsmbillofloading`"
									v-model="model.dsmcfrontrear"
									:label="$t('sdsmbillofloading.dsmcfrontrear')"
									:disabled="formDisabled"
									toplabel
									maxlength="35"
								></pui-text-field>
							</v-col>
							<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
								<pui-text-field
									:id="`ordernumberfrontrear-sdsmbillofloading`"
									v-model="model.ordernumberfrontrear"
									:label="$t('sdsmbillofloading.ordernumberfrontrear')"
									:disabled="formDisabled"
									toplabel
									maxlength="35"
								></pui-text-field>
							</v-col>
						</v-row>
					</pui-field-set>
				</v-col>
			</v-row>
		</v-form>
	</div>
</template>

<script>
import SdsmbillofloadingUtils from './../SdsmbillofloadingUtils';
import { mapState } from 'vuex';
import { EntityStatusUtils } from '../../../utils/entityStates';
export default {
	name: 'sdsmbillofloading-form-for-quickedit',

	props: {
		model: { type: Object, default: () => {} }
	},
	data() {
		return {
			loading: false,
			ready: false,
			originSelected: false,
			loadcodeSelected: false,
			transhipmentcodeSelected: false,
			destinationcodeSelected: false,
			headerData: null,
			GoodsFromDoc: null,
			EQsFromDoc: null,
			ROROsFromDoc: null,
			isCreatingElement: false,
			formDisabled: false
		};
	},
	methods: {
		selectIfOnlyOneOptionorigincode(event) {
			if (event.key == 'Backspace' && this.model.origincode != null) {
				this.model.origincode = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.locinfo.filter((item) => {
				return item.locode.toLowerCase().includes(text.toLowerCase());
			});
			if (resultadosFiltrados.length == 1) {
				this.model.origincode = resultadosFiltrados[0].locode;

				this.$refs.origincodeRef.isMenuActive = false;
			}
		},
		selectIfOnlyOneOptionloadcode(event) {
			if (event.key == 'Backspace' && this.model.loadcode != null) {
				this.model.loadcode = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.locinfo.filter((item) => {
				return item.locode.toLowerCase().includes(text.toLowerCase());
			});
			if (resultadosFiltrados.length == 1) {
				this.model.loadcode = resultadosFiltrados[0].locode;

				this.$refs.loadcodeRef.isMenuActive = false;
			}
		},
		selectIfOnlyOneOptiontranshipmentcode(event) {
			if (event.key == 'Backspace' && this.model.transhipmentcode != null) {
				this.model.transhipmentcode = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.locinfo.filter((item) => {
				return item.locode.toLowerCase().includes(text.toLowerCase());
			});
			if (resultadosFiltrados.length == 1) {
				this.model.transhipmentcode = resultadosFiltrados[0].locode;

				this.$refs.transhipmentcode.isMenuActive = false;
			}
		},
		selectIfOnlyOneOptiondestinationcode(event) {
			if (event.key == 'Backspace' && this.model.destinationcode != null) {
				this.model.destinationcode = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.locinfo.filter((item) => {
				return item.locode.toLowerCase().includes(text.toLowerCase());
			});
			if (resultadosFiltrados.length == 1) {
				this.model.destinationcode = resultadosFiltrados[0].locode;

				this.$refs.destinationcode.isMenuActive = false;
			}
		},
		WatchOriginCode(newValue) {
			this.model.origindes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo);
		},
		WatchLoadCode(newValue) {
			this.model.loaddes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo);
		},
		WatchTranshipmentCode(newValue) {
			this.model.transhipmentdes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo);
		},
		WatchDestinationCode(newValue) {
			this.model.destinationdes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo);
		},
		async Save() {
			let bol = await SdsmbillofloadingUtils.save(this, this.model);
			return bol;
		}
	},
	computed: {
		...mapState({
			locinfo: (state) => state.PCS.locinfo
		}),
		jointserviceItemsToSelect() {
			return [{ jointserviceid: this.model.jointserviceid }];
		},
		origincodeItemsToSelect() {
			return [{ origincode: this.model.origincode }];
		},
		stevedoreItemsToSelect() {
			return [{ stevedoreid: this.model.stevedoreid }];
		},
		billtypeItemsToSelect() {
			return [{ bltypeid: this.model.bltypeid }];
		},
		goodtypeItemsToSelect() {
			return [{ goodtypeid: this.model.goodtypeid }];
		},
		frontreartransidItemsToSelect() {
			return [{ frontreartransid: this.model.frontreartransid }];
		},
		dockItemsToSelect() {
			return [{ dockid: this.model.dockid }];
		},
		docklocationItemsToSelect() {
			return [{ docklocationid: this.model.docklocationid }];
		},
		fixedFilterDock() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'dockid', op: 'eq', data: this.model.dockid },
							{ field: 'dateexpiration', op: 'nu' }
						]
					}
				],
				groupOp: 'and',
				rules: []
			};
		},
		FilterTransport() {
			const filter = {
				groups: [
					{
						rules: [{ field: 'edicode', op: 'eq', data: 'ZZ1' }]
					},
					{
						rules: [{ field: 'edicode', op: 'eq', data: 'ZZ2' }]
					},
					{
						rules: [{ field: 'edicode', op: 'eq', data: 'ZZ6' }]
					}
				],
				groupOp: 'or',
				rules: []
			};
			return this.isPassengers ? filter : null;
		},
		isCargo() {
			return this.model.bltypeid === 1;
		},
		isPassengers() {
			return this.model.bltypeid === 2;
		},
		isEmpty() {
			return this.model.bltypeid === 3;
		}
	},
	async created() {
		this.formDisabled = !EntityStatusUtils.checkEditionAvailable(this.model.blstateid);
		this.model.creauser = this.model.usralta;
		this.model.creadate = this.model.fecalta;
		this.model.moduser = this.model.usrmodif;
		this.model.moddate = this.model.fecmodif;
		this.loading = true;
		this.$watch('model.origincode', this.WatchOriginCode);
		this.$watch('model.loadcode', this.WatchLoadCode);
		this.$watch('model.transhipmentcode', this.WatchTranshipmentCode);
		this.$watch('model.destinationcode', this.WatchDestinationCode);
		await SdsmbillofloadingUtils.getLocInfo(this);
		this.GoodsFromDoc = await SdsmbillofloadingUtils.getGoodsStats(this, this.model);
		this.EQsFromDoc = await SdsmbillofloadingUtils.getUtisStats(this, this.model);
		this.ROROsFromDoc = await SdsmbillofloadingUtils.getUtisByBL(this, this.model);
		this.loading = false;

		await this.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'vhdbillofloading',
				filter: {
					groups: [
						{
							groupOp: 'and',
							rules: [
								{ field: 'jointserviceid', op: 'eq', data: this.model.jointserviceid },
								{ field: 'langtra', op: 'eq', data: this.$store.getters.getUserLanguage }
							]
						}
					],
					groupOp: 'and',
					rules: []
				}
			},
			(responsee) => {
				if (responsee?.data?.data && responsee.data.data.length > 0) {
					// puede o no tener el joint asociado
					responsee.data.data.forEach((element) => {
						if (element.blid == this.model.blid) {
							this.headerData = element;
						}
					});
					//si no ha sido asignado, asignarlo
					if (this.headerData == null) {
						this.headerData = responsee.data.data[0];
					}
				}
			}
		);
	},
	watch: {
		'model.origincode'(newQuestion) {
			if (newQuestion != null) {
				this.originSelected = true;
			} else {
				this.originSelected = false;
			}
		},
		'model.loadcode'(newQuestion) {
			if (newQuestion != null) {
				this.loadcodeSelected = true;
			} else {
				this.loadcodeSelected = false;
			}
		},
		'model.transhipmentcode'(newQuestion) {
			if (newQuestion != null) {
				this.transhipmentcodeSelected = true;
			} else {
				this.transhipmentcodeSelected = false;
			}
		},
		'model.destinationcode'(newQuestion) {
			if (newQuestion != null) {
				this.destinationcodeSelected = true;
			} else {
				this.destinationcodeSelected = false;
			}
		},
		async 'model.jointserviceid'(newQuestion) {
			if (newQuestion != null) {
				this.documentSelected = newQuestion;
			} else {
				this.documentSelected = null;
			}
			this.headerData = null;
			await this.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'vhdbillofloading',
					filter: {
						groups: [
							{
								groupOp: 'and',
								rules: [
									{ field: 'jointserviceid', op: 'eq', data: newQuestion },
									{ field: 'langtra', op: 'eq', data: this.$store.getters.getUserLanguage }
								]
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(responsee) => {
					if (responsee?.data?.data && responsee.data.data.length > 0) {
						// puede o no tener el joint asociado
						responsee.data.data.forEach((element) => {
							if (element.blid == this.model.blid) {
								this.headerData = element;
							}
						});
						//si no ha sido asignado, asignarlo
						if (this.headerData == null) {
							this.headerData = responsee.data.data[0];
						}
					}
				}
			);

			this.orderNumber = await this.getOrdernumberProv();
		}
	}
};
</script>
