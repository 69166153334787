const SdsmjointserviceUtils = {
	checkBeforeSaving(ctx) {
		// Check form fields
		if (ctx.$refs.form && ctx.$refs.form.validate && !ctx.$refs.form.validate()) {
			ctx.$puiNotify.error(ctx.$t('pui9.error.form_errors'));
			ctx.resetAfterSaveActions ? ctx.resetAfterSaveActions() : null;
			return false;
		}
		let valid = true;
		return valid;
	},
	async getJointServices(ctx, filter) {
		let JSs = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmjointservice',
				rows: null,
				filter
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					JSs = response.data.data;
				}
			},
			() => {}
		);
		return JSs;
	},
	getFixedFilterOperator(model, isCreatingElement) {
		// Filter for new elements
		const filterCreate = {
			groups: [
				{
					groupOp: 'and',
					rules: [{ field: 'dateexpiration', op: 'nu' }]
				}
			],
			groupOp: 'and',
			rules: []
		};
		// Filter for editing element
		const filterEdit = {
			groups: [
				{
					groupOp: 'and',
					rules: [{ field: 'dateexpiration', op: 'nu' }]
				}
				/*{
					groupOp: 'or',
					rules: [{ field: 'operatorid', op: 'eq', data: model.operatorid }]
				} */
			],
			groupOp: 'or',
			rules: []
		};

		return isCreatingElement ? filterCreate : filterEdit;
	},
	getFixedFilterDock(model, isCreatingElement) {
		// Filter for new elements
		const filterNew = {
			groups: [
				{
					groupOp: 'and',
					rules: [{ field: 'dateexpiration', op: 'nu' }]
				}
			],
			groupOp: 'and',
			rules: []
		};
		// Filter for editing element
		const filterEdit = {
			groups: [
				{
					groupOp: 'and',
					rules: [{ field: 'dateexpiration', op: 'nu' }]
				}
				/* {
					groupOp: 'or',
					rules: [{ field: 'dockid', op: 'eq', data: model.dockid }]
				} */
			],
			groupOp: 'and',
			rules: []
		};
		return isCreatingElement ? filterNew : filterEdit;
	},
	getFixedFilterDockLocation(model, isCreatingElement) {
		// Filter for new elements
		const filterNew = {
			groups: [
				{
					groupOp: 'and',
					rules: [{ field: 'dateexpiration', op: 'nu' }]
				}
			],
			groupOp: 'and',
			rules: []
		};
		// Filter for editing element
		const filterEdit = {
			groups: [
				{
					groupOp: 'and',
					rules: [{ field: 'dateexpiration', op: 'nu' }]
				}
				/* {
					groupOp: 'or',
					rules: [{ field: 'docklocationid', op: 'eq', data: model.docklocationid }]
				} */
			],
			groupOp: 'and',
			rules: []
		};
		return isCreatingElement ? filterNew : filterEdit;
	},
	/*************************/
	/* Insert Joint Service */
	/***********************/
	/* async insertJointService(ctx, data) {
		let jointservice = null;
		await ctx.$puiRequests.postRequest(
			'/sdsmjointservice/insert',
			data,
			(response) => {
				jointservice = response.data;
			},
			(e) => {
				ctx.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		return jointservice;
	}, */
	/***********************/
	/* Save Joint Service */
	/*********************/
	async saveJointService(ctx, data, isCreating) {
		let jointservice = null;
		let metodo = isCreating ? '/sdsmjointservice/save' : '/sdsmjointservice/saveUpd';
		await ctx.$puiRequests.postRequest(
			metodo,
			data,
			(response) => {
				jointservice = response.data;
				ctx.$puiNotify.success(ctx.$t('general.success'));
			},
			(e) => {
				ctx.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		return jointservice;
	},
	/*************************/
	/* Update Joint Service */
	/***********************/
	/* async updateJointService(ctx, data) {
		let jointservice = null;
		await ctx.$puiRequests.putRequest(
			'/sdsmjointservice/update',
			data,
			(response) => {
				jointservice = response.data;
			},
			(e) => {
				ctx.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		return jointservice;
	}, */
	/*************************/
	/* Delete Joint Service */
	/***********************/
	async deleteJointService(ctx, data) {
		let deleted = true;
		if (data.jointserviceid) {
			deleted = false;
			const params = {
				jointserviceid: data.jointserviceid
			};
			await ctx.$puiRequests.deleteRequest(
				'/sdsmjointservice/delete',
				null,
				() => {
					deleted = true;
					ctx.$puiNotify.success(ctx.$t('general.remove'));
				},
				(e) => {
					ctx.$puiNotify.error(e.response.data.message);
				},
				params,
				null
			);
		}
		return deleted;
	},
	async getBLsStats(ctx, model) {
		let BLS = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'vhdcjsbltype',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'jointserviceid', op: 'eq', data: model.jointserviceid }]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					BLS = response.data.data;
				}
			},
			() => {}
		);
		return BLS;
	},
	async getGoodsStats(ctx, model) {
		let goods = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'vhdcjsdocweight',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'jointserviceid', op: 'eq', data: model.jointserviceid }]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					goods = response.data.data;
				}
			},
			() => {}
		);
		return goods;
	},
	async getUtisStats(ctx, model) {
		let bls = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmbillofloading',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'jointserviceid', op: 'eq', data: model.jointserviceid }]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					bls = response.data.data;
				}
			},
			() => {}
		);

		let blids = [];
		bls.forEach((element) => {
			blids.push(element.blid);
		});

		let eqs = [];
		if (bls.length > 0) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'vhdcblutifromdoc',
					rows: null,
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'blid', op: 'in', data: blids }]
						//rules: [{ field: 'blid', op: 'in', data: blids }]
					}
				},
				(response) => {
					if (response.data && response.data.totalRecords > 0) {
						eqs = response.data.data;
					}
				},
				() => {}
			);
		}
		return eqs;
	},
	async getUtisByBL(ctx, model) {
		let bls = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmbillofloading',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'jointserviceid', op: 'eq', data: model.jointserviceid }]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords >= 0) {
					bls = response.data.data;
				}
			},
			() => {}
		);

		let blids = [];
		bls.forEach((element) => {
			blids.push(element.blid);
		});

		let voids = [];
		if (bls.length > 0) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmuti',
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'blid', op: 'in', data: blids }]
					}
				},
				(response) => {
					if (response.data && response.data.totalRecords >= 0) {
						voids = response.data.data;
					}
				},
				() => {}
			);
		}
		return voids;
	},
	async getROROsStats(ctx, model) {
		let eqs = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'vhdcjsrorofromdoc',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'jointserviceid', op: 'eq', data: model.jointserviceid }]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					eqs = response.data.data;
				}
			},
			() => {}
		);
		return eqs;
	},

	async getItemsHeader(ctx) {
		let JSs = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmdocument',
				rows: null
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					JSs = response.data.data;
				}
			},
			() => {}
		);
		return JSs;
	},

	async getOperator(ctx, model, opid) {
		let str = [false, false, false];

		if (opid == null) {
			str[0] = true;
			return str;
		}

		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmdocument',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'operatorid', op: 'eq', data: opid },
						{ field: 'documentid', op: 'eq', data: model.documentid }
					]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					//es operador de buque
					str[1] = true;
				}
			},
			() => {}
		);
		if (model.operatorid == opid) {
			str[2] = true;
		}

		return str;
	},

	/****************************/
	/* Update js from Massive */
	/**************************/
	async massiveupdate(ctx, data, jointserviceid) {
		let updated = false;
		let error = null;
		await ctx.$puiRequests.postRequest(
			'/sdsmjointservice/massiveupdate',
			{ data, jointserviceid },
			() => {
				updated = true;
			},
			(e) => {
				error = e.response.data.message;
			},
			null,
			null
		);
		return { updated, error };
	},
	/************/
	/* New JS */
	/**********/
	async newJS(ctx, data) {
		let eq = null;
		await ctx.$puiRequests.getRequest(
			'/sdsmjointservice/template',
			data,
			(response) => {
				eq = response.data;
			},
			() => {},
			null,
			null
		);
		return eq;
	},

	async getAlerts(ctx, pk) {
		let alerts = [];
		await ctx.$puiRequests.getRequest(
			'/sdsmjointservice/getAlerts',
			{ pk },
			(response) => {
				alerts = response.data;
			},
			() => {},
			null,
			null
		);
		return alerts;
	}
};

export default SdsmjointserviceUtils;
