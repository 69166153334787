<template>
	<div class="mcountryFormHeader puiformheader">
		<v-row>
			<v-col cols="3">
				<pui-field :label="$t('header.mcountry.countrycode')" :value="getCountryCode"></pui-field>
			</v-col>
			<v-col cols="9">
				<pui-field :label="$t('header.mcountry.description')" :value="getDescription"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mcountry-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mcountry'
		};
	},
	computed: {
		getDescription() {
			return this.model && this.model.description ? this.model.description : '0';
		},
		getCountryCode() {
			return this.model && this.model.countrycode ? this.model.countrycode : '0';
		}
	}
};
</script>
