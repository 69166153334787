<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:actions="actions"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:showDeleteBtn="false"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
		<vutmessagegood-modals :modelName="modelName"></vutmessagegood-modals>
	</div>
</template>

<script>
import vutmessagegoodActions from './VutmessagegoodActions';
import vutmessagegoodModals from './VutmessagegoodModals.vue';
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixinAccess';

export default {
	name: 'vutmessagegood-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		'vutmessagegood-modals': vutmessagegoodModals
	},
	data() {
		return {
			modelName: 'vutmessagegood',
			actions: vutmessagegoodActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
