export class EntityStatusUtils {
	/**
	 * Status where edition / deletion is allowed:
	 * 1: Pending
	 * 3: Creation accepted by customs
	 * 4: Message refused because of errors
	 * 5: Updated
	 * 7: Update refused by customs
	 * 8: Update accepted by customs
	 */
	static editStates = [1, 3, 4, 5, 7, 8, 11];

	/**
	 * Status where sending is allowed:
	 * 1: Pending
	 * 4: Message refused errors
	 * 5: Updated
	 * 7: Update refused customs
	 * 9: Deleted
	 * 11: Delete refused customs
	 */
	static sendStates = [1, 4, 5, 7, 9, 11];

	/**
	 * Method for checking if status allows edition
	 * @param {*} state
	 * @returns
	 */
	static checkEditionAvailable(state) {
		return !state || this.editStates.includes(state);
	}

	/**
	 * Method for checking if status allows sending
	 * @param {*} state
	 * @returns
	 */
	static checkSendAvailable(state) {
		return this.sendStates.includes(state);
	}
}
