<template>
	<div class="divHeader">
		<pcsum-header :data="billOfLoadingHeaderData" :params="{}"></pcsum-header>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin'
import PcsumHeader from '../general/PcsumHeader'

export default {
	name: 'sdsmuti-form-header',
	mixins: [PuiFormHeaderMixin],
	components: {
		'pcsum-header': PcsumHeader
	},
	props: {
		data: { type: Object, default: () => {} },
		params: { type: Object, default: () => {} }
	},
	data () {
		return {
			modelName: 'sdsmuti'
		}
	},
	methods: {
		blnumberComputed () {
			if (this.data.blnumber) {
				return this.data.ordernumber + '0' + this.data.blnumber
			} else {
				return this.data.ordernumber
			}
		}
	},
	computed: {
		billOfLoadingHeaderData () {
			return this.data
				? [
						{ label: 'header.sdsmgood.documentnumber', value: this.data.documentnumber },

						{ label: 'header.sdsmgood.cargooperator', value: this.data.operator, showId: true, id: this.data.operatorid },

						{ label: 'header.sdsmgood.jointservicenumber', value: this.data.jointservicenumber },
						{ label: 'header.sdsmgood.billofloadingtype', value: this.data.bltype },
						{ label: 'header.sdsmgood.blnumber', value: this.blnumberComputed() },
						{ label: 'header.sdsmgood.jointservicestatus', value: this.data.jsstate, pill: true },
						{ label: 'header.sdsmgood.billofloadingstatus', value: this.data.blstate, pill: true }
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  ]
				: null
		}
	}
}
</script>
