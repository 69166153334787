<template>
	<div class="pui-form">
		<sdsmdocument-modals :modelName="modelName"></sdsmdocument-modals>

		<!-- Headers if editing -->
		<template v-if="!isCreatingElement">
			<sdsmdocument-form-header v-if="modelLoaded" :data="model" :alerts="alerts" :params="{}"></sdsmdocument-form-header>
		</template>

		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-tabs v-model="tabmodel" class="ml-4 mb-4 mr-4 mt-4" slider-color="primary" show-arrows>
					<v-tab :key="0" :href="'#maintab'">{{ $t('form.sdsmdocument.formtitle') }}</v-tab>
					<v-tab :key="1" :href="'#sdsmjointservice'" v-if="!isCreatingElement">{{ $t('form.sdsmdocument.section') }}</v-tab>
					<v-tab :key="2" :href="'#sdsmbillofloading'" v-if="!isCreatingElement">{{ $t('form.sdsmdocument.sdsmbillofloading') }}</v-tab>
					<v-tab :key="3" :href="'#sdsmuti'" v-if="!isCreatingElement">{{ $t('form.sdsmdocument.sdsmuti') }}</v-tab>
					<v-tab :key="4" :href="'#sdsmgoods'" v-if="!isCreatingElement">{{ $t('form.sdsmdocument.sdsmgood') }}</v-tab>
					<v-tab :key="5" :href="'#vutmessagedoc'" v-if="!isCreatingElement">{{ $t('form.sdsmmessage.formtitle') }}</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tabmodel" style="width: 100% !important">
					<v-tab-item :key="0" :value="'maintab'">
						<v-col cols="12">
							<pui-field-set :title="$t('form.sdsmdocument.formtitle')">
								<v-row dense align="center">
									<!-- DOCTYPEID -->
									<v-col cols="4 pl-2">
										<v-radio-group v-model="model.doctypeid" :disabled="formDisabled" row style="position: relative; top: 4px">
											<v-radio :label="$t('sdsmdocument.documenttype.upload')" :value="1"></v-radio>
											<v-radio :label="$t('sdsmdocument.documenttype.download')" :value="2"></v-radio>
										</v-radio-group>
									</v-col>

									<!-- DOCUMENTNUMBER -->
									<v-col cols="2 offset-sm-6 offset-md-6 offset-lg-6 offset-xl-6">
										<pui-text-field
											:id="`documentnumber-sdsmdocument`"
											v-model="model.documentnumber"
											:label="$t('sdsmdocument.documentnumber')"
											disabled
											toplabel
											maxlength="15"
										></pui-text-field>
									</v-col>
								</v-row>
								<v-row dense>
									<!-- PORTID -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
										<pui-select
											id="portid-sdsmdocument"
											attach="portid-sdsmdocument"
											:label="$t('sdsmdocument.port')"
											toplabel
											clearable
											required
											reactive
											singleItemAutoselect
											v-model="model"
											modelName="mlocalport"
											:disabled="formDisabled"
											:modelFormMapping="{ portid: 'portid' }"
											:itemsToSelect="portItemsToSelect"
											:itemValue="['portid']"
											:queryFields="['description']"
											:itemText="(item) => `${item.description}`"
											:order="{ description: 'asc' }"
											:fixedFilter="filterPortid"
										></pui-select>
									</v-col>
									<!-- OPERATORID -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-5 col-xl-5">
										<pui-select
											id="operatorid-sdsmdocument"
											attach="operatorid"
											:label="$t('sdsmdocument.operator')"
											toplabel
											clearable
											required
											singleItemAutoselect
											v-model="model"
											modelName="sdsmoperator"
											:disabled="formDisabled"
											:modelFormMapping="{ operatorid: 'operatorid' }"
											:itemsToSelect="[{ operatorid: model.operatorid }]"
											:itemValue="['operatorid']"
											:queryFields="['description']"
											:itemText="(item) => `${item.description}`"
											:order="{ operatorid: 'asc' }"
											:fixedFilter="getDateExpirationFilter"
										></pui-select>
									</v-col>

									<!-- CALLYEAR -->
									<v-col cols="12 col-xs-12 col-sm-2 col-md-2 col-lg-1 col-xl-1">
										<pui-number-field
											:id="`callyear-sdsmdocument`"
											v-model="model.callyear"
											:label="$t('sdsmdocument.callyear')"
											:disabled="formDisabled"
											required
											toplabel
											integer
											min="0"
											max="9999"
										></pui-number-field>
									</v-col>
									<!-- CALLNUMBER -->
									<v-col cols="12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
										<template v-if="model.callnumber || loadingEscalas">
											<div class="customInputWithIcon">
												<pui-text-field
													:id="`callnumber-sdsmdocument`"
													v-model="model.callnumber"
													:label="$t('sdsmdocument.callnumber')"
													toplabel
													disabled
													required
													:loading="loadingEscalas"
													maxlength="120"
												>
												</pui-text-field>
												<v-btn text :disabled="formDisabled" plain @click="cleanCallNumber()"
													><i class="fas fa-edit"></i
												></v-btn>
											</div>
										</template>
										<template v-else>
											<div style="padding: 2px 5px">
												<label class="customLabel v-label--required">{{ $t('sdsmdocument.callnumber') }}</label>
												<v-autocomplete
													class="customSelector"
													outlined
													required
													dense
													:rules="[(v) => !!v || $t('requiredMessage')]"
													:key="callnumberKey"
													v-model="model.callnumber"
													:loading="loading"
													:items="escalas"
													:disabled="formDisabled || loadingEscalas"
													clearable
													item-value="numescala"
													:item-text="(item) => `${item.numescala}`"
													height="39px"
												>
												</v-autocomplete>
											</div>
										</template>
									</v-col>
								</v-row>
								<v-row dense>
									<!-- CUSTOMSID -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3">
										<pui-select
											id="customsid"
											attach="customsid"
											:label="$t('sdsmdocument.customsdes')"
											toplabel
											disabled
											reactive
											v-model="model"
											modelName="sdsmcustoms"
											:modelFormMapping="{ customsid: 'customsid' }"
											:itemsToSelect="[{ customsid: model.customsid }]"
											:itemValue="['customsid']"
											:queryFields="['code', 'description']"
											:itemText="(item) => `${item.code} - ${item.description}`"
											:fixedFilter="filterCustomsid"
										></pui-select>
									</v-col>
									<!-- CALLLASTPORT -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`calllastport-sdsmdocument`"
											v-model="model.calllastport"
											:label="$t('sdsmdocument.calllastport')"
											disabled
											toplabel
											maxlength="120"
										></pui-text-field>
									</v-col>
									<!-- CALLNEXTPORT -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`callnextport-sdsmdocument`"
											v-model="model.callnextport"
											:label="$t('sdsmdocument.callnextport')"
											disabled
											toplabel
											maxlength="120"
										></pui-text-field>
									</v-col>
									<!-- SHIPNAME -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2">
										<pui-text-field
											:id="`shipname-sdsmdocument`"
											v-model="model.shipname"
											:label="$t('sdsmdocument.shipname')"
											disabled
											toplabel
											maxlength="17"
										></pui-text-field>
									</v-col>
									<!-- SHIPIMO -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-1 col-xl-1">
										<pui-text-field
											:id="`shipimo-sdsmdocument`"
											v-model="model.shipimo"
											:label="$t('sdsmdocument.shipimo')"
											disabled
											toplabel
											maxlength="9"
										></pui-text-field>
									</v-col>
									<!-- SHIPFLAG -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-1 col-xl-1">
										<pui-text-field
											:id="`shipflag-sdsmdocument`"
											v-model="model.shipflag"
											:label="$t('sdsmdocument.shipflag')"
											disabled
											toplabel
											maxlength="3"
										></pui-text-field>
									</v-col>
									<!-- CALLFIRSTENTRY -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2">
										<pui-select
											id="callfirstentry"
											attach="callfirstentry"
											:label="$t('sdsmdocument.callfirstentry')"
											toplabel
											reactive
											:disabled="formDisabled"
											v-model="model"
											modelName="mcountry"
											:modelFormMapping="{ countrycode: 'callfirstentry' }"
											:itemsToSelect="[{ countrycode: model.callfirstentry }]"
											:itemValue="['countrycode']"
											singleItemAutoselect
											:queryFields="['countrycode', 'description']"
											:itemText="(item) => `${item.countrycode} - ${item.description}`"
											:order="{ countrycode: 'asc' }"
											:fixedFilter="getISEUActiveFilter"
										></pui-select>
									</v-col>
									<!-- ETA / ATD -->
									<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<pui-date-field
											:id="`etaatd-sdsmdocument`"
											v-model="model.etaatd"
											:label="$t('sdsmdocument.etaatd')"
											:disabled="formDisabled"
											:time="true"
											required
											toplabel
											maxlength="13"
										></pui-date-field>
									</v-col>

									<!-- STEVEDOREID -->
									<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
										<pui-select
											id="stevedoreid"
											attach="stevedoreid"
											:label="$t('sdsmdocument.stevedore')"
											toplabel
											clearable
											required
											:disabled="formDisabled"
											v-model="model"
											modelName="sdsmstevedore"
											:modelFormMapping="{ stevedoreid: 'stevedoreid' }"
											:itemsToSelect="[{ stevedoreid: model.stevedoreid }]"
											:itemValue="['stevedoreid']"
											:queryFields="['description']"
											:itemText="(item) => `${item.description}`"
											:order="{ stevedoreid: 'asc' }"
											:fixedFilter="filterStevedoreid"
											singleItemAutoselect
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense>
									<!-- DOCKID -->
									<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
										<pui-select
											id="dockid"
											attach="dockid"
											:label="$t('sdsmdocument.dock')"
											toplabel
											clearable
											required
											singleItemAutoselect
											v-model="model"
											:disabled="formDisabled"
											modelName="mdock"
											:modelFormMapping="{ dockid: 'dockid' }"
											:itemsToSelect="[{ dockid: model.dockid }]"
											:itemValue="['dockid']"
											:queryFields="['description']"
											:itemText="(item) => `${item.description}`"
											:order="{ dockid: 'asc' }"
											:fixedFilter="filterDockid"
										></pui-select>
									</v-col>
									<!-- DOCKLOCATIONID -->
									<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
										<pui-select
											id="docklocationid"
											attach="docklocationid"
											:label="$t('sdsmdocument.docklocation')"
											toplabel
											clearable
											required
											singleItemAutoselect
											v-model="model"
											:disabled="formDisabled"
											modelName="mdockdocklocation"
											:modelFormMapping="{ docklocationid: 'docklocationid' }"
											:itemsToSelect="[{ docklocationid: model.docklocationid }]"
											:itemValue="['docklocationid']"
											:queryFields="['docklocation']"
											:itemText="(item) => `${item.docklocation} `"
											:order="{ docklocationid: 'asc' }"
											:fixedFilter="getDockFilter"
										></pui-select>
									</v-col>
								</v-row>
							</pui-field-set>

							<pui-field-set :title="$t('form.sdsmdocument.service')">
								<v-row dense>
									<!-- MOVGOODID -->
									<v-col cols="12 col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
										<pui-select
											id="movgoodid"
											attach="movgoodid"
											:label="$t('sdsmdocument.movgood')"
											toplabel
											:disabled="formDisabled"
											v-model="model"
											required
											singleItemAutoselect
											modelName="vutmmovementgoodtra"
											:modelFormMapping="{ movgoodid: 'movgoodid' }"
											:itemsToSelect="[{ movgoodid: model.movgoodid }]"
											:itemValue="['movgoodid']"
											:queryFields="['description']"
											:itemText="(item) => `${item.description}`"
											:fixedFilter="filterDoctypeid"
											:order="{ description: 'asc' }"
										></pui-select>
									</v-col>

									<!-- REGSERID -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
										<pui-select
											id="regserid"
											attach="regserid"
											:label="$t('sdsmdocument.regularservice')"
											toplabel
											clearable
											required
											:disabled="formDisabled"
											v-model="model"
											modelName="vutregularservicetypetra"
											:modelFormMapping="{ regserid: 'regserid' }"
											:itemsToSelect="[{ regserid: model.regserid }]"
											:itemValue="['regserid']"
											:queryFields="['description']"
											:itemText="(item) => `${item.description}`"
											:order="{ description: 'asc' }"
											:fixedFilter="filterRegserid"
											singleItemAutoselect
										></pui-select>
									</v-col>
									<!-- SERVICENUMBER -->
									<v-col cols="12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
										<pui-number-field
											:id="`servicenumber-sdsmdocument`"
											v-model="model.servicenumber"
											:label="$t('sdsmdocument.servicenumber')"
											:disabled="formDisabled || !model.regserid || model.regserid === 2"
											:required="model.regserid && model.regserid === 1"
											toplabel
											min="0"
											max="999"
										></pui-number-field>
									</v-col>
									<!-- VOYAGENUMBER -->
									<v-col cols="12 col-xs-12 col-sm-1 col-md-1 col-lg-1 col-xl-1">
										<pui-number-field
											:id="`voyagenumber-sdsmdocument`"
											v-model="model.voyagenumber"
											:label="$t('sdsmdocument.voyagenumber')"
											:disabled="formDisabled"
											toplabel
											maxlength="17"
											min="0"
										></pui-number-field>
									</v-col>
								</v-row>
								<v-row dense>
									<!-- SIMTRANID -->
									<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											id="simtranid"
											attach="simtranid"
											:label="$t('sdsmdocument.simplifiedtransit')"
											toplabel
											reactive
											required
											singleItemAutoselect
											:disabled="formDisabled"
											v-model="model"
											modelName="msimplifiedtransit"
											:modelFormMapping="{ simtranid: 'simtranid' }"
											:itemsToSelect="[{ simtranid: model.simtranid }]"
											:itemValue="['simtranid']"
											:queryFields="['description']"
											:itemText="(item) => `${item.description}`"
											:order="{ description: 'asc' }"
											:fixedFilter="filterSimtranid"
										></pui-select>
									</v-col>
									<!-- SIMTRANOPERATORID -->
									<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<pui-select
											id="simtranoperatorid"
											attach="simtranoperatorid"
											:label="$t('sdsmdocument.simtranoperator')"
											toplabel
											clearable
											reactive
											:disabled="!model.simtranid || model.simtranid == 3 || formDisabled"
											:required="model.simtranid && model.simtranid !== 3"
											v-model="model"
											singleItemAutoselect
											modelName="sdsmoperator"
											:modelFormMapping="{ operatorid: 'simtranoperatorid' }"
											:itemsToSelect="[{ operatorid: model.simtranoperatorid }]"
											:itemValue="['operatorid']"
											:queryFields="['description']"
											:itemText="(item) => `${item.description}`"
											:order="{ operatorid: 'asc' }"
											:fixedFilter="filterSimtranoperatorid"
										></pui-select>
									</v-col>
									<!-- GOODSERVICE -->
									<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`goodservice-sdsmdocument`"
											v-model="model.goodservice"
											:label="$t('sdsmdocument.goodservice')"
											:disabled="formDisabled"
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-col>
									<!-- MANIPULATIONTERMINAL -->
									<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`manipulationterminal-sdsmdocument`"
											v-model="model.manipulationterminal"
											:label="$t('sdsmdocument.manipulationterminal')"
											:disabled="formDisabled"
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>

							<!-- New Document > Create Joint Services -->
							<sdsmdocument-jointservices-create
								ref="JS"
								v-if="isCreatingElement"
								:document="model"
								:params="{ isCreatingElement, formDisabled }"
							></sdsmdocument-jointservices-create>

							<!-- Update Document > List of Joint Services -->
							<sdsmdocument-jointservices-edit
								ref="JS"
								v-else
								:document="model"
								:params="{ isCreatingElement, formDisabled }"
							></sdsmdocument-jointservices-edit>

							<!-- MINI AUDIT -->
							<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
						</v-col>
					</v-tab-item>
					<v-tab-item :key="1" :value="'sdsmjointservice'">
						<pui-master-detail
							componentName="sdsmjointservice-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ documentid: 'documentid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
						></pui-master-detail>
					</v-tab-item>
					<v-tab-item :key="2" :value="'sdsmbillofloading'">
						<pui-master-detail
							componentName="sdsmbillofloading-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ documentid: 'documentid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
							:props="{ documentid: 'documentid' }"
						></pui-master-detail>
					</v-tab-item>
					<v-tab-item :key="3" :value="'sdsmuti'">
						<pui-master-detail
							componentName="sdsmuti-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ documentid: 'documentid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
						></pui-master-detail>
					</v-tab-item>
					<v-tab-item :key="4" :value="'sdsmgoods'">
						<pui-master-detail
							componentName="sdsmgood-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ documentid: 'documentid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
						></pui-master-detail
					></v-tab-item>
					<v-tab-item :key="5" :value="'vutmessagedoc'">
						<pui-master-detail
							componentName="vutmessagedoc-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ documentid: 'documentid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
						></pui-master-detail
					></v-tab-item>
				</v-tabs-items>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="SaveDocumentAndNew"
					:saveAndUpdate="SaveDocumentAndUpdate"
					:save="SaveDocumentAndBack"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import sdsmdocumentActions from './SdsmdocumentActions';
import sdsmdocumentModals from './SdsmdocumentModals.vue';
import SdsmdocumentUtils from './SdsmdocumentUtils';
import formUtils from '@/utils/form';
import SdsmJointServiceUtils from '../sdsmjointservice/SdsmjointserviceUtils';
import filters from '@/utils/filters';
import sdsmdocumentJointservicesCreate from './SdsmdocumentFormJSCreate.vue';
import sdsmdocumentJointservicesEdit from './SdsmdocumentFormJSEdit.vue';
import { mapState } from 'vuex';
import { EntityStatusUtils } from '../../utils/entityStates';

export default {
	name: 'sdsmdocument-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'sdsmdocument-modals': sdsmdocumentModals,
		'sdsmdocument-jointservices-create': sdsmdocumentJointservicesCreate,
		'sdsmdocument-jointservices-edit': sdsmdocumentJointservicesEdit
	},
	data() {
		return {
			modelName: 'sdsmdocument',
			actions: sdsmdocumentActions.formactions,
			escalas: [],
			loadingEscalas: false,
			callnumberKey: 0,
			loading: false,
			saving: false,
			BLsFromDoc: null,
			GoodsFromDoc: null,
			EQsFromDoc: null,
			// puertosUser: [],
			itemsHeader: [],
			EQsState: null,
			alerts: null,
			filters: {
				portid: '',
				simtranoperatorid: '',
				simtranid: '',
				regserid: '',
				doctypeid: ''
			},
			documentSelected: '',
			issimpregBool: false,
			jsnumber: [],
			jsnNext: '0',
			codartRules: [(v) => this.checkOperator(v) || this.$t('sdsmjointservice.orderinuse')],
			docOpId: '',
			opNumProv: '',
			jsid: '',
			headerData: {},
			dateHd: null,
			locationCount: 0,
			consignatarioClass: '12 col-xs-12 col-sm-10 col-md-10 col-lg-10 col-xl-10'
		};
	},
	methods: {
		async afterGetData() {
			formUtils.updateMiniAudit(this.model);
			this.formDisabled = !EntityStatusUtils.checkEditionAvailable(this.model.documentstateid);
			this.$set(this.model, 'customscode', null);
			this.$set(this.model, 'fiscalcode', null);
			this.$set(this.model, 'locode', null);
			this.$watch('model.callnumber', this.WatchCallNumber);
			this.$watch('model.portid', this.WatchPortID);
			this.$watch('model.operatorid', this.WatchOperatorID);
			this.$watch('model.callyear', this.WatchCallYear);
			this.$watch('model.doctypeid', this.WatchDocTypeID);
			this.$watch('model.simtranid', this.WatchSimTranId);
			this.$watch('model.regserid', this.WatchRegularServiceID);
			this.$watch('model.movgoodid', this.WatchMovGoodId);
			this.changePortID();
			this.changeOperatorID();
			this.filters.portid = this.model.portid;
			this.filters.simtranoperatorid = this.model.simtranoperatorid;
			this.filters.simtranid = this.model.simtranid;
			this.filters.regserid = this.model.regserid;
			this.filters.doctypeid = this.model.doctypeid;
			if (!this.isCreatingElement) {
				this.alerts = await SdsmdocumentUtils.getAlerts(this, this.model.documentid);
			} else if (this.model.simtranid == null) {
				this.model.simtranid = 3;
			}
		},
		disableByDoc() {
			return this.documentSelected;
		},
		generateDocumentNumber() {
			this.model.documentnumber = SdsmdocumentUtils.generateDocumentNumber(this.model);
		},
		beforeSave() {
			this.model.documentstateid = 1;
		},
		changePortID() {
			this.$puiEvents.$on(`onPuiSelect_selectedItems-portid-sdsmdocument`, (newValPort) => {
				if (this.model && newValPort?.model) {
					const lupamodel = newValPort.model;
					this.model.customsid = lupamodel.customsid;
					this.model.customscode = lupamodel.customscode;
					this.model.locode = lupamodel.countrycode + lupamodel.portcode;
				}
			});
		},
		changeOperatorID() {
			this.$puiEvents.$on(`onPuiSelect_selectedItems-operatorid-sdsmdocument`, (newValOperator) => {
				if (newValOperator?.model) {
					this.model.fiscalcode = newValOperator.model.fiscalcode;
				}
			});
		},
		setEscalaFields() {
			const call = this.escalas.find((e) => e.numescala == this.model.callnumber);
			this.model.calllastport = call?.locodeorigen;
			this.model.callnextport = call?.locodedestino;
			this.model.shipname = call?.nombuque;
			this.model.shipimo = call?.imo;
			this.model.shipflag = call?.bandera;
			this.model.callcode = call?.codescala;
		},
		cleanCallNumber() {
			this.model.callnumber = null;
			this.getEscalasPCS();
		},
		async getEscalasPCS() {
			if (this.model?.locode && this.model?.callyear && this.model?.fiscalcode) {
				this.callnumberKey++;
				this.loadingEscalas = true;
				this.escalas = await SdsmdocumentUtils.getEscalasPCS(this, this.model, this.model.locode, this.model.callyear, this.model.fiscalcode);
				this.setEscalaFields();
				this.generateDocumentNumber();
				this.loadingEscalas = false;
			}
		},
		WatchDocTypeID(newValue, oldValue) {
			if (this.isCreatingElement || (!this.isCreatingElement && newValue && oldValue && newValue !== oldValue)) {
				this.filters.doctypeid = newValue;
			}
			this.model.movgoodid = null;
		},
		WatchCallYear(newValue, oldValue) {
			if (this.isCreatingElement || (!this.isCreatingElement && newValue && oldValue && newValue !== oldValue)) {
				this.model.callnumber = null;
				this.getEscalasPCS();
			}
		},
		WatchOperatorID(newValue, oldValue) {
			if (this.isCreatingElement || (!this.isCreatingElement && newValue && oldValue && newValue !== oldValue)) {
				this.model.callnumber = null;
				this.changeOperatorID();
				this.getEscalasPCS();
			}
		},
		WatchPortID(newValue, oldValue) {
			if (this.isCreatingElement || (!this.isCreatingElement && newValue && oldValue && newValue !== oldValue)) {
				this.model.callnumber = null;
				this.changePortID();
				this.getEscalasPCS();
			}
		},
		WatchCallNumber(newValue, oldValue) {
			if (this.isCreatingElement || (!this.isCreatingElement && newValue && newValue !== oldValue)) {
				this.setEscalaFields();
				this.generateDocumentNumber();
			}
		},
		WatchSimTranId(newValue, oldValue) {
			if (newValue !== oldValue && newValue === 3) {
				this.model.simtranoperatorid = null;
			}
		},
		WatchRegularServiceID(newValue, oldValue) {
			if (newValue !== oldValue && newValue === 2) {
				this.model.servicenumber = null;
			}
		},
		WatchMovGoodId(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.model.movgoodid = newValue;
				this.$set(this.model, 'movgoodid', newValue);
			} else {
				this.model.movgoodid = oldValue;
				this.$set(this.model, 'movgoodid', oldValue);
			}
		},
		// Save Document and New
		async SaveDocumentAndNew() {
			const data = await this.SaveDocument();
			if (data) {
				this.refreshRouter();
			}
		},
		// Save Document and Keep editing
		async SaveDocumentAndUpdate() {
			const data = await this.SaveDocument();
			if (data) {
				if (this.isCreatingElement) {
					this.goToUpdate(data);
				} else {
					this.refreshRouter();
				}
			}
		},
		// Save Document and Back
		async SaveDocumentAndBack() {
			const data = await this.SaveDocument();
			if (data) {
				this.back();
			}
		},
		// Save Document
		async SaveDocument() {
			let document = null;
			const jointservices = this.$refs.JS?.jointservices || [];
			const valid = SdsmdocumentUtils.checkBeforeSaving(this, this.model, jointservices, this.isCreatingElement);
			if (valid) {
				this.saving = true;
				document = await SdsmdocumentUtils.saveDocument(this, this.model, jointservices, this.isCreatingElement);
				this.saving = false;
			}
			return document;
		},
		checkOperator(u) {
			let b = true;
			if (u == 0 && this.docOpId != this.model.operatorid) {
				return false;
			}

			if (this.jsnumber.length > 0 && this.opNumProv != u) {
				this.jsnumber.forEach((element) => {
					if (element == u) {
						b = false;
					}
				});
			}
			return b;
		},
		sugestJSnumber() {
			this.jsnumber.sort();
			if (this.docOpId == this.model.operatorid) {
				this.opNumProv = 0;
			} else {
				this.opNumProv = 1;
			}
			this.jsnumber.forEach((element) => {
				if (element === this.opNumProv) {
					this.opNumProv++;
				}
			});
			this.model.jointservicenumber = this.opNumProv;
			return true;
		},
		// Save JS and New
		async SaveJSAndNew() {
			const data = await this.SaveJS();
			if (data) {
				this.goToCreate();
			}
		},
		// Save JS and Keep editing
		async SaveJSAndUpdate() {
			const data = await this.SaveJS();
			if (data) {
				if (this.isCreatingElement) {
					this.goToUpdate(data);
				} else {
					this.refreshRouter();
				}
			}
		},
		// Save JS and Back
		async SaveJSAndBack() {
			const data = await this.SaveJS();
			if (data) {
				this.back();
			}
		},
		// Save JS
		async SaveJS() {
			let js = null;
			const valid = SdsmJointServiceUtils.checkBeforeSaving(this, this.model);
			if (valid) {
				this.saving = true;
				js = await SdsmJointServiceUtils.saveJointService(this, this.model);
				this.saving = false;
			}
			return js;
		}
	},
	computed: {
		...mapState({
			operatorId: (state) => state.session.properties.GESTAUT_OPERATOR_ID,
			portsList: (state) => state.session.properties.GESTAUT_PROPERTIES_PORTS
		}),
		getMovGoodFilter() {
			return SdsmdocumentUtils.getMovGoodFilter(this, this.model);
		},
		getDockFilter() {
			return filters.getDockDockLockFilter(this.model.dockid);
		},
		getDateExpirationFilter() {
			return SdsmdocumentUtils.getDateExpirationFilter2(this.operatorId);
		},
		getISEUActiveFilter() {
			return filters.expirationDatePlus(1, 'iseu');
		},
		filterPortid() {
			const puertosUser = this.portsList.map((obj) => {
				return { countrycode: obj.locode.substring(0, 2), portcode: obj.locode.substring(2) };
			});
			return filters.dockFilter(puertosUser, this.filters.portid);
		},
		filterSimtranoperatorid() {
			return filters.expirationDatePlus(this.filters.simtranoperatorid, 'simtranoperatorid');
		},
		filterSimtranid() {
			return filters.expirationDatePlus(this.filters.simtranid, 'simtranid');
		},
		filterRegserid() {
			return filters.expirationDatePlus(this.filters.regserid, 'regserid');
		},
		filterDoctypeid() {
			return filters.getDocTypeId(this.model.doctypeid);
		},
		filterCustomsid() {
			return filters.expirationDatePlus(this.filters.customsid, 'customsid');
		},
		filterStevedoreid() {
			return filters.expirationDatePlus(this.filters.stevedoreid, 'stevedoreid');
		},
		filterDockid() {
			return filters.expirationDateArray([
				{ data: this.filters.dockid, field: 'dockid' },
				{ field: 'portid', data: this.model.portid }
			]);
		},
		dateHdcal(item) {
			return this.$moment(item.etaatd).format('DD/MM/YYYY HH:mm');
		},
		OperatorFilter() {
			return SdsmJointServiceUtils.getFixedFilterOperator(this.model, this.isCreatingElement);
		},
		fixedDate() {
			return filters.expirationDate();
		},
		fixedDatePlus() {
			return filters.expirationDatePlus(this.model.dockid, 'dockid');
		},
		portItemsToSelect() {
			return [{ portid: this.model.portid }];
		},
		documentItemsToSelect() {
			return [{ documentid: this.model.documentid }];
		},
		operatorItemsToSelect() {
			return [{ operatorid: this.model.operatorid }];
		},
		dockItemsToSelect() {
			return [{ dockid: this.model.dockid }];
		},
		docklocationItemsToSelect() {
			return [{ docklocationid: this.model.docklocationid }];
		},
		simtranItemsToSelect() {
			return [{ simtranid: this.model.simtranid }];
		},
		simpregoperatoridItemsToSelect() {
			return [{ operatorid: this.model.simpregoperatorid }];
		},
		filterDocklocationid() {
			return filters.getDockDockLockFilter(this.model.dockid);
		},
		filterStateid() {
			return filters.expirationDatePlus(this.filters.stateid, 'stateid');
		},
		filterOperatorid() {
			return filters.expirationDatePlus(this.filters.operatorid, 'operatorid');
		},
		filterSimpregoperatorid() {
			return filters.expirationDatePlus(this.filters.simpregoperatorid, 'simpregoperatorid');
		}
	}
};
</script>
