// import SdsmjointserviceUtils from '../sdsmjointservice/SdsmjointserviceUtils';

const SdsmDocumentUtils = {
	checkBeforeSaving(ctx, model, jointservices, isCreatingElement) {
		if (ctx.$refs.form && ctx.$refs.form.validate && !ctx.$refs.form.validate()) {
			ctx.$puiNotify.error(ctx.$t('pui9.error.form_errors'));
			ctx.resetAfterSaveActions ? ctx.resetAfterSaveActions() : null;
			return false;
		}
		let valid = true;

		// Checking Joint Services if creating Document
		if (isCreatingElement) {
			if (jointservices && jointservices.length > 0) {
				for (let i = 0; i < jointservices.length; i++) {
					// Checking valid JS operator
					if (!jointservices[i].operatorid) {
						valid = false;
						ctx.$puiNotify.error(ctx.$t('errors.jointserviceoperator'));
					}
					// Checking valid JS amount
					if (
						!jointservices[i].jointserviceamount ||
						parseInt(jointservices[i].jointserviceamount) < 1 ||
						parseInt(jointservices[i].jointserviceamount) > 999
					) {
						valid = false;
						ctx.$puiNotify.error(ctx.$t('errors.jointserviceamount'));
					}
				}
			}
		}
		return valid;
	},
	/******************/
	/* Send Document */
	/****************/
	async SendDocument(ctx, document, jointservice) {
		let data = null;
		let metodo = '/sdsmdocument/send';

		let documento = {
			documentid: document,
			jointserviceid: jointservice
		};

		await ctx.$puiRequests.postRequest(
			metodo,
			documento,
			(response) => {
				data = response.data;
				ctx.$puiNotify.success(ctx.$t('general.sendsuccess'));
			},
			(e) => {
				ctx.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		return data;
	},
	/******************/
	/* Save Document */
	/****************/
	async saveDocument(ctx, document, jointservices, isCreatingElement) {
		let data = null;
		let metodo = isCreatingElement ? '/sdsmdocument/save' : '/sdsmdocument/saveUpd';
		document.jointservices = isCreatingElement ? jointservices : [];
		await ctx.$puiRequests.postRequest(
			metodo,
			document,
			(response) => {
				data = response.data;
				ctx.$puiNotify.success(ctx.$t('general.success'));
			},
			(e) => {
				ctx.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		return data;
	},
	/******************/
	/* Copy Document */
	/****************/
	async copyDocument(ctx, data) {
		let document = null;
		await ctx.$puiRequests.postRequest(
			'/sdsmdocument/copyDocument',
			data,
			(response) => {
				document = response;
				ctx.$puiNotify.success(ctx.$t('action.sdsmdocument.copyAction.success'));
			},
			(e) => {
				ctx.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		return document;
	},
	async newDOC(ctx, data) {
		let eq = null;
		await ctx.$puiRequests.getRequest(
			'/sdsmdocument/template',
			data,
			(response) => {
				eq = response.data;
			},
			() => {},
			null,
			null
		);
		return eq;
	},
	async massiveupdate(ctx, data, docid) {
		let updated = false;
		let error = null;
		await ctx.$puiRequests.postRequest(
			'/sdsmdocument/massiveupdate',
			{ data, docid },
			() => {
				updated = true;
			},
			(e) => {
				error = e.response.data.message;
			},
			null,
			null
		);
		return { updated, error };
	},
	/*async insertDocument(ctx, data) {
		let document = null;
		await ctx.$puiRequests.postRequest(
			'/sdsmdocument/insert',
			data,
			(response) => {
				document = response.data;
			},
			(e) => {
				ctx.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		return document;
	},
	async updateDocument(ctx, data) {
		let document = null;
		await ctx.$puiRequests.putRequest(
			'/sdsmdocument/update',
			data,
			(response) => {
				document = response.data;
			},
			(e) => {
				ctx.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		return document;
	},
	async saveJointServices(ctx, document, jointservices) {
		let jointservicenumber = 1; // número de tramo asignado dentro de una misma cabecera (0, 1, 2, etc). Será 0 si el consig. buque = consig. mercancía // TODO
		for (let op = 0; op < jointservices.length; op++) {
			let ordernumber = 1; // número de orden dentro de los tramos asignados a un consignatario de mercancia (0001, 0002, 0001, etc)
			if (jointservices[op].isNew) {
				for (let js = 0; js < parseInt(jointservices[op].jointserviceamount); js++) {
					await SdsmjointserviceUtils.insertJointService(ctx, {
						...jointservices[op],
						jointserviceid: null,
						documentid: document.documentid,
						operatorid: jointservices[op].operatorid,
						jointservicenumber: jointservicenumber,
						ordernumber: ('000' + ordernumber.toString()).slice(-3),
						issimpreg: 0
					});
					jointservicenumber++;
					ordernumber++;
				}
			}
		}
	}, */
	async getBLsStats(ctx, model) {
		let BLS = [];
		let voids = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'vhdblfromdoc',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'documentid', op: 'eq', data: model.documentid }]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					BLS = response.data.data;
				}
			},
			() => {}
		);

		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmuti',
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'documentid', op: 'eq', data: model.documentid },
						{ field: 'bltypeid', op: 'eq', data: 3 }
					]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					voids = response.data.data;
				}
			},
			() => {}
		);

		let n = 0;

		voids.forEach((element) => {
			n = n + element.units;
		});

		if (BLS.length < 0) {
			BLS[0].vacios = n;
		}
		return BLS;
	},
	async getGoodsStats(ctx, model) {
		let goods = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'vhdgoodsfromdoc',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'documentid', op: 'eq', data: model.documentid }]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					goods = response.data.data;
				}
			},
			() => {}
		);
		return goods;
	},
	async getUtisStats(ctx, model) {
		let bls = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmbillofloading',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'documentid', op: 'eq', data: model.documentid }]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					bls = response.data.data;
				}
			},
			() => {}
		);
		let blids = [];
		bls.forEach((element) => {
			blids.push(element.blid);
		});

		let eqs = [];
		if (bls.length > 0) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'vhdcblutifromdoc',
					rows: null,
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'blid', op: 'in', data: blids }]
					}
				},
				(response) => {
					if (response.data && response.data.totalRecords > 0) {
						eqs = response.data.data;
					}
				},
				() => {}
			);
		}
		return eqs;
	},
	async getUtisByBL(ctx, model) {
		let bls = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmbillofloading',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'documentid', op: 'eq', data: model.documentid }]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					bls = response.data.data;
				}
			},
			() => {}
		);

		let blids = [];
		bls.forEach((element) => {
			blids.push(element.blid);
		});

		let voids = [];
		if (bls.length > 0) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmuti',
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'blid', op: 'in', data: blids }]
					}
				},
				(response) => {
					if (response.data && response.data.totalRecords > 0) {
						voids = response.data.data;
					}
				},
				() => {}
			);
		}
		return voids;
	},
	async deleteDoc(ctx, pk) {
		let params = { pk, reset: 42 };
		ctx.$puiRequests.deleteRequest(
			'/sdsmdocument/delete',
			null,
			() => {},
			(e) => {
				let message = this.$puiI18n.t('puiaction.notifyError');
				if (e.response && e.response.data) {
					message = e.response.data.message;
				}
				this.$puiNotify.error(message);
			},
			params,
			null
		);
		return true;
	},
	async getAlerts(ctx, pk) {
		let alerts = [];
		await ctx.$puiRequests.getRequest(
			'/sdsmdocument/getAlerts',
			{ pk },
			(response) => {
				alerts = response.data;
			},
			() => {},
			null,
			null
		);
		return alerts;
	},
	async getEscalasPCS(ctx, model, locode, anyo, numdoc) {
		let escalas = [];
		const params = { locode, anyo, numdoc };
		await ctx.$puiRequests.getRequest(
			'/sdsmdocument/getEscalasPCS',
			params,
			(response) => {
				if (response.data.resultado == '0' && response.data.escalaspcsum.length) {
					escalas = response.data.escalaspcsum;
				}
			},
			() => {}
		);
		return escalas;
	},
	generateDocumentNumber(model) {
		let documentnumber = null;
		if (model.customscode && model.callyear && model.callnumber) {
			const customDigits = model.customscode.slice(model.customscode.length - 4);
			const yearDigit = model.callyear.toString().slice(model.callyear.toString().length - 1);
			const callNumberDigit = model.callnumber.slice(model.callnumber.length - 6);
			documentnumber = customDigits + yearDigit + callNumberDigit;
		}
		return documentnumber;
	},
	/* Filter */
	getMovGoodFilter(ctx, model) {
		return {
			groups: [
				{
					groupOp: 'and',
					rules: [
						{ field: 'doctypeid', op: 'eq', data: model.doctypeid },
						{ field: 'dateexpiration', op: 'nu' }
					]
				}
			],
			groupOp: 'and',
			rules: []
		};
	},
	getDockFilter(ctx, model) {
		return {
			groups: [
				{
					groupOp: 'and',
					rules: [
						{ field: 'dockid', op: 'eq', data: model.dockid },
						{ field: 'dateexpiration', op: 'nu' }
					]
				}
			],
			groupOp: 'and',
			rules: []
		};
	},
	getDateExpirationFilter() {
		return {
			groups: [
				{
					groupOp: 'and',
					rules: [{ field: 'dateexpiration', op: 'nu' }]
				}
			],
			groupOp: 'and',
			rules: []
		};
	},
	getDateExpirationFilter2(opid) {
		return {
			groups: [
				{
					groupOp: 'and',
					rules: [
						{ field: 'dateexpiration', op: 'nu' },
						{ field: 'operatorid', op: 'eq', data: opid }
					]
				}
			],
			groupOp: 'and',
			rules: []
		};
	},
	getISEUActiveFilter() {
		return {
			groups: [
				{
					groupOp: 'and',
					rules: [{ field: 'iseu', op: 'eq', data: '1' }]
				}
			],
			groupOp: 'and',
			rules: []
		};
	},
	async getDocStats(ctx, model) {
		let document = null;
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmdocument',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'documentid', op: 'eq', data: model.documentid }]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					document = response.data.data[0];
				}
			},
			() => {}
		);

		return document;
	}
};

export default SdsmDocumentUtils;
