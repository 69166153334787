<template>
	<pcsum-edition-grid
		:label="$t('sdsmdocument.jointservices')"
		:labelEmpty="$t('sdsmdocument.nojointservices')"
		:rows="jointservices"
		:fields="fields"
		:params="params"
		:loading="loading"
		:allowCreate="!formDisabled"
		:allowRemove="!formDisabled"
		@EmitCreate="EmitCreate"
		@EmitRefresh="EmitRefresh"
		@EmitRemove="EmitRemove"
		@EmitChange="EmitChange"
		@EmitClone="EmitClone"
		@EmitLaunch="EmitLaunch"
	></pcsum-edition-grid>
</template>

<script>
import SdsmjointserviceUtils from '../sdsmjointservice/SdsmjointserviceUtils';
import PcsumEditionGrid from './../general/PcsumEditionGrid';

export default {
	name: 'sdsmdocument-jointservices-create',
	components: {
		'pcsum-edition-grid': PcsumEditionGrid
	},
	props: {
		document: { type: Object, default: () => {} },
		params: { type: Object, default: () => {} }
	},
	data() {
		return {
			loading: false,
			jointservices: [],
			formDisabled: false,
			emptyRegistry: {
				operatorid: null,
				jointserviceamount: null
			},
			fields: {
				operatorid: {
					rules: [(value) => !!value || this.$t('requiredMessage')],
					label: 'sdsmjointservice.operatorid',
					cols: '12 col-xs-12 col-sm-10 col-md-10 col-lg-10 col-xl-10',
					itemText: ['description'],
					modelName: 'sdsmoperator',
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'dateexpiration', op: 'nu' }]
					}
				},
				jointserviceamount: {
					rules: [
						(value) => !!value || this.$t('requiredMessage'),
						(value) => (value > 0 && value < 999) || this.$t('errors.jointserviceamount')
					],
					label: 'sdsmdocument.jointserviceamount',
					cols: '12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2'
				}
			}
		};
	},
	methods: {
		/* Create empty Row */
		EmitCreate() {
			this.jointservices.push({
				...this.emptyRegistry,
				...{
					isNew: true,
					randomKey: Math.floor(Math.random() * 1000)
				}
			});
		},
		/* Editing new row */
		EmitChange(registry) {
			const index = registry.rowIndex;
			this.jointservices[index] = { ...this.jointservices[index], ...registry };
		},
		/* Deleting row */
		EmitRemove(registry) {
			this.jointservices.splice(registry.rowIndex, 1);
			SdsmjointserviceUtils.deleteJointService(this, registry);
		},
		EmitRefresh() {},
		EmitClone() {},
		EmitLaunch() {}
	},
	created() {
		this.formDisabled = this.params.formDisabled;
	}
};
</script>
