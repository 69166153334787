export default {
	M__MASSIVE_CREATE_MODAL(state, data) {
		state.modal.massiveCreate.open = data.open;
		state.modal.massiveCreate.jointservice = data.jointservice;
		state.modal.massiveCreate.billofloading = data.billofloading;
	},
	M__DOCUMENTIMPORTCSV_MODAL(state, data) {
		state.modal.DocumentImportCSV.open = data.open;
	},
	M__JSIMPORTCSV_MODAL(state, data) {
		state.modal.JSImportCSV.open = data.open;
		state.modal.JSImportCSV.jointservice = data.jointservice;
	},
	M__LOCINFO(state, data) {
		state.PCS.locinfo = data;
	},
	M__TARIC(state, data) {
		state.PCSUM.tarcodes = data;
	}
};
