<template>
	<div class="pui-form">
		<mequipmentsize-modals :modelName="modelName"></mequipmentsize-modals>
		<!-- <pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<mequipmentsize-form-header :modelPk="modelPk"></mequipmentsize-form-header>
		</pui-form-header> -->
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<!-- EQSIZEID -->
						<v-col cols="1" v-if="!isCreatingElement">
							<pui-text-field
								:id="`eqsizeid-mequipmentsize`"
								v-model="model.eqsizeid"
								:label="$t('mequipmentsize.eqsizeid')"
								disabled
								toplabel
								maxlength="12"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- ISOCODE -->
						<v-col cols="1">
							<pui-text-field
								:id="`isocode-mequipmentsize`"
								v-model="model.isocode"
								:label="$t('mequipmentsize.isocode')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="4"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DESCRIPTION -->
						<v-col cols="6">
							<pui-text-field
								:id="`description-mequipmentsize`"
								v-model="model.description"
								:label="$t('mequipmentsize.description')"
								:disabled="formDisabled"
								toplabel
								required
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DATEEXPIRATION -->
						<v-col cols="4" v-if="!isCreatingElement">
							<pui-date-field
								:id="`dateexpiration-mequipmentsize`"
								v-model="model.dateexpiration"
								:label="$t('mequipmentsize.dateexpiration')"
								disabled
								toplabel
								maxlength="13"
								time
							></pui-date-field>
						</v-col>
					</v-row>
					<!-- MINI AUDIT -->
					<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin'
import mequipmentsizeActions from './MequipmentsizeActions'
import mequipmentsizeModals from './MequipmentsizeModals.vue'
import formUtils from '@/utils/form'

export default {
	name: 'mequipmentsize-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mequipmentsize-modals': mequipmentsizeModals
	},
	data () {
		return {
			modelName: 'mequipmentsize',
			actions: mequipmentsizeActions.formActions
		}
	},
	methods: {
		afterGetData () {
			formUtils.updateMiniAudit(this.model)
		},
		beforeSave () {
			this.dtohash = null
		}
	},
	computed: {},
	created () {}
}
</script>
