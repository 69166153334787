<template>
	<div>
		<!-- Copy/Wizard Action -->
		<pui-modal-dialog-form
			scrollable
			:dialogName="sdsmutiCopyModal.name"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.sdsmuti.copy.title')"
			:modelName="modelName"
			:onOk="onOkEquipmentCopyModal"
			:okText="sdsmutiCopyModal.okText"
			:onShow="onShowEquipmentCopyModal"
		>
			<template slot="message" slot-scope="data">
				<pcsum-copy-wizard :ref="sdsmutiCopyModal.refWizard" @EmitSetOkText="t => (sdsmutiCopyModal.okText = t)">
					<sdsmuti-form-for-wizard :ref="sdsmutiCopyModal.refWizardForm" :modeltemplate="data.modalData"></sdsmuti-form-for-wizard>
				</pcsum-copy-wizard>
			</template>
		</pui-modal-dialog-form>

		<!-- MassiveUpdate Action -->
		<pui-modal-dialog-form
			scrollable
			widthDialog="75%"
			:dialogName="sdsmutiMassiveUpdateModal.name"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.sdsmuti.massiveedit.title')"
			:modelName="modelName"
			:onOk="onOkEquipmentMassiveUpdateModal"
			:okText="sdsmutiMassiveUpdateModal.okText"
			:onShow="onShowEquipmentMassiveUpdateModal"
		>
			<template slot="message" slot-scope="data">
				<sdsmuti-form-for-massive-update
					:ref="sdsmutiMassiveUpdateModal.refForm"
					:utis="data.modalData.utis"
				></sdsmuti-form-for-massive-update>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
import PcsumCopyWizard from './../general/PcsumCopyWizard'
import SdsmutiFormForWizard from './modalForms/SdsmutiFormForWizard.vue'
import SdsmutiFormForMassiveUpdate from './modalForms/SdsmutiFormForMassiveUpdate.vue'

export default {
	name: 'sdsmuti-modals',
	components: {
		'pcsum-copy-wizard': PcsumCopyWizard,
		'sdsmuti-form-for-wizard': SdsmutiFormForWizard,
		'sdsmuti-form-for-massive-update': SdsmutiFormForMassiveUpdate
	},
	data () {
		return {
			sdsmutiCopyModal: {
				okText: null,
				refWizard: 'utiWizard',
				refWizardForm: 'utiFormWizard',
				name: 'idEquipmentCopyAction'
			},
			sdsmutiMassiveUpdateModal: {
				okText: null,
				refForm: 'utiFormMassiveUpdate',
				name: 'idEquipmentMassiveUpdateAction'
			}
		}
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		/* Copy / Wizard */
		async onOkEquipmentCopyModal () {
			const saveMethod = this.$refs[this.sdsmutiCopyModal.refWizardForm] ? this.$refs[this.sdsmutiCopyModal.refWizardForm].SaveWizard : null
			const completed = await this.$refs[this.sdsmutiCopyModal.refWizard].nextStep(saveMethod)
			if (completed) {
				return new Promise(resolve => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName)
					resolve(true)
				})
			}
		},
		onShowEquipmentCopyModal () {},
		/* Massive Edit */
		async onOkEquipmentMassiveUpdateModal () {
			const completed = await this.$refs[this.sdsmutiMassiveUpdateModal.refForm].SendUtiMassiveEdit()
			if (completed) {
				return new Promise(resolve => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName)
					resolve(true)
				})
			}
		},
		onShowEquipmentMassiveUpdateModal () {}
	}
}
</script>
