<template>
	<div class="pui-form">
		<sdsmuti-modals :modelName="modelName"></sdsmuti-modals>
		<sdsmuti-form-header v-if="modelLoaded && headerData" :data="headerData" :params="{}"></sdsmuti-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-tabs v-model="tabmodel" class="ml-4 mb-4 mr-4 mt-4" slider-color="primary" show-arrows>
					<v-tab :key="0" :href="'#maintab'">{{ $t('form.sdsmuti') }}</v-tab>
					<v-tab :key="1" :href="'#vutmessageuti'" v-if="!isCreatingElement">{{ $t('form.sdsmmessage.formtitle') }}</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tabmodel" style="width: 100% !important">
					<v-tab-item :key="0" :value="'maintab'">
						<v-col cols="12">
							<pui-field-set :title="$t('form.sdsmuti')">
								<v-row dense>
									<!-- BLID -->
									<v-col cols="12 pl-2 pr-2">
										<pui-select
											:id="`blid-sdsmgood`"
											:attach="`blid-sdsmgood`"
											:label="$t('sdsmgood.blid')"
											:disabled="!isCreatingElement || formDisabled"
											toplabel
											clearable
											modelName="vhdgood"
											v-model="model"
											:singleItemAutoselect="true"
											reactive
											outlined
											dense
											:itemsToSelect="[{ blid: this.model.blid }]"
											:modelFormMapping="{ blid: 'blid' }"
											:itemValue="['blid']"
											:itemTemplate="true"
											:itemText="''"
											:selectTemplate="true"
											:fixedFilter="filteredBLID"
											@keyup="mainSelectorEvent"
											ref="vacMainRef"
											:queryFields="[
												'blid',
												'documentnumber',
												'operator',
												'jointservicenumber',
												'ordernumber',
												'blnumber',
												'bltype'
											]"
											:order="{ blid: 'desc' }"
										>
											<template v-slot:selectionTemplate="{ props, item }">
												<div class="SlotOptionWithDetails" v-bind="props">
													{{ $t('sdsmgood.blid') }}:
													<span class="value">{{ item.blid || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.documentnumber') }}:
													<span class="value">{{ item.documentnumber || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.cargooperator') }}:
													<span class="value">{{ item.operator || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.jointservicenumber') }}:
													<span class="value">{{ item.jointservicenumber || '-' }} </span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.conocimiento') }}:
													<span class="value">{{ item.ordernumber || '-' }}</span>
													<span class="separator">/</span>
													<span class="value">{{ item.blnumber || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.bltype') }}:
													<span class="value">{{ item.bltype }} </span>
												</div>
											</template>
											<template v-slot:theTemplate="{ props, item }">
												<div class="SlotOptionWithDetails" v-bind="props">
													{{ $t('sdsmgood.blid') }}:
													<span class="value">{{ item.blid || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.documentnumber') }}:
													<span class="value">{{ item.documentnumber || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.cargooperator') }}:
													<span class="value">{{ item.operator || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.jointservicenumber') }}:
													<span class="value">{{ item.jointservicenumber || '-' }} </span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.conocimiento') }}:
													<span class="value">{{ item.ordernumber || '-' }}</span>
													<span class="separator">-</span>
													<span class="value">{{ item.blnumber || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.bltype') }}:
													<span class="value">{{ item.bltype }} </span>
												</div>
											</template>
										</pui-select>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col class="12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<pui-select
											id="`eqtypeid-sdsmuti`"
											attach="eqtypeid-sdsmuti"
											:label="$t('sdsmuti.eqtypeid')"
											toplabel
											clearable
											required
											reactive
											singleItemAutoselect
											v-model="model"
											:disabled="formDisabled"
											modelName="mequipmenttype"
											:modelFormMapping="{ eqtypeid: 'eqtypeid' }"
											:itemsToSelect="[{ eqtypeid: this.model.eqtypeid }]"
											:itemValue="'eqtypeid'"
											:queryFields="['edicode', 'description']"
											:itemText="(item) => `${item.edicode} - ${item.description}`"
											:order="{ description: 'asc' }"
											detailComponentName="sdsmuti-form"
											:fixedFilter="filterEqtypeid"
										></pui-select>
									</v-col>

									<!-- UTISTATUSID -->
									<v-col class="12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
										<pui-select
											id="`utistatusid-sdsmuti`"
											attach="utistatusid-sdsmuti"
											:label="$t('sdsmuti.utistatusid')"
											toplabel
											clearable
											required
											reactive
											singleItemAutoselect
											v-model="model"
											:disabled="formDisabled"
											modelName="mutistatus"
											:modelFormMapping="{ utistatusid: 'utistatusid' }"
											:itemsToSelect="[{ utistatusid: this.model.utistatusid }]"
											:itemValue="'utistatusid'"
											:queryFields="['description']"
											:itemText="(item) => `${item.description}`"
											:order="{ description: 'asc' }"
											:fixedFilter="filterUtistatusid"
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col class="12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
										<pui-select
											id="`eqsizeid-sdsmuti`"
											attach="eqsizeid-sdsmuti"
											:label="$t('sdsmuti.eqsizeid')"
											toplabel
											clearable
											required
											reactive
											singleItemAutoselect
											v-model="model"
											:disabled="formDisabled"
											modelName="mequipmentsize"
											:modelFormMapping="{ eqsizeid: 'eqsizeid' }"
											:itemsToSelect="[{ eqsizeid: this.model.eqsizeid }]"
											:itemValue="'eqsizeid'"
											:queryFields="['isocode', 'description']"
											:itemText="(item) => `${item.isocode} - ${item.description}`"
											:order="{ description: 'asc' }"
											detailComponentName="sdsmuti-form"
											:fixedFilter="filterEqsizeid"
										></pui-select>
									</v-col>
									<v-col class="12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
										<pui-number-field
											:id="`tare-sdsmuti`"
											v-model="model.tare"
											:label="$t('sdsmuti.tare')"
											required
											toplabel
											:disabled="formDisabled"
										></pui-number-field>
									</v-col>

									<v-col v-if="showUnits" class="12 col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
										<pui-number-field
											:id="`units-sdsmuti`"
											v-model="model.units"
											:label="$t('sdsmuti.units')"
											:required="showUnits"
											toplabel
											:disabled="formDisabled"
										></pui-number-field>
									</v-col>

									<v-col
										class="12 col-xs-12 col-sm-12 mt-6"
										align="right"
										:class="{ ' col-md-2 col-lg-2 col-xl-2': showUnits, ' col-md-3 col-lg-3 col-xl-3': !showUnits }"
									>
										<pui-checkbox
											:id="`fullfilliso-sdsmuti`"
											:label="$t('sdsmuti.fullfilliso')"
											v-model="model.fullfilliso"
											:disabled="formDisabled"
											true-value="1"
											false-value="0"
										></pui-checkbox>
									</v-col>
									<v-col class="12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`utiidentification-sdsmuti`"
											v-model="model.utiidentification"
											:label="$t('sdsmuti.utiidentification')"
											:required="model.fullfilliso === '1' || IdentificationRequired"
											toplabel
											:disabled="formDisabled"
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>
							<!-- SEALS -->
							<sdsmuti-form-seals
								ref="child"
								v-if="model"
								:uti="model"
								:params="{ isCreatingElement, formDisabled }"
							></sdsmuti-form-seals>

							<!------------------ MINI-AUDIT ------------------>
							<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
						</v-col>
					</v-tab-item>
					<v-tab-item :key="1" :value="'vutmessageuti'">
						<pui-master-detail
							componentName="vutmessageuti-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ utiid: 'utiid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
						></pui-master-detail
					></v-tab-item>
				</v-tabs-items>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveEQandNew"
					:saveAndUpdate="saveEQandUpdate"
					:save="saveEQandBack"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import sdsmutiActions from './SdsmutiActions';
import sdsmutiModals from './SdsmutiModals.vue';
import formUtils from '@/utils/form';
import SdsmutiSealsVue from './SdsmutiFormSeals.vue';
import SdsmUtiUtils from './SdsmutiUtils';
import filters from '@/utils/filters';
import { mapState } from 'vuex';
import { EntityStatusUtils } from '../../utils/entityStates';

export default {
	name: 'sdsmuti-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'sdsmuti-modals': sdsmutiModals,
		'sdsmuti-form-seals': SdsmutiSealsVue
	},
	data() {
		return {
			modelName: 'sdsmuti',
			actions: sdsmutiActions.formactions,
			billofloadings: [],
			blidkey: 0,
			documentSelected: '',
			blidDisabled: false,
			headerData: {},
			filters: {
				eqtypeid: '',
				utistatusid: '',
				eqsizeid: ''
			}
		};
	},
	methods: {
		async afterGetData() {
			if (this.isCreatingElement) {
				this.model.fullfilliso = '0';
			}
			formUtils.updateMiniAudit(this.model);
		},
		mainSelectorEvent(event) {
			if (event.key == 'Backspace' && this.model.blid != null) {
				this.model.blid = null;
			}
		},
		// Save EQ and New
		async saveEQandNew() {
			const data = await this.saveEQ();
			if (data) {
				this.refreshRouter();
			}
		},
		// Save EQ and Keep editing
		async saveEQandUpdate() {
			const data = await this.saveEQ();
			if (data) {
				if (this.isCreatingElement) {
					this.goToUpdate(data);
				} else {
					this.refreshRouter();
				}
			}
		},
		// Save EQ and Back
		async saveEQandBack() {
			const data = await this.saveEQ();
			if (data) {
				this.back();
			}
		},
		// Save EQ
		async saveEQ() {
			let uti = null;
			const seals = this.$refs.child.seals || [];
			const valid = await SdsmUtiUtils.checkBeforeSaving(this, this.model, seals);
			if (valid) {
				uti = SdsmUtiUtils.saveUTI(this, this.model, seals, this.isCreatingElement);
			}
			return uti;
		}
	},
	watch: {
		'model.blid'(blid) {
			this.documentSelected = blid || null;
			this.headerData = null;
			this.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'vhdgood',
					rows: null,
					filter: {
						groups: [
							{
								groupOp: 'and',
								rules: [
									{ field: 'blid', op: 'eq', data: blid },
									{ field: 'langtra', op: 'eq', data: this.$store.getters.getUserLanguage }
								]
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(responsee) => {
					if (responsee?.data?.data.length > 0) {
						this.headerData = responsee.data.data[0];
					}
				}
			);
		}
	},
	computed: {
		...mapState({
			operatorId: (state) => state.session.properties.GESTAUT_OPERATOR_ID
		}),
		showUnits() {
			const bl = this.headerData;
			return bl?.isempty === 1;
		},
		IdentificationRequired() {
			const bl = this.headerData;
			return bl ? bl.isempty && (bl.ftedicode == 'ZZ4' || bl.ftedicode == 'ZZ5') : false;
		},
		DateExpirationFilter() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'dateexpiration', op: 'nu' }]
					}
				],
				groupOp: 'and',
				rules: []
			};
		},
		filterEqtypeid() {
			return filters.expirationDatePlus(this.filters.eqtypeid, 'eqtypeid');
		},
		filterUtistatusid() {
			const bl = this.headerData;

			if (bl) {
				if (bl.isempty) {
					return {
						groups: [
							{
								groupOp: 'and',
								rules: [
									{ field: 'dateexpiration', op: 'nu' },
									{ field: 'utistatusid', op: 'eq', data: 1 }
								]
							}
						],
						groupOp: 'and',
						rules: []
					};
				} else {
					return {
						groups: [],
						groupOp: 'or',
						rules: [
							{ field: 'dateexpiration', op: 'nu' },
							{ field: 'utistatusid', op: 'eq', data: 2 },
							{ field: 'utistatusid', op: 'eq', data: 3 },
							{ field: 'utistatusid', op: 'eq', data: 4 }
						]
					};
				}
			} else {
				return filters.expirationDatePlus(this.filters.utistatusid, 'utistatusid');
			}
		},
		filterEqsizeid() {
			return filters.expirationDatePlus(this.filters.eqsizeid, 'eqsizeid');
		},
		filteredBLID() {
			// Prepare basic filter
			let filter = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'operatorid', op: 'eq', data: this.operatorId },
					{ field: 'langtra', op: 'eq', data: this.$store.getters.getUserLanguage },
					{ field: 'ispassengers', op: 'eq', data: 0 }
				]
			};

			if (!this.$attrs.parentpk && this.isCreatingElement) {
				filter.groups.push({
					groupOp: 'or',
					rules: EntityStatusUtils.editStates.map((stateId) => ({
						field: 'blstateid',
						op: 'eq',
						data: stateId
					}))
				});
			}

			// Filter if parent PK
			if (this.$attrs.parentpk && this.isCreatingElement) {
				const parentpk = JSON.parse(atob(this.$attrs.parentpk));
				if (parentpk.documentid) {
					filter.rules.push({ field: 'documentid', op: 'eq', data: parentpk.documentid });
				}
				if (parentpk.jointserviceid) {
					filter.rules.push({ field: 'jointserviceid', op: 'eq', data: parentpk.jointserviceid });
				}
				if (parentpk.blid) {
					filter.rules.push({ field: 'blid', op: 'eq', data: parentpk.blid });
				}
			}
			return filter;
		}
	},
	created() {}
};
</script>
