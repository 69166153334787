<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<mpackagetype-modals :modelName="modelName"></mpackagetype-modals>
	</div>
</template>

<script>
import mpackagetypeActions from './MpackagetypeActions';
import mpackagetypeModals from './MpackagetypeModals.vue';

export default {
	name: 'mpackagetype-grid',
	components: {
		'mpackagetype-modals': mpackagetypeModals
	},
	data() {
		return {
			modelName: 'mpackagetype',
			actions: mpackagetypeActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
