<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<mdocklocation-modals :modelName="modelName"></mdocklocation-modals>
	</div>
</template>

<script>
import mdocklocationActions from './MdocklocationActions';
import mdocklocationModals from './MdocklocationModals.vue';

export default {
	name: 'mdocklocation-grid',
	components: {
		'mdocklocation-modals': mdocklocationModals
	},
	data() {
		return {
			modelName: 'mdocklocation',
			actions: mdocklocationActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
