<template>
	<div>
		<pcsum-header :data="documentHeader"></pcsum-header>

		<pui-field-set :title="$t('modal.sdsmdocument.documentsend.jointservice')">
			<!-- 	<pui-select
				id="jointserviceid"
				attach="jointserviceid"
				:label="$t('sdsmjointservice.jointservicenumber')"
				toplabel
				clearable
				reactive
				:disabled="tramoDisable"
				v-model="modelo"
				modelName="sdsmjointservice"
				:modelFormMapping="{ jointserviceid: 'jointserviceid' }"
				:itemsToSelect="SendItemsToSelect"
				:itemValue="['jointserviceid']"
				:itemText="item => `${item.jointservicenumber}`"
				:order="{ jointserviceid: 'asc' }"
				:fixedFilter="filter"
			>
			</pui-select> -->
			<v-select
				class="customSelector"
				outlined
				dense
				v-model="modelo.jointserviceid"
				:items="items"
				:disabled="tramoDisable"
				item-value="jointserviceid"
				:item-text="(item) => `${item.jointservicenumber}`"
			></v-select>
		</pui-field-set>
	</div>
</template>
<script>
import PcsumHeader from './../../general/PcsumHeader';
import { mapState } from 'vuex';
import SdsmdocumentUtils from '../SdsmdocumentUtils';

export default {
	name: 'sdsmdocument-form-for-send',
	components: {
		'pcsum-header': PcsumHeader
	},
	props: {
		model: { type: Object, default: () => {} }
	},
	data() {
		return {
			selected: [],
			tramoDisable: true,
			strNinguno: this.$t('modal.sdsmdocument.documentsend.ninguno'),
			items: [{ jointserviceid: null, jointservicenumber: this.$t('modal.sdsmdocument.documentsend.ninguno') }],
			modelo: {
				documentid: null,
				jointserviceid: null
			}
		};
	},
	methods: {
		async afterGetData() {
			let js = await this.getJS();
			js.forEach((element) => {
				this.items.push({ jointserviceid: element.jointserviceid, jointservicenumber: element.jointservicenumber });
			});
			const nullItem = this.items.filter((item) => item.jointservicenumber === 'Ninguno');

			const itemsWithValue = this.items
				.filter((item) => item.jointservicenumber !== 'Ninguno')
				.sort((a, b) => a.jointservicenumber - b.jointservicenumber);

			this.items = nullItem.concat(itemsWithValue);
		},
		async buildDocumentHeader() {
			if (this.model.state == undefined || this.model.documenttype == undefined) {
				let document = await SdsmdocumentUtils.getDocStats(this, this.model);
				this.model = document;
			}
			this.documentHeader = [
				{ label: 'header.sdsmdocument.documentnumber', value: this.model.documentnumber },
				{ label: 'header.sdsmdocument.type', value: this.model.documenttype },
				{ label: 'header.sdsmdocument.operator', value: this.model.operator, showId: true, id: this.model.operatorid },
				{ label: 'header.sdsmdocument.callnumber', value: this.model.callnumber, pill: true },
				{ label: 'header.sdsmdocument.shipname', value: this.model.shipname },
				{ label: 'header.sdsmdocument.state', value: this.model.state, pill: true }
			];
		},
		// Send Document  [{ jointserviceid: modelo.jointserviceid }]
		async SendDocument() {
			let document = null;

			document = await SdsmdocumentUtils.SendDocument(this, this.modelo.documentid, this.modelo.jointserviceid);

			return document;
		},
		async getJS() {
			let JS = [];
			await this.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmjointservice',
					rows: null,
					filter: this.filter
				},
				(response) => {
					if (response.data && response.data.totalRecords > 0) {
						JS = response.data.data;
					}
				},
				() => {}
			);
			return JS;
		}
	},
	computed: {
		...mapState({
			operatorId: (state) => state.session.properties.GESTAUT_OPERATOR_ID
		}),
		filter() {
			return {
				groupOp: 'and',
				rules: [
					{ field: 'operatorid', op: 'eq', data: this.operatorId },
					{ field: 'documentid', op: 'eq', data: this.model.documentid }
				],
				groups: []
			};
		},
		SendItemsToSelect() {
			return [{ jointserviceid: this.modelo.jointserviceid }];
		}
	},
	async created() {
		await this.buildDocumentHeader();
		this.modelo.documentid = this.model.documentid;
		this.afterGetData();
		//comprobar si hay tramos
		if (this.model.documentstateid == 1 && this.model.operatorid == this.operatorId) {
			this.tramoDisable = false;
		}
	}
};
</script>
