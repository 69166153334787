<template>
	<div>
		<pui-datatable v-if="!masterDetail" :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>

		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:readOnly="true"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixinAccess'

export default {
	name: 'mequipmenttypegroup-grid',
	components: {},
	mixins: [PuiGridDetailMixin],
	data () {
		return {
			modelName: 'mequipmenttypegroup',
			actions: [],
			modelColumnDefs: {}
		}
	}
}
</script>
