const filters = {
	expirationDate() {
		return {
			groups: [
				{
					groupOp: 'and',
					rules: [{ field: 'dateexpiration', op: 'nu' }]
				}
			],
			groupOp: 'and',
			rules: []
		};
	},

	expirationDatePlus(did, field) {
		//TODO pasar a array para meter filtros de usuario
		if (!did || !field) {
			return this.expirationDate();
		}

		return {
			groups: [],
			groupOp: 'or',
			rules: [
				{ field: 'dateexpiration', op: 'nu' },
				{ field: field, op: 'eq', data: did }
			]
		};
	},
	expirationDateArray(array) {
		if (!array) {
			return this.expirationDate();
		}

		const reglas = {
			groupOp: 'and',
			groups: [
				{
					groupOp: 'or',
					groups: [],
					rules: []
				}
			],
			rules: [{ field: 'dateexpiration', op: 'nu' }]
		};

		array.forEach(function (item) {
			reglas.groups[0].rules.push({ field: item.field, op: 'eq', data: item.data });
		});
		return reglas;
	},
	dockFilter(array, portid) {
		if (!array) {
			return this.expirationDate();
		}

		const reglas = {
			groupOp: 'and',
			rules: [{ field: 'dateexpiration', op: 'nu' }],
			groups: [
				{
					groupOp: 'or',
					rules: [],
					groups: []
				}
			]
		};

		array.forEach(function (item) {
			let newGroup = {
				groupOp: 'and',
				rules: [
					{ field: 'countrycode', op: 'eq', data: item.countrycode },
					{ field: 'portcode', op: 'eq', data: item.portcode }
				],
				groups: []
			};

			reglas.groups[0].groups.push(newGroup);
		});

		if (!portid) {
			return reglas;
		} else {
			let newGroup2 = {
				groupOp: 'or',
				rules: [{ field: 'portid', op: 'eq', data: portid }],
				groups: []
			};

			reglas.groups[0].groups.push(newGroup2);
			return reglas;
		}
	},
	async getListLupa(ctx, modelName) {
		let r = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: modelName
				//filter: this.expirationDate()
			},
			(response) => {
				r = response.data.data;
			},
			() => {}
		);

		return r;
	},

	getDockDockLockFilter(did) {
		return {
			groups: [
				{
					groupOp: 'and',
					rules: [
						{ field: 'dockid', op: 'eq', data: did },
						{ field: 'dateexpiration', op: 'nu' }
					]
				}
			],
			groupOp: 'and',
			rules: []
		};
	},
	getDockDockLockFilterNot(did) {
		return {
			groups: [
				{
					groupOp: 'and',
					rules: [
						{ field: 'dateexpiration', op: 'nu' },
						{ field: 'dockid', op: 'ne', data: did }
					]
				}
			],
			groupOp: 'and',
			rules: []
		};
	},
	getDocTypeId(did) {
		return {
			groupOp: 'and',
			rules: [
				{ field: 'doctypeid', op: 'eq', data: did },
				{ field: 'dateexpiration', op: 'nu' }
			],
			groups: []
		};
	}
};

export default filters;
