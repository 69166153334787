import Vue from 'vue';

//GRIDS
import MlocalportGrid from '@/components/mlocalport/MlocalportGrid.vue';
import McountryGrid from '@/components/mcountry/McountryGrid.vue';
import SdsmcustomsGrid from '@/components/sdsmcustoms/SdsmcustomsGrid.vue';
import MvehiclebrandGrid from '@/components/mvehiclebrand/MvehiclebrandGrid.vue';
import MequipmenttypeGrid from '@/components/mequipmenttype/MequipmenttypeGrid.vue';
import SdsmstevedoreGrid from '@/components/sdsmstevedore/SdsmstevedoreGrid.vue';
import MpackagetypeGrid from '@/components/mpackagetype/MpackagetypeGrid.vue';
import MequipmentsizeGrid from '@/components/mequipmentsize/MequipmentsizeGrid.vue';
import SdsmoperatorGrid from '@/components/sdsmoperator/SdsmoperatorGrid.vue';
import MdockGrid from '@/components/mdock/MdockGrid.vue';
import MtaricGrid from '@/components/mtaric/MtaricGrid.vue';
import MdocklocationGrid from '@/components/mdocklocation/MdocklocationGrid.vue';
import MdockdocklocationGrid from '@/components/mdockdocklocation/MdockdocklocationGrid';
import MequipmenttypegroupGrid from '@/components/mequipmenttypegroup/MequipmenttypegroupGrid';

//FORMS
import MlocalportForm from '@/components/mlocalport/MlocalportForm.vue';
import McountryForm from '@/components/mcountry/McountryForm.vue';
import SdsmcustomsForm from '@/components/sdsmcustoms/SdsmcustomsForm.vue';
import MvehiclebrandForm from '@/components/mvehiclebrand/MvehiclebrandForm.vue';
import MequipmenttypeForm from '@/components/mequipmenttype/MequipmenttypeForm.vue';
import SdsmstevedoreForm from '@/components/sdsmstevedore/SdsmstevedoreForm.vue';
import MpackagetypeForm from '@/components/mpackagetype/MpackagetypeForm.vue';
import MequipmentsizeForm from '@/components/mequipmentsize/MequipmentsizeForm.vue';
import SdsmoperatorForm from '@/components/sdsmoperator/SdsmoperatorForm.vue';
import MdockForm from '@/components/mdock/MdockForm.vue';
import MtaricForm from '@/components/mtaric/MtaricForm.vue';
import MdocklocationForm from '@/components/mdocklocation/MdocklocationForm.vue';
import MdockdocklocationForm from '@/components/mdockdocklocation/MdockdocklocationForm';
import MequipmenttypegroupForm from '@/components/mequipmenttypegroup/MequipmenttypegroupForm';

//HEADERS
import MvehiclebrandHeader from '@/components/mvehiclebrand/MvehiclebrandFormHeader.vue';
import MequipmenttypeHeader from '@/components/mequipmenttype/MequipmenttypeFormHeader.vue';
import SdsmstevedoreHeader from '@/components/sdsmstevedore/SdsmstevedoreFormHeader.vue';
import MpackagetypeHeader from '@/components/mpackagetype/MpackagetypeFormHeader.vue';
import MequipmentsizeHeader from '@/components/mequipmentsize/MequipmentsizeFormHeader.vue';
import SdsmoperatorHeader from '@/components/sdsmoperator/SdsmoperatorFormHeader.vue';
import McountryHeader from '@/components/mcountry/McountryFormHeader.vue';
import MdockHeader from '@/components/mdock/MdockFormHeader.vue';
import SdsmdocumentHeader from '@/components/sdsmdocument/SdsmdocumentFormHeader.vue';
import MdockdocklocationHeader from '@/components/mdockdocklocation/MdockdocklocationFormHeader';
import MequipmenttypegroupHeader from '@/components/mequipmenttypegroup/MequipmenttypegroupFormHeader';

//GRIDS
Vue.component(MlocalportGrid.name, MlocalportGrid);
Vue.component(McountryGrid.name, McountryGrid);
Vue.component(SdsmcustomsGrid.name, SdsmcustomsGrid);
Vue.component(MvehiclebrandGrid.name, MvehiclebrandGrid);
Vue.component(MequipmenttypeGrid.name, MequipmenttypeGrid);
Vue.component(SdsmstevedoreGrid.name, SdsmstevedoreGrid);
Vue.component(MpackagetypeGrid.name, MpackagetypeGrid);
Vue.component(MequipmentsizeGrid.name, MequipmentsizeGrid);
Vue.component(SdsmoperatorGrid.name, SdsmoperatorGrid);
Vue.component(SdsmcustomsGrid.name, SdsmcustomsGrid);
Vue.component(MdockGrid.name, MdockGrid);
Vue.component(MtaricGrid.name, MtaricGrid);
Vue.component(MdockdocklocationGrid.name, MdockdocklocationGrid);
Vue.component(MequipmenttypegroupGrid.name, MequipmenttypegroupGrid);

//FORMS
Vue.component(MlocalportForm.name, MlocalportForm);
Vue.component(McountryForm.name, McountryForm);
Vue.component(SdsmcustomsForm.name, SdsmcustomsForm);
Vue.component(MvehiclebrandForm.name, MvehiclebrandForm);
Vue.component(MequipmenttypeForm.name, MequipmenttypeForm);
Vue.component(SdsmstevedoreForm.name, SdsmstevedoreForm);
Vue.component(MpackagetypeForm.name, MpackagetypeForm);
Vue.component(MequipmentsizeForm.name, MequipmentsizeForm);
Vue.component(SdsmoperatorForm.name, SdsmoperatorForm);
Vue.component(MdockForm.name, MdockForm);
Vue.component(MtaricForm.name, MtaricForm);
Vue.component(MdockdocklocationForm.name, MdockdocklocationForm);
Vue.component(MequipmenttypegroupForm.name, MequipmenttypegroupForm);

//HEADERS
Vue.component(MvehiclebrandHeader.name, MvehiclebrandHeader);
Vue.component(MequipmenttypeHeader.name, MequipmenttypeHeader);
Vue.component(SdsmstevedoreHeader.name, SdsmstevedoreHeader);
Vue.component(MpackagetypeHeader.name, MpackagetypeHeader);
Vue.component(MequipmentsizeHeader.name, MequipmentsizeHeader);
Vue.component(SdsmoperatorHeader.name, SdsmoperatorHeader);
Vue.component(McountryHeader.name, McountryHeader);
Vue.component(MdockHeader.name, MdockHeader);
Vue.component(SdsmdocumentHeader.name, SdsmdocumentHeader);
Vue.component(MdockdocklocationHeader.name, MdockdocklocationHeader);
Vue.component(MequipmenttypegroupHeader.name, MequipmenttypegroupHeader);

const maestrasRouter = [
	{
		path: 'mlocalport',
		component: MlocalportGrid
	},
	{
		path: '(.*)mlocalport/:method/:pk',
		props: true,
		component: MlocalportForm
	},
	{
		path: 'mcountry',
		component: McountryGrid
	},
	{
		path: '(.*)mcountry/:method/:pk',
		props: true,
		component: McountryForm
	},
	{
		path: 'mvehiclebrand',
		component: MvehiclebrandGrid
	},
	{
		path: '(.*)mvehiclebrand/:method/:pk',
		props: true,
		component: MvehiclebrandForm
	},
	{
		path: 'mequipmenttype',
		component: MequipmenttypeGrid
	},
	{
		path: '(.*)mequipmenttype/:method/:pk',
		props: true,
		component: MequipmenttypeForm
	},
	{
		path: 'sdsmstevedore',
		component: SdsmstevedoreGrid
	},
	{
		path: '(.*)sdsmstevedore/:method/:pk',
		props: true,
		component: SdsmstevedoreForm
	},
	{
		path: 'mpackagetype',
		component: MpackagetypeGrid
	},
	{
		path: '(.*)mpackagetype/:method/:pk',
		props: true,
		component: MpackagetypeForm
	},
	{
		path: 'sdsmcustoms',
		component: SdsmcustomsGrid
	},
	{
		path: '(.*)sdsmcustoms/:method/:pk',
		props: true,
		component: SdsmcustomsForm
	},
	{
		path: 'mequipmentsize',
		component: MequipmentsizeGrid
	},
	{
		path: '(.*)mequipmentsize/:method/:pk',
		props: true,
		component: MequipmentsizeForm
	},
	{
		path: 'sdsmoperator',
		component: SdsmoperatorGrid
	},
	{
		path: '(.*)sdsmoperator/:method/:pk',
		props: true,
		component: SdsmoperatorForm
	},
	{
		path: 'mdock',
		component: MdockGrid
	},
	{
		path: '(.*)mdock/:method/:pk',
		props: true,
		component: MdockForm
	},
	{
		path: 'mtaric',
		component: MtaricGrid
	},
	{
		path: '(.*)mtaric/:method/:pk',
		props: true,
		component: MtaricForm
	},
	{
		path: 'mdocklocation',
		component: MdocklocationGrid
	},
	{
		path: '(.*)mdocklocation/:method/:pk',
		props: true,
		component: MdocklocationForm
	},
	{
		path: 'mdockdocklocation',
		component: MdockdocklocationGrid
	},
	{
		path: '(.*)mdockdocklocation/:method/:pk',
		props: true,
		component: MdockdocklocationForm
	},
	{
		path: 'mequipmenttypegroup',
		component: MequipmenttypegroupGrid
	},
	{
		path: '(.*)mequipmenttypegroup/:method/:pk',
		props: true,
		component: MequipmenttypegroupForm
	}
];

export default {
	routes: maestrasRouter
};
