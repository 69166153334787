<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<sdsmstevedore-modals :modelName="modelName"></sdsmstevedore-modals>
	</div>
</template>

<script>
import sdsmstevedoreActions from './SdsmstevedoreActions';
import sdsmstevedoreModals from './SdsmstevedoreModals.vue';

export default {
	name: 'sdsmstevedore-grid',
	components: {
		'sdsmstevedore-modals': sdsmstevedoreModals
	},
	data() {
		return {
			modelName: 'sdsmstevedore',
			actions: sdsmstevedoreActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
