<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:navigableDetail="false"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixinAccess';

export default {
	name: 'sdsmmessageaudit-grid',
	mixins: [PuiGridDetailMixin],
	components: {},
	data() {
		return {
			modelName: 'sdsmmessageaudit',
			modelColumnDefs: {
				registerdate: {
					render: (data) => {
						return this.parseDate(data);
					}
				},
				creadate: {
					render: (data) => {
						return this.parseDate(data);
					}
				},
				moddate: {
					render: (data) => {
						return this.parseDate(data);
					}
				}
			}
		};
	},
	methods: {
		parseDate(data) {
			const fecha = new Date(data);
			return fecha.toLocaleString('es-ES', {
				timeZone: 'Europe/Madrid',
				hour12: false
			});
		}
	}
};
</script>
