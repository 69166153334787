<template>
	<div class="pui-form">
		<sdsmjointservice-modals :modelName="modelName"></sdsmjointservice-modals>

		<sdsmjointservice-form-header
			v-if="modelLoaded && headerData"
			:data="headerData"
			:alerts="alerts"
			:params="{ isCreatingElement }"
		></sdsmjointservice-form-header>

		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-tabs v-model="tabmodel" class="ml-4 mb-4 mr-4 mt-4" slider-color="primary" show-arrows>
					<v-tab :key="0" :href="'#maintab'">{{ $t('form.sdsmjointservice.formtitle') }}</v-tab>
					<v-tab :key="1" :href="'#sdsmbillofloading'" v-if="!isCreatingElement">{{ $t('form.sdsmjointservice.sdsmbillofloading') }}</v-tab>
					<v-tab :key="2" :href="'#sdsmuti'" v-if="!isCreatingElement">{{ $t('form.sdsmjointservice.sdsmuti') }}</v-tab>
					<v-tab :key="3" :href="'#sdsmgoods'" v-if="!isCreatingElement">{{ $t('form.sdsmjointservice.sdsmgood') }}</v-tab>
					<v-tab :key="4" :href="'#vutmessagejs'" v-if="!isCreatingElement">{{ $t('form.sdsmmessage.formtitle') }}</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tabmodel" style="width: 100% !important">
					<v-tab-item :key="0" :value="'maintab'">
						<v-col cols="12">
							<pui-field-set :title="$t('sdsmjointservice.jointserviceid')">
								<v-row dense>
									<v-col cols="12">
										<!-- DOCUMENTID -->
										<pui-select
											:id="`sdsmjointservice.document`"
											:attach="`sdsmjointservice.document`"
											:label="$t('sdsmjointservice.document')"
											:disabled="documentidDisabled || blockAll || blockMercancia || formDisabled || !isCreatingElement"
											toplabel
											modelName="sdsmdocument"
											v-model="model"
											reactive
											clearable
											:itemsToSelect="[{ documentid: this.model.documentid }]"
											:modelFormMapping="{ documentid: 'documentid' }"
											:itemValue="['documentid']"
											:itemTemplate="true"
											:itemText="''"
											:selectTemplate="true"
											:fixedFilter="filteredDOCID"
											outlined
											dense
											singleItemAutoselect
											@keyup="mainSelectorEvent"
											ref="vacMainRef"
											:order="{ documentid: 'desc' }"
										>
											<template v-slot:selectionTemplate="{ props, item }">
												<div class="SlotOptionWithDetails" v-bind="props">
													{{ $t('sdsmjointservice.documentid') }}:
													<span class="value">{{ item.documentid || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmjointservice.document') }}: <span class="value">{{ item.documentnumber || '-' }}</span
													><span class="separator">/</span>

													{{ $t('sdsmjointservice.operator') }}:
													<span class="value">{{ item.operator || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmbillofloading.callcode') }}:
													<span class="value">{{ item.callcode || '-' }} </span>
													<span class="separator">/</span>

													{{ $t('sdsmjointservice.date') }}:
													<span class="value">{{ $moment(item.etaatd).format('DD/MM/YYYY HH:mm') }}</span>
												</div>
											</template>
											<template v-slot:theTemplate="{ props, item }">
												<div class="SlotOptionWithDetails" v-bind="props">
													{{ $t('sdsmjointservice.documentid') }}:
													<span class="value">{{ item.documentid || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmjointservice.document') }}: <span class="value">{{ item.documentnumber || '-' }}</span
													><span class="separator">/</span>

													{{ $t('sdsmjointservice.operator') }}:
													<span class="value">{{ item.operator || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmbillofloading.callcode') }}:
													<span class="value">{{ item.callcode || '-' }} </span>
													<span class="separator">/</span>

													{{ $t('sdsmjointservice.date') }}:
													<span class="value">{{ $moment(item.etaatd).format('DD/MM/YYYY HH:mm') }}</span>
												</div>
											</template>
										</pui-select>
									</v-col>
								</v-row>
								<v-row dense>
									<!-- OPERATOR ID -->
									<v-col class="consignatarioClass">
										<pui-select
											id="`operatorid-sdsmjointservice`"
											attach="operatorid-sdsmjointservice"
											:label="$t('sdsmjointservice.operatorid')"
											toplabel
											clearable
											required
											reactive
											:disabled="disableByDoc() || blockAll || blockMercancia || formDisabled"
											v-model="model"
											modelName="sdsmoperator"
											:modelFormMapping="{ operatorid: 'operatorid' }"
											:itemsToSelect="operatorItemsToSelect"
											:itemValue="['operatorid']"
											:queryFields="['operatorid', 'description']"
											:itemText="(item) => `${item.operatorid} - ${item.description}`"
											:order="{ operatorid: 'asc' }"
											:fixedFilter="OperatorFilter"
											singleItemAutoselect
										></pui-select>
									</v-col>
									<!-- JOINT SERVICE NUMBER -->
									<v-col class="12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
										<pui-number-field
											:id="`jointservicenumber-sdsmjointservice`"
											v-model="model.jointservicenumber"
											:label="$t('sdsmjointservice.jointservicenumber')"
											:disabled="disableByDoc() || blockAll || formDisabled"
											required
											toplabel
											:rules="codartRules"
											max="999"
										></pui-number-field>
									</v-col>
									<v-col v-if="!isCreatingElement" class="12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
										<pui-text-field
											:id="`ordernumber-sdsmjointservice`"
											v-model="model.ordernumber"
											:label="$t('sdsmjointservice.ordernumber')"
											disabled
											toplabel
										></pui-text-field>
									</v-col>
								</v-row>

								<v-row dense>
									<v-col class="12 col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
										<!-- DOCK ID -->

										<pui-select
											id="`dockid-sdsmjointservice`"
											attach="dockid-sdsmjointservice"
											:label="$t('sdsmjointservice.dockid')"
											toplabel
											clearable
											reactive
											:disabled="disableByDoc() || blockAll || formDisabled"
											v-model="model"
											modelName="mdock"
											:modelFormMapping="{ dockid: 'dockid' }"
											:itemsToSelect="dockItemsToSelect"
											:itemValue="'dockid'"
											:queryFields="['description']"
											:itemText="(item) => `${item.description}`"
											:order="{ dockid: 'asc' }"
											:fixedFilter="filterDockid"
											singleItemAutoselect
										></pui-select>
									</v-col>
									<!-- DOCK LOCATION -->
									<v-col class="12 col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
										<pui-select
											id="`docklocationid-sdsmjointservice`"
											attach="docklocationid-sdsmjointservice"
											:label="$t('sdsmjointservice.docklocationid')"
											toplabel
											clearable
											reactive
											:disabled="disableByDoc() || blockAll || formDisabled"
											v-model="model"
											modelName="mdockdocklocation"
											:modelFormMapping="{ docklocationid: 'docklocationid' }"
											:itemsToSelect="[{ docklocationid: model.docklocationid }]"
											:itemValue="['docklocationid']"
											:itemText="(item) => `${item.docklocation} `"
											:order="{ docklocationid: 'asc' }"
											:fixedFilter="filterDocklocationid"
											singleItemAutoselect
										></pui-select>
									</v-col>
									<v-col class="12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
										<!-- ISSIMPREG -->
										<pui-text-field
											:id="`comcode-sdsmjointservice`"
											v-model="model.comcode"
											:label="$t('sdsmjointservice.comcode')"
											:disabled="disableByDoc() || blockAll || formDisabled"
											toplabel
											maxlength="3"
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>
							<pui-field-set :title="$t('sdsmjointservice.simreg')">
								<v-row dense>
									<v-col class="12 col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 pl-2">
										<pui-radio-group
											v-model="model.issimpreg"
											toplabel
											:disabled="blockAll || formDisabled"
											:label="$t('sdsmjointservice.simtran')"
											row
											column
											:radios="[
												{ id: '1', label: $t('general.yes') },
												{ id: '0', label: $t('general.no') }
											]"
										>
										</pui-radio-group>
									</v-col>
									<v-col class="12 col-xs-12 col-sm-7 col-md-7 col-lg-7 col-xl-7">
										<pui-select
											id="`simpregoperatorid-sdsmjointservice`"
											attach="simpregoperatorid-sdsmjointservice"
											:label="$t('sdsmjointservice.trts')"
											toplabel
											clearable
											reactive
											:disabled="blockAll || formDisabled || model.issimpreg != 1"
											:required="this.issimpregBool"
											v-model="model"
											modelName="sdsmoperator"
											singleItemAutoselect
											:queryFields="['description']"
											:modelFormMapping="{ operatorid: 'simpregoperatorid' }"
											:itemsToSelect="simpregoperatoridItemsToSelect"
											:itemValue="'operatorid'"
											:itemText="(item) => `${item.description}`"
											:fixedFilter="filterSimpregoperatorid"
										></pui-select>
									</v-col>
								</v-row>
							</pui-field-set>

							<sdsmjointservice-billofloadings
								v-if="!isCreatingElement"
								:jointservice="model"
								:opMode="opMode"
								:params="{ isCreatingElement, formDisabled }"
							>
							</sdsmjointservice-billofloadings>

							<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
						</v-col>
					</v-tab-item>
					<v-tab-item :key="1" :value="'sdsmbillofloading'">
						<pui-master-detail
							componentName="sdsmbillofloading-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ jointserviceid: 'jointserviceid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
							:readOnly="formDisabled"
							:showCreateBtn="!blockBuque"
							:showDeleteBtn="!blockBuque"
						></pui-master-detail>
					</v-tab-item>
					<v-tab-item :key="2" :value="'sdsmuti'">
						<pui-master-detail
							componentName="sdsmuti-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ jointserviceid: 'jointserviceid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
							:showCreateBtn="!blockBuque"
							:showDeleteBtn="!blockBuque"
						></pui-master-detail>
					</v-tab-item>
					<v-tab-item :key="3" :value="'sdsmgoods'">
						<pui-master-detail
							componentName="sdsmgood-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ jointserviceid: 'jointserviceid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
							:showCreateBtn="!blockBuque"
							:showDeleteBtn="!blockBuque"
						></pui-master-detail
					></v-tab-item>
					<v-tab-item :key="4" :value="'vutmessagejs'">
						<pui-master-detail
							componentName="vutmessagejs-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ jointserviceid: 'jointserviceid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
						></pui-master-detail
					></v-tab-item>
				</v-tabs-items>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					v-if="!blockAll"
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="SaveJSAndNew"
					:saveAndUpdate="SaveJSAndUpdate"
					:save="SaveJSAndBack"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import sdsmjointserviceActions from './SdsmjointserviceActions';
import sdsmjointserviceModals from './SdsmjointserviceModals.vue';
import formUtils from '@/utils/form';
import SdsmJointServiceUtils from './SdsmjointserviceUtils';
import SdsmjointserviceBillofloadings from './SdsmjointserviceBillofloadings.vue';
import filters from '@/utils/filters.js';
import { mapState } from 'vuex';
import { EntityStatusUtils } from '../../utils/entityStates';

export default {
	name: 'sdsmjointservice-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'sdsmjointservice-billofloadings': SdsmjointserviceBillofloadings,
		'sdsmjointservice-modals': sdsmjointserviceModals
	},
	data() {
		return {
			modelName: 'sdsmjointservice',
			actions: sdsmjointserviceActions.formactions,
			documentSelected: '',
			issimpregBool: false,
			jsnumber: [],
			jsnNext: '0',
			codartRules: [(v) => this.checkOperator(v) || this.$t('sdsmjointservice.orderinuse')],
			docOpId: '',
			opNumProv: '',
			jointserviceid: '',
			blockAll: false,
			blockBuque: false,
			opMode: [],
			BLsFromDoc: null,
			GoodsFromDoc: null,
			EQsState: null,
			EQsFromDoc: null,
			headerData: {},
			itemsHeader: [],
			blockMercancia: false,
			dateHd: null,
			consignatarioClass: '12 col-xs-12 col-sm-10 col-md-10 col-lg-10 col-xl-10',
			alerts: null,
			filters: {
				dockid: '',
				docklocationid: '',
				stateid: '',
				operatorid: '',
				simpregoperatorid: '',
				documentid: ''
			},
			documentidkey: 0,
			documentidDisabled: false
		};
	},
	methods: {
		async afterGetData() {
			formUtils.updateMiniAudit(this.model);
			this.formDisabled = !EntityStatusUtils.checkEditionAvailable(this.model.stateid);
			this.model.issimpreg = '0';
			this.jointserviceid = this.model.jointserviceid;

			this.filters.documentid = this.model.documentid;
			this.filters.dockid = this.model.dockid;
			this.filters.docklocationid = this.model.docklocationid;
			this.filters.stateid = this.model.stateid;
			this.filters.operatorid = this.model.operatorid;
			this.filters.simpregoperatorid = this.model.simpregoperatorid;

			// this.itemsHeader = await SdsmJointServiceUtils.getItemsHeader(this)
			this.opMode = await SdsmJointServiceUtils.getOperator(this, this.model, this.operatorId);

			if (!this.isCreatingElement) {
				this.AUTMod();
				this.consignatarioClass = '12 col-xs-12 col-sm-8 col-md-8 col-lg-8 col-xl-8';
				this.alerts = await SdsmJointServiceUtils.getAlerts(this, this.model.jointserviceid);
			}
		},
		async AUTMod() {
			if (!this.profiles.includes('CARGO_OPERATOR')) {
				this.opMode[2] = false;
			}

			if (!this.profiles.includes('SHIP_OPERATOR')) {
				this.opMode[1] = false;
			}

			if (this.opMode[1] && !this.opMode[2]) {
				//es operador de buque pero no es de mercancia
				this.blockBuque = true;
				//this.blockAll = true
			}

			if (!this.opMode[1] && this.opMode[2]) {
				//si no es op buque pero es de mercancia
				this.blockMercancia = true;
			}

			if (this.opMode[0]) {
				this.blockAll = true;
			}
		},
		mainSelectorEvent(event) {
			if (event.key == 'Backspace' && this.model.documentid != null) {
				this.model.documentid = null;
			}
		},
		refreshJSNumber() {
			this.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmjointservice',
					rows: null,
					filter: {
						groups: [
							{
								groupOp: 'and',
								rules: [{ field: 'documentid', op: 'eq', data: this.model.documentid }]
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					this.jsnumber = [];
					if (response?.data?.data && response.data.data.length > 0) {
						response.data.data.forEach((element) => {
							this.jsnumber.push(element.jointservicenumber);
						});
					}
					this.jsnumber.sort((a, b) => a - b);
				}
			);
		},
		disableByDoc() {
			return !this.documentSelected;
		},
		checkOperator(u) {
			let b = true;
			if (u == 0 && this.docOpId != this.model.operatorid) {
				return false;
			}

			if (this.jsnumber.length > 0 && this.opNumProv != u) {
				this.jsnumber.forEach((element) => {
					if (element == u) {
						if (!this.isCreatingElement) {
							if (this.model.jointservicenumber == u) {
								b = true;
							}
						} else {
							b = false;
						}
					}
				});
			}
			return b;
		},
		sugestJSnumber() {
			if (this.docOpId == this.model.operatorid) {
				this.opNumProv = 0;
			} else {
				this.opNumProv = 1;
			}
			this.jsnumber.forEach((element) => {
				if (element == this.opNumProv) {
					this.opNumProv++;
				}
			});
			this.model.jointservicenumber = this.opNumProv;
			return true;
		},
		// Save JS and New
		async SaveJSAndNew() {
			const data = await this.SaveJS();
			if (data) {
				this.refreshRouter();
			}
		},
		// Save JS and Keep editing
		async SaveJSAndUpdate() {
			const data = await this.SaveJS();
			if (data) {
				if (this.isCreatingElement) {
					this.goToUpdate(data);
				} else {
					this.refreshRouter();
				}
			}
		},
		// Save JS and Back
		async SaveJSAndBack() {
			const data = await this.SaveJS();
			if (data) {
				this.back();
			}
		},
		// Save JS
		async SaveJS() {
			let js = null;
			const valid = SdsmJointServiceUtils.checkBeforeSaving(this, this.model);
			if (valid) {
				this.saving = true;
				js = await SdsmJointServiceUtils.saveJointService(this, this.model);
				this.saving = false;
			}
			return js;
		}
	},
	watch: {
		'model.documentid'(newQuestion) {
			if (newQuestion != null) {
				this.documentSelected = newQuestion;
			} else {
				this.documentSelected = null;
			}
			this.headerData = null;
			this.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmdocument',
					rows: null,
					filter: {
						groups: [
							{
								groupOp: 'and',
								rules: [
									{ field: 'documentid', op: 'eq', data: this.model.documentid },
									{ field: 'langtra', op: 'eq', data: this.$store.getters.getUserLanguage }
								]
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(responsee) => {
					if (responsee?.data?.data && responsee.data.data.length > 0) {
						this.docOpId = responsee.data.data[0].operatorid;
					}
				}
			);

			this.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'vhdjointser',
					rows: null,
					filter: {
						groups: [
							{
								groupOp: 'and',
								rules: [
									{ field: 'documentid', op: 'eq', data: this.model.documentid },
									{ field: 'langtra', op: 'eq', data: this.$store.getters.getUserLanguage }
								]
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(responsee) => {
					if (responsee?.data?.data && responsee.data.data.length > 0) {
						if (this.isCreatingElement) {
							//no hay joint por creacion
							this.headerData = responsee.data.data[0];
							this.headerData.state = this.headerData.pending;
							this.headerData.stateid = 1;
						} else {
							// puede o no tener el joint asociado
							responsee.data.data.forEach((element) => {
								if (element.jointserviceid == this.model.jointserviceid) {
									this.headerData = element;
								}
							});
							//si no ha sido asignado, asignarlo
							if (this.headerData == null) {
								this.headerData = responsee.data.data[0];
							}
						}
					}
				}
			);

			this.refreshJSNumber();

			if (this.isCreatingElement) {
				this.sugestJSnumber();
			}
		},
		'model.issimpreg'(newQuestion) {
			if (newQuestion != null && newQuestion != 0) {
				this.issimpregBool = true;
			} else {
				this.issimpregBool = false;
				this.model.simpregoperatorid = '';
			}
		},
		'model.jointservicenumber'() {
			this.refreshJSNumber();
		},

		'model.operatorid'() {
			this.refreshJSNumber();

			if (this.isCreatingElement) {
				this.sugestJSnumber();
			}
		}
	},
	computed: {
		...mapState({
			operatorId: (state) => state.session.properties.GESTAUT_OPERATOR_ID,
			portsList: (state) => state.session.properties.GESTAUT_PROPERTIES_PORTS,
			profiles: (state) => state.session.properties.GESTAUT_PROPERTIES_PROFILES
		}),
		dateHdcal(item) {
			return this.$moment(item.etaatd).format('DD/MM/YYYY HH:mm');
		},
		OperatorFilter() {
			return SdsmJointServiceUtils.getFixedFilterOperator(this.model, this.isCreatingElement);
		},
		fixedDate() {
			return filters.expirationDate();
		},
		fixedDatePlus() {
			return filters.expirationDatePlus(this.model.dockid, 'dockid');
		},
		documentItemsToSelect() {
			return [{ documentid: this.model.documentid }];
		},
		operatorItemsToSelect() {
			if (this.opMode[0]) {
				return [{ operatorid: this.model.operatorid }];
			} else if (this.opMode[1]) {
				return [{ operatorid: this.model.operatorid }];
			} else if (this.opMode[2]) {
				return [{ operatorid: this.model.operatorid }].filter((b) => b.operatorid === this.operatorId);
			}
			return [{ operatorid: this.model.operatorid }];
		},
		dockItemsToSelect() {
			return [{ dockid: this.model.dockid }];
		},
		docklocationItemsToSelect() {
			return [{ docklocationid: this.model.docklocationid }];
		},
		simtranItemsToSelect() {
			return [{ simtranid: this.model.simtranid }];
		},
		simpregoperatoridItemsToSelect() {
			return [{ operatorid: this.model.simpregoperatorid }];
		},
		filterDockid() {
			if (this.headerData) {
				return filters.expirationDateArray([
					{ data: this.filters.dockid, field: 'dockid' },
					{ field: 'portid', data: this.headerData.portid }
				]);
			} else {
				return filters.expirationDatePlus(this.filters.dockid, 'dockid');
			}
		},
		filterDocklocationid() {
			return filters.getDockDockLockFilter(this.model.dockid);
		},
		filterStateid() {
			return filters.expirationDatePlus(this.filters.stateid, 'stateid');
		},
		filterOperatorid() {
			return filters.expirationDatePlus(this.filters.operatorid, 'operatorid');
		},
		filterSimpregoperatorid() {
			return filters.expirationDatePlus(this.filters.simpregoperatorid, 'simpregoperatorid');
		},
		filteredDOCID() {
			const filter = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'operatorid', op: 'eq', data: this.operatorId }]
			};

			if (!this.$attrs.parentpk && this.isCreatingElement) {
				filter.groups.push({
					groupOp: 'or',
					rules: EntityStatusUtils.editStates.map((stateId) => ({
						field: 'documentstateid',
						op: 'eq',
						data: stateId
					}))
				});
			}

			if (this.$attrs.parentpk && this.isCreatingElement) {
				const parentpk = JSON.parse(atob(this.$attrs.parentpk));
				if (parentpk.documentid) {
					filter.rules.push({ field: 'documentid', op: 'eq', data: parentpk.documentid });
				}
			}
			return filter;
		}
	},
	created() {}
};
</script>
