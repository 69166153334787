<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mtaric-modals
			:modelName="modelName"
		></mtaric-modals>
	</div>
</template>

<script>
import mtaricActions from './MtaricActions';
import mtaricModals from './MtaricModals.vue';

export default {
	name: 'mtaric-grid',
	components: {
		'mtaric-modals': mtaricModals
	},
	data() {
		return {
			modelName: 'mtaric',
			actions: mtaricActions.gridactions,
			modelColumnDefs: {
				ispassenger: {
					render: (data, type, row) => {
						return row.ispassenger === '1' ? `<i class="fas fa-check state-check"></i>` : '<i class="fal fa-times state-no"></i>'
					}
				},
				iscustomsexitmandatory: {
					render: (data, type, row) => {
						return row.iscustomsexitmandatory === '1' ? `<i class="fas fa-check state-check"></i>` : '<i class="fal fa-times state-no"></i>'
					}
				}
			}
		}
	}
}
</script>
