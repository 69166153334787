<template>
	<div class="pui-form">
		<mdockdocklocation-modals :modelName="modelName"></mdockdocklocation-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<mdockdocklocation-form-header :modelPk="modelPk"></mdockdocklocation-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<!-- DOCKID -->
						<v-col cols="2">
							<pui-text-field
								id="`dockid-mdockdocklocation`"
								v-model="model.dockid"
								:label="$t('mdockdocklocation.dockid')"
								disabled
								required
								toplabel
								maxlength="3"
							></pui-text-field>
						</v-col>
						<v-col cols="4">
							<pui-select
								id="`dockdes-mdockdocklocation`"
								attach="dockdes-mdockdocklocation"
								:label="$t('mdockdocklocation.dock')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="mdock"
								:modelFormMapping="{ dockid: 'dockid' }"
								:itemsToSelect="dockItemsToSelect"
								:itemValue="'dockid'"
								:itemText="item => `${item.description}`"
								:order="{ dockid: 'asc' }"
								:fixedFilter="fixedDock"
								singleItemAutoselect
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DOCKLOCATIONID -->
						<v-col cols="2">
							<pui-text-field
								id="`docklocationid-mdockdocklocation`"
								v-model="model.docklocationid"
								:label="$t('mdockdocklocation.docklocationid')"
								disabled
								required
								toplabel
								maxlength="3"
							></pui-text-field>
						</v-col>
						<v-col cols="4">
							<pui-select
								id="`docklocationdes-mdockdocklocation`"
								attach="docklocationdes-mdockdocklocation"
								:label="$t('mdockdocklocation.docklocation')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="mdocklocation"
								:modelFormMapping="{ docklocationid: 'docklocationid' }"
								:itemsToSelect="docklocationItemsToSelect"
								:itemValue="'docklocationid'"
								:itemText="item => `${item.description}`"
								:order="{ docklocationid: 'asc' }"
								:fixedFilter="fixedLocation"
								singleItemAutoselect
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DATEEXPIRATION -->
						<v-col cols="4" v-if="!isCreatingElement">
							<pui-date-field
								:id="`dateexpiration-mdockdocklocation`"
								v-model="model.dateexpiration"
								:label="$t('mdockdocklocation.dateexpiration')"
								disabled
								toplabel
								time
							></pui-date-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin'
import mdockdocklocationActions from './MdockdocklocationActions'
import mdockdocklocationModals from './MdockdocklocationModals.vue'
import filters from '@/utils/filters'

export default {
	name: 'mdockdocklocation-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mdockdocklocation-modals': mdockdocklocationModals
	},
	data () {
		return {
			modelName: 'mdockdocklocation',
			actions: mdockdocklocationActions.formActions,
			filters: {
				docklocationid: '',
				dockid: ''
			}
		}
	},
	methods: {
		afterGetData () {
			// Do something after get data from server

			this.filters.dockid = this.model.dockid
			this.filters.docklocationid = this.model.docklocationid
		}
	},
	computed: {
		dockItemsToSelect () {
			return [{ dockid: this.model.dockid }]
		},
		docklocationItemsToSelect () {
			return [{ docklocationid: this.model.docklocationid }]
		},
		fixedLocation () {
			return filters.expirationDatePlus(this.filters.docklocationid, 'docklocationid')
		},
		fixedDock () {
			return filters.expirationDatePlus(this.filters.dockid, 'dockid')
		}
	},
	created () {}
}
</script>
