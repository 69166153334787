export default {
	global: {
		appVersion: '1.0.0',
		appDefaultEntryPoint: 'home',
		baseUrl: '/pcsumserver',
		appContainerHeader: false,
		appLoginDisplayArea: true,
		appLoginActions: false,
		appUserPanelElement: false,
		containerActionHeader: true,
		langContainerActionHeader: false,
		//	timeFormat: 'HH:mm:ss',
		webSocketsEnabled: false
	},
	login: {
		forgetPassword: false
	},
	menu: {
		homeBtn: true,
		searchBtn: false,
		infoBtn: true,
		notificationsBtn: false,
		helpBtn: false,
		keepExpanded: false
	},
	form: {
		showSaveAndUpdate: true,
		showSaveAndNew: true
	},
	modal: {
		massiveCreate: {
			open: false,
			jointservice: null,
			billofloading: null
		},
		JSImportCSV: {
			open: false,
			jointservice: null
		},
		DocumentImportCSV: {
			open: false,
			document: null
		}
	},
	PCSUM: {
		tarcodes: []
	},
	PCS: {
		ddgg: [],
		locinfo: []
	}
};
