<template>
	<div>
		<pui-modal-dialog-form
			:titleText="$t('modal.mdockdocklocation.sample.title')"
			:modelName="modelName"
			:dialogName="mdockdocklocationSampleModal"
			:componentHeaderName="'mdockdocklocation-form-header'"
			:onOk="onOkSampleModal"
			:onShow="onShowSampleModal"
		>
			<template slot="message" slot-scope="data">
				<v-row dense>
					<!-- DOCKDOCKLOCATIONID -->
					<v-col cols="4">
						<pui-text-field
							:id="`dockdocklocationid-mdockdocklocation`"
							v-model="data.modalData.dockdocklocationid"
							:label="$t('mdockdocklocation.dockdocklocationid')"
							:disabled="formDisabled"
							required
							toplabel
							maxlength="12"
						></pui-text-field>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'mdockdocklocation-modals',
	data() {
		return {
			mdockdocklocationSampleModal: 'mdockdocklocationSampleAction'
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onOkSampleModal(modalData) {
			const params = {
				attr1: modalData.field1
			};

			const url = this.$store.getters.getModelByName(this.modelName).url.sampleUrl;
			const title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.mdockdocklocation.sampleaction.title');
			return new Promise((resolve) => {
				this.$puiRequests.postRequest(
					url,
					null,
					() => {
						// Valid response, do code and close modal
						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response?.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					},
					null,
					params
				);
			});
		},
		onShowSampleModal(/* modalData */) {
			// Put here code to execute before open modal.
			// Examples:
			// - Watch pui-select/pui-datatable with events
			// - change values or set new values "this.$set(modalData,'field3', null)"
			// ...
		}
	}
};
</script>
