<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:navigableDetail="false"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixinAccess'
export default {
	name: 'sdsmmessageelement-grid',
	mixins: [PuiGridDetailMixin],
	components: {},
	data () {
		return {
			modelName: 'sdsmmessageelement',
			modelColumnDefs: {}
		}
	}
}
</script>
