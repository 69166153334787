<template>
	<div class="pui-form">
		<template v-if="model && billOfLoadingHeaderData && ready">
			<sdsmuti-form-header
				:data="billOfLoadingHeaderData"
				:params="{}"
				style="margin: 0px !important; padding: 0px; margin-bottom: 8px !important"
			></sdsmuti-form-header>
			<v-form class="mb-4 pb-4" ref="form" lazy-validation @submit.prevent>
				<v-row class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('menu.sdsmuti')">
							<v-row dense>
								<!-- BLID -->
								<v-col cols="12 pl-2 pr-2">
									<pui-select
										:id="`blid-sdsmgood`"
										:attach="`blid-sdsmgood`"
										:label="$t('sdsmgood.bl')"
										:disabled="!isCreatingElement || formDisable"
										toplabel
										clearable
										modelName="vhdgood"
										v-model="model"
										reactive
										:itemsToSelect="[{ blid: this.model.blid }]"
										:modelFormMapping="{ blid: 'blid' }"
										:itemValue="['blid']"
										:itemTemplate="true"
										:itemText="''"
										:selectTemplate="true"
										:fixedFilter="filteredBLID"
									>
										<template v-slot:selectionTemplate="{ props, item }">
											<div class="SlotOptionWithDetails" v-bind="props">
												{{ $t('sdsmgood.blid') }} <span class="separator">:</span>
												<span class="value">{{ item.blid || '0' }}</span
												><span class="separator">/</span>

												{{ $t('sdsmgood.documentnumber') }} <span class="separator">:</span>
												<span class="value">{{ item.documentnumber || '0' }}</span
												><span class="separator">/</span> {{ $t('sdsmgood.cargooperator') }} <span class="separator">:</span>
												<span class="value">{{ item.operator || '0' }}</span> <span class="separator">/</span>
												{{ $t('sdsmgood.jointservicenumber') }}
												<span class="separator">:</span>
												<span class="value">{{ item.jointservicenumber !== null ? item.jointservicenumber : '0' }} </span
												><span class="separator">/</span> {{ $t('sdsmgood.conocimiento') }}
												<span class="separator">:</span>
												<span class="value"
													>{{ item.ordernumber || '0' }}<span class="separator">-</span> {{ item.blnumber || '0' }}</span
												><span class="separator">/</span> {{ $t('sdsmgood.bltype') }}<span class="separator">:</span>
												<span class="value">{{ item.bltype }} </span>
											</div>
										</template>
										<template v-slot:theTemplate="{ props, item }">
											<div class="SlotOptionWithDetails" v-bind="props">
												{{ $t('sdsmgood.blid') }} <span class="separator">:</span>
												<span class="value">{{ item.blid || '0' }}</span
												><span class="separator">/</span>

												{{ $t('sdsmgood.documentnumber') }} <span class="separator">:</span>
												<span class="value">{{ item.documentnumber || '0' }}</span
												><span class="separator">/</span> {{ $t('sdsmgood.cargooperator') }} <span class="separator">:</span>
												<span class="value">{{ item.operator || '0' }}</span> <span class="separator">/</span>
												{{ $t('sdsmgood.jointservicenumber') }}
												<span class="separator">:</span>
												<span class="value">{{ item.jointservicenumber !== null ? item.jointservicenumber : '0' }} </span
												><span class="separator">/</span> {{ $t('sdsmgood.conocimiento') }}
												<span class="separator">:</span>
												<span class="value"
													>{{ item.ordernumber || '0' }}<span class="separator">-</span> {{ item.blnumber || '0' }}</span
												><span class="separator">/</span> {{ $t('sdsmgood.bltype') }}<span class="separator">:</span>
												<span class="value">{{ item.bltype }} </span>
											</div>
										</template>
									</pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col class="12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<pui-select
										id="`eqtypeid-sdsmuti`"
										attach="eqtypeid-sdsmuti"
										:label="$t('sdsmuti.eqtypeid')"
										toplabel
										clearable
										required
										singleItemAutoselect
										v-model="model"
										modelName="mequipmenttype"
										:modelFormMapping="{ eqtypeid: 'eqtypeid' }"
										:itemsToSelect="eqtypeidItemsToSelect"
										:itemValue="'eqtypeid'"
										:itemText="item => `${item.edicode} - ${item.description}`"
										:order="{ description: 'asc' }"
										detailComponentName="sdsmuti-form"
										:fixedFilter="DateExpirationFilter"
									></pui-select>
								</v-col>

								<!-- UTISTATUSID -->
								<v-col class="12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
									<pui-select
										id="`utistatusid-sdsmuti`"
										attach="utistatusid-sdsmuti"
										:label="$t('sdsmuti.utistatusid')"
										toplabel
										clearable
										required
										singleItemAutoselect
										v-model="model"
										modelName="mutistatus"
										:modelFormMapping="{ utistatusid: 'utistatusid' }"
										:itemsToSelect="utistatusidItemsToSelect"
										:itemValue="'utistatusid'"
										:itemText="item => `${item.description}`"
										:order="{ description: 'asc' }"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col class="12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
									<pui-select
										id="`eqsizeid-sdsmuti`"
										attach="eqsizeid-sdsmuti"
										:label="$t('sdsmuti.eqsizeid')"
										toplabel
										clearable
										required
										singleItemAutoselect
										v-model="model"
										modelName="mequipmentsize"
										:modelFormMapping="{ eqsizeid: 'eqsizeid' }"
										:itemsToSelect="eqsizeidItemsToSelect"
										:itemValue="'eqsizeid'"
										:itemText="item => `${item.isocode} - ${item.description}`"
										:order="{ description: 'asc' }"
										detailComponentName="sdsmuti-form"
										:fixedFilter="DateExpirationFilter"
									></pui-select>
								</v-col>
								<v-col class="12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
									<pui-text-field
										:id="`tare-sdsmuti`"
										v-model="model.tare"
										:label="$t('sdsmuti.tare')"
										required
										toplabel
									></pui-text-field>
								</v-col>

								<v-col v-if="showUnits" class="12 col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
									<pui-number-field
										:id="`units-sdsmuti`"
										v-model="model.units"
										:label="$t('sdsmuti.units')"
										:required="showUnits"
										toplabel
									></pui-number-field>
								</v-col>

								<v-col
									class="12 col-xs-12 col-sm-12 mt-6"
									align="right"
									:class="{ ' col-md-2 col-lg-2 col-xl-2': showUnits, ' col-md-3 col-lg-3 col-xl-3': !showUnits }"
								>
									<pui-checkbox
										:id="`fullfilliso-sdsmuti`"
										:label="$t('sdsmuti.fullfilliso')"
										v-model="model.fullfilliso"
										true-value="1"
										false-value="0"
									></pui-checkbox>
								</v-col>
								<v-col class="12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
									<pui-text-field
										:id="`utiidentification-sdsmuti`"
										v-model="model.utiidentification"
										:label="$t('sdsmuti.utiidentification')"
										:required="model.fullfilliso === '1' || IdentificationRequired"
										toplabel
									></pui-text-field>
								</v-col>
							</v-row>
						</pui-field-set>
						<!-- SEALS -->
						<sdsmuti-form-seals ref="child" :uti="model" :params="{ isCreatingElement: true }"></sdsmuti-form-seals>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-else>
			<v-progress-linear color="primary" indeterminate></v-progress-linear>
		</template>
	</div>
</template>

<script>
import SdsmutiSealsVue from '../SdsmutiFormSeals.vue'
import SdsmUtiUtils from '../SdsmutiUtils'

export default {
	name: 'sdsmuti-form',
	components: {
		'sdsmuti-form-seals': SdsmutiSealsVue
	},
	props: {
		modeltemplate: { type: Object, default: () => {} }
	},
	data () {
		return {
			billOfLoadingHeaderData: null,
			ready: false
		}
	},
	methods: {
		async SaveWizard () {
			let uti = null
			if (this.ready) {
				const seals = this.$refs.child?.seals || []
				const valid = await SdsmUtiUtils.checkBeforeSaving(this, this.model, seals)
				if (valid) {
					// Ready flag
					this.ready = false
					// Saving
					uti = await SdsmUtiUtils.saveUTI(this, this.model, seals, this.isCreatingElement)
					// Ready flag
					this.ready = true
					if (uti) {
						// Notify
						//	this.$puiNotify.success(this.$t('general.copysuccess'))
						// Restoring template
						this.model = { ...this.modeltemplate }
						// Cleaning eqs and docs (when the component is visible)
						if (this.$refs.child) {
							this.$refs.child.seals = []
						}
					}
				}
			}
			return uti
		},
		async setHeaderData () {
			await this.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'vhdgood',
					rows: null,
					filter: {
						groups: [
							{
								groupOp: 'and',
								rules: [
									{ field: 'blid', op: 'eq', data: this.model.blid },
									{ field: 'langtra', op: 'eq', data: this.$store.getters.getUserLanguage }
								]
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				responsee => {
					if (responsee && responsee.data && responsee.data.data && responsee.data.data.length > 0) {
						this.billOfLoadingHeaderData = responsee.data.data[0]
					}
				}
			)
		}
	},
	computed: {
		showUnits () {
			const bl = this.billOfLoadingHeaderData
			return bl ? bl.isempty : false
		},
		IdentificationRequired () {
			const bl = this.billOfLoadingHeaderData
			return bl ? bl.isempty && (bl.ftedicode == 'ZZ4' || bl.ftedicode == 'ZZ5') : false
		},
		eqsizeidItemsToSelect () {
			return [{ eqsizeid: this.model.eqsizeid }]
		},
		eqtypeidItemsToSelect () {
			return [{ eqtypeid: this.model.eqtypeid }]
		},
		utistatusidItemsToSelect () {
			return [{ utistatusid: this.model.utistatusid }]
		},
		filteredBLID () {
			// Prepare basic filter
			const filter = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'operatorid', op: 'eq', data: this.operatorId },
					{ field: 'isempty', op: 'eq', data: 0 }
				]
			}
			return filter
		}
	},
	async created () {
		this.model = { ...this.modeltemplate }
		await this.setHeaderData()
		this.ready = true
	}
}
</script>
