<template>
	<div class="pui-form">
		<sdsmoperator-modals :modelName="modelName"></sdsmoperator-modals>
		<!-- <pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<sdsmoperator-form-header :modelPk="modelPk"></sdsmoperator-form-header>
		</pui-form-header> -->
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense v-if="!isCreatingElement">
						<!-- OPERATORID -->
						<v-col cols="1">
							<pui-text-field
								:id="`operatorid-sdsmoperator`"
								v-model="model.operatorid"
								:label="$t('sdsmoperator.operatorid')"
								disabled
								toplabel
								maxlength="12"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- FISCALCODE -->
						<v-col cols="2">
							<pui-text-field
								:id="`fiscalcode-sdsmoperator`"
								v-model="model.fiscalcode"
								:label="$t('sdsmoperator.fiscalcode')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="15"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DESCRIPTION -->
						<v-col cols="5">
							<pui-text-field
								:id="`description-sdsmoperator`"
								v-model="model.description"
								:label="$t('sdsmoperator.description')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DATEEXPIRATION -->
						<v-col cols="4" v-if="!isCreatingElement">
							<pui-date-field
								:id="`dateexpiration-sdsmoperator`"
								v-model="model.dateexpiration"
								:label="$t('sdsmoperator.dateexpiration')"
								disabled
								readonly
								toplabel
								maxlength="13"
								time
							></pui-date-field>
						</v-col>
					</v-row>
					<!-- MINI AUDIT -->
					<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin'
import sdsmoperatorActions from './SdsmoperatorActions'
import sdsmoperatorModals from './SdsmoperatorModals.vue'
import formUtils from '@/utils/form'

export default {
	name: 'sdsmoperator-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'sdsmoperator-modals': sdsmoperatorModals
	},
	data () {
		return {
			modelName: 'sdsmoperator',
			actions: sdsmoperatorActions.formActions
		}
	},
	methods: {
		afterGetData () {
			formUtils.updateMiniAudit(this.model)
		}
	},
	computed: {},
	created () {}
}
</script>
