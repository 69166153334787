<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:actions="actions"
			:externalFilter="externalFilter"
			:modelColumnDefs="modelColumnDefs"
			:showDeleteBtn="false"
		>
			<template slot="toolbarAdditionalBtns">
				<div class="pui-gridActionsBtn mt-2 pr-2" v-show="deleteSorted">
					<v-btn
						:id="`gridbtn-legend-${modelName}`"
						class="elevation-0 pui-toolbar__actionBtn primary white--text"
						:title="$t('general.confirmremove1title')"
						@click.native.stop="showDialog()"
						icon
						:disabled="deleteDisabled"
					>
						<v-icon>far fa-trash-alt</v-icon>
					</v-btn>
				</div>
			</template>
		</pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:masterDetail="masterDetail"
			:actions="actions"
			:externalFilter="externalFilter"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:modelColumnDefs="modelColumnDefs"
			:key="jsidkey"
			:showCreateBtn="showCreateBtn"
			:showUpdateBtn="true"
			:showDeleteBtn="false"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		>
			<template v-if="showDeleteBtn" slot="toolbarAdditionalBtns">
				<div class="pui-gridActionsBtn mt-2 pr-2" v-show="deleteSorted">
					<v-btn
						:id="`gridbtn-legend-${modelName}`"
						class="elevation-0 pui-toolbar__actionBtn primary white--text"
						:title="$t('general.confirmremove1title')"
						@click.native.stop="showDialog()"
						icon
						:disabled="deleteDisabled"
					>
						<v-icon>far fa-trash-alt</v-icon>
					</v-btn>
				</div>
			</template>
		</pui-datatable>

		<sdsmbillofloading-modals :modelName="modelName"></sdsmbillofloading-modals>
		<!-- MODAL START-->
		<v-dialog v-model="openDialogRemove" max-width="500">
			<v-card class="pa-2">
				<v-card-title class="pl-3 mb-4 headline">{{ $t('general.confirmremove1title') }}</v-card-title>
				<v-card-subtitle class="pl-3">{{ $t('general.confirmremove1subtitle') }}</v-card-subtitle>
				<v-card-subtitle class="pl-3" v-if="dialogLoading"
					><v-progress-linear color="primary" indeterminate></v-progress-linear
				></v-card-subtitle>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn style="box-shadow: none" @click="cancel()" :disabled="dialogLoading">{{ $t('general.cancel') }}</v-btn>
					<v-btn style="box-shadow: none" color="primary" @click="accept()" :disabled="dialogLoading">{{ $t('general.accept') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- MODAL END -->
	</div>
</template>

<script>
import sdsmbillofloadingActions from './SdsmbillofloadingActions';
import sdsmbillofloadingModals from './SdsmbillofloadingModals.vue';
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixinAccess';
import { mapState } from 'vuex';
import SdsmbillofloadingUtils from './SdsmbillofloadingUtils';
import { EntityStatusUtils } from '../../utils/entityStates';

export default {
	name: 'sdsmbillofloading-grid',
	components: {
		'sdsmbillofloading-modals': sdsmbillofloadingModals
	},
	mixins: [PuiGridDetailMixin],
	props: {
		jointserviceid: {
			required: false,
			type: Object
		},
		documentid: {
			required: false,
			type: Object
		},
		blockBuque: {
			required: false,
			type: Object
		}
	},
	data() {
		return {
			deleteSorted: false,
			modelName: 'sdsmbillofloading',
			actions: sdsmbillofloadingActions.gridactions,
			jsidkey: 0,
			modelColumnDefs: {
				state: {
					render: (data, type, row) => {
						return `<span class="state isGrid state-${this.modelName}-${row.blstateid}">${data}</span>`;
					}
				}
			},
			openDialogRemove: false,
			dialogLoading: false,
			deleteDisabled: false,
			selectedRows: []
		};
	},
	methods: {
		async afterGetData() {
			this.opMode = await SdsmbillofloadingUtils.getOperatorDoc(this, JSON.parse(atob(this.parentPk)), this.operatorId);
		},
		showDialog() {
			if (this.selectedRows.length != 0) {
				this.openDialogRemove = true;
			} else {
				this.$puiNotify.warning(this.$puiI18n.t('errors.warnremove'));
			}
		},
		cancel() {
			this.openDialogRemove = false;
		},
		async accept() {
			this.dialogLoading = true;
			let pk = this.selectedRows[0].blid;
			let params = { data: pk };
			await this.$puiRequests.deleteRequest(
				'/sdsmbillofloading/deleteBL',
				null,
				() => {
					this.openDialogRemove = false;
					this.dialogLoading = false;
					document.getElementById('gridbtn-refresh-sdsmbillofloading').click();
					this.selectedRows = [];
				},
				(e) => {
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response?.data) {
						message = e.response.data.message;
					}
					this.$puiNotify.error(message);
					this.openDialogRemove = false;
					this.dialogLoading = false;
				},
				params,
				null
			);
		}
	},
	created() {
		this.jsidkey++;
		if (this.masterDetail) {
			this.afterGetData();
		}
	},
	mounted() {
		setTimeout(() => {
			this.$puiEvents.$on(`onPui-selectItem-dataTable-${this.modelName}`, (selectedRows) => {
				this.selectedRows = selectedRows.map((row) => {
					const rowValue = Object.values(row)[0];
					this.deleteDisabled = !EntityStatusUtils.checkEditionAvailable(rowValue.blstateid);
					return rowValue;
				});
			});

			const btns = this.$el.querySelectorAll('.pui-gridActionsBtn');
			const deleteBtn = btns[3];
			btns[3].parentNode.removeChild(btns[3]);
			btns[2].parentNode.insertBefore(deleteBtn, btns[2]);
			this.deleteSorted = true;
		}, 100);
	},
	destroyed() {
		this.$puiEvents.$off(`onPui-selectItem-dataTable-${this.modelName}`);
	},
	computed: {
		...mapState({
			operatorId: (state) => state.session.properties.GESTAUT_OPERATOR_ID
		}),

		showBtnCreate() {
			return this.operatorId;
		}
	}
};
</script>
