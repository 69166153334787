<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:actions="actions"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:showDeleteBtn="false"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
		<vutmessagedoc-modals :modelName="modelName"></vutmessagedoc-modals>
	</div>
</template>

<script>
import vutmessagedocActions from './VutmessagedocActions';
import vutmessagedocModals from './VutmessagedocModals.vue';
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixinAccess';

export default {
	name: 'vutmessagedoc-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		'vutmessagedoc-modals': vutmessagedocModals
	},
	data() {
		return {
			modelName: 'vutmessagedoc',
			actions: vutmessagedocActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
