<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<mcountry-modals :modelName="modelName"></mcountry-modals>
	</div>
</template>

<script>
import mcountryActions from './McountryActions';
import mcountryModals from './McountryModals.vue';

export default {
	name: 'mcountry-grid',
	components: {
		'mcountry-modals': mcountryModals
	},
	data() {
		return {
			modelName: 'mcountry',
			actions: mcountryActions.gridactions,
			modelColumnDefs: {
				iseu: {
					render: (data) => {
						return data === '1' ? `<i class="fas fa-check state-check"></i>` : '<i class="fal fa-times state-no"></i>'
					}
				},
				isactive: {
					render: (data) => {
						return data === '1' ? `<i class="fas fa-check state-check"></i>` : '<i class="fal fa-times state-no"></i>'
					}
				}
			}
		};
	},
	computed: {
		returnYes() {
			return this.$t('yes');
		},

		returnNo() {
			return this.$t('no');
		}
	}
};
</script>
