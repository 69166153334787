<template>
	<pcsum-edition-grid
		:label="$t('sdsmgood.equipments')"
		:labelEmpty="$t('sdsmgood.noequipments')"
		:rows="equipmentsGood"
		:fields="fields"
		:params="params"
		:loading="loading"
		:allowCreate="!formDisabled"
		:allowEdit="!formDisabled"
		:allowClone="!formDisabled"
		:allowRemove="!formDisabled"
		isNewSearchField="utiidentification"
		:isSelectedEditableFields="['utiidentification', 'packagenumber']"
		:isEditedEditableFields="['packagenumber']"
		@EmitCreate="EmitCreate"
		@EmitRefresh="EmitRefresh"
		@EmitRemove="EmitRemove"
		@EmitChange="EmitChange"
		@EmitClone="EmitClone"
		@EmitLaunch="EmitLaunch"
	></pcsum-edition-grid>
</template>

<script>
import PcsumEditionGrid from '../general/PcsumEditionGrid';
import SdsmGoodUtils from './SdsmgoodUtils';
import filters from '@/utils/filters';

export default {
	name: 'sdsmgood-form-equipments',
	components: {
		'pcsum-edition-grid': PcsumEditionGrid
	},
	props: {
		good: { type: Object, default: () => {} },
		params: { type: Object, default: () => {} }
	},
	data() {
		return {
			loading: false,
			equipmentsGood: [],
			equipmentsBL: [],
			formDisabled: true,
			eqsizeending1: [80, 81, 82, 83, 85, 86, 87, 88], // From PUI_VARIABLE -> TODO
			emptyRegistry: {
				utiidentification: null,
				fullfilliso: '1',
				eqtypeid: null,
				eqsizeid: null,
				tare: null,
				packagenumber: 0,
				utistatusid: null,
				goodid: this.good?.goodid,
				creadate: null,
				moddate: null
			},
			fields: {
				utiidentification: {
					label: 'sdsmgood.utiidentification',
					cols: '12 col-xs-12 col-sm-6 col-md-6 col-lg-2 col-xl-2'
				},
				eqtypeid: {
					label: 'sdsmgood.eqtypeid',
					cols: '12 col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3',
					rules: [(value) => !!value || this.$t('requiredMessage')],
					itemText: ['edicode', 'description'],
					modelName: 'mequipmenttype',
					filter: filters.expirationDate()
				},
				eqsizeid: {
					label: 'sdsmgood.eqsizeid',
					cols: '12 col-xs-12 col-sm-6 col-md-6 col-lg-2 col-xl-2',
					rules: [(value) => !!value || this.$t('requiredMessage')],
					itemText: ['isocode', 'description'],
					modelName: 'mequipmentsize',
					filter: filters.expirationDate()
				},
				tare: {
					label: 'sdsmgood.tare',
					rules: [(value) => value > 0 || this.$t('requiredMessage')],
					cols: '12 col-xs-12 col-sm-6 col-md-6 col-lg-1 col-xl-1'
				},
				packagenumber: {
					label: 'sdsmgood.packagenumber',
					rules: [(value) => value >= 0 || this.$t('requiredMessage')],
					cols: '12 col-xs-12 col-sm-6 col-md-6 col-lg-1 col-xl-1'
				},
				utistatusid: {
					label: 'sdsmgood.utistatusid',
					rules: [(value) => !!value || this.$t('requiredMessage')],
					cols: '12 col-xs-12 col-sm-6 col-md-6 col-lg-2 col-xl-2',
					itemText: ['edicode', 'description'],
					modelName: 'mutistatus'
				},
				fullfilliso: {
					label: 'sdsmgood.fullfilliso',
					cols: '12 col-xs-12 col-sm-6 col-md-6 col-lg-1 col-xl-1',
					itemText: ['label'],
					items: [
						{ fullfilliso: '0', label: this.$t('sdsmgood.isfullfilliso.no') },
						{ fullfilliso: '1', label: this.$t('sdsmgood.isfullfilliso.yes') }
					]
				}
			}
		};
	},
	watch: {
		'good.blid'(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.getEquipmentsFromBL();
			}
		}
	},
	methods: {
		getEquipmentsFromBL() {
			if (this.good.blid) {
				const self = this;
				this.$puiRequests.postRequest(
					'/puisearch',
					{
						model: 'vututifrombl',
						rows: null,
						filter: {
							groups: [],
							groupOp: 'and',
							rules: [{ field: 'blid', op: 'eq', data: self.good.blid }]
						}
					},
					(response) => {
						self.equipmentsBL = response.data.data;
					},
					() => {}
				);
			}
		},
		getEquipmentsFromGood() {
			if (this.good.goodid) {
				this.loading = true;
				this.$puiRequests.postRequest(
					'/puisearch',
					{
						model: 'sdsmgooduti',
						rows: null,
						filter: {
							groups: [],
							groupOp: 'and',
							rules: [{ field: 'goodid', op: 'eq', data: this.good.goodid }]
						}
					},
					(response) => {
						this.loading = false;
						this.equipmentsGood = [...response.data.data];
						for (let i = 0; i < this.equipmentsGood.length; i++) {
							this.equipmentsGood[i].isNew = false;
							this.equipmentsGood[i].isSelected = false;
							this.equipmentsGood[i].isEdited = false;
							this.equipmentsGood[i].randomKey = Math.floor(Math.random() * 1000);
						}
					},
					() => {
						this.loading = false;
					}
				);
			}
		},
		calculatePackagesNumber() {
			let goodpackagesnumber = parseInt(this.good.packagesnumber);
			let packagenumber = 0;
			for (let i = 0; i < this.equipmentsGood.length; i++) {
				packagenumber += parseInt(this.equipmentsGood[i].packagenumber);
			}
			return goodpackagesnumber > packagenumber ? goodpackagesnumber - packagenumber : 0;
		},
		/* Create empty Row */
		EmitCreate() {
			this.equipmentsGood.push({
				...this.emptyRegistry,
				...{
					isNew: true,
					isSelected: false,
					isEdited: false,
					items: this.filterEquipmentsBL,
					packagenumber: this.calculatePackagesNumber()
				}
			});
		},
		/* Editing new row */
		EmitChange(registry) {
			// Status 4, packages 0
			registry.packagenumber = registry.utistatusid === 4 ? 0 : registry.packagenumber;
			const index = registry.rowIndex;
			this.equipmentsGood[index] = { ...this.equipmentsGood[index], ...registry };
		},
		/* Deleting row */
		EmitRemove(registry) {
			this.equipmentsGood.splice(registry.rowIndex, 1);
			SdsmGoodUtils.deleteGOODUTI(this, registry);
		},
		EmitRefresh() {
			this.getEquipmentsFromGood();
		},
		EmitClone(registry) {
			this.equipmentsGood.push({
				...registry,
				...{
					goodutiid: null,
					utiid: null,
					utiidentification: null,
					isNew: true,
					isSelected: false,
					isEdited: false,
					items: this.filterEquipmentsBL,
					packagenumber: this.calculatePackagesNumber()
				}
			});
		},
		EmitLaunch() {}
	},
	computed: {
		filterEquipmentsBL() {
			return this.equipmentsBL;
		}
	},
	created() {
		this.getEquipmentsFromBL();
		this.getEquipmentsFromGood();
		this.formDisabled = this.params.formDisabled;
	}
};
</script>
