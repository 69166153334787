const ImportCSV = {
	columns: {
		DOCUMENTNUMBER: 0,
		DOCTYPE: 1,
		JOINTSERVICENUMBER: 2,
		SHIPIMO: 3,
		SHIPNAME: 4,
		SHIPFLAG: 5,
		VOYAGENUMBER: 6,
		CALLFIRSTENTRY: 7,
		CALLLASTPORT: 8,
		CALLNEXTPORT: 9,
		STEVEDORE_CODE: 10,
		DOCK_CODE: 11,
		OPERATOR_CODE: 12,
		GOOD_SERVICE: 13,
		ETA_ATD: 14,
		MANIPULATIONTERMINAL: 15,
		MOVGOODID: 16,
		REGSER_CODE: 17,
		SERVICENUMBER: 18,
		SIMTRAN_CODE: 19,
		COMCODE: 20,
		ISIMPREG: 21,
		BLNUMBER: 22,
		BLTYPE_CODE: 23,
		ORIGIN_CODE: 25,
		LOAD_CODE: 26,
		TRANSHIPMENT_CODE: 27,
		DESTINATION_CODE: 28,
		STEVEDORE_CODE_BL: 29,
		DOCK_CODE_BL: 30,
		GOODSERVICE: 31,
		COMCODE_BL: 32,
		SHIPIMO_BL: 33,
		MANIPULATIONTERMINAL_BL: 34,
		GOODTYPE_CODE: 35,
		FRONTREARTRANS_CODE: 36,
		DSMCFRONTREAR: 37,
		ORDERNUMBERFRONTREAR: 38,
		PACKAGESNUMBER: 40,
		WEIGHT: 41,
		VOLUME: 42,
		PACKAGETYPE_CODE: 43,
		TARIC_CODE: 44,
		VEHBRA_CODE: 46,
		MARKINGINSTR_CODE: 47,
		CUSSITUATION_CODE: 48,
		IMDGUNONUMBER: 49,
		IMDGUNODESC: 49,
		IMDGPAGE: 50,
		IMDGVERSION: 51,
		IMDGCLASS: 52,
		STEVEDORE_CODE_GOOD: 53,
		COMCODE_GOOD: 54,
		SPECIALINSTUSE: 55,
		MANIPULATIONTERMINAL_GOOD: 56,
		ISMANDATORYENS: 57,
		DSDTNUMBER: 60,
		ORDERNUMBERGOODDSDT: 61,
		UTIIDENTIFICATION: 62,
		UTISTATUS_CODE: 63,
		EQTYPE_CODE: 64,
		EQSIZE_CODE: 65,
		TARE: 66,
		UNITS: 67,
		PACKAGENUMBER: 67,
		SEAL: 69,
		DOCUMENTCSTYPE_CODE: 70,
		DOCUMENTCSDATE: 71,
		DOCUMENTCSNUMBER: 72,
		DOCUMENTCSSTATUS_CODE: 73
	},
	/**
	 * Import document from CSV
	 * @param {*} document
	 * @returns
	 */
	buildDocument(fileData, templateDocument) {
		const builtData = {
			document: { ...templateDocument },
			message: null,
			progress: 0,
			errors: []
		};

		// Setting document fields
		builtData.message = 'modal.importCSV.processing1';
		builtData.progress = 5;
		const row = fileData[0]; // 1 document per CSV
		builtData.document.documentnumber = row[this.columns.DOCUMENTNUMBER].padStart('11', '0');
		builtData.document.doctypeid = row[this.columns.DOCTYPE] === 'C' ? 1 : 2;
		builtData.document.regser_code = row[this.columns.REGSER_CODE]; // check -> set regserid
		builtData.document.servicenumber = row[this.columns.SERVICENUMBER] || null;
		builtData.document.simtran_code = row[this.columns.SIMTRAN_CODE]; // check -> set simtranid
		builtData.document.movgoodid = row[this.columns.MOVGOODID];
		builtData.document.operator_code = row[this.columns.OPERATOR_CODE]; // check -> set operatorid
		builtData.document.shipimo = row[this.columns.SHIPIMO];
		builtData.document.shipname = row[this.columns.SHIPNAME];
		builtData.document.shipflag = row[this.columns.SHIPFLAG];
		builtData.document.calllastport = row[this.columns.CALLLASTPORT];
		builtData.document.callnextport = row[this.columns.CALLNEXTPORT];
		builtData.document.callfirstentry_code = row[this.columns.CALLFIRSTENTRY];
		builtData.document.callfirstentry = null;
		builtData.document.etaatd = this.parseDate(row[this.columns.ETA_ATD]);
		builtData.document.stevedore_code = row[this.columns.STEVEDORE_CODE]; // check -> set stevedoreid
		builtData.document.dock_code = row[this.columns.DOCK_CODE]; // check -> set dockid
		builtData.document.voyagenumber = row[this.columns.VOYAGENUMBER] || null;
		builtData.document.goodservice = row[this.columns.GOOD_SERVICE] || null;
		builtData.document.manipulationterminal = row[this.columns.MANIPULATIONTERMINAL] || null;
		builtData.document.callyear = new Date().getFullYear();
		builtData.document.jointservices = [];
		return builtData;
	},
	/**
	 * Building Joint Service from CSV
	 * @param {*} js
	 * @param {*} fileData
	 * @returns
	 */
	buildJointServices(builtData, fileData, templateJS) {
		builtData.message = 'modal.importCSV.processing2';
		builtData.progress = 10;
		for (const row of fileData) {
			if (row[this.columns.JOINTSERVICENUMBER]) {
				const jointservicenumber = parseInt(row[this.columns.JOINTSERVICENUMBER]);
				const documentNumber = row[this.columns.DOCUMENTNUMBER].padStart('11', '0');
				if (
					!builtData.document.jointservices.find(
						(j) => j.jointservicenumber === jointservicenumber && builtData.document.documentnumber === documentNumber
					)
				) {
					const jointservice = { ...templateJS, ...ImportCSV.emptyUserFields() };
					const issimpreg = row[this.columns.ISIMPREG]?.toLowerCase()?.includes('s') ? '1' : '0';
					jointservice.comcode = row[this.columns.COMCODE] || null;
					jointservice.dock_code = row[this.columns.DOCK_CODE] || null;
					jointservice.jointservicenumber = jointservicenumber;
					jointservice.issimpreg = issimpreg;
					jointservice.simpregoperator_code = issimpreg === '1' ? row[this.columns.OPERATOR_CODE] || null : null;
					jointservice.operator_code = row[this.columns.OPERATOR_CODE];
					jointservice.billofloadings = [];
					builtData.document.jointservices.push(jointservice);
				}
			}
		}

		return builtData;
	},

	/**
	 * Applying system JointService
	 * @param {*} jointservice
	 * @param {*} builtData
	 * @returns
	 */
	applySystemJointService(jointservice, builtData) {
		if (jointservice.documentnumber === builtData.document.documentnumber) {
			const index = builtData.document.jointservices.findIndex((j) => j.jointservicenumber === jointservice.jointservicenumber);
			if (index > -1) {
				const newData = { ...builtData.document.jointservices[index] };
				if (newData.comcode) {
					jointservice.comcode = newData.comcode;
				}
				if (newData.dock_code) {
					jointservice.dock_code = newData.dock_code;
				}
				if (newData.issimpreg) {
					jointservice.issimpreg = newData.issimpreg;
				}
				if (newData.simpregoperator_code) {
					jointservice.simpregoperator_code = newData.simpregoperator_code;
				}
			}
			jointservice.billofloadings = [];
			builtData.document.jointservices = [jointservice];
		} else {
			builtData.document.jointservices = [];
		}
		return builtData;
	},

	/**
	 * Building Bill of ladings from CSV
	 * @param {*} ctx
	 * @param {*} builtData
	 * @param {*} fileData
	 */
	buildBillofLoadings(builtData, fileData, templateBL) {
		builtData.message = 'modal.importCSV.processing3';
		builtData.progress = 15;
		for (const T in builtData.document.jointservices) {
			const jointservicenumber = builtData.document.jointservices[T].jointservicenumber;
			const rows = fileData.filter(
				(r) =>
					parseInt(r[this.columns.JOINTSERVICENUMBER]) === jointservicenumber &&
					r[this.columns.DOCUMENTNUMBER].padStart('11', '0') === builtData.document.documentnumber
			);
			for (const row of rows) {
				if (row[this.columns.BLTYPE_CODE] && row[24]) {
					const ordernumber = String(row[24]).padStart('4', '0');
					if (!builtData.document.jointservices[T].billofloadings.find((b) => b.ordernumber === ordernumber)) {
						const billofloading = { ...templateBL, ...ImportCSV.emptyUserFields() };
						billofloading.jointserviceid = builtData.document.jointservices[T].jointserviceid;
						billofloading.bltypeid = null;
						billofloading.bltype_code = row[this.columns.BLTYPE_CODE] || null; // check -> set bltypeid
						billofloading.blnumber = row[this.columns.BLNUMBER] || null;
						billofloading.ordernumber = ordernumber;
						billofloading.origincode = row[this.columns.ORIGIN_CODE]; // check -> set origindes
						billofloading.loadcode = row[this.columns.LOAD_CODE]; // check -> set loaddes
						billofloading.transhipmentcode = row[this.columns.TRANSHIPMENT_CODE]; // check -> set transhipmentdes
						billofloading.destinationcode = row[this.columns.DESTINATION_CODE]; // check -> set destinationdes
						billofloading.frontreartransid = null;
						billofloading.frontreartrans_code = row[this.columns.FRONTREARTRANS_CODE] || null; // check -> set frontreartransid
						billofloading.goodtype_code = row[this.columns.GOODTYPE_CODE] || null; // check -> set goodtypeid
						billofloading.stevedore_code = row[this.columns.STEVEDORE_CODE_BL] || null; // check -> set stevedoreid
						billofloading.dock_code = row[this.columns.DOCK_CODE_BL] || null; // check --> set dockid + first docklocationid
						billofloading.comcode = row[this.columns.COMCODE_BL];
						billofloading.manipulationterminal = row[this.columns.MANIPULATIONTERMINAL_BL];
						billofloading.shipimo = row[this.columns.SHIPIMO_BL];
						billofloading.goodservice = row[this.columns.GOODSERVICE];
						billofloading.blstateid = 1;
						billofloading.dsmcfrontrear = row[this.columns.DSMCFRONTREAR];
						billofloading.ordernumberfrontrear = row[this.columns.ORDERNUMBERFRONTREAR];
						billofloading.goods = [];
						billofloading.utis = [];
						builtData.document.jointservices[T].billofloadings.push(billofloading);
					}
				}
			}
		}

		return builtData;
	},

	/**
	 * Building Goods from CSV
	 * @param {*} builtData
	 * @param {*} fileData
	 */
	buildGoods(builtData, fileData, templateGood) {
		builtData.message = 'modal.importCSV.processing4';
		builtData.progress = 20;
		for (const T in builtData.document.jointservices) {
			// Joint service number
			const jointservicenumber = builtData.document.jointservices[T].jointservicenumber;
			for (const B in builtData.document.jointservices[T].billofloadings) {
				// Bill of lading type
				const bltype_code = builtData.document.jointservices[T].billofloadings[B].bltype_code;
				const blnumber = builtData.document.jointservices[T].billofloadings[B].blnumber;
				const ordernumber = builtData.document.jointservices[T].billofloadings[B].ordernumber;
				// Catching Goods from the Bill of lading
				const rows = fileData.filter(
					(r) =>
						blnumber === r[this.columns.BLNUMBER] &&
						String(r[24]).padStart('4', '0') === ordernumber &&
						r[this.columns.DOCUMENTNUMBER].padStart('11', '0') === builtData.document.documentnumber &&
						parseInt(r[this.columns.JOINTSERVICENUMBER]) === jointservicenumber
				);
				for (const row of rows) {
					if (row[39]) {
						const good = { ...templateGood, ...ImportCSV.emptyUserFields() };
						good.goodid = null;
						good.blid = null;
						good.ordernumber = String(jointservicenumber).padStart('2', '0') + String(row[39]).padStart('3', '0');
						good.packagesnumber = parseInt(row[this.columns.PACKAGESNUMBER] || 0);
						good.packagetypeid = null;
						good.packagetype_code = bltype_code !== 'ZZ2' ? row[this.columns.PACKAGETYPE_CODE] : null;
						good.stevedoreid = null;
						good.stevedore_code = row[this.columns.STEVEDORE_CODE_GOOD] || null; // check -> set stevedoreid
						good.weight = bltype_code !== 'ZZ2' ? parseInt(row[this.columns.WEIGHT]) || 0 : null;
						good.volume = bltype_code !== 'ZZ2' ? parseInt(row[this.columns.VOLUME]) || null : null;
						good.taric_code = row[this.columns.TARIC_CODE] ? String(row[this.columns.TARIC_CODE]).padStart('4', '0') : null;
						good.vehbra_code = row[this.columns.VEHBRA_CODE] || null; // check -> set vehbraid
						good.markinginstr_code = row[this.columns.MARKINGINSTR_CODE] || null; // check -> set markinginstrid
						good.cussituation_code = row[this.columns.CUSSITUATION_CODE] || null; // check -> set cussituationid
						good.imdgclass = row[this.columns.IMDGCLASS] || null;
						good.imdgpage = row[this.columns.IMDGPAGE] || null;
						good.imdgversion = row[this.columns.IMDGVERSION] || null;
						good.imdgunonumber = row[this.columns.IMDGUNONUMBER] || null;
						good.imdgunodesc = row[this.columns.IMDGUNONUMBER] || null; // check?
						good.comcode = row[this.columns.COMCODE_GOOD] || null;
						good.manipulationterminal = row[this.columns.MANIPULATIONTERMINAL_GOOD] || null;
						good.specialinstuse = row[this.columns.SPECIALINSTUSE] || null;
						good.ismandatoryens = row[this.columns.ISMANDATORYENS] || null;
						good.dsdtnumber = row[this.columns.DSDTNUMBER] || null;
						good.ordernumbergooddsdt = row[this.columns.ORDERNUMBERGOODDSDT] || null;
						good.stateid = 1;
						good.utis = [];
						good.docs = [];

						builtData.document.jointservices[T].billofloadings[B].goods.push(good);
					}
				}
			}
		}
		return builtData;
	},

	/**
	 * Building Utis
	 * @param {*} builtData
	 * @param {*} fileData
	 * @param {*} templateUTI
	 * @returns
	 */
	buildUtis(builtData, fileData, templateUTI) {
		builtData.message = 'modal.importCSV.processing5';
		builtData.progress = 25;
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				// ZZ1/ZZ2
				if (builtData.document.jointservices[T].billofloadings[B].bltype_code !== 'ZZ3') {
					for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
						const jsnumber = builtData.document.jointservices[T].jointservicenumber;
						const blnumber = builtData.document.jointservices[T].billofloadings[B].blnumber;
						const goodordernumber = builtData.document.jointservices[T].billofloadings[B].goods[G].ordernumber;
						// Rows from the current Good
						const rows = fileData.filter(
							(r) =>
								parseInt(r[this.columns.JOINTSERVICENUMBER]) === jsnumber &&
								r[this.columns.BLNUMBER] === blnumber &&
								String(r[this.columns.JOINTSERVICENUMBER]).padStart('2', '0') + String(r[39]).padStart('3', '0') ===
									goodordernumber &&
								r[this.columns.DOCUMENTNUMBER].padStart('11', '0') === builtData.document.documentnumber
						);
						for (const row of rows) {
							// Adding Equipment if exists
							if (row[this.columns.EQTYPE_CODE]) {
								const equipment = this.buildEquipment(templateUTI, row);
								builtData.document.jointservices[T].billofloadings[B].goods[G].utis.push(equipment);
							}
						}
					}
				} else {
					// Rows from the current BL
					const rows = fileData.filter(
						(r) =>
							builtData.document.jointservices[T].billofloadings[B].blnumber === r[this.columns.BLNUMBER] &&
							r[this.columns.DOCUMENTNUMBER].padStart('11', '0') === builtData.document.documentnumber
					);
					for (const row of rows) {
						// Adding Equipment if exists
						if (row[this.columns.EQTYPE_CODE]) {
							const equipment = this.buildEquipment(templateUTI, row);
							builtData.document.jointservices[T].billofloadings[B].utis.push(equipment);
						}
					}
				}
			}
		}
		return builtData;
	},

	/**
	 * Building equipment for adding into the Good or BL
	 * @param {*} templateUTI
	 * @param {*} row
	 * @returns
	 */
	buildEquipment(templateUTI, row) {
		const equipment = { ...templateUTI, ...ImportCSV.emptyUserFields() };

		let units = null;
		let packagenumber = null;
		if (row[this.columns.BLTYPE_CODE] !== 'ZZ3') {
			units = row[this.columns.UNITS] ? parseInt(row[this.columns.UNITS]) : 0;
			packagenumber = row[this.columns.PACKAGENUMBER] ? parseInt(row[this.columns.UNITS]) : 0;
		} else {
			units = 1;
		}

		equipment.utiidentification = row[this.columns.UTIIDENTIFICATION] || null;
		equipment.fullfilliso = row[this.columns.UTIIDENTIFICATION] ? '1' : '0';
		equipment.eqtype_code = row[this.columns.EQTYPE_CODE] || null; // check -> set eqtypeid
		equipment.eqsize_code = row[this.columns.EQSIZE_CODE] || null; // check -> set eqsizeid
		equipment.units = units; // check vacíos
		equipment.packagenumber = packagenumber; // <--- SDSM_GOOD_UTI
		equipment.tare = parseInt(row[this.columns.TARE] || 0); // check commas
		equipment.utistatus_code = row[this.columns.UTISTATUS_CODE] || null; // check -> set utistatusid
		equipment.seals = row[this.columns.SEAL] ? [{ sealnumber: row[this.columns.SEAL] }] : [];
		return equipment;
	},

	/**
	 * Building Docs
	 * @param {*} builtData
	 * @param {*} fileData
	 * @param {*} templateDoc
	 * @returns
	 */
	buildDocs(builtData, fileData, templateDoc) {
		builtData.message = 'modal.importCSV.processing6';
		builtData.progress = 30;
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
					const documentnumber = builtData.document.documentnumber;
					const jsnumber = builtData.document.jointservices[T].jointservicenumber;
					const blnumber = builtData.document.jointservices[T].billofloadings[B].blnumber;
					const goodordernumber = builtData.document.jointservices[T].billofloadings[B].goods[G].ordernumber;
					// Rows from the current Good
					const rowsFromBL = fileData.filter(
						(r) =>
							r[this.columns.BLNUMBER] === blnumber &&
							parseInt(r[this.columns.JOINTSERVICENUMBER]) === jsnumber &&
							r[this.columns.DOCUMENTNUMBER].padStart('11', '0') === documentnumber
					);

					const rows = rowsFromBL.filter(function (r) {
						const on = String(r[2]).padStart('2', '0') + String(r[39]).padStart('3', '0');
						return on === goodordernumber;
					});
					for (const row of rows) {
						// Adding Document if exists
						if (row[this.columns.DOCUMENTCSNUMBER]) {
							const document = { ...templateDoc, ...ImportCSV.emptyUserFields() };
							document.documentcsid = null;
							document.goodid = null;
							document.documentcstypeid = null;
							document.documentcstype_code = row[this.columns.DOCUMENTCSTYPE_CODE] || null; // check -> set documentcstypeid
							document.documentnumber = row[this.columns.DOCUMENTCSNUMBER];
							document.documentdate = ImportCSV.parseDate(row[this.columns.DOCUMENTCSDATE]);
							document.documentcsstatus_code = row[this.columns.DOCUMENTCSSTATUS_CODE] || null; // check -> set documentcsstatusid
							builtData.document.jointservices[T].billofloadings[B].goods[G].docs.push(document);
						}
					}
				}
			}
		}
		return builtData;
	},

	/**
	 * Retrieving additional data for document
	 * @param {*} ctx
	 * @param {*} builtData
	 * @returns
	 */
	async retrieveDocumentAdditionalData(ctx, builtData) {
		builtData.message = 'modal.importCSV.processing7';
		builtData.progress = 35;
		// Validate callfirstentry
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'mcountry',
				filter: {
					groups: [
						{
							groupOp: 'or',
							rules: [{ field: 'countrycode', op: 'eq', data: builtData.document.callfirstentry_code }]
						}
					],
					groupOp: 'and',
					rules: []
				}
			},
			(response) => {
				if (response?.data?.data?.[0]) {
					builtData.document.callfirstentry = builtData.document.callfirstentry_code;
				}
			}
		);

		// Retrieving 'reserid' from 'regser_code'
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'mregularservice',
				filter: {
					rules: [{ field: 'edicode', op: 'eq', data: builtData.document.regser_code }]
				}
			},
			async (response) => {
				if (response?.data?.data?.[0]) {
					builtData.document.regserid = response.data.data[0].regserid;
				}
			}
		);
		// Retrieving 'simtranid' from 'simtran_code'
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'msimplifiedtransit',
				filter: {
					rules: [{ field: 'edicode', op: 'eq', data: builtData.document.simtran_code }]
				}
			},
			async (response) => {
				if (response?.data?.data?.[0]) {
					builtData.document.simtranid = response.data.data[0].simtranid;
				}
			}
		);

		// Retrieving 'dockid' from dock_code
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'mdock',
				filter: {
					rules: [
						{ field: 'code', op: 'eq', data: builtData.document.dock_code },
						{ field: 'dateexpiration', op: 'nu' }
					]
				}
			},
			async (response) => {
				if (response?.data?.data?.[0]) {
					builtData.document.dockid = response.data.data[0].dockid;
					builtData.document.portid = response.data.data[0].portid;
				}
			}
		);

		// Retrieving 'docklocationid' from 'dockid' if not 'docklocationid'
		const dockid = builtData.document.dockid;
		if (dockid) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mdockdocklocation',
					filter: {
						rules: [
							{ field: 'dockid', op: 'eq', data: dockid },
							{ field: 'dateexpiration', op: 'nu' }
						]
					}
				},
				async (response) => {
					if (response?.data?.data?.[0]) {
						builtData.document.docklocationid = response.data.data[0].docklocationid;
					}
				}
			);
		}

		// Retrieving 'operatorid' from operator_code
		const operator_code = builtData.document.operator_code;
		if (operator_code) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmoperator',
					filter: {
						rules: [{ field: 'fiscalcode', op: 'eq', data: operator_code }]
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						builtData.document.operatorid = response.data.data[0].operatorid;
						builtData.document.simtranoperatorid = response.data.data[0].operatorid;
					}
				}
			);
		}

		// Retrieving 'stevedoreid' from stevedore_code
		const stevedore_code = builtData.document.stevedore_code;
		if (stevedore_code) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmstevedore',
					filter: {
						rules: [{ field: 'code', op: 'eq', data: stevedore_code }]
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						builtData.document.stevedoreid = response.data.data[0].stevedoreid;
					}
				}
			);
		}

		return builtData;
	},

	/**
	 * Retrieve / set additional data for the Joint Service
	 * It requires several puisearch for checking / retrieving additional data
	 * @param {*} ctx
	 * @param {*} builtData
	 * @returns
	 */
	async retrieveJointServiceAdditionalData(ctx, builtData) {
		builtData.message = 'modal.importCSV.processing8';
		builtData.progress = 45;
		// Retrieving 'simpregoperatorid' from simpregoperator_code
		const simpregoperatorsRules = [];
		for (const T in builtData.document.jointservices) {
			const simpregoperator_code = builtData.document.jointservices[T].simpregoperator_code;
			if (simpregoperator_code) {
				simpregoperatorsRules.push({ field: 'fiscalcode', op: 'eq', data: simpregoperator_code });
			}
		}
		if (simpregoperatorsRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmoperator',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: simpregoperatorsRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							const simpregoperator_code = builtData.document.jointservices[T].simpregoperator_code;
							const simpregoperator = response.data.data.find((d) => d.fiscalcode === simpregoperator_code);
							if (simpregoperator) {
								builtData.document.jointservices[T].simpregoperatorid = simpregoperator.operatorid;
								builtData.document.jointservices[T].simpregoperator = simpregoperator.description;
							}
						}
					}
				}
			);
		}

		// setting 'operatorid' from Document > operatorid
		const operatorid = builtData.document.operatorid;
		const operator_code = builtData.document.operator_code;
		for (const T in builtData.document.jointservices) {
			builtData.document.jointservices[T].operatorid = operatorid;
			builtData.document.jointservices[T].operator_code = operator_code;
		}

		// Retrieving 'dockid' from dock_code
		const docksRules = [];
		for (const T in builtData.document.jointservices) {
			const dock_code = builtData.document.jointservices[T].dock_code;
			if (dock_code) {
				docksRules.push({ field: 'code', op: 'eq', data: dock_code });
			}
		}
		if (docksRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mdock',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: docksRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				async (response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							const dock_code = builtData.document.jointservices[T].dock_code;
							const dock = response.data.data.find((d) => d.code === dock_code);
							if (dock) {
								builtData.document.jointservices[T].dockid = dock.dockid;
								builtData.document.jointservices[T].dock = dock.description;
								builtData.document.jointservices[T].docklocationid = null;
								builtData.document.jointservices[T].docklocation = null;
							}
						}
					}
				}
			);
		}
		// Retrieving 'docklocationid' from 'dockid' if not 'docklocationid'
		const dockLocationRules = [];
		for (const T in builtData.document.jointservices) {
			const dockid = builtData.document.jointservices[T].dockid;
			const docklocationid = builtData.document.jointservices[T].docklocationid;
			if (dockid && !docklocationid) {
				dockLocationRules.push({ field: 'dockid', op: 'eq', data: dockid });
			}
		}
		if (dockLocationRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mdockdocklocation',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: dockLocationRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				async (response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							const dockid = builtData.document.jointservices[T].dockid;
							const dock = response.data.data.find((d) => d.dockid === dockid);
							if (dock) {
								builtData.document.jointservices[T].docklocationid = dock.docklocationid;
								builtData.document.jointservices[T].docklocation = dock.docklocation;
							}
						}
					}
				}
			);
		}
		return builtData;
	},

	/**
	 * Retrieve/set additional data for Bill of ladings
	 * @param {*} ctx
	 * @param {*} builtData
	 * @param {*} locodes
	 * @returns
	 */
	async retrieveBillofLoadingAdditionalData(ctx, builtData) {
		builtData.message = 'modal.importCSV.processing9';
		builtData.progress = 55;
		const codeFields = ['origin', 'load', 'transhipment', 'destination'];
		const codes = {};
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const F in codeFields) {
					if (builtData.document.jointservices[T].billofloadings[B][codeFields[F] + 'code']) {
						codes[builtData.document.jointservices[T].billofloadings[B][codeFields[F] + 'code']] = false;
					}
				}
			}
		}

		for (const c in codes) {
			await ctx.$puiRequests.getRequest(
				'/sdsmbillofloading/getInfoLocalidades',
				{ criterio: c },
				(response) => {
					if (response?.data?.[0]) {
						codes[c] = response.data[0];
					}
				},
				() => {}
			);
		}

		// Setting codes
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const F in codeFields) {
					const locode = codes[builtData.document.jointservices[T].billofloadings[B][codeFields[F] + 'code']];
					if (locode) {
						builtData.document.jointservices[T].billofloadings[B][codeFields[F] + 'des'] = locode.descripcion;
					}
				}
			}
		}

		// Retrieving 'goodtypeid' from goodtype_code
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'mgoodtype'
			},
			(response) => {
				if (response?.data?.data?.[0]) {
					for (const T in builtData.document.jointservices) {
						for (const B in builtData.document.jointservices[T].billofloadings) {
							const goodtype_code = builtData.document.jointservices[T].billofloadings[B].goodtype_code;
							const goodtype = response.data.data.find((d) => d.edicode === goodtype_code);
							if (goodtype) {
								builtData.document.jointservices[T].billofloadings[B].goodtypeid = goodtype.goodtypeid;
							}
						}
					}
				}
			}
		);

		// Retrieving 'stevedoreid' from stevedor_code
		const stevedoreRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				const stevedore_code = builtData.document.jointservices[T].billofloadings[B].stevedore_code;
				if (stevedore_code) {
					stevedoreRules.push({ field: 'code', op: 'eq', data: stevedore_code });
				}
			}
		}
		if (stevedoreRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmstevedore',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: stevedoreRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								const stevedore_code = builtData.document.jointservices[T].billofloadings[B].stevedore_code;
								const stevedore = response.data.data.find((d) => d.code === stevedore_code);
								if (stevedore) {
									builtData.document.jointservices[T].billofloadings[B].stevedoreid = stevedore.stevedoreid;
								}
							}
						}
					}
				}
			);
		}

		// Retrieving 'bltypeid' from bltype_code
		const bltypesRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				const bltype_code = builtData.document.jointservices[T].billofloadings[B].bltype_code;
				if (bltype_code) {
					bltypesRules.push({ field: 'edicode', op: 'eq', data: bltype_code });
				}
			}
		}
		if (bltypesRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'vutbillofloadingtype',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: bltypesRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								const bltype_code = builtData.document.jointservices[T].billofloadings[B].bltype_code;
								const bltype = response.data.data.find((d) => d.edicode === bltype_code);
								if (bltype) {
									builtData.document.jointservices[T].billofloadings[B].bltypeid = bltype.bltypeid;
									builtData.document.jointservices[T].billofloadings[B].bltype = bltype.edicode;
									builtData.document.jointservices[T].billofloadings[B].bltypedescription = bltype.description;
								}
							}
						}
					}
				}
			);
		}

		// Retrieving 'bltypeid' from bltype_code
		const frontreartransRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				const frontreartrans_code = builtData.document.jointservices[T].billofloadings[B].frontreartrans_code;
				if (frontreartrans_code) {
					frontreartransRules.push({ field: 'edicode', op: 'eq', data: frontreartrans_code });
				}
			}
		}
		if (frontreartransRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mfrontreartransport',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: frontreartransRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								const frontreartrans_code = builtData.document.jointservices[T].billofloadings[B].frontreartrans_code;
								const frontreartrans = response.data.data.find((d) => d.edicode === frontreartrans_code);
								if (frontreartrans) {
									builtData.document.jointservices[T].billofloadings[B].frontreartransid = frontreartrans.frontreartransid;
								}
							}
						}
					}
				}
			);
		}

		// Setting 'dockid' from dock_codes
		const docksRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				const dock_code = builtData.document.jointservices[T].billofloadings[B].dock_code;
				if (dock_code) {
					docksRules.push({ field: 'code', op: 'eq', data: dock_code });
				}
			}
			if (docksRules.length) {
				await ctx.$puiRequests.postRequest(
					'/puisearch',
					{
						model: 'mdock',
						filter: {
							groups: [],
							groupOp: 'and',
							rules: docksRules
						}
					},
					(response) => {
						if (response?.data?.data?.[0]) {
							for (const T in builtData.document.jointservices) {
								for (const B in builtData.document.jointservices[T].billofloadings) {
									const dock_code = builtData.document.jointservices[T].billofloadings[B].dock_code;
									const dock = response.data.data.find((d) => d.code === dock_code);
									if (dock) {
										builtData.document.jointservices[T].billofloadings[B].dock = dock.dockid;
										builtData.document.jointservices[T].billofloadings[B].dock = dock.description;
									}
								}
							}
						}
					}
				);
			}
		}

		return builtData;
	},

	/**
	 * Retrieve additional data for Goods
	 * @param {*} ctx
	 * @param {*} builtData
	 * @returns
	 */
	async retrieveGoodsAdditionalData(ctx, builtData) {
		builtData.message = 'modal.importCSV.processing10';
		builtData.progress = 75;

		// Retrieving 'packagetypeid' from packagetype_code
		const packageTypeRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
					const packagetype_code = builtData.document.jointservices[T].billofloadings[B].goods[G].packagetype_code;
					if (packagetype_code) {
						packageTypeRules.push({ field: 'edicode', op: 'eq', data: packagetype_code });
					}
				}
			}
		}
		if (packageTypeRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mpackagetype',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: packageTypeRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
									const packagetype_code = builtData.document.jointservices[T].billofloadings[B].goods[G].packagetype_code;
									const packagetype = response.data.data.find((d) => d.edicode === packagetype_code);
									if (packagetype) {
										builtData.document.jointservices[T].billofloadings[B].goods[G].packagetypeid = packagetype.packagetypeid;
									}
								}
							}
						}
					}
				}
			);
		}

		// Retrieving 'stevedoreid' from stevedor_code
		const stevedoreRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
					const stevedore_code = builtData.document.jointservices[T].billofloadings[B].goods[G].stevedore_code;
					if (stevedore_code) {
						stevedoreRules.push({ field: 'code', op: 'eq', data: stevedore_code });
					}
				}
			}
		}
		if (stevedoreRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmstevedore',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: stevedoreRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
									const stevedore_code = builtData.document.jointservices[T].billofloadings[B].goods[G].stevedore_code;
									const stevedore = response.data.data.find((d) => d.code === stevedore_code);
									if (stevedore) {
										builtData.document.jointservices[T].billofloadings[B].goods[G].stevedoreid = stevedore.stevedoreid;
									}
								}
							}
						}
					}
				}
			);
		}

		// Retrieving 'stevedoreid' from stevedor_code
		const vehbraRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
					const vehbra_code = builtData.document.jointservices[T].billofloadings[B].goods[G].vehbra_code;
					if (vehbra_code) {
						vehbraRules.push({ field: 'edicode', op: 'eq', data: vehbra_code });
					}
				}
			}
		}
		if (vehbraRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mvehiclebrand',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: vehbraRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
									const vehbra_code = builtData.document.jointservices[T].billofloadings[B].goods[G].vehbra_code;
									const vehbra = response.data.data.find((d) => d.edicode === vehbra_code);
									if (vehbra) {
										builtData.document.jointservices[T].billofloadings[B].goods[G].vehbraid = vehbra.vehbraid;
									}
								}
							}
						}
					}
				}
			);
		}

		// Retrieving 'marginginstrid' from markinginstr_code
		const markinginstrRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
					const markinginstr_code = builtData.document.jointservices[T].billofloadings[B].goods[G].markinginstr_code;
					if (markinginstr_code) {
						markinginstrRules.push({ field: 'edicode', op: 'eq', data: markinginstr_code });
					}
				}
			}
		}
		if (markinginstrRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mmarkinginstruction',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: markinginstrRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
									const markinginstr_code = builtData.document.jointservices[T].billofloadings[B].goods[G].markinginstr_code;
									const markinginstr = response.data.data.find((d) => d.edicode === markinginstr_code);
									if (markinginstr) {
										builtData.document.jointservices[T].billofloadings[B].goods[G].markinginstrid = markinginstr.markinginstrid;
									}
								}
							}
						}
					}
				}
			);
		}

		// Retrieving 'cussituationid' from cussituation_code
		const cussituationRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
					const cussituation_code = builtData.document.jointservices[T].billofloadings[B].goods[G].cussituation_code;
					if (cussituation_code) {
						cussituationRules.push({ field: 'edicode', op: 'eq', data: cussituation_code });
					}
				}
			}
		}
		if (cussituationRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mcustomssituation',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: cussituationRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
									const cussituation_code = builtData.document.jointservices[T].billofloadings[B].goods[G].cussituation_code;
									const cussituation = response.data.data.find((d) => d.edicode === cussituation_code);
									if (cussituation) {
										builtData.document.jointservices[T].billofloadings[B].goods[G].cussituationid = cussituation.cussituationid;
									}
								}
							}
						}
					}
				}
			);
		}

		// Retrieving 'taricid' from taric_code
		const taricRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
					const taric_code = builtData.document.jointservices[T].billofloadings[B].goods[G].taric_code;
					if (taric_code) {
						taricRules.push({ field: 'tariccod', op: 'eq', data: taric_code });
					}
				}
			}
		}
		if (taricRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mtaric',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: taricRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
									const taric_code = builtData.document.jointservices[T].billofloadings[B].goods[G].taric_code;
									const taric = response.data.data.find(
										(d) =>
											d.tariccod === taric_code &&
											ImportCSV.checkAvailableTaric(d, builtData.document.jointservices[T].billofloadings[B])
									);
									if (taric) {
										builtData.document.jointservices[T].billofloadings[B].goods[G].taricid = taric.taricid;
									}
								}
							}
						}
					}
				}
			);
		}

		return builtData;
	},

	/**
	 * Check if taric is available for the current Bill of lading type
	 * @param {*} taric
	 * @param {*} billofloading
	 * @returns
	 */
	checkAvailableTaric(taric, billofloading) {
		const BLIsPassengers = billofloading.bltype === 'ZZ2' ? '1' : '0';
		return taric.ispassenger === BLIsPassengers;
	},

	/**
	 * Retrieve additional data for Utis
	 * @param {*} ctx
	 * @param {*} builtData
	 */
	async retrieveUtisAdditionalData(ctx, builtData) {
		builtData.message = 'modal.importCSV.processing11';
		builtData.progress = 85;
		// Retrieving 'eqsizeid' from eqsize_code
		const eqsizeRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
					for (const U in builtData.document.jointservices[T].billofloadings[B].goods[G].utis) {
						const eqsize_code = builtData.document.jointservices[T].billofloadings[B].goods[G].utis[U].eqsize_code;
						if (eqsize_code) {
							eqsizeRules.push({ field: 'isocode', op: 'eq', data: eqsize_code });
						}
					}
				}
			}
		}
		if (eqsizeRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mequipmentsize',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: eqsizeRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
									for (const U in builtData.document.jointservices[T].billofloadings[B].goods[G].utis) {
										const eqsize_code = builtData.document.jointservices[T].billofloadings[B].goods[G].utis[U].eqsize_code;
										const eqsize = response.data.data.find((d) => d.isocode === eqsize_code);
										if (eqsize) {
											builtData.document.jointservices[T].billofloadings[B].goods[G].utis[U].eqsizeid = eqsize.eqsizeid;
										}
									}
								}
							}
						}
					}
				}
			);
		}

		// Retrieving 'eqtypeid' from eqtype_code
		const eqtypeRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
					for (const U in builtData.document.jointservices[T].billofloadings[B].goods[G].utis) {
						const eqtype_code = builtData.document.jointservices[T].billofloadings[B].goods[G].utis[U].eqtype_code;
						if (eqtype_code) {
							eqtypeRules.push({ field: 'edicode', op: 'eq', data: eqtype_code });
						}
					}
				}
			}
		}
		if (eqtypeRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mequipmenttype',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: eqtypeRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
									for (const U in builtData.document.jointservices[T].billofloadings[B].goods[G].utis) {
										const eqtype_code = builtData.document.jointservices[T].billofloadings[B].goods[G].utis[U].eqtype_code;
										const eqtype = response.data.data.find((d) => d.edicode === eqtype_code);
										if (eqtype) {
											builtData.document.jointservices[T].billofloadings[B].goods[G].utis[U].eqtypeid = eqtype.eqtypeid;
										}
									}
								}
							}
						}
					}
				}
			);
		}

		// Retrieving 'utistatusid' from utistatus_code
		const utistatusCodes = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
					for (const U in builtData.document.jointservices[T].billofloadings[B].goods[G].utis) {
						const utistatus_code = builtData.document.jointservices[T].billofloadings[B].goods[G].utis[U].utistatus_code;
						if (utistatus_code) {
							utistatusCodes.push({ field: 'edicode', op: 'eq', data: utistatus_code });
						}
					}
				}
			}
		}
		if (utistatusCodes.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mutistatus',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: utistatusCodes
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
									for (const U in builtData.document.jointservices[T].billofloadings[B].goods[G].utis) {
										const utistatus_code = builtData.document.jointservices[T].billofloadings[B].goods[G].utis[U].utistatus_code;
										const utistatus = response.data.data.find((d) => d.edicode === utistatus_code);
										if (utistatus) {
											builtData.document.jointservices[T].billofloadings[B].goods[G].utis[U].utistatusid =
												utistatus.utistatusid;
										}
									}
								}
							}
						}
					}
				}
			);
		}

		// Retrieving 'utistatusid' from utistatus_code
		const BLutistatusCodes = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const U in builtData.document.jointservices[T].billofloadings[B].utis) {
					const utistatus_code = builtData.document.jointservices[T].billofloadings[B].utis[U].utistatus_code;
					if (utistatus_code) {
						BLutistatusCodes.push({ field: 'edicode', op: 'eq', data: utistatus_code });
					}
				}
			}
		}
		if (BLutistatusCodes.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mutistatus',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: BLutistatusCodes
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								for (const U in builtData.document.jointservices[T].billofloadings[B].utis) {
									const utistatus_code = builtData.document.jointservices[T].billofloadings[B].utis[U].utistatus_code;
									const utistatus = response.data.data.find((d) => d.edicode === utistatus_code);
									if (utistatus) {
										builtData.document.jointservices[T].billofloadings[B].utis[U].utistatusid = utistatus.utistatusid;
									}
								}
							}
						}
					}
				}
			);
		}

		// Retrieving 'eqtypeid' from eqtype_code
		const BLeqtypeRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const U in builtData.document.jointservices[T].billofloadings[B].utis) {
					const eqtype_code = builtData.document.jointservices[T].billofloadings[B].utis[U].eqtype_code;
					if (eqtype_code) {
						BLeqtypeRules.push({ field: 'edicode', op: 'eq', data: eqtype_code });
					}
				}
			}
		}
		if (BLeqtypeRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mequipmenttype',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: BLeqtypeRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								for (const U in builtData.document.jointservices[T].billofloadings[B].utis) {
									const eqtype_code = builtData.document.jointservices[T].billofloadings[B].utis[U].eqtype_code;
									const eqtype = response.data.data.find((d) => d.edicode === eqtype_code);
									if (eqtype) {
										builtData.document.jointservices[T].billofloadings[B].utis[U].eqtypeid = eqtype.eqtypeid;
									}
								}
							}
						}
					}
				}
			);
		}

		// Retrieving 'eqsizeid' from eqsize_code
		const BLeqsizeRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const U in builtData.document.jointservices[T].billofloadings[B].utis) {
					const eqsize_code = builtData.document.jointservices[T].billofloadings[B].utis[U].eqsize_code;
					if (eqsize_code) {
						BLeqsizeRules.push({ field: 'isocode', op: 'eq', data: eqsize_code });
					}
				}
			}
		}
		if (BLeqsizeRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mequipmentsize',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: BLeqsizeRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								for (const U in builtData.document.jointservices[T].billofloadings[B].utis) {
									const eqsize_code = builtData.document.jointservices[T].billofloadings[B].utis[U].eqsize_code;
									const eqsize = response.data.data.find((d) => d.isocode === eqsize_code);
									if (eqsize) {
										builtData.document.jointservices[T].billofloadings[B].utis[U].eqsizeid = eqsize.eqsizeid;
									}
								}
							}
						}
					}
				}
			);
		}

		return builtData;
	},

	/**
	 * Retrieve additional data for Docs
	 * @param {*} ctx
	 * @param {*} builtData
	 */
	async retrieveDocsAdditionalData(ctx, builtData) {
		builtData.message = 'modal.importCSV.processing12';
		builtData.progress = 85;
		// Retrieving 'documentcsstatusid' from documentcsstatus_code
		const documentcsstatusRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
					for (const D in builtData.document.jointservices[T].billofloadings[B].goods[G].docs) {
						const documentcsstatus_code = builtData.document.jointservices[T].billofloadings[B].goods[G].docs[D].documentcsstatus_code;
						if (documentcsstatus_code) {
							documentcsstatusRules.push({ field: 'documentcode', op: 'eq', data: documentcsstatus_code });
						}
					}
				}
			}
		}
		if (documentcsstatusRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mdocumentcsstatus',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: documentcsstatusRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
									for (const D in builtData.document.jointservices[T].billofloadings[B].goods[G].docs) {
										const documentcsstatus_code =
											builtData.document.jointservices[T].billofloadings[B].goods[G].docs[D].documentcsstatus_code;
										const documentcsstatus = response.data.data.find((d) => d.documentcode === documentcsstatus_code);
										if (documentcsstatus) {
											builtData.document.jointservices[T].billofloadings[B].goods[G].docs[D].documentcsstatusid =
												documentcsstatus.documentcsstatusid;
										}
									}
								}
							}
						}
					}
				}
			);
		}

		// Retrieving 'documentcstypeid' from documentcstype_code
		const documentcsTypesRules = [];
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
					for (const D in builtData.document.jointservices[T].billofloadings[B].goods[G].docs) {
						const documentcstype_code = builtData.document.jointservices[T].billofloadings[B].goods[G].docs[D].documentcstype_code;
						if (documentcstype_code) {
							documentcsTypesRules.push({ field: 'documentcode', op: 'eq', data: documentcstype_code });
						}
					}
				}
			}
		}
		if (documentcsTypesRules.length) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'mdocumentcstype',
					filter: {
						groups: [
							{
								groupOp: 'or',
								rules: documentcsTypesRules
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						for (const T in builtData.document.jointservices) {
							for (const B in builtData.document.jointservices[T].billofloadings) {
								for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
									for (const D in builtData.document.jointservices[T].billofloadings[B].goods[G].docs) {
										const documentcstype_code =
											builtData.document.jointservices[T].billofloadings[B].goods[G].docs[D].documentcstype_code;
										const documentcstype = response.data.data.find((d) => d.documentcode === documentcstype_code);
										if (documentcstype) {
											builtData.document.jointservices[T].billofloadings[B].goods[G].docs[D].documentcstypeid =
												documentcstype.documentcstypeid;
										}
									}
								}
							}
						}
					}
				}
			);
		}

		return builtData;
	},

	async validateDocument(ctx, builtData, userCompanyLegalCode, jointservice) {
		builtData.progress = 85;

		// Validating country
		if (builtData.document.callfirstentry_code && !builtData.document.callfirstentry) {
			builtData.errors.push({
				entity: 'modal.importCSV.document',
				msg: 'modal.importCSV.error22',
				type: 'error',
				params: { 0: builtData.document.callfirstentry_code }
			});
		}

		// Validating Manifest number (same as selected Joint service)
		if (jointservice?.documentNumber && builtData.document.documentnumber !== jointservice?.documentNumber) {
			builtData.errors.push({
				entity: 'modal.importCSV.document',
				msg: 'modal.importCSV.error1',
				type: 'error',
				params: { 0: builtData.document.documentnumber, 1: jointservice?.documentNumber }
			});
		}
		// Validating Manifest number (doesn't exist)
		if (!jointservice) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmdocument',
					filter: {
						groupOp: 'and',
						rules: [
							{
								field: 'documentnumber',
								op: 'eq',
								data: builtData.document.documentnumber
							},
							{
								field: 'doctypeid',
								op: 'eq',
								data: builtData.document.doctypeid
							}
						]
					}
				},
				(response) => {
					if (response?.data?.data?.[0]) {
						builtData.errors.push({
							entity: 'modal.importCSV.document',
							msg: 'modal.importCSV.error25',
							type: 'error',
							params: {
								0: builtData.document.documentnumber
							}
						});
					}
				}
			);
		}

		// Validate goodmoveid
		if (builtData.document.movgoodid !== '1' && builtData.document.movgoodid !== '2') {
			builtData.errors.push({
				entity: 'modal.importCSV.document',
				msg: 'modal.importCSV.error26',
				type: 'error',
				params: {
					0: builtData.document.movgoodid
				}
			});
		}

		// Validate reg. service and service number
		if (builtData.document.regser_code == 'ZRE' && !builtData.document.servicenumber) {
			builtData.errors.push({
				entity: 'modal.importCSV.document',
				msg: 'modal.importCSV.error24',
				type: 'error',
				params: {
					0: builtData.document.regser_code
				}
			});
		}

		// Validating Manifest type
		if (jointservice?.doctypeid && builtData.document.doctypeid !== jointservice?.doctypeid) {
			builtData.errors.push({
				entity: 'modal.importCSV.document',
				msg: 'modal.importCSV.error23',
				type: 'error',
				params: { 0: builtData.document.doctypeid === 1 ? 'C' : 'D' }
			});
		}

		// Validating userCompanyLegalCode
		if (userCompanyLegalCode && builtData.document.operator_code !== userCompanyLegalCode) {
			builtData.errors.push({
				entity: 'modal.importCSV.document',
				msg: 'modal.importCSV.error0',
				type: 'error',
				params: {
					0: builtData.document.operator_code
				}
			});
		}

		// Validating dock
		if (!builtData.document.dockid && builtData.document.dock_code) {
			builtData.errors.push({
				entity: 'modal.importCSV.document',
				msg: 'modal.importCSV.error19',
				type: 'error',
				params: {
					0: builtData.document.dock_code
				}
			});
		}

		// Validating docklocation
		if (!builtData.document.docklocationid) {
			builtData.errors.push({
				entity: 'modal.importCSV.document',
				msg: 'modal.importCSV.error20',
				type: 'error',
				params: {
					0: builtData.document.dock_code
				}
			});
		}

		// Validating stevedore
		if (builtData.document.stevedore_code && !builtData.document.stevedoreid) {
			builtData.errors.push({
				entity: 'modal.importCSV.document',
				msg: 'modal.importCSV.error8',
				type: 'error',
				params: {
					0: builtData.document.stevedore_code
				}
			});
		}

		return builtData;
	},
	/**
	 * Validate Joint Service
	 * @param {*} ctx
	 * @param {*} builtData
	 * @returns
	 */
	validateJointServices(ctx, builtData, userCompanyLegalCode) {
		builtData.progress = 95;
		if (!builtData.document.jointservices.length) {
			builtData.errors.push({
				entity: 'modal.importCSV.jointservice',
				msg: 'modal.importCSV.error2',
				type: 'warning',
				params: {
					0: builtData.document.documentnumber
				}
			});
		}

		for (const T in builtData.document.jointservices) {
			// Validating userCompanyLegalCode
			if (userCompanyLegalCode && builtData.document.jointservices[T].operator_code !== userCompanyLegalCode) {
				builtData.errors.push({
					entity: 'modal.importCSV.jointservice',
					msg: 'modal.importCSV.error0',
					type: 'error',
					params: {
						0: builtData.document.jointservices[T].operator_code
					}
				});
			}

			// Validating simpregoperator
			if (
				builtData.document.jointservices[T].simpregoperatorid &&
				builtData.document.jointservices[T].simpregoperator_code &&
				builtData.document.jointservices[T].simpregoperator_code !== userCompanyLegalCode
			) {
				builtData.errors.push({
					entity: 'modal.importCSV.jointservice',
					msg: 'modal.importCSV.error3',
					type: 'error',
					params: {
						0: builtData.document.jointservices[T].simpregoperator_code
					}
				});
			}

			// Validating dock
			if (builtData.document.jointservices[T].dock_code && !builtData.document.jointservices[T].dockid) {
				builtData.errors.push({
					entity: 'modal.importCSV.jointservice',
					msg: 'modal.importCSV.error4',
					type: 'error',
					params: {
						0: builtData.document.jointservices[T].dock_code
					}
				});
			}

			// Validating docklocation
			if (builtData.document.jointservices[T].dock_code && !builtData.document.jointservices[T].docklocationid) {
				builtData.errors.push({
					entity: 'modal.importCSV.jointservice',
					msg: 'modal.importCSV.error5',
					type: 'error',
					params: {
						0: builtData.document.jointservices[T].dock_code
					}
				});
			}
		}
		return builtData;
	},
	/**
	 * Validate Bill of ladings
	 * @param {*} ctx
	 * @param {*} builtData
	 * @returns
	 */
	async validateBillofLoadings(ctx, builtData) {
		builtData.progress = 95;
		for (const T in builtData.document.jointservices) {
			if (!builtData.document.jointservices[T].billofloadings.length) {
				builtData.errors.push({
					entity: 'modal.importCSV.billofloading',
					msg: 'modal.importCSV.error6',
					type: 'warning',
					params: {
						0: builtData.document.jointservices[T].jointservicenumber
					}
				});
			}
		}

		// Validate destinationcode, transhipmentcode, loadcode,origincode
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				const ordernumber = builtData.document.jointservices[T].billofloadings[B].ordernumber;
				const origincode = builtData.document.jointservices[T].billofloadings[B].origincode;
				const origindes = builtData.document.jointservices[T].billofloadings[B].origindes;
				const loadcode = builtData.document.jointservices[T].billofloadings[B].loadcode;
				const loaddes = builtData.document.jointservices[T].billofloadings[B].loaddes;
				const transhipmentcode = builtData.document.jointservices[T].billofloadings[B].transhipmentcode;
				const transhipmentdes = builtData.document.jointservices[T].billofloadings[B].transhipmentdes;
				const destinationcode = builtData.document.jointservices[T].billofloadings[B].destinationcode;
				const destinationdes = builtData.document.jointservices[T].billofloadings[B].destinationdes;
				if (origincode && !origindes) {
					builtData.errors.push({
						entity: 'modal.importCSV.billofloading',
						msg: 'modal.importCSV.error13',
						type: 'error',
						params: {
							0: origincode,
							1: ordernumber
						}
					});
				}
				if (loadcode && !loaddes) {
					builtData.errors.push({
						entity: 'modal.importCSV.billofloading',
						msg: 'modal.importCSV.error14',
						type: 'error',
						params: {
							0: loadcode,
							1: ordernumber
						}
					});
				}
				if (transhipmentcode && !transhipmentdes) {
					builtData.errors.push({
						entity: 'modal.importCSV.billofloading',
						msg: 'modal.importCSV.error15',
						type: 'error',
						params: {
							0: transhipmentcode,
							1: ordernumber
						}
					});
				}
				if (destinationcode && !destinationdes) {
					builtData.errors.push({
						entity: 'modal.importCSV.billofloading',
						msg: 'modal.importCSV.error16',
						type: 'error',
						params: {
							0: destinationcode,
							1: ordernumber
						}
					});
				}
			}
		}

		// Checking ordernumbers don't exist already
		const ordernumbers = [];
		const ordernumberRules = [];
		for (const T in builtData.document.jointservices) {
			const jointserviceid = builtData.document.jointservices[T].jointserviceid;
			const jointserviceordernumber = builtData.document.jointservices[T].jointservicenumber;
			for (const B in builtData.document.jointservices[T].billofloadings) {
				const ordernumber = builtData.document.jointservices[T].billofloadings[B].ordernumber;
				if (jointserviceid && ordernumber) {
					ordernumbers.push(ordernumber);
					ordernumberRules.push({ field: 'ordernumber', op: 'eq', data: ordernumber });
				}
			}

			if (ordernumbers.length) {
				await ctx.$puiRequests.postRequest(
					'/puisearch',
					{
						model: 'sdsmbillofloading',
						filter: {
							groups: [
								{
									groupOp: 'or',
									rules: ordernumberRules
								}
							],
							groupOp: 'and',
							rules: [{ field: 'jointserviceid', op: 'eq', data: jointserviceid }]
						}
					},
					(response) => {
						if (response?.data?.data?.[0]) {
							for (const B of response.data.data) {
								builtData.errors.push({
									entity: 'modal.importCSV.billofloading',
									msg: 'modal.importCSV.error7',
									type: 'error',
									params: { 0: B.ordernumber, 1: jointserviceordernumber }
								});
							}
						}
					}
				);
			}
		}

		// Checking blnunbers don't exist already
		const blnumbers = [];
		const blnumbersRules = [];
		for (const T in builtData.document.jointservices) {
			const jointserviceid = builtData.document.jointservices[T].jointserviceid;
			const jointserviceordernumber = builtData.document.jointservices[T].jointservicenumber;
			for (const B in builtData.document.jointservices[T].billofloadings) {
				const blnumber = builtData.document.jointservices[T].billofloadings[B].blnumber;
				if (jointserviceid && blnumber) {
					blnumbers.push(blnumber);
					blnumbersRules.push({ field: 'blnumber', op: 'eq', data: blnumber });
				}
			}

			if (blnumbers.length) {
				await ctx.$puiRequests.postRequest(
					'/puisearch',
					{
						model: 'sdsmbillofloading',
						filter: {
							groups: [
								{
									groupOp: 'or',
									rules: blnumbersRules
								}
							],
							groupOp: 'and',
							rules: [{ field: 'jointserviceid', op: 'eq', data: jointserviceid }]
						}
					},
					(response) => {
						if (response?.data?.data?.[0]) {
							for (const B of response.data.data) {
								builtData.errors.push({
									entity: 'modal.importCSV.billofloading',
									msg: 'modal.importCSV.error18',
									type: 'error',
									params: { 0: B.blnumber, 1: jointserviceordernumber }
								});
							}
						}
					}
				);
			}
		}
		return builtData;
	},

	/**
	 * Validate Goods
	 * @param {*} ctx
	 * @param {*} builtData
	 */
	async validateGoods(ctx, builtData) {
		builtData.progress = 100;
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
					// Validating package type
					if (
						builtData.document.jointservices[T].billofloadings[B].goods[G].packagetype_code &&
						!builtData.document.jointservices[T].billofloadings[B].goods[G].packagetypeid
					) {
						builtData.errors.push({
							entity: 'modal.importCSV.good',
							msg: 'modal.importCSV.error21',
							type: 'error',
							params: {
								0: builtData.document.jointservices[T].billofloadings[B].goods[G].packagetype_code
							}
						});
					}

					// Validating stevedore
					if (
						builtData.document.jointservices[T].billofloadings[B].goods[G].stevedore_code &&
						!builtData.document.jointservices[T].billofloadings[B].goods[G].stevedoreid
					) {
						builtData.errors.push({
							entity: 'modal.importCSV.good',
							msg: 'modal.importCSV.error8',
							type: 'error',
							params: {
								0: builtData.document.jointservices[T].billofloadings[B].goods[G].stevedore_code
							}
						});
					}

					// Validating vehbrand
					if (
						builtData.document.jointservices[T].billofloadings[B].goods[G].vehbra_code &&
						!builtData.document.jointservices[T].billofloadings[B].goods[G].vehbraid
					) {
						builtData.errors.push({
							entity: 'modal.importCSV.good',
							msg: 'modal.importCSV.error9',
							type: 'error',
							params: {
								0: builtData.document.jointservices[T].billofloadings[B].goods[G].vehbra_code
							}
						});
					}

					// Validating taricid
					if (
						builtData.document.jointservices[T].billofloadings[B].goods[G].taric_code &&
						!builtData.document.jointservices[T].billofloadings[B].goods[G].taricid
					) {
						builtData.errors.push({
							entity: 'modal.importCSV.good',
							msg: 'modal.importCSV.error10',
							type: 'error',
							params: {
								0: builtData.document.jointservices[T].billofloadings[B].goods[G].taric_code,
								1: builtData.document.jointservices[T].billofloadings[B].goods[G].ordernumber,
								2: builtData.document.jointservices[T].billofloadings[B].bltype
							}
						});
					}
				}
			}
		}
		return builtData;
	},
	/**
	 * Validate Utis
	 * @param {*} ctx
	 * @param {*} builtData
	 */
	validateUtis(ctx, builtData) {
		builtData.progress = 100;
		return builtData;
	},
	/**
	 * Validate Docs
	 * @param {*} ctx
	 * @param {*} builtData
	 */
	validateDocs(ctx, builtData) {
		for (const T in builtData.document.jointservices) {
			for (const B in builtData.document.jointservices[T].billofloadings) {
				for (const G in builtData.document.jointservices[T].billofloadings[B].goods) {
					for (const D in builtData.document.jointservices[T].billofloadings[B].goods[G].docs) {
						// Validating document date
						if (!builtData.document.jointservices[T].billofloadings[B].goods[G].docs[D].documentdate) {
							builtData.errors.push({
								entity: 'modal.importCSV.doc',
								msg: 'modal.importCSV.error17',
								type: 'error',
								params: {
									0: builtData.document.jointservices[T].billofloadings[B].goods[G].ordernumber
								}
							});
						}
						// Validating document status
						if (
							builtData.document.jointservices[T].billofloadings[B].goods[G].docs[D].documentcsstatus_code &&
							!builtData.document.jointservices[T].billofloadings[B].goods[G].docs[D].documentcsstatusid
						) {
							builtData.errors.push({
								entity: 'modal.importCSV.doc',
								msg: 'modal.importCSV.error11',
								type: 'error',
								params: {
									0: builtData.document.jointservices[T].billofloadings[B].goods[G].docs[D].documentcsstatus_code,
									1: builtData.document.jointservices[T].billofloadings[B].goods[G].ordernumber
								}
							});
						}
						// Validating document type
						if (
							builtData.document.jointservices[T].billofloadings[B].goods[G].docs[D].documentcstype_code &&
							!builtData.document.jointservices[T].billofloadings[B].goods[G].docs[D].documentcstypeid
						) {
							builtData.errors.push({
								entity: 'modal.importCSV.doc',
								msg: 'modal.importCSV.error12',
								type: 'error',
								params: {
									0: builtData.document.jointservices[T].billofloadings[B].goods[G].docs[D].documentcstype_code,
									1: builtData.document.jointservices[T].billofloadings[B].goods[G].ordernumber
								}
							});
						}
					}
				}
			}
		}
		builtData.progress = null;
		return builtData;
	},
	/**
	 * Send builtData to back
	 * @param {*} ctx
	 * @param {*} jointservice
	 * @returns
	 */
	emptyUserFields() {
		return {
			creauser: null,
			moduser: null,
			creadate: null,
			moddate: null
		};
	},
	/**
	 * Parse string dates
	 * @param {*} value
	 * @returns
	 */
	parseDate(value) {
		value = value.replace(/\//g, '-');
		let parsedValue = null;
		try {
			const valueSplit = value.split(' ');
			const date = valueSplit[0];
			const time = valueSplit[1];
			parsedValue = new Date(date.split('-').reverse().join('-'));
			if (time) {
				const timeSplit = time.split(':');
				const hours = timeSplit[0];
				const minutes = timeSplit[1];
				parsedValue.setHours(hours, minutes, 0);
			}
			parsedValue = parsedValue.toISOString();
		} catch (ex) {
			parsedValue = null;
		}
		return parsedValue;
	},
	sort(builtData) {
		builtData.document.jointservices.sort((a, b) => parseInt(a.jointservicenumber) - parseInt(b.jointservicenumber));
		for (const T in builtData.document.jointservices) {
			builtData.document.jointservices[T].billofloadings.sort((a, b) => parseInt(a.ordernumber) - parseInt(b.ordernumber));
		}
	},
	async sendJS(ctx, jointservice) {
		let r = false;
		await ctx.$puiRequests.postRequest(
			'/sdsmjointservice/importcsv',
			{ data: jointservice, jointserviceid: jointservice.jointserviceid },
			(response) => {
				r = response;
			},
			(e) => {
				ctx.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		return r;
	},
	async sendDocument(ctx, document) {
		let r = false;
		await ctx.$puiRequests.postRequest(
			'/sdsmdocument/importcsv',
			document,
			(response) => {
				r = response;
			},
			(e) => {
				ctx.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		return r;
	}
};
export default ImportCSV;
