<template>
	<div>
		<!-- BL Massive Create/Edit -->
		<v-dialog v-if="massiveCreateModal.jointservice" v-model="massiveCreateModal.open" persistent scrollable>
			<sdsmbillofloading-massive-create
				:key="massiveCreateModal.jointservice.jointserviceid"
				:jointservice="massiveCreateModal.jointservice"
				:billofloading="massiveCreateModal.billofloading"
				:modelName="this.modelName"
				:isCreatingElement="false"
			></sdsmbillofloading-massive-create>
		</v-dialog>

		<!-- MassiveUpdate Action -->
		<pui-modal-dialog-form
			scrollable
			widthDialog="75%"
			:dialogName="sdsmBillofloadingMasUpdModal.name"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.sdsmbillofloading.massiveupd.title')"
			:modelName="modelName"
			:overflow="false"
			:onOk="onOkBillofloadingMasUpdModal"
			:okText="sdsmBillofloadingMasUpdModal.okText"
			:onShow="() => {}"
		>
			<template slot="message" slot-scope="data">
				<sdsmbillofloading-masupd
					:ref="sdsmBillofloadingMasUpdModal.refForm"
					:billofloadings="data.modalData.billofloadings"
				></sdsmbillofloading-masupd>
			</template>
		</pui-modal-dialog-form>

		<!-- Warnings modal -->
		<pui-modal-dialog-form
			scrollable
			widthDialog="30%"
			:dialogName="alertsWarningModal.name"
			modelName="sdsmbillofloading"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.alertmodal.warnings')"
			:okText="alertsWarningModal.okText"
			:onShow="() => {}"
			:disableCancel="true"
		>
			<template slot="message" slot-scope="data">
				<sdsmbillofloading-warnings :ref="alertsWarningModal.refForm" :alerts="data.modalData.alerts"></sdsmbillofloading-warnings>
			</template>
		</pui-modal-dialog-form>

		<!-- Errors modal -->
		<pui-modal-dialog-form
			scrollable
			widthDialog="30%"
			:dialogName="alertsErrorModal.name"
			modelName="sdsmbillofloading"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.alertmodal.errors')"
			:okText="alertsErrorModal.okText"
			:onShow="() => {}"
			:disableCancel="true"
		>
			<template slot="message" slot-scope="data">
				<sdsmbillofloading-errors :ref="alertsErrorModal.refForm" :alerts="data.modalData.alerts"></sdsmbillofloading-errors>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
import SdsmbillofloadingMassiveCreate from './../sdsmbillofloading/modalForms/SdsmbillofloadingMassiveCreate.vue';
import { mapState } from 'vuex';
import alertsWarningModal from '../general/PcsumHeader/alertWarningModal_new.vue';
import alertsErrorModal from '../general/PcsumHeader/alertErrorModal_new.vue';
import sdsmBillofloadingMasUpdModal from './../sdsmbillofloading/modalForms/SdsmbillofloadingMasUpd.vue';
export default {
	name: 'sdsmbillofloading-modals',
	components: {
		'sdsmbillofloading-massive-create': SdsmbillofloadingMassiveCreate,
		'sdsmbillofloading-warnings': alertsWarningModal,
		'sdsmbillofloading-errors': alertsErrorModal,
		'sdsmbillofloading-masupd': sdsmBillofloadingMasUpdModal
	},
	data() {
		return {
			sdsmBillofloadingMasUpdModal: {
				okText: null,
				refForm: 'sdsmBillofloadingMasUpdModal',
				name: 'idMassUpdateAction'
			},
			alertsWarningModal: {
				okText: this.$t('modal.alertmodal.close'),
				refForm: 'modalWarningAlerts',
				name: 'modalWarningAlerts'
			},
			alertsErrorModal: {
				okText: this.$t('modal.alertmodal.close'),
				refForm: 'modalErrorAlerts',
				name: 'modalErrorAlerts'
			}
		};
	},
	computed: {
		...mapState({
			massiveCreateModal: (state) => state.modal.massiveCreate
		})
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onShowBillofloadingMasUpdModal() {},
		async onOkBillofloadingMasUpdModal() {
			const completed = await this.$refs[this.sdsmBillofloadingMasUpdModal.refForm].SendBLMassiveEdit();

			if (completed) {
				return new Promise((resolve) => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					resolve(true);
				});
			}
		}
	}
};
</script>
