<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mdockdocklocation-modals
			:modelName="modelName"
		></mdockdocklocation-modals>
	</div>
</template>

<script>
import mdockdocklocationActions from './MdockdocklocationActions';
import mdockdocklocationModals from './MdockdocklocationModals.vue';

export default {
	name: 'mdockdocklocation-grid',
	components: {
		'mdockdocklocation-modals': mdockdocklocationModals
	},
	data() {
		return {
			modelName: 'mdockdocklocation',
			actions: mdockdocklocationActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
