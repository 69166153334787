<template>
	<div class="sdsmstevedoreFormHeader puiformheader">
		<v-row>
			<v-col cols="3">
				<pui-field :label="$t('header.sdsmstevedore.code')" :value="getCode"></pui-field>
			</v-col>
			<v-col cols="9">
				<pui-field :label="$t('header.sdsmstevedore.description')" :value="getDescription"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'sdsmstevedore-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'sdsmstevedore'
		};
	},
	computed: {
		getStevedorId() {
			return this.model && this.model.stevedoreid ? this.model.stevedoreid : '0';
		},
		getDescription() {
			return this.model && this.model.description ? this.model.description : '0';
		},
		getCode() {
			return this.model && this.model.code ? this.model.code : '0';
		}
	}
};
</script>
