<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:navigableDetail="false"
		></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixinAccess'

export default {
	name: 'sdsmmessageerror-grid',
	mixins: [PuiGridDetailMixin],
	components: {},
	data () {
		return {
			modelName: 'sdsmmessageerror',
			modelColumnDefs: {
				errordate: {
					render: data => {
						var fecha = new Date(data)
						return fecha.toLocaleString('es-ES', {
							timeZone: 'Europe/Madrid',
							hour12: false
						})
					}
				}
			}
		}
	}
}
</script>
