<template>
	<div class="pui-form">
		<sdsmmessage-modals :modelName="modelName"></sdsmmessage-modals>
		<template>
			<sdsmmessage-form-header
				v-if="modelLoaded"
				:data="headerData"
				:params="{ isCreatingElement }"
				:modelPk="modelPk"
			></sdsmmessage-form-header>
		</template>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary" show-arrows>
					<v-tab :key="0" :href="'#maintab'">{{ $t('form.sdsmmessage.formtitle') }}</v-tab>
					<v-tab :key="1" :href="'#msgelement'">{{ $t('form.sdsmmessage.msgelement') }}</v-tab>
					<v-tab :key="2" :href="'#msgaudit'">{{ $t('form.sdsmmessage.msgaudit') }}</v-tab>
					<v-tab :key="3" :href="'#msgerror'">{{ $t('form.sdsmmessage.msgerror') }}</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tabmodel" style="width: 100% !important">
					<v-tab-item :key="0" lazy :value="'maintab'">
						<pui-field-set :title="$t('sdsmmessage.message')">
							<v-col cols="12">
								<v-row dense>
									<!-- MESSAGEID -->
									<v-col cols="3">
										<pui-text-field
											:id="`messageid-sdsmmessage`"
											v-model="model.messageid"
											:label="$t('sdsmmessage.messageid')"
											:disabled="true"
											toplabel
											required
											maxlength="12"
										></pui-text-field>
									</v-col>

									<v-col cols="3">
										<pui-select
											:id="`stateid-sdsmmessage`"
											v-model="model"
											:label="$t('sdsmmessage.state')"
											:disabled="formDisabled"
											toplabel
											required
											modelName="sdsmmessagestate"
											:modelFormMapping="{ stateid: 'stateid' }"
											:itemsToSelect="[{ stateid: model.stateid }]"
											:itemValue="['stateid']"
											:itemText="(item) => `${item.description} `"
											:order="{ stateid: 'asc' }"
										></pui-select>
									</v-col>

									<v-col cols="3">
										<pui-date-field
											:id="`messagedate-sdsmmessage`"
											v-model="model.messagedate"
											required
											:label="$t('sdsmmessage.messagedate')"
											:disabled="formDisabled"
											toplabel
											time
											timesecs
										></pui-date-field>
									</v-col>

									<v-col cols="3">
										<pui-date-field
											:id="`registerdate-sdsmmessage`"
											v-model="model.registerdate"
											required
											:label="$t('sdsmmessage.registerdate')"
											:disabled="formDisabled"
											toplabel
											time
											timesecs
										></pui-date-field>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="2">
										<pui-select
											:id="`messageclassid-sdsmmessage`"
											v-model="model"
											:label="$t('sdsmmessage.class')"
											:disabled="formDisabled"
											required
											toplabel
											modelName="sdsmmessageclass"
											:modelFormMapping="{ messageclassid: 'messageclassid' }"
											:itemsToSelect="[{ messageclassid: model.messageclassid }]"
											:itemValue="['messageclassid']"
											:itemText="(item) => `${item.description} `"
											:order="{ messageclassid: 'asc' }"
										></pui-select>
									</v-col>
									<v-col cols="3">
										<pui-select
											:id="`messagetypeid-sdsmmessage`"
											v-model="model"
											:label="$t('sdsmmessage.messagetype')"
											:disabled="formDisabled"
											required
											toplabel
											modelName="sdsmmessagetype"
											:modelFormMapping="{ messagetypeid: 'messagetypeid' }"
											:itemsToSelect="[{ messagetypeid: model.messagetypeid }]"
											:itemValue="['messagetypeid']"
											:itemText="(item) => `${item.description} `"
											:order="{ messagetypeid: 'asc' }"
										></pui-select>
									</v-col>
									<v-col cols="3">
										<pui-select
											:id="`functionid-sdsmmessage`"
											v-model="model"
											:label="$t('sdsmmessage.function')"
											:disabled="formDisabled"
											required
											toplabel
											modelName="sdsmmessagefunction"
											:modelFormMapping="{ functionid: 'functionid' }"
											:itemsToSelect="[{ functionid: model.functionid }]"
											:itemValue="['functionid']"
											:itemText="(item) => `${item.description} `"
											:order="{ functionid: 'asc' }"
										></pui-select>
									</v-col>
									<v-col cols="2">
										<pui-text-field
											:id="`documenttypecod-sdsmmessage`"
											v-model="model.documenttypecod"
											:label="$t('sdsmmessage.documenttpecod')"
											:disabled="formDisabled"
											toplabel
											maxlength="12"
										></pui-text-field>
									</v-col>
									<v-col cols="2">
										<pui-text-field
											:id="`direction-sdsmmessage`"
											v-model="model.direction"
											required
											:label="$t('sdsmmessage.direction')"
											:disabled="formDisabled"
											toplabel
											maxlength="12"
										></pui-text-field>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="6">
										<pui-text-field
											:id="`sender-sdsmmessage`"
											v-model="model.sender"
											:label="$t('sdsmmessage.sender')"
											required
											:disabled="formDisabled"
											toplabel
											maxlength="12"
										></pui-text-field>
									</v-col>
									<v-col cols="6">
										<pui-text-field
											:id="`receiver-sdsmmessage`"
											v-model="model.receiver"
											required
											:label="$t('sdsmmessage.receiver')"
											:disabled="formDisabled"
											toplabel
											maxlength="12"
										></pui-text-field>
									</v-col>
								</v-row>

								<v-row dense>
									<v-col cols="4">
										<pui-text-field
											:id="`messageref-sdsmmessage`"
											v-model="model.messageref"
											:label="$t('sdsmmessage.messageref')"
											:disabled="formDisabled"
											toplabel
											maxlength="12"
										></pui-text-field>
									</v-col>
									<v-col cols="4">
										<pui-text-field
											:id="`senderref-sdsmmessage`"
											v-model="model.senderref"
											:label="$t('sdsmmessage.senderref')"
											:disabled="formDisabled"
											toplabel
											maxlength="12"
										></pui-text-field>
									</v-col>
									<v-col cols="4">
										<pui-text-field
											:id="`receiverref-sdsmmessage`"
											v-model="model.receiverref"
											:label="$t('sdsmmessage.receiverref')"
											:disabled="formDisabled"
											toplabel
											maxlength="12"
										></pui-text-field>
									</v-col>
								</v-row>

								<v-row dense>
									<v-col cols="4">
										<pui-text-field
											:id="`originref-sdsmmessage`"
											v-model="model.originref"
											:label="$t('sdsmmessage.originref')"
											:disabled="formDisabled"
											toplabel
											maxlength="12"
										></pui-text-field>
									</v-col>
									<v-col cols="3">
										<pui-text-field
											:id="`messageoriginid-sdsmmessage`"
											v-model="model.messageoriginid"
											:label="$t('sdsmmessage.messageoriginid')"
											:disabled="formDisabled"
											toplabel
											maxlength="12"
										></pui-text-field>
									</v-col>
									<v-col cols="4">
										<pui-text-field
											:id="`filename-sdsmmessage`"
											v-model="model.filename"
											:label="$t('sdsmmessage.filename')"
											:disabled="formDisabled"
											toplabel
											maxlength="12"
										></pui-text-field>
									</v-col>
									<v-col cols="1">
										<v-col column cols="12" dense class="pa-0">
											<label class="customLabel v-label--">{{ $t('sdsmmessage.answered') }}</label>
										</v-col>
										<v-col column cols="12" dense>
											<input type="checkbox" id="activado" v-model="answered" reactive />
										</v-col>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12">
										<label class="customLabel v-label--">{{ $t('sdsmmessage.message') }}</label>

										<v-textarea rows="10" readonly v-model="model.message"></v-textarea>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12">
										<label class="customLabel v-label--">{{ $t('sdsmmessage.errordes') }}</label>

										<v-textarea rows="10" v-model="model.errordes" readonly></v-textarea>
									</v-col>
								</v-row>
							</v-col>
						</pui-field-set>
					</v-tab-item>
					<v-tab-item :key="1" lazy :value="'msgelement'">
						<pui-master-detail
							componentName="sdsmmessageelement-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ messageid: 'messageid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
							:showCreateBtn="false"
							:showDeleteBtn="false"
						></pui-master-detail>
					</v-tab-item>
					<v-tab-item :key="2" lazy :value="'msgaudit'">
						<pui-master-detail
							componentName="sdsmmessageaudit-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ messageid: 'messageid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
							:showCreateBtn="false"
							:showDeleteBtn="false"
						></pui-master-detail>
					</v-tab-item>
					<v-tab-item :key="3" lazy :value="'msgerror'">
						<pui-master-detail
							componentName="sdsmmessageerror-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ messageid: 'messageid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
							:showCreateBtn="false"
							:showDeleteBtn="false"
						></pui-master-detail>
					</v-tab-item>
				</v-tabs-items>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import sdsmmessageActions from './SdsmmessageActions';
import sdsmmessageModals from './SdsmmessageModals.vue';

export default {
	name: 'sdsmmessage-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'sdsmmessage-modals': sdsmmessageModals
	},
	data() {
		return {
			modelName: 'sdsmmessage',
			actions: sdsmmessageActions.formactions,
			headerData: {},
			answered: false
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
			this.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'vhdmessage',
					rows: null,
					filter: {
						groups: [
							{
								groupOp: 'and',
								rules: [
									{ field: 'messageid', op: 'eq', data: this.model.messageid },
									{ field: 'lang', op: 'eq', data: this.$store.getters.getUserLanguage }
								]
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(responsee) => {
					if (responsee?.data?.data && responsee.data.data.length > 0) {
						this.headerData = responsee.data.data[0];
					}
				}
			);
		}
	},
	computed: {},
	created() {}
};
</script>
