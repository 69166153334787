<template>
	<div class="pui-form">
		<template v-if="model && billOfLoadingHeaderData && ready">
			<sdsmgood-form-header
				:data="billOfLoadingHeaderData"
				:params="{}"
				style="margin: 0px !important; padding: 0px; margin-bottom: 8px !important"
			></sdsmgood-form-header>
			<v-form class="mb-4 pb-4" ref="form" lazy-validation @submit.prevent>
				<v-row class="pui-form-layout">
					<v-col cols="12">
						<!------------------ GOOODS ------------------>
						<pui-field-set :title="$t('form.sdsmgood')">
							<v-row dense align="center">
								<!-- BLID -->
								<v-col cols="12 col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 pl-2 pr-2">
									<pui-select
										:id="`blid-sdsmgood`"
										:attach="`blid-sdsmgood`"
										:label="$t('sdsmgood.bl')"
										:disabled="true"
										toplabel
										clearable
										modelName="vhdgood"
										v-model="model"
										reactive
										:itemsToSelect="[{ blid: this.model.blid }]"
										:modelFormMapping="{ blid: 'blid' }"
										:itemValue="['blid']"
										:itemTemplate="true"
										:itemText="''"
										:selectTemplate="true"
										:fixedFilter="filteredBLID"
									>
										<template v-slot:selectionTemplate="{ props, item }">
											<div class="SlotOptionWithDetails" v-bind="props">
												{{ $t('sdsmgood.blid') }} <span class="separator">:</span>
												<span class="value">{{ item.blid || '0' }}</span
												><span class="separator">/</span>

												{{ $t('sdsmgood.documentnumber') }} <span class="separator">:</span>
												<span class="value">{{ item.documentnumber || '0' }}</span
												><span class="separator">/</span> {{ $t('sdsmgood.cargooperator') }} <span class="separator">:</span>
												<span class="value">{{ item.operator || '0' }}</span> <span class="separator">/</span>
												{{ $t('sdsmgood.jointservicenumber') }}
												<span class="separator">:</span>
												<span class="value">{{ item.jointservicenumber !== null ? item.jointservicenumber : '0' }} </span
												><span class="separator">/</span> {{ $t('sdsmgood.conocimiento') }}
												<span class="separator">:</span>
												<span class="value"
													>{{ item.ordernumber || '0' }}<span class="separator">-</span> {{ item.blnumber || '0' }}</span
												><span class="separator">/</span> {{ $t('sdsmgood.bltype') }}<span class="separator">:</span>
												<span class="value">{{ item.bltype }} </span>
											</div>
										</template>
										<template v-slot:theTemplate="{ props, item }">
											<div class="SlotOptionWithDetails" v-bind="props">
												{{ $t('sdsmgood.blid') }} <span class="separator">:</span>
												<span class="value">{{ item.blid || '0' }}</span
												><span class="separator">/</span>

												{{ $t('sdsmgood.documentnumber') }} <span class="separator">:</span>
												<span class="value">{{ item.documentnumber || '0' }}</span
												><span class="separator">/</span> {{ $t('sdsmgood.cargooperator') }} <span class="separator">:</span>
												<span class="value">{{ item.operator || '0' }}</span> <span class="separator">/</span>
												{{ $t('sdsmgood.jointservicenumber') }}
												<span class="separator">:</span>
												<span class="value">{{ item.jointservicenumber !== null ? item.jointservicenumber : '0' }} </span
												><span class="separator">/</span> {{ $t('sdsmgood.conocimiento') }}
												<span class="separator">:</span>
												<span class="value"
													>{{ item.ordernumber || '0' }}<span class="separator">-</span> {{ item.blnumber || '0' }}</span
												><span class="separator">/</span> {{ $t('sdsmgood.bltype') }}<span class="separator">:</span>
												<span class="value">{{ item.bltype }} </span>
											</div>
										</template>
									</pui-select>
								</v-col>

								<!-- ORDERNUMBER -->
								<v-col class="12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
									<pui-text-field
										:id="`ordernumber-sdsmgood`"
										v-model="model.ordernumber"
										:label="$t('sdsmgood.ordernumber')"
										:disabled="true"
										toplabel
									></pui-text-field>
								</v-col>
							</v-row>
							<template v-if="model.blid">
								<v-row dense>
									<!-- PACKAGESNUMBER -->
									<v-col
										cols="12 col-xs-12"
										:class="{
											'col-sm-2 col-md-2 col-lg-2 col-xl-2': isPassengers,
											'col-sm-1 col-md-1 col-lg-1 col-xl-1': !isPassengers
										}"
									>
										<pui-text-field
											:id="`packagesnumber-sdsmgood`"
											v-model="model.packagesnumber"
											:label="isPassengers ? $t('sdsmgood.passengersnumber') : $t('sdsmgood.packagesnumber')"
											:disabled="false"
											:rules="[v => v >= 0 || $t('requiredMessage')]"
											toplabel
											maxlength="12"
										></pui-text-field>
									</v-col>

									<!-- WEIGHT -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-1 col-xl-1" v-if="!isPassengers">
										<pui-text-field
											:id="`weight-sdsmgood`"
											v-model="model.weight"
											:label="$t('sdsmgood.weight')"
											:disabled="isPassengers"
											:required="!isPassengers"
											toplabel
											maxlength="18"
										></pui-text-field>
									</v-col>

									<!-- VOLUME -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-1 col-xl-1" v-show="!isPassengers">
										<pui-text-field
											:id="`volume-sdsmgood`"
											v-model="model.volume"
											:label="$t('sdsmgood.volume')"
											:disabled="isPassengers"
											toplabel
											maxlength="18"
										></pui-text-field>
									</v-col>

									<!-- LOWVALUESENT -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-1 col-xl-1" v-show="!isPassengers">
										<pui-text-field
											:id="`lowvaluesent-sdsmgood`"
											v-model="model.lowvaluesent"
											:label="$t('sdsmgood.lowvaluesent')"
											:disabled="isPassengers"
											toplabel
											maxlength="18"
										></pui-text-field>
									</v-col>
									<!-- PACKAGETYPEID -->
									<v-col cols="12 col-xs-12 col-sm-8 col-md-8 col-lg-4 col-xl-4" v-show="!isPassengers">
										<pui-select
											:id="`packagetypeid-sdsmgood`"
											:attach="`packagetypeid-sdsmgood`"
											:label="$t('sdsmgood.packagetypeid')"
											:disabled="isPassengers"
											:required="!isPassengers"
											toplabel
											clearable
											modelName="mpackagetype"
											v-model="model"
											reactive
											:itemsToSelect="[{ packagetypeid: model.packagetypeid }]"
											:modelFormMapping="{ packagetypeid: 'packagetypeid' }"
											:itemValue="['packagetypeid']"
											:itemText="item => `${item.description}`"
										></pui-select>
									</v-col>

									<!-- CUSSITUATIONID -->
									<v-col
										cols="12 col-xs-12"
										v-if="!isPassengers"
										:class="{
											'col-sm-10 col-md-10 col-lg-10 col-xl-10': isPassengers,
											'col-sm-4 col-md-4 col-lg-4 col-xl-4': !isPassengers
										}"
									>
										<pui-select
											:id="`cussituationid-sdsmgood`"
											:attach="`cussituationid-sdsmgood`"
											:label="$t('sdsmgood.cussituationid')"
											:disabled="false"
											toplabel
											clearable
											modelName="mcustomssituation"
											v-model="model"
											reactive
											:required="!isPassengers"
											singleItemAutoselect
											:itemsToSelect="[{ cussituationid: model.cussituationid }]"
											:modelFormMapping="{ cussituationid: 'cussituationid' }"
											:itemValue="['cussituationid']"
											:itemText="item => `${item.cussituationid} - ${item.description}`"
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense align="center">
									<!-- TARICID -->
									<v-col
										cols="12 col-xs-12 col-sm-12 col-md-12 pl-2 pr-2"
										:class="{ 'col-lg-5 col-xl-5': isPassengers, 'col-lg-10 col-xl-10 ': !isPassengers }"
									>
										<label class="customLabel v-label--required">{{ $t('sdsmgood.taricid') }}</label>
										<v-autocomplete
											class="customSelector"
											outlined
											dense
											v-model="model.taricid"
											:items="filteredTarCodes"
											:disabled="false"
											item-value="taricid"
											:rules="[v => !!v || $t('requiredMessage')]"
											required
											:item-text="item => `${item.tariccod} - ${item.description}`"
										>
											<template v-slot:item="{ props, item }">
												<div class="SlotOptionWithDetails" v-bind="props">
													<span class="value">{{ item.tariccod }}</span>
													<span class="separator"> - </span>
													<span>{{ item.description }}</span>
												</div>
											</template>
										</v-autocomplete>
									</v-col>

									<!-- VEHBRAID -->
									<v-col cols="12 col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2" v-show="!isPassengers">
										<pui-select
											:id="`vehbraid-sdsmgood`"
											:attach="`vehbraid-sdsmgood`"
											:label="$t('sdsmgood.vehbraid')"
											:disabled="false"
											:required="checkVehBraIDRequired"
											toplabel
											clearable
											modelName="mvehiclebrand"
											v-model="model"
											reactive
											:itemsToSelect="[{ vehbraid: model.vehbraid }]"
											:modelFormMapping="{ vehbraid: 'vehbraid' }"
											:itemValue="['vehbraid']"
											:itemText="item => `${item.vehbraid} - ${item.description}`"
										></pui-select>
									</v-col>

									<!-- MARKINGINSTRID -->
									<v-col cols="12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" v-show="!isPassengers">
										<pui-select
											:id="`markinginstrid-sdsmgood`"
											:attach="`markinginstrid-sdsmgood`"
											:label="$t('sdsmgood.markinginstrid')"
											:disabled="isPassengers"
											toplabel
											clearable
											modelName="vutmmarkinginstructiontra"
											v-model="model"
											reactive
											:itemsToSelect="[{ markinginstrid: model.markinginstrid }]"
											:modelFormMapping="{ markinginstrid: 'markinginstrid' }"
											:itemValue="['markinginstrid']"
											:itemText="item => `${item.edicode} - ${item.description}`"
										></pui-select>
									</v-col>

									<!-- PACKAGESMARKING -->
									<v-col cols="12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" v-if="!isPassengers">
										<pui-text-field
											:id="`packagesmarking-sdsmgood`"
											v-model="model.packagesmarking"
											:label="$t('sdsmgood.packagesmarking')"
											:disabled="isPassengers"
											toplabel
											maxlength="350"
										></pui-text-field>
									</v-col>

									<!-- STEVEDOREID -->
									<v-col
										cols="12 col-xs-12"
										v-if="!isPassengers"
										:class="{ 'col-lg-5 col-xl-5': isPassengers, 'col-lg-6 col-xl-6 ': !isPassengers }"
									>
										<pui-select
											:id="`stevedoreid-sdsmgood`"
											:attach="`stevedoreid-sdsmgood`"
											:label="$t('sdsmgood.stevedoreid')"
											:disabled="false"
											toplabel
											clearable
											modelName="sdsmstevedore"
											v-model="model"
											reactive
											:itemsToSelect="[{ stevedoreid: model.stevedoreid }]"
											:modelFormMapping="{ stevedoreid: 'stevedoreid' }"
											:itemValue="['stevedoreid']"
											:itemText="item => `${item.description}`"
										></pui-select>
									</v-col>

									<!-- ISMANDATORYENS -->
									<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6" v-if="!isPassengers">
										<v-radio-group v-model="model.ismandatoryens" row style="position: relative; top: 4px">
											<v-radio :label="$t('sdsmgood.ismandatoryens.yes')" value="1"></v-radio>
											<v-radio :label="$t('sdsmgood.ismandatoryens.no')" value="0"></v-radio>
										</v-radio-group>
									</v-col>

									<!-- MANIPULATIONTERMINAL -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4" v-if="!isPassengers">
										<pui-text-field
											:id="`manipulationterminal-sdsmgood`"
											v-model="model.manipulationterminal"
											:label="$t('sdsmgood.manipulationterminal')"
											:disabled="isPassengers"
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-col>

									<!-- SPECIALINSTUSE -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2" v-if="!isPassengers">
										<pui-text-field
											:id="`specialinstuse-sdsmgood`"
											v-model="model.specialinstuse"
											:label="$t('sdsmgood.specialinstuse')"
											:disabled="isPassengers"
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-col>

									<!-- DSDTNUMBER -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2" v-if="!isPassengers">
										<pui-text-field
											:id="`dsdtnumber-sdsmgood`"
											v-model="model.dsdtnumber"
											:label="$t('sdsmgood.dsdtnumber')"
											:required="checkDsdtRequired"
											:disabled="isPassengers"
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-col>

									<!-- ORDERNUMBERGOODDSDT -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2" v-if="!isPassengers">
										<pui-text-field
											:id="`ordernumbergooddsdt-sdsmgood`"
											v-model="model.ordernumbergooddsdt"
											:label="$t('sdsmgood.ordernumbergooddsdt')"
											:required="checkDsdtRequired"
											:disabled="isPassengers"
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-col>

									<!-- COMCODE -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2">
										<pui-text-field
											:id="`comcode-sdsmgood`"
											v-model="model.comcode"
											:label="$t('sdsmgood.comcode')"
											:disabled="false"
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-col>
								</v-row>
							</template>
						</pui-field-set>
						<!------------------ DDGG ------------------>
						<pui-field-set :title="$t('sdsmgood.mmpp')" v-if="model.blid && !isPassengers">
							<v-row dense>
								<!-- DDGG CRITERIA -->
								<v-col cols="2" class="pl-2 pr-2">
									<label class="customLabel">{{ $t('sdsmgood.search') }}</label>
									<v-autocomplete
										class="customSelector"
										outlined
										dense
										v-model="ddggcriteria"
										:loading="loading"
										:items="ddgg"
										:disabled="false"
										clearable
										item-value="onu"
										:item-text="item => `${item.onu} - ${item.denominacion}`"
									>
										<template v-slot:item="{ props, item }">
											<div class="BasicSlotOption" v-bind="props">
												<span> {{ item?.onu }} </span>
												<span> {{ item?.denominacion }} </span>
											</div>
										</template>
									</v-autocomplete>
								</v-col>

								<!-- IMDGUNONUMBER -->
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-1 col-xl-1">
									<pui-text-field
										:id="`imdgunonumber-sdsmgood`"
										v-model="model.imdgunonumber"
										:label="$t('sdsmgood.imdgunonumber')"
										:disabled="true"
										toplabel
										maxlength="4"
									></pui-text-field>
								</v-col>

								<!-- IMDGPAGE -->
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-1 col-xl-1">
									<pui-text-field
										:id="`imdgpage-sdsmgood`"
										v-model="model.imdgpage"
										:label="$t('sdsmgood.imdgpage')"
										:disabled="true"
										toplabel
										maxlength="10"
									></pui-text-field>
								</v-col>

								<!-- IMDGVERSION -->
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-1 col-xl-1">
									<pui-text-field
										:id="`imdgversion-sdsmgood`"
										v-model="model.imdgversion"
										:label="$t('sdsmgood.imdgversion')"
										:disabled="true"
										toplabel
										maxlength="10"
									></pui-text-field>
								</v-col>
								<!-- IMDGCLASS -->
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-1 col-xl-1">
									<pui-text-field
										:id="`imdgclass-sdsmgood`"
										v-model="model.imdgclass"
										:label="$t('sdsmgood.imdgclass')"
										:disabled="true"
										toplabel
										maxlength="10"
									></pui-text-field>
								</v-col>
								<!-- IMDGUNODESC -->
								<v-col cols="12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
									<pui-text-field
										:id="`imdgunodesc-sdsmgood`"
										v-model="model.imdgunodesc"
										:label="$t('sdsmgood.imdgunodesc')"
										:disabled="true"
										toplabel
										maxlength="100"
									></pui-text-field>
								</v-col>
							</v-row>
						</pui-field-set>
						<template v-if="model.blid">
							<template v-if="!isPassengers">
								<!------------------ EQUIPMENTS ------------------>
								<sdsm-good-equipments ref="child1" :good="model" :params="{ isCreatingElement: true }"></sdsm-good-equipments>
								<!------------------ DOCUMENTS ------------------->
								<sdsm-good-documents ref="child2" :good="model" :params="{ isCreatingElement: true }"></sdsm-good-documents>
							</template>
						</template>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-else>
			<v-progress-linear color="primary" indeterminate></v-progress-linear>
		</template>
	</div>
</template>

<script>
import sdsmgoodEquipments from '../SdsmgoodFormEquipments.vue'
import sdsmgoodDocuments from '../SdsmgoodFormDocuments.vue'
import SdsmGoodUtils from '../SdsmgoodUtils'
import { mapState } from 'vuex'
export default {
	name: 'sdsmgood-form-for-wizard',
	components: {
		'sdsm-good-equipments': sdsmgoodEquipments,
		'sdsm-good-documents': sdsmgoodDocuments
	},
	props: {
		modeltemplate: { type: Object, default: () => {} }
	},
	data () {
		return {
			billOfLoadingHeaderData: null,
			model: null,
			ddggcriteria: null,
			ddgg: null,
			loading: false,
			ready: false
		}
	},
	watch: {
		ddggcriteria () {
			if (this.ddgg) {
				const DG = this.ddgg.find(e => e.onu === this.ddggcriteria)
				this.model.imdgunonumber = DG?.onu || null
				this.model.imdgpage = DG?.pagina || null
				this.model.imdgversion = DG?.version || null
				this.model.imdgclass = DG?.clase || null
				this.model.imdgunodesc = DG?.denominacion || null
			}
		}
	},
	computed: {
		...mapState({
			tarcodes: state => state.PCSUM.tarcodes
		}),
		getTaricInfo () {
			return this.tarcodes.find(t => t.taricid === this.model.taricid)
		},
		filteredTarCodes () {
			return SdsmGoodUtils.filterTarCodes(this.tarcodes, this.isPassengers)
		},
		checkDsdtRequired () {
			return this.model.taricid && this.tarcodes.length ? this.getTaricInfo.iscustomsexitmandatory == '1' : false
		},
		isCargo () {
			return this.billOfLoadingHeaderData && this.billOfLoadingHeaderData.iscargo ? true : false
		},
		isPassengers () {
			return this.billOfLoadingHeaderData && this.billOfLoadingHeaderData.ispassengers ? true : false
		},
		isEmpty () {
			return this.billOfLoadingHeaderData && this.billOfLoadingHeaderData.isempty ? true : false
		},
		checkVehBraIDRequired () {
			return this.model.taricid && this.tarcodes.length ? SdsmGoodUtils.checkVehBrandRequired(this.getTaricInfo.tariccod) : false
		},
		filteredBLID () {
			// Prepare basic filter
			const filter = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'operatorid', op: 'eq', data: this.operatorId },
					{ field: 'isempty', op: 'eq', data: 0 }
				]
			}
			// Filter if parent PK -> Document or Joint Service
			if (this.$attrs.parentpk && this.isCreatingElement) {
				const parentpk = JSON.parse(atob(this.$attrs.parentpk))
				if (parentpk.documentid) {
					filter.rules.push({ field: 'documentid', op: 'eq', data: parentpk.documentid })
				}
				if (parentpk.jointserviceid) {
					filter.rules.push({ field: 'jointserviceid', op: 'eq', data: parentpk.jointserviceid })
				}
				if (parentpk.blid) {
					filter.rules.push({ field: 'blid', op: 'eq', data: parentpk.blid })
				}
			}
			return filter
		}
	},
	methods: {
		async setHeaderData () {
			if (this.model.blid) {
				await this.$puiRequests.postRequest(
					'/puisearch',
					{
						model: 'vhdgood',
						rows: null,
						filter: {
							groups: [
								{
									groupOp: 'and',
									rules: [
										{ field: 'blid', op: 'eq', data: this.model.blid },
										{ field: 'langtra', op: 'eq', data: this.$store.getters.getUserLanguage }
									]
								}
							],
							groupOp: 'and',
							rules: []
						}
					},
					responsee => {
						if (responsee && responsee.data && responsee.data.data && responsee.data.data.length > 0) {
							this.billOfLoadingHeaderData = responsee.data.data[0]
						}
					}
				)
			}
		},
		async SaveWizard () {
			let good = null
			if (this.ready) {
				const eqs = this.$refs.child1?.equipmentsGood || []
				const docs = this.$refs.child2?.documentscs || []
				const valid = await SdsmGoodUtils.checkBeforeSaving(this, this.model, eqs, docs)
				if (valid) {
					// Ready flag
					this.ready = false
					// Saving
					this.model.jointserviceid = this.billOfLoadingHeaderData.jointserviceid
					this.model.jointservicenumber = this.billOfLoadingHeaderData.jointservicenumber
					// this.model.ordernumber = null
					good = await SdsmGoodUtils.save(this, this.model, eqs, docs)
					// Ready flag
					this.ready = true
					if (good) {
						// Notify
						//	this.$puiNotify.success(this.$t('general.copysuccess'))
						// Restoring model from Template
						this.model = { ...this.modeltemplate }
						this.model.ordernumber = await SdsmGoodUtils.generateNextGoodOrderNumber(
							this,
							this.billOfLoadingHeaderData.jointserviceid,
							this.billOfLoadingHeaderData.jointservicenumber
						)
						// Cleaning eqs and docs (when the component is visible)
						if (this.$refs.child1) {
							this.$refs.child1.equipmentsGood = []
						}
						if (this.$refs.child2) {
							this.$refs.child2.documentscs = []
						}
					}
				}
			}
			return good
		}
	},
	async created () {
		this.model = { ...this.modeltemplate }
		await this.setHeaderData()
		this.ddgg = await SdsmGoodUtils.getDDGG(this)
		SdsmGoodUtils.getTarCodes(this)
		this.model.ordernumber = await SdsmGoodUtils.generateNextGoodOrderNumber(
			this,
			this.billOfLoadingHeaderData.jointserviceid,
			this.billOfLoadingHeaderData.jointservicenumber
		)

		this.ready = true
	}
}
</script>
