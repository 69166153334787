<template>
	<v-card class="PcsumStatsHeader" tile elevation="0" :loading="!data">
		<!-- title -->
		<v-card-title>{{ $t(label) }}</v-card-title>
		<!-- Loop -->
		<template v-if="data">
			<v-card-text :class="{ 'columns-2': data2.length > 1 }">
				<div>
					<template v-for="(D, index) in data">
						<div :key="'CH-' + index">
							<label>{{ D.label }}</label>
							<v-chip small label>{{ D.value?.toLocaleString() }}</v-chip>
						</div>
					</template>
					<div v-if="showTotal">
						<label class="isTotal">{{ $t('general.total') }}</label>
						<v-chip class="isTotal" small label>{{ getTotal?.toLocaleString() }}</v-chip>
					</div>
				</div>
				<div v-if="data2">
					<template v-for="(D, index) in data2">
						<div :key="'CH-' + index">
							<label>{{ D.label }}</label>
							<v-chip small label>{{ D.value?.toLocaleString() }}</v-chip>
						</div>
					</template>
				</div>
				<!-- Total -->
			</v-card-text>
		</template>
	</v-card>
</template>

<script>
export default {
	name: 'pcsum-stats-header',
	props: {
		label: { type: String, default: '' },
		data: { type: Array, default: () => [] },
		showTotal: { type: Boolean, default: false },
		data2: { type: Array, default: () => [] },
	},
	computed: {
		getTotal () {
			let t = 0
			for (let d in this.data) {
				t += !isNaN(this.data[d].value) ? this.data[d].value : 0
			}
			return t
		}
	}
}
</script>

<style scoped>
.PcsumStatsHeader {
	border-radius: 5px;
	background: var(--main-10) !important;
	height: 100%;
}
.PcsumStatsHeader .v-card__title {
	font-weight: bold;
	font-size: 14px !important;
	color: var(--primarycolor);
}
.PcsumStatsHeader .v-card__text {
	display: block;
	padding: 0px 16px 16px 16px !important;
}
.PcsumStatsHeader .v-card__text.columns-2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
}

.PcsumStatsHeader .v-card__text > div > div {
	font-size: 13px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 3px;
	padding-bottom: 3px;
	border-bottom: 1px dashed #c9d2dd;
}
.PcsumStatsHeader .v-card__text > div > div:last-child {
	border-bottom: 0px
}

.PcsumStatsHeader .v-card__text > div > label {
	font-weight: bold;
	font-size: 0.85em;
}
.PcsumStatsHeader label.isTotal {
	font-weight: bold;
	color: var(--main-85);
}
.PcsumStatsHeader .v-card__text .v-chip {
	background: var(--main-25);
	font-size: 0.85em;
	min-width: 40px;
	height: 20px;
	display: flex;
	justify-content: center;
}
.PcsumStatsHeader .v-card__text .v-chip.isTotal {
	background: var(--main-85);
	border: 0px !important;
	color: white;
	margin-left: auto;
}
</style>
