import { EntityStatusUtils } from '../../utils/entityStates';

const MassEditionAction = {
	id: 'idMassEditionAction',
	selectionType: 'single',
	label: 'modal.sdsmbillofloading.massiveedit.title',
	description: 'action.sdsmbillofloading.massiveedit.description',
	functionality: 'MAS_EDIT_BL',
	showInForm: true,
	checkAvailability: function (reg) {
		return (
			reg?.[0] &&
			reg[0].shipoperatorid == this.$store.state.session.properties.GESTAUT_OPERATOR_ID &&
			EntityStatusUtils.checkEditionAvailable(reg[0].blstateid)
		);
	},
	runAction: function (action, model, registries) {
		this.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmjointservice',
				filter: {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: 'jointserviceid', op: 'eq', data: registries[0].jointserviceid }]
						}
					],
					groupOp: 'and',
					rules: []
				}
			},
			(response) => {
				const billofloading = registries[0];
				billofloading.isAction = true;

				this.$store.commit('M__MASSIVE_CREATE_MODAL', { open: true, jointservice: response.data.data[0], billofloading });
			},
			() => {}
		);
	}
};

const MassUpdateAction = {
	id: 'idMassUpdateAction',
	selectionType: 'multiple',
	label: 'modal.sdsmbillofloading.massiveupd.title',
	description: 'action.sdsmbillofloading.massiveupd.description',
	functionality: 'MAS_UPD_BL',
	showInForm: true,
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		const data = {
			billofloadings: registries,
			isAction: true
		};
		this.$puiEvents.$emit('pui-modalDialogForm-idMassUpdateAction-' + model.name + '-show', data);
	}
};

const BillofloadingSend = {
	id: 'BillofloadingSendAction',
	selectionType: 'single',
	label: 'action.sdsmbillofloading.billofloadingSendAction.title',
	functionality: 'SEND_BL',
	//functionality: null,
	showInForm: true,
	checkAvailability: function (reg) {
		if (reg?.[0]) {
			return EntityStatusUtils.checkSendAvailable(reg[0].blstateid);
		}
	},
	runAction: async function (action, model, registries) {
		// Get PK for the header
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		await this.$puiRequests.postRequest(
			'/sdsmbillofloading/send',
			registries,
			() => {
				this.$puiNotify.success(this.$t('general.sendsuccess'));
			},
			(e) => {
				this.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
	}
};

const BillofloadingUndoDelete = {
	id: 'BillofloadingUndoDelete',
	selectionType: 'single',
	label: 'action.sdsmbillofloading.BillofloadingUndoDeleteAction.title',
	functionality: 'UNDO_DEL_BL',

	//functionality: null,
	showInForm: true,
	checkAvailability: function (reg) {
		return reg?.[0]?.blstateid == 9;
	},
	runAction: async function (action, model, registries) {
		// Get PK for the header
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		await this.$puiRequests.postRequest(
			'/sdsmbillofloading/undoDel',
			registries[0].blid,
			() => {
				this.$puiNotify.success(this.$t('general.success'));
			},
			(e) => {
				this.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
	}
};

export default {
	gridactions: [MassEditionAction, MassUpdateAction, BillofloadingSend, BillofloadingUndoDelete],
	formactions: [BillofloadingSend, BillofloadingUndoDelete]
};
