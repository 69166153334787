<template>
	<div class="pui-form">
		<template v-if="model && ready">
			<pcsum-header :data="documentHeader"></pcsum-header>
			<v-form class="mb-4 pb-4" ref="form" lazy-validation @submit.prevent>
				<v-row class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('form.sdsmdocument.header')">
							<v-row dense align="center">
								<!-- DOCTYPEID -->
								<v-col cols="4 pl-2">
									<v-radio-group v-model="model.doctypeid" row style="position: relative; top: 4px" disabled>
										<v-radio disabled :label="$t('sdsmdocument.documenttype.upload')" :value="1"></v-radio>
										<v-radio disabled :label="$t('sdsmdocument.documenttype.download')" :value="2"></v-radio>
									</v-radio-group>
								</v-col>

								<!-- DOCUMENTNUMBER -->
								<v-col cols="2 offset-sm-6 offset-md-6 offset-lg-6 offset-xl-6">
									<pui-text-field
										:id="`documentnumber-sdsmdocument`"
										v-model="model.documentnumber"
										:label="$t('sdsmdocument.documentnumber')"
										disabled
										toplabel
										maxlength="15"
									></pui-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<!-- PORTID -->
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
									<pui-select
										id="portid-sdsmdocument"
										attach="portid"
										:label="$t('sdsmdocument.port')"
										toplabel
										clearable
										required
										singleItemAutoselect
										v-model="model"
										:queryFields="['description']"
										modelName="mlocalport"
										:modelFormMapping="{ portid: 'portid' }"
										:itemsToSelect="[{ portid: model.portid }]"
										:itemValue="['portid']"
										:itemText="(item) => `${item.description}`"
										:order="{ portid: 'asc' }"
										:fixedFilter="getDateExpirationFilter"
									></pui-select>
								</v-col>
								<!-- CUSTOMSID -->
								<v-col cols="12 col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
									<pui-select
										id="customsid"
										attach="customsid"
										:label="$t('sdsmdocument.customsdes')"
										toplabel
										disabled
										reactive
										v-model="model"
										modelName="sdsmcustoms"
										:modelFormMapping="{ customsid: 'customsid' }"
										:itemsToSelect="[{ customsid: model.customsid }]"
										:itemValue="['customsid']"
										:itemText="(item) => `${item.code} - ${item.description}`"
										:fixedFilter="getDateExpirationFilter"
									></pui-select>
								</v-col>
								<!-- OPERATORID -->
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-5 col-xl-5" style="display: none">
									<pui-select
										id="operatorid-sdsmdocument"
										attach="operatorid"
										:label="$t('sdsmdocument.operator')"
										toplabel
										clearable
										required
										:disabled="true"
										v-model="model"
										modelName="sdsmoperator"
										:modelFormMapping="{ operatorid: 'operatorid' }"
										:itemsToSelect="[{ operatorid: model.operatorid }]"
										:itemValue="['operatorid']"
										:itemText="(item) => `${item.description}`"
										:order="{ operatorid: 'asc' }"
										:fixedFilter="getDateExpirationFilter"
									></pui-select>
								</v-col>
								<!-- CALLYEAR -->
								<v-col cols="12 col-xs-12 col-sm-2 col-md-2 col-lg-1 col-xl-1">
									<pui-number-field
										:id="`callyear-sdsmdocument`"
										v-model="model.callyear"
										:label="$t('sdsmdocument.callyear')"
										:disabled="false"
										required
										toplabel
										integer
										min="0"
										max="9999"
									></pui-number-field>
								</v-col>
								<!-- CALLNUMBER -->
								<v-col cols="12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
									<label class="customLabel v-label--required">{{ $t('sdsmdocument.callnumber') }}</label>
									<v-autocomplete
										class="customSelector"
										outlined
										required
										dense
										:rules="[(v) => !!v || $t('requiredMessage')]"
										reactive
										:key="callnumberKey"
										v-model="model.callnumber"
										:loading="loadingEscalas"
										:items="escalas"
										:disabled="loadingEscalas"
										clearable
										item-value="numescala"
										:item-text="(item) => `${item.numescala}`"
									>
									</v-autocomplete>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12">
									<pui-checkbox
										:id="`isactive-copyBill`"
										:label="$t('action.sdsmdocument.copyBill.title')"
										v-model="model.isActiveCopyBill"
										true-value="1"
										false-value="0"
									></pui-checkbox>
								</v-col>
								<v-col cols="12">
									<pui-checkbox
										v-if="model.isActiveCopyBill === '1'"
										:id="`isactive-copyEquipment`"
										:label="$t('action.sdsmdocument.copyEquipment.title')"
										v-model="model.isActiveCopyEquipment"
										true-value="1"
										false-value="0"
									></pui-checkbox>
								</v-col>
								<v-col cols="12">
									<pui-checkbox
										v-if="model.isActiveCopyBill === '1'"
										:id="`isactive-copyGoods`"
										:label="$t('action.sdsmdocument.copyGoods.title')"
										v-model="model.isActiveCopyGoods"
										true-value="1"
										false-value="0"
									></pui-checkbox>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-else>
			<v-progress-linear color="primary" indeterminate></v-progress-linear>
		</template>
	</div>
</template>

<script>
import SdsmdocumentUtils from '../SdsmdocumentUtils';
import formUtils from '@/utils/form';
import PcsumHeader from './../../general/PcsumHeader';
import Vue from 'vue';
export default {
	name: 'sdsmdocument-form-for-copy',
	components: {
		'pcsum-header': PcsumHeader
	},
	props: {
		model: { type: Object, default: () => {} }
	},
	data() {
		return {
			ready: false,
			escalas: [],
			loadingEscalas: false,
			callnumberKey: 0,
			loading: false
		};
	},
	methods: {
		generateDocumentNumber() {
			this.model.documentnumber = SdsmdocumentUtils.generateDocumentNumber(this.model);
		},
		beforeSave() {
			this.model.documentstateid = 1;
		},
		changePortID() {
			this.$puiEvents.$on(`onPuiSelect_selectedItems-portid-sdsmdocument`, (newValPort) => {
				if (newValPort) {
					const lupamodel = newValPort.model;
					this.model.customsid = lupamodel.customsid;
					this.model.customscode = lupamodel.customscode;
					this.model.locode = lupamodel.countrycode + lupamodel.portcode;
				}
			});
		},
		changeOperatorID() {
			this.$puiEvents.$on(`onPuiSelect_selectedItems-operatorid-sdsmdocument`, (newValOperator) => {
				if (newValOperator && newValOperator.model) {
					this.model.fiscalcode = newValOperator.model.fiscalcode;
				}
			});
		},
		setEscalaFields() {
			const call = this.escalas.find((e) => e.numescala == this.model.callnumber);
			this.model.calllastport = call?.locodeorigen;
			this.model.callnextport = call?.locodedestino;
			this.model.shipname = call?.nombuque;
			this.model.shipimo = call?.imo;
			this.model.shipflag = call?.bandera;
			this.model.callcode = call?.codescala;
		},
		cleanCallNumber() {
			this.model.callnumber = null;
			this.getEscalasPCS();
		},
		async getEscalasPCS() {
			if (this.model && this.model.locode && this.model.callyear && this.model.fiscalcode) {
				this.callnumberKey++;
				this.loadingEscalas = true;
				this.escalas = await SdsmdocumentUtils.getEscalasPCS(this, this.model, this.model.locode, this.model.callyear, this.model.fiscalcode);
				this.setEscalaFields();
				this.generateDocumentNumber();
				this.loadingEscalas = false;
			}
		},

		WatchCallYear(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.model.callnumber = null;
				this.getEscalasPCS();
			}
		},
		WatchOperatorID(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.model.callnumber = null;
				this.changeOperatorID();
				this.getEscalasPCS();
			}
		},
		WatchPortID(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.model.callnumber = null;
				this.changePortID();
				this.getEscalasPCS();
			}
		},
		WatchCallNumber(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.setEscalaFields();
				this.generateDocumentNumber();
			}
		},
		async buildDocumentHeader() {
			if (this.model.documenttype == undefined) {
				let document = await SdsmdocumentUtils.getDocStats(this, this.model);
				this.model = document;
			}
			this.documentHeader = [
				{ label: 'header.sdsmdocument.documentnumber', value: this.model.documentnumber },
				{ label: 'header.sdsmdocument.type', value: this.model.documenttype },
				{ label: 'header.sdsmdocument.operator', value: this.model.operator, showId: true, id: this.model.operatorid },
				{ label: 'header.sdsmdocument.callnumber', value: this.model.callnumber, pill: true },
				{ label: 'header.sdsmdocument.shipname', value: this.model.shipname }
			];
		},
		// Copy Document
		async CopyDocument() {
			let document = null;
			if (this.ready) {
				this.ready = false;
				document = await SdsmdocumentUtils.copyDocument(this, this.model);
				this.ready = true;
			}
			if (this.storedData) this.modelDependency.storedData = this.storedData;
			this.$puiEvents.$emit('onPuiRouter-sameModelAndComponent-refreshRouter');
			return document;
		}
	},
	computed: {
		getDockFilter() {
			return SdsmdocumentUtils.getDockFilter(this, this.model);
		},
		getDateExpirationFilter() {
			return SdsmdocumentUtils.getDateExpirationFilter();
		},
		getISEUActiveFilter() {
			return SdsmdocumentUtils.getISEUActiveFilter();
		}
	},
	async created() {
		formUtils.updateMiniAudit(this.model);
		// Header data
		this.buildDocumentHeader();
		// Cleaning model
		this.model.documentnumber = null;
		this.model.callnumber = null;
		Vue.set(this.model, 'isActiveCopyBill', '0');
		Vue.set(this.model, 'isActiveCopyEquipment', '0');
		Vue.set(this.model, 'isActiveCopyGoods', '0');
		// Watchers and init data
		this.$set(this.model, 'customscode', null);
		this.$set(this.model, 'fiscalcode', null);
		this.$set(this.model, 'locode', null);
		this.$watch('model.callnumber', this.WatchCallNumber);
		this.$watch('model.portid', this.WatchPortID);
		this.$watch('model.operatorid', this.WatchOperatorID);
		this.$watch('model.callyear', this.WatchCallYear);

		this.$watch('model.locode', this.getEscalasPCS);
		this.$watch('model.fiscalcode', this.getEscalasPCS);
		this.changePortID();
		this.changeOperatorID();
		this.ready = true;
	}
};
</script>
