<template>
	<div class="pui-form">
		<template v-if="model">
			<v-form class="mb-4 pb-4" ref="form" lazy-validation @submit.prevent>
				<v-row class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('modal.sdsmgood.massiveedit.utis')">
							<v-chip-group column>
								<template v-for="(G, index) in goods">
									<template v-if="G.goodid">
										<v-tooltip bottom :key="'GOOD-' + index">
											<template v-slot:activator="{ on, attrs }">
												<v-chip label outlined :color="getStatus(G).color" v-bind="attrs" v-on="on">
													<span>{{ G.ordernumber }}</span>
													<template v-if="G.status">
														<v-icon small class="pl-3" :color="getStatus(G).color">{{ getStatus(G).icon }} </v-icon>
													</template>
												</v-chip>
											</template>
											<span class="massiveToolTip">
												<div class="content">
													<div>{{ $t('sdsmdocument.documentnumber') }}: {{ G.documentnumber }}</div>
													<div>{{ $t('sdsmbillofloading.blnumber') }}: {{ G.blnumber }} - {{ G.ordernumber }}</div>
													<div>{{ $t('sdsmgood.bltype') }}: {{ G.bltypedes }}</div>
													<div>{{ $t('sdsmgood.state') }}: {{ G.state }}</div>
													<div>{{ $t('sdsmgood.packagesnumber') }}: {{ G.packagesnumber }}</div>
													<div>{{ $t('sdsmgood.customssituation') }}: {{ G.customssituation }}</div>
													<div>{{ $t('sdsmgood.taricid') }}: {{ G.tariccod }} - {{ G.taricdescription }}</div>
												</div>
												<div v-if="G.error" class="messages">
													<i class="fas fa-exclamation-triangle"></i> <span>{{ G.error }}</span>
												</div>
											</span>
										</v-tooltip>
									</template>
								</template>
							</v-chip-group>
						</pui-field-set>
					</v-col>
					<v-col cols="12">
						<pui-field-set :title="$t('modal.sdsmgood.massiveedit.newvalues')">
							<v-row dense>
								<!-- PACKAGESNUMBER -->
								<v-col cols="12 col-xs-12 col-sm-1 col-md-1 col-lg-1 col-xl-1">
									<pui-text-field
										:id="`packagesnumber-sdsmgood`"
										v-model="model.packagesnumber"
										:label="$t('sdsmgood.packagesnumber')"
										toplabel
										maxlength="12"
									></pui-text-field>
								</v-col>

								<!-- WEIGHT -->
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-1 col-xl-1">
									<pui-text-field
										:id="`weight-sdsmgood`"
										v-model="model.weight"
										:label="$t('sdsmgood.weight')"
										toplabel
										maxlength="18"
									></pui-text-field>
								</v-col>

								<!-- VOLUME -->
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-1 col-xl-1">
									<pui-text-field
										:id="`volume-sdsmgood`"
										v-model="model.volume"
										:label="$t('sdsmgood.volume')"
										toplabel
										maxlength="18"
									></pui-text-field>
								</v-col>

								<!-- LOWVALUESENT -->
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-1 col-xl-1">
									<pui-text-field
										:id="`lowvaluesent-sdsmgood`"
										v-model="model.lowvaluesent"
										:label="$t('sdsmgood.lowvaluesent')"
										toplabel
										maxlength="18"
									></pui-text-field>
								</v-col>
								<!-- PACKAGETYPEID -->
								<v-col cols="12 col-xs-12 col-sm-8 col-md-8 col-lg-4 col-xl-4">
									<pui-select
										:id="`packagetypeid-sdsmgood`"
										:attach="`packagetypeid-sdsmgood`"
										:label="$t('sdsmgood.packagetypeid')"
										toplabel
										clearable
										modelName="mpackagetype"
										v-model="model"
										reactive
										singleItemAutoselect
										:queryFields="['description']"
										:itemsToSelect="[{ packagetypeid: model.packagetypeid }]"
										:modelFormMapping="{ packagetypeid: 'packagetypeid' }"
										:itemValue="['packagetypeid']"
										:itemText="(item) => `${item.description}`"
									></pui-select>
								</v-col>

								<!-- CUSSITUATIONID -->
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
									<pui-select
										:id="`cussituationid-sdsmgood`"
										:attach="`cussituationid-sdsmgood`"
										:label="$t('sdsmgood.cussituationid')"
										toplabel
										clearable
										modelName="mcustomssituation"
										v-model="model"
										reactive
										:itemsToSelect="[{ cussituationid: model.cussituationid }]"
										:modelFormMapping="{ cussituationid: 'cussituationid' }"
										singleItemAutoselect
										:queryFields="['cussituationid', 'description']"
										:itemValue="['cussituationid']"
										:itemText="(item) => `${item.cussituationid} - ${item.description}`"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense align="center">
								<!-- TARICID -->
								<v-col cols="12 col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 pl-2 pr-2">
									<label class="customLabel">{{ $t('sdsmgood.taricid') }}</label>
									<v-autocomplete
										class="customSelector"
										outlined
										dense
										reactive
										clearable
										v-model="model.taricid"
										@keyup="selectIfOnlyOneOption"
										:readonly="taricSelected"
										ref="taricid"
										:items="filteredTarCodes"
										item-value="taricid"
										:item-text="(item) => `${item.tariccod} - ${item.description}`"
									>
										<template v-slot:item="{ props, item }">
											<div class="SlotOptionWithDetails" v-bind="props">
												<span class="value">{{ item.tariccod }}</span>
												<span class="separator"> - </span>
												<span>{{ item.description }}</span>
											</div>
										</template>
									</v-autocomplete>
								</v-col>

								<!-- VEHBRAID -->
								<v-col cols="12 col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
									<pui-select
										:id="`vehbraid-sdsmgood`"
										:attach="`vehbraid-sdsmgood`"
										:label="$t('sdsmgood.vehbraid')"
										toplabel
										clearable
										:required="checkVehBraIDRequired"
										modelName="mvehiclebrand"
										v-model="model"
										reactive
										:itemsToSelect="[{ vehbraid: model.vehbraid }]"
										:modelFormMapping="{ vehbraid: 'vehbraid' }"
										:itemValue="['vehbraid']"
										singleItemAutoselect
										:queryFields="['vehbraid', 'description']"
										:itemText="(item) => `${item.vehbraid} - ${item.description}`"
									></pui-select>
								</v-col>

								<!-- MARKINGINSTRID -->
								<v-col cols="12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
									<pui-select
										:id="`markinginstrid-sdsmgood`"
										:attach="`markinginstrid-sdsmgood`"
										:label="$t('sdsmgood.markinginstrid')"
										toplabel
										clearable
										modelName="vutmmarkinginstructiontra"
										v-model="model"
										reactive
										:itemsToSelect="[{ markinginstrid: model.markinginstrid }]"
										:modelFormMapping="{ markinginstrid: 'markinginstrid' }"
										:itemValue="['markinginstrid']"
										singleItemAutoselect
										:queryFields="['edicode', 'description']"
										:itemText="(item) => `${item.edicode} - ${item.description}`"
									></pui-select>
								</v-col>

								<!-- PACKAGESMARKING -->
								<v-col cols="12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
									<pui-text-field
										:id="`packagesmarking-sdsmgood`"
										v-model="model.packagesmarking"
										:label="$t('sdsmgood.packagesmarking')"
										toplabel
										maxlength="350"
									></pui-text-field>
								</v-col>

								<!-- STEVEDOREID -->
								<v-col cols="12 col-xs-12 col-lg-6 col-xl-6">
									<pui-select
										:id="`stevedoreid-sdsmgood`"
										:attach="`stevedoreid-sdsmgood`"
										:label="$t('sdsmgood.stevedoreid')"
										toplabel
										clearable
										modelName="sdsmstevedore"
										v-model="model"
										reactive
										:itemsToSelect="[{ stevedoreid: model.stevedoreid }]"
										:modelFormMapping="{ stevedoreid: 'stevedoreid' }"
										:itemValue="['stevedoreid']"
										singleItemAutoselect
										:queryFields="['description']"
										:itemText="(item) => `${item.description}`"
									></pui-select>
								</v-col>

								<!-- ISMANDATORYENS -->
								<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
									<v-radio-group v-model="model.ismandatoryens" row style="position: relative; top: 4px">
										<v-radio :label="$t('sdsmgood.ismandatoryens.yes')" value="1"></v-radio>
										<v-radio :label="$t('sdsmgood.ismandatoryens.no')" value="0"></v-radio>
									</v-radio-group>
								</v-col>

								<!-- MANIPULATIONTERMINAL -->
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
									<pui-text-field
										:id="`manipulationterminal-sdsmgood`"
										v-model="model.manipulationterminal"
										:label="$t('sdsmgood.manipulationterminal')"
										toplabel
										maxlength="35"
									></pui-text-field>
								</v-col>

								<!-- SPECIALINSTUSE -->
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2">
									<pui-text-field
										:id="`specialinstuse-sdsmgood`"
										v-model="model.specialinstuse"
										:label="$t('sdsmgood.specialinstuse')"
										toplabel
										maxlength="35"
									></pui-text-field>
								</v-col>

								<!-- DSDTNUMBER -->
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2">
									<pui-text-field
										:id="`dsdtnumber-sdsmgood`"
										v-model="model.dsdtnumber"
										:label="$t('sdsmgood.dsdtnumber')"
										toplabel
										maxlength="35"
									></pui-text-field>
								</v-col>

								<!-- ORDERNUMBERGOODDSDT -->
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2">
									<pui-text-field
										:id="`ordernumbergooddsdt-sdsmgood`"
										v-model="model.ordernumbergooddsdt"
										:label="$t('sdsmgood.ordernumbergooddsdt')"
										toplabel
										maxlength="35"
									></pui-text-field>
								</v-col>

								<!-- COMCODE -->
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2">
									<pui-text-field
										:id="`comcode-sdsmgood`"
										v-model="model.comcode"
										:label="$t('sdsmgood.comcode')"
										toplabel
										maxlength="35"
									></pui-text-field>
								</v-col>
							</v-row>
						</pui-field-set>
						<!------------------ DDGG ------------------>
						<pui-field-set :title="$t('sdsmgood.mmpp')">
							<v-row dense>
								<!-- DDGG CRITERIA -->
								<v-col cols="2" class="pl-2 pr-2">
									<label class="customLabel">{{ $t('sdsmgood.search') }}</label>
									<v-autocomplete
										class="customSelector"
										outlined
										dense
										v-model="ddggcriteria"
										:loading="loading"
										:items="ddgg"
										validate-on-blur
										clearable
										reactive
										@keyup="selectIfOnlyOneOptionDDGG"
										@focus="focusDDGG"
										ref="ddggRef"
										item-value="onu"
										:item-text="ddggcriteria ? `''` : (item) => `${item.onu} - ${item.denominacion}`"
									>
										<template v-slot:item="{ props, item }">
											<div class="BasicSlotOption" v-bind="props">
												<span> {{ item?.onu }} </span>
												<span> {{ item?.denominacion }} </span>
											</div>
										</template>
										<template v-slot:selection="{}"> </template>
									</v-autocomplete>
								</v-col>

								<!-- IMDGUNONUMBER -->
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-1 col-xl-1">
									<pui-text-field
										:id="`imdgunonumber-sdsmgood`"
										v-model="model.imdgunonumber"
										:label="$t('sdsmgood.imdgunonumber')"
										:disabled="true"
										toplabel
										maxlength="4"
									></pui-text-field>
								</v-col>

								<!-- IMDGPAGE -->
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-1 col-xl-1">
									<pui-text-field
										:id="`imdgpage-sdsmgood`"
										v-model="model.imdgpage"
										:label="$t('sdsmgood.imdgpage')"
										:disabled="true"
										toplabel
										maxlength="10"
									></pui-text-field>
								</v-col>

								<!-- IMDGVERSION -->
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-1 col-xl-1">
									<pui-text-field
										:id="`imdgversion-sdsmgood`"
										v-model="model.imdgversion"
										:label="$t('sdsmgood.imdgversion')"
										:disabled="true"
										toplabel
										maxlength="10"
									></pui-text-field>
								</v-col>
								<!-- IMDGCLASS -->
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-1 col-xl-1">
									<pui-text-field
										:id="`imdgclass-sdsmgood`"
										v-model="model.imdgclass"
										:label="$t('sdsmgood.imdgclass')"
										:disabled="true"
										toplabel
										maxlength="10"
									></pui-text-field>
								</v-col>
								<!-- IMDGUNODESC -->
								<v-col cols="12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
									<pui-text-field
										:id="`imdgunodesc-sdsmgood`"
										v-model="model.imdgunodesc"
										:label="$t('sdsmgood.imdgunodesc')"
										:disabled="true"
										toplabel
										maxlength="100"
									></pui-text-field>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-else>
			<v-progress-linear color="primary" indeterminate></v-progress-linear>
		</template>
	</div>
</template>

<script>
import SdsmGoodUtils from '../SdsmgoodUtils';
import MassiveUtils from '../../../utils/massive';
import { mapState } from 'vuex';
export default {
	name: 'sdsmgood-form-for-massive-update',
	props: {
		goods: { type: Array, default: () => [] }
	},
	data() {
		return {
			model: null,
			ddggcriteria: null,
			ddgg: null,
			loading: false,
			taricSelected: false,
			ready: false
		};
	},
	watch: {
		ddggcriteria() {
			if (this.ddgg) {
				const DG = this.ddgg.find((e) => e.onu === this.ddggcriteria);
				this.model.imdgunonumber = DG?.onu || null;
				this.model.imdgpage = DG?.pagina || null;
				this.model.imdgversion = DG?.version || null;
				this.model.imdgclass = DG?.clase || null;
				this.model.imdgunodesc = DG?.denominacion || null;
			}
		},
		'model.taricid'(newQuestion) {
			if (newQuestion != null) {
				this.taricSelected = true;
			} else {
				this.taricSelected = false;
			}
		}
	},
	computed: {
		...mapState({
			tarcodes: (state) => state.PCSUM.tarcodes
		}),
		checkVehBraIDRequired() {
			return this.model.taricid && this.tarcodes.length ? SdsmGoodUtils.checkVehBrandRequired(this.getTaricInfo.tariccod) : false;
		},
		getTaricInfo() {
			return this.tarcodes.find((t) => t.taricid === this.model.taricid);
		},
		filteredTarCodes() {
			//filtrar tambien por fecha
			return SdsmGoodUtils.filterTarCodes(this.tarcodes, this.isPassengers, this.isCreatingElement, this.resetTar);
		}
	},
	methods: {
		getStatus(uti) {
			return MassiveUtils.getStatus(uti);
		},
		async SendGoodMassiveUpdate() {
			let everythingupdated = true;
			for (const g in this.goods) {
				if (this.goods[g].goodid) {
					this.goods[g].status = 1;
					let response = await SdsmGoodUtils.massiveupdate(this, this.model, this.goods[g].goodid);
					if (response.updated) {
						this.goods[g].status = 2;
						this.goods[g].error = false;
					} else {
						this.goods[g].status = 3;
						this.goods[g].error = response.error;
						everythingupdated = false;
					}
				}
			}
			if (everythingupdated) {
				this.$puiNotify.success(this.$t('modal.sdsmuti.massiveedit.notifySuccess'));
			}
			return everythingupdated;
		},
		selectIfOnlyOneOption(event) {
			if (event.key == 'Backspace' && this.model.taricid != null) {
				this.model.taricid = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.filteredTarCodes.filter((item) => {
				return item.tariccod.toLowerCase().includes(text.toLowerCase()) || item.description.toLowerCase().includes(text.toLowerCase());
			});

			if (resultadosFiltrados.length == 1) {
				this.model.taricid = resultadosFiltrados[0].taricid;

				this.$refs.taricid.isMenuActive = false;
			}
		},
		selectIfOnlyOneOptionDDGG(event) {
			if (event.key == 'Backspace' && this.ddggcriteria != null) {
				this.ddggcriteria = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.ddgg.filter((item) => {
				return item.onu?.toLowerCase().includes(text.toLowerCase()) || item.descripcion?.toLowerCase().includes(text.toLowerCase());
			});

			if (resultadosFiltrados.length == 1) {
				this.ddggcriteria = resultadosFiltrados[0].onu;

				this.$refs.ddggRef.isMenuActive = false;
			}
		},
		async focusDDGG() {
			this.loading = true;
			this.ddgg = await SdsmGoodUtils.getDDGG(this);
			this.loading = false;
		}
	},
	async created() {
		for (const g in this.goods) {
			if (this.goods[g].goodid) {
				this.$set(this.goods[g], 'status', null);
				this.$set(this.goods[g], 'error', null);
			}
		}

		SdsmGoodUtils.getTarCodes(this);
		this.model = await SdsmGoodUtils.newGood(this);
		this.model.stateid = null;
		this.model.cussituationid = null;
	}
};
</script>
