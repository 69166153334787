<template>
	<pui-field-set :title="$t(label)" class="PcsumEditionGrid" :class="classes">
		<template v-if="!loading">
			<div class="PcsumEditionGridRows">
				<!-- Rows -->
				<template v-if="rows.length > 0">
					<template v-for="(R, index) in rows">
						<pcsum-edition-grid-row
							:key="'RG-' + index + '0' + R.utiid + '0' + R.randomKey"
							:row="{ ...R, ...{ rowIndex: index } }"
							:fields="fields"
							:basicDisplay="basicDisplay"
							:allowCreate="allowCreate"
							:allowEdit="allowEdit"
							:allowClone="allowClone"
							:allowRemove="allowRemove"
							:allowLaunch="allowLaunch"
							:launchIcon="launchIcon"
							:userEditable="userEditable"
							:isNewSearchField="isNewSearchField"
							:isSelectedEditableFields="isSelectedEditableFields"
							:isEditedEditableFields="isEditedEditableFields"
							:editInModal="editInModal"
							@EmitShowRemoveDialog="EmitShowRemoveDialog"
							@EmitChange="EmitChange"
							@EmitClone="EmitClone"
							@EmitLaunch="EmitLaunch"
							@EmitEditInModal="EmitEditInModal"
						>
						</pcsum-edition-grid-row>
					</template>
				</template>

				<!-- No rows -->
				<template v-else>
					<div class="pl-1 pr-1">
						<v-alert color="blue" dense text icon="far fa-info-circle" type="info">
							{{ $t(labelEmpty) }}
						</v-alert>
					</div>
				</template>
			</div>
			<!-- Menu -->
			<div class="PcsumEditionGridMenu pb-2 pr-1 pl-1" :key="'GR-' + this.rows.length">
				<v-btn depressed color="primary" @click="EmitRefresh()" v-if="!params.isCreatingElement">
					<i class="far fa-redo-alt"></i>
				</v-btn>
				<v-btn
					v-if="this.allowCreate"
					depressed
					color="primary"
					@click="EmitCreate()"
					:class="{ blocked: this.limit && this.limit <= this.rows.length }"
				>
					<i class="far fa-plus-circle"></i>
				</v-btn>
			</div>
		</template>
		<template v-else>
			<v-progress-linear color="primary" indeterminate></v-progress-linear>
		</template>

		<!-- Dialog > Remove? -->
		<v-dialog v-model="removeDialog" persistent max-width="800">
			<v-card>
				<v-card-title>{{ $t('general.confirmremove') }}</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn elevation="0" @click="cancelRemove()">{{ $t('general.cancel') }}</v-btn>
					<v-btn elevation="0" color="secondary" @click="EmitRemove()">{{ $t('general.remove') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</pui-field-set>
</template>

<script>
import { mapState } from 'vuex';
import PcsumEditionGridRow from './PcsumEditionGridRow.vue';
export default {
	name: 'pcsum-edition-grid',
	components: {
		'pcsum-edition-grid-row': PcsumEditionGridRow
	},
	props: {
		classes: { type: String, default: '' },
		label: { type: String, default: '' },
		labelEmpty: { type: String, default: '' },
		loading: { type: Boolean, default: false },
		fields: { type: Object, default: () => [] },
		rows: { type: Array, default: () => [] },
		limit: { type: Number, default: null },
		basicDisplay: { type: Boolean, default: () => false },
		allowCreate: { type: Boolean, default: () => false },
		allowEdit: { type: Boolean, default: () => false },
		allowRemove: { type: Boolean, default: () => false },
		allowLaunch: { type: Boolean, default: () => false },
		allowClone: { type: Boolean, default: () => false },
		userEditable: { type: Boolean, default: () => false },
		launchIcon: { type: String, default: '' },
		params: { type: Object, default: () => {} },
		editInModal: { type: Boolean, default: false },
		isNewSearchField: { type: String, default: '' },
		isSelectedEditableFields: { type: Array, default: () => [] },
		isEditedEditableFields: { type: Array, default: () => [] }
	},
	data() {
		return {
			tempRow: null,
			removeDialog: false
		};
	},
	computed: {
		...mapState({
			operatorId: (state) => state.session.properties.GESTAUT_OPERATOR_ID
		})
	},
	methods: {
		EmitRefresh() {
			this.$emit('EmitRefresh');
		},
		EmitCreate() {
			if (this.rows.length < this.limit || !this.limit) {
				this.$emit('EmitCreate');
			}
		},
		EmitShowRemoveDialog(registry) {
			this.removeDialog = true;
			this.tempRow = registry;
		},
		EmitRemove() {
			this.removeDialog = false;
			this.$emit('EmitRemove', this.tempRow);
		},
		EmitEditInModal(registry) {
			this.$emit('EmitEditInModal', registry);
		},
		EmitChange(registry) {
			this.$emit('EmitChange', registry);
		},
		EmitClone(registry) {
			this.$emit('EmitClone', registry);
		},
		EmitLaunch(registry) {
			this.$emit('EmitLaunch', registry);
		},
		cancelRemove() {
			this.removeDialog = false;
			this.tempRow = null;
		}
	}
};
</script>

<style>
.PcsumEditionGrid .PcsumEditionGridMenu {
	display: flex;
	grid-gap: 8px;
}
.PcsumEditionGrid .PcsumEditionGridMenu button {
	font-size: 1.25em;
}
.PcsumEditionGrid .PcsumEditionGridMenu button.blocked {
	opacity: 0.25;
	pointer-events: none;
}
.PcsumEditionGrid .v-alert .v-alert__wrapper {
	font-size: 0.9em !important;
}
</style>
