import SdsmGoodUtils from '../sdsmgood/SdsmgoodUtils';

const SdsmbillofloadingUtils = {
	async getBillOfLoadings(ctx, filter) {
		let BLs = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'vhdgood',
				rows: null,
				filter
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					BLs = response.data.data;
				}
			},
			() => {}
		);
		return BLs;
	},
	async getAlerts(ctx, pk) {
		let alerts = [];
		await ctx.$puiRequests.getRequest(
			'/sdsmbillofloading/getAlerts',
			{ pk },
			(response) => {
				alerts = response.data;
			},
			() => {},
			null,
			null
		);
		return alerts;
	},
	async shipmoRequiered(ctx, model) {
		let goods = [];
		let js = [];
		if (model.blid) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmgood',
					rows: null,
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'blid', op: 'eq', data: model.blid },
							{ field: 'iscustomsexitmandatory', op: 'eq', data: '1' }
						]
					}
				},
				(response) => {
					goods = response.data.data;
				},
				() => {}
			);

			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmjointservice',
					rows: null,
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'jointserviceid', op: 'eq', data: model.jointserviceid },
							{ field: 'doctypeid', op: 'eq', data: '2' }
						]
					}
				},
				(response) => {
					js = response.data.data;
				},
				() => {}
			);
		}
		return goods.length > 0 && js.length > 0;
	},

	async getOrdernumberProv(ctx, jsid) {
		if (jsid != null) {
			let r = null;
			const params = {
				data: jsid
			};

			await ctx.$puiRequests.getRequest(
				'/sdsmbillofloading/getOrdernumberProv',
				params,
				(response) => {
					r = response.data;
				},
				() => {}
			);
			return r;
		}
	},

	async getEquipmentsFromBOL(ctx, blid) {
		let eqs = [];
		if (blid) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'vututifrombl',
					rows: null,
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'blid', op: 'eq', data: blid }]
					}
				},
				(response) => {
					eqs = response.data.data;
				},
				() => {}
			);
		}
		return eqs;
	},
	async getLocInfo(ctx) {
		const params = {
			criterio: ''
		};
		await ctx.$puiRequests.getRequest(
			'/sdsmbillofloading/getInfoLocalidades',
			params,
			(response) => {
				ctx.$store.commit('M__LOCINFO', response.data);
			},
			() => {}
		);
	},
	getDesFromLoccode(code, locinfo) {
		let loc = null;
		if (code && locinfo) {
			loc = locinfo.find((l) => l.locode === code);
		}
		return loc ? loc.descripcion : null;
	},
	async getAndPrepareBOLForMassiveEdit(ctx, billofloading) {
		const bol = { ...billofloading };
		bol.creauser = bol.usralta;
		bol.creadate = bol.fecalta;
		bol.moduser = bol.usrmodif;
		bol.moddate = bol.fecmodif;
		const goods = [];
		// Getting Goods from this Billofloading
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmgood',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'blid', op: 'eq', data: bol.blid }]
				}
			},
			async (response) => {
				if (response.data && response.data.totalRecords > 0) {
					for (let g = 0; g < response.data.data.length; g++) {
						const good = { ...response.data.data[g] };
						good.creauser = good.usralta;
						good.creadate = good.fecalta;
						good.moduser = good.usrmodif;
						good.moddate = good.fecmodif;
						good.utis = [];
						good.docs = [];
						// Getting GOOD-DOCS from the current GOOD
						await ctx.$puiRequests.postRequest(
							'/puisearch',
							{
								model: 'sdsmdocumentcustoms',
								rows: null,
								filter: {
									groups: [],
									groupOp: 'and',
									rules: [{ field: 'goodid', op: 'eq', data: good.goodid }]
								}
							},
							(response2) => {
								if (response2.data && response2.data.totalRecords > 0) {
									for (let gd = 0; gd < response2.data.data.length; gd++) {
										const gooddoc = { ...response2.data.data[gd] };
										good.docs.push(gooddoc);
									}
								}
							},
							() => {}
						);
						// Getting GOOD-UTIs from the current GOOD
						await ctx.$puiRequests.postRequest(
							'/puisearch',
							{
								model: 'sdsmgooduti',
								rows: null,
								filter: {
									groups: [],
									groupOp: 'and',
									rules: [{ field: 'goodid', op: 'eq', data: good.goodid }]
								}
							},
							(response3) => {
								if (response3.data && response3.data.totalRecords > 0) {
									for (let gu = 0; gu < response3.data.data.length; gu++) {
										const gooduti = { ...response3.data.data[gu] };
										gooduti.creauser = gooduti.usralta;
										gooduti.creadate = gooduti.fecalta;
										gooduti.moduser = gooduti.usrmodif;
										gooduti.moddate = gooduti.fecmodif;
										good.utis.push(gooduti);
									}
								}
							},
							() => {}
						);
						goods.push(good);
					}
				}
			},
			() => {}
		);
		if (goods.length) {
			// Getting UTIs from the current Bill of lading
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmuti',
					rows: null,
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'blid', op: 'eq', data: bol.blid }]
					}
				},
				async (response4) => {
					if (response4.data && response4.data.totalRecords > 0) {
						for (let u = 0; u < response4.data.data.length; u++) {
							const uti = { ...response4.data.data[u] };
							uti.creauser = uti.usralta;
							uti.creadate = uti.fecalta;
							uti.moduser = uti.usrmodif;
							uti.moddate = uti.fecmodif;
							for (let g = 0; g < goods.length; g++) {
								const index = goods[g].utis.findIndex((ut) => ut.utiid === uti.utiid);
								if (index >= 0) {
									goods[g].utis[index] = { ...goods[g].utis[index], ...uti };
									goods[g].utis[index].seals = [];

									// Getting SEALS
									await ctx.$puiRequests.postRequest(
										'/puisearch',
										{
											model: 'sdsmutiseal',
											rows: null,
											filter: {
												groups: [],
												groupOp: 'and',
												rules: [{ field: 'utiid', op: 'eq', data: uti.utiid }]
											}
										},
										async (response5) => {
											if (response4.data && response5.data.totalRecords > 0) {
												goods[g].utis[index].seal = { ...response5.data.data[0] };
											} else {
												goods[g].utis[index].seal = { sealnumber: null };
											}
										},
										() => {}
									);
								}
							}
						}
					}
				},
				() => {}
			);
		}
		// Goods
		bol.goods = goods;
		return bol;
	},
	async getAndPrepareBOLForMassiveEdit2(ctx, billofloading) {
		const bol = { ...billofloading };
		bol.creauser = bol.usralta;
		bol.creadate = bol.fecalta;
		bol.moduser = bol.usrmodif;
		bol.moddate = bol.fecmodif;
		const goods = [];
		const goods_docs = [];
		const goods_utis = [];
		const goods_filter = {
			groups: [],
			groupOp: 'or',
			rules: []
		};

		// Getting Goods from this Billofloading
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmgood',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'blid', op: 'eq', data: bol.blid }]
				}
			},
			async (response) => {
				if (response.data && response.data.totalRecords > 0) {
					for (let item of response.data.data) {
						const good = { ...item };
						// Gathering good ids fro single /puisearch
						good.creauser = good.usralta;
						good.creadate = good.fecalta;
						good.moduser = good.usrmodif;
						good.moddate = good.fecmodif;
						good.utis = [];
						good.docs = [];
						goods.push(good);
						goods_filter.rules.push({ field: 'goodid', op: 'eq', data: good.goodid });
					}
					await ctx.$puiRequests.postRequest(
						'/puisearch',
						{
							model: 'sdsmdocumentcustoms',
							rows: null,
							filter: goods_filter
						},
						(response2) => {
							if (response2.data && response2.data.totalRecords > 0) {
								for (let item of response2.data.data) {
									const gooddoc = { ...item };
									goods_docs.push(gooddoc);
								}
							}
						},
						() => {}
					);
					for (let GoodDoc of goods_docs) {
						for (let Good of goods) {
							if (Good.goodid === GoodDoc.goodid) {
								Good.docs.push(GoodDoc);
							}
						}
					}

					// Getting GOOD-UTIs from the current GOOD
					await ctx.$puiRequests.postRequest(
						'/puisearch',
						{
							model: 'sdsmgooduti',
							rows: null,
							filter: goods_filter
						},
						(response3) => {
							if (response3.data && response3.data.totalRecords > 0) {
								for (let item of response3.data.data) {
									const gooduti = { ...item };
									gooduti.creauser = gooduti.usralta;
									gooduti.creadate = gooduti.fecalta;
									gooduti.moduser = gooduti.usrmodif;
									gooduti.moddate = gooduti.fecmodif;
									goods_utis.push(gooduti);
								}
							}
						},
						() => {}
					);
					for (let GoodUti of goods_utis) {
						for (let Good of goods) {
							if (Good.goodid === GoodUti.goodid) {
								GoodUti.seal = { sealnumber: null };
								Good.utis.push(GoodUti);
							}
						}
					}
				}
			},
			() => {}
		);

		// Goods
		goods.sort((a, b) => a.ordernumber - b.ordernumber);
		bol.goods = goods;
		// Utis
		return bol;
	},
	async MergeBOLAndEquipmentsFromBOL(ctx, bol, utis) {
		// Merging GoodUti + uti
		if (bol.goods.length) {
			for (let G of bol.goods) {
				for (let U of G.utis) {
					const u = utis.find((ut) => ut.utiid === U.utiid);
					if (u) {
						U = { ...U, ...u };
						U.creadate = u.creadate;
						U.creauser = u.creauser;
						U.moddate = u.moddate;
						U.moduser = u.moduser;
					}
				}
			}

			const filter = {
				groups: [],
				groupOp: 'or',
				rules: []
			};
			for (let u of utis) {
				filter.rules.push({ field: 'utiid', op: 'eq', data: u.utiid });
			}

			// Getting SEALS
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmutiseal',
					rows: null,
					filter
				},
				async (response) => {
					if (response.data && response.data.totalRecords > 0) {
						const seals = response.data.data;
						for (let G of bol.goods) {
							for (let U of G.utis) {
								const s = seals.filter((s) => s.utiid === U.utiid);
								if (s.length) {
									U.seal.sealnumber = s[0].sealnumber;
								}
							}
						}
					}
				},
				() => {}
			);
		}
		return bol;
	},
	fillMassiveCreateFields(ctx, jointservice, billofloading) {
		// Bill of lading
		billofloading.jointservicenumber = jointservice.jointservicenumber;
		billofloading.jointserviceid = jointservice.jointserviceid;
		// Goods
		for (let g = 0; g < billofloading.goods.length; g++) {
			// Utis
			for (let u = 0; u < billofloading.goods[g].utis.length; u++) {
				// ISO flag
				billofloading.goods[g].utis[u].fullfilliso = billofloading.goods[g].utis[u].utiidentification ? '1' : '0';
				// Seal
				if (billofloading.goods[g].utis[u].seal.sealnumber) {
					billofloading.goods[g].utis[u].seals = [billofloading.goods[g].utis[u].seal];
				} else {
					billofloading.goods[g].utis[u].seals = [];
				}
			}
		}

		//BL -> Equipments
		for (let u = 0; u < billofloading.utis.length; u++) {
			// ISO flag
			billofloading.utis[u].fullfilliso = billofloading.utis[u].utiidentification ? '1' : '0';
		}
		return billofloading;
	},
	async checkMasiveCreateBeforeSaving(ctx, jointservice, billofloading) {
		if (ctx.$refs.massiveFormHeader && ctx.$refs.massiveFormHeader.validate && !ctx.$refs.massiveFormHeader.validate()) {
			ctx.$puiNotify.error(ctx.$t('pui9.error.form_errors'));
			return false;
		}
		if (ctx.$refs.massiveFormContent && ctx.$refs.massiveFormContent.validate && !ctx.$refs.massiveFormContent.validate()) {
			ctx.$puiNotify.error(ctx.$t('pui9.error.form_errors'));
			return false;
		}

		let valid = true;
		//Checking Goods/Uti
		for (let g = 0; g < billofloading.goods.length; g++) {
			const validGood = await SdsmGoodUtils.checkBeforeSaving(
				ctx,
				billofloading.goods[g],
				billofloading.goods[g].utis,
				billofloading.goods[g].docs
			);
			if (!validGood) {
				valid = false;
				break;
			}
		}
		// Checking duplicated BL > UTIs
		for (let u = 0; u < billofloading.utis.length; u++) {
			// Duplicated Identification
			if (billofloading.utis[u].utiidentification) {
				const exist = billofloading.utis.filter((e) => e.utiidentification === billofloading.utis[u].utiidentification);
				if (exist.length > 1) {
					valid = false;
					ctx.$puiNotify.error(ctx.$t('errors.eqduplicated'));
				}
			}
			// Required fields
			if (
				!billofloading.utis[u].eqsizeid ||
				!billofloading.utis[u].eqtypeid ||
				!billofloading.utis[u].tare ||
				!billofloading.utis[u].utistatusid
			) {
				valid = false;
				ctx.$puiNotify.error(ctx.$t('errors.eqfield'));
			}
		}
		return valid;
	},
	async saveMassive(ctx, jointservice, billofloading) {
		let r = false;
		await ctx.$puiRequests.postRequest(
			'/sdsmbillofloading/savemassive',
			billofloading,
			(response) => {
				r = response;
				ctx.$puiNotify.success(ctx.$t('general.success'));
			},
			(e) => {
				ctx.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		// }
		return r;
	},
	async massiveupdate(ctx, data, blid) {
		let updated = false;
		let error = null;
		await ctx.$puiRequests.postRequest(
			'/sdsmbillofloading/massiveupdate',
			{ data, blid },
			() => {
				updated = true;
			},
			(e) => {
				error = e.response.data.message;
			},
			null,
			null
		);
		return { updated, error };
	},
	/************************/
	/* Save Bill of lading */
	/***********************/
	async save(ctx, billofloading, isCreating) {
		let data = null;
		let metodo = isCreating ? '/sdsmbillofloading/save' : '/sdsmbillofloading/saveUpd';

		await ctx.$puiRequests.postRequest(
			metodo,
			billofloading,
			(response) => {
				data = response.data;
				ctx.$puiNotify.success(ctx.$t('general.success'));
			},
			(e) => {
				ctx.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		return data;
	},
	/************************/
	/* New Bill of lading */
	/**********************/
	async newBillOfLoading(ctx) {
		let bol = null;
		await ctx.$puiRequests.getRequest(
			'/sdsmbillofloading/template',
			null,
			(response) => {
				bol = response.data;
			},
			() => {},
			null,
			null
		);
		return bol;
	},
	async getGoodsStats(ctx, model) {
		let goods = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'vhdcjsdocweight',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'blid', op: 'eq', data: model.blid }]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					goods = response.data.data;
				}
			},
			() => {}
		);

		return goods;
	},
	async getUtisStats(ctx, model) {
		let eqs = [];

		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'vhdcblutifromdoc',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'blid', op: 'eq', data: model.blid }]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					eqs = response.data.data;
				}
			},
			() => {}
		);

		return eqs;
	},
	async getUtisByBL(ctx, model) {
		let voids = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmuti',
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'blid', op: 'eq', data: model.blid }]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					voids = response.data.data;
				}
			},
			() => {}
		);

		return voids;
	},
	async getOperatorDoc(ctx, model, opid) {
		let str = [false, false, false];

		if (opid == null) {
			str[0] = true;
			return str;
		}

		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmdocument',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'operatorid', op: 'eq', data: opid },
						{ field: 'documentid', op: 'eq', data: model.documentid }
					]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					//es operador de buque
					str[1] = true;
				}
			},
			() => {}
		);
		if (model.operatorid == opid) {
			str[2] = true;
		}

		return str;
	},
	async getDocumentByid(ctx, documentid) {
		let doc = [];
		if (documentid) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmdocument',
					rows: null,
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'documentid', op: 'eq', data: documentid }]
					}
				},
				(response) => {
					doc = response.data.data;
				},
				() => {}
			);
		}
		return doc;
	},
	async getJointserviceByid(ctx, jsid) {
		let doc = [];
		if (jsid) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmjointservice',
					rows: null,
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'jointserviceid', op: 'eq', data: jsid }]
					}
				},
				(response) => {
					doc = response.data.data;
				},
				() => {}
			);
		}
		return doc;
	},
	async getItemsHeader(ctx) {
		let JSs = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmdocument',
				rows: null
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					JSs = response.data.data;
				}
			},
			() => {}
		);
		return JSs;
	}
};
export default SdsmbillofloadingUtils;
