<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<mlocalportmodals :modelName="modelName"></mlocalportmodals>
	</div>
</template>

<script>
import mlocalportActions from './MlocalportActions'
import mlocalportModals from './MlocalportModals.vue'

export default {
	name: 'mlocalportgrid',
	components: {
		mlocalportmodals: mlocalportModals
	},
	data () {
		return {
			modelName: 'mlocalport',
			actions: mlocalportActions.gridactions,
			modelColumnDefs: {}
		}
	}
}
</script>
