<template>
	<div class="pui-form">
		<mtaric-modals :modelName="modelName"></mtaric-modals>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense v-if="!isCreatingElement">
						<!-- TARICID -->
						<v-col cols="1">
							<pui-text-field
								:id="`taricid-mtaric`"
								v-model="model.taricid"
								:label="$t('mtaric.taricid')"
								disabled
								toplabel
								maxlength="12"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- TARICCOD -->
						<v-col cols="2">
							<pui-text-field
								:id="`tariccod-mtaric`"
								v-model="model.tariccod"
								:label="$t('mtaric.tariccod')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DESCRIPTION -->
						<v-col cols="9">
							<pui-text-field
								:id="`description-mtaric`"
								v-model="model.description"
								:label="$t('mtaric.description')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="150"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- EFFECTIVESTARTDATE -->
						<v-col cols="4">
							<pui-date-field
								:id="`effectivestartdate-mtaric`"
								v-model="model.effectivestartdate"
								:label="$t('mtaric.effectivestartdate')"
								:disabled="formDisabled"
								toplabel
								:max="model.effectiveenddate"
							></pui-date-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- EFFECTIVEENDDATE -->
						<v-col cols="4">
							<pui-date-field
								:id="`effectiveenddate-mtaric`"
								v-model="model.effectiveenddate"
								:label="$t('mtaric.effectiveenddate')"
								:disabled="formDisabled"
								toplabel
								:min="model.effectivestartdate"
							></pui-date-field>
						</v-col>
					</v-row>

					<v-row dense>
						<v-col cols="2">
							<pui-checkbox
								:id="`ispassenger-mtaric`"
								:label="$t('mtaric.ispassenger')"
								v-model="model.ispassenger"
								true-value="1"
								false-value="0"
							></pui-checkbox>
						</v-col>
						<v-col cols="2">
							<pui-checkbox
								:id="`iscustomsexitmandatory-mtaric`"
								:label="$t('mtaric.iscustomsexitmandatory')"
								v-model="model.iscustomsexitmandatory"
								true-value="1"
								false-value="0"
							></pui-checkbox>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DATEEXPIRATION -->
						<v-col cols="4" v-if="!isCreatingElement">
							<pui-date-field
								:id="`dateexpiration-mtaric`"
								v-model="model.dateexpiration"
								:label="$t('mtaric.dateexpiration')"
								disabled
								toplabel
								time
							></pui-date-field>
						</v-col>
					</v-row>
					<!-- MINI AUDIT -->
					<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin'
import mtaricActions from './MtaricActions'
import mtaricModals from './MtaricModals.vue'
import formUtils from '@/utils/form'

export default {
	name: 'mtaric-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mtaric-modals': mtaricModals
	},
	data () {
		return {
			modelName: 'mtaric',
			actions: mtaricActions.formActions
		}
	},

	methods: {
		afterGetData () {
			formUtils.updateMiniAudit(this.model)
		}
	},
	computed: {},
	created () {}
}
</script>
