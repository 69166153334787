<template>
	<div class="divHeader">
		<pcsum-header :data="billOfLoadingHeaderData" :params="{ entity: 'sdsmgood' }"></pcsum-header>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';
import PcsumHeader from '../general/PcsumHeader';

export default {
	name: 'sdsmgood-form-header',
	mixins: [PuiFormHeaderMixin],
	components: {
		'pcsum-header': PcsumHeader
	},
	props: {
		data: { type: Object, default: () => {} },
		params: { type: Object, default: () => {} },
		alerts: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			modelName: 'sdsmgood'
		};
	},
	computed: {
		billOfLoadingHeaderData() {
			let fields = [];
			if (this.data) {
				fields.push({ label: 'header.sdsmgood.documentnumber', value: this.data.documentnumber });
				fields.push({ label: 'header.sdsmgood.billofloadingtype', value: this.data.bltype });
				fields.push({ label: 'header.sdsmgood.cargooperator', value: this.data.operator, showId: true, id: this.data.operatorid });
				fields.push({ label: 'header.sdsmgood.jointservicenumber', value: this.data.jointservicenumber });
				fields.push({ label: 'header.sdsmgood.blnumber', value: this.data.ordernumber });
				fields.push({ label: 'header.sdsmgood.jointservicestatus', value: this.data.jsstate, pill: true, statusId: this.data.jsstateid });
				fields.push({ label: 'header.sdsmgood.billofloadingstatus', value: this.data.blstate, pill: true, statusId: this.data.blstateid });
			}
			if (this.data.goodstate) {
				fields.push({ label: 'header.sdsmgood.stateid', value: this.data.goodstate, pill: true, statusId: this.data.goodstateid });
			} else if (this.model) {
				fields.push({ label: 'header.sdsmgood.stateid', value: this.model.state, pill: true, statusId: this.model.stateid });
			}
			if (this.data) {
				fields.push({ label: 'header.sdsmjointservice.alert', alert: this.alerts });
			}
			return fields;
		}
	}
};
</script>
