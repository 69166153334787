<template>
	<div class="pui-form">
		<template v-if="model && ready">
			<v-form class="mb-4 pb-4" ref="form" lazy-validation @submit.prevent>
				<v-row class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('sdsmjointservice.jointserviceid')">
							<v-row dense>
								<v-col cols="12">
									<!-- DOCUMENTID -->
									<pui-select
										id="`documentid-sdsmjointservice`"
										attach="documentid-sdsmjointservice"
										:label="$t('sdsmjointservice.documentid')"
										toplabel
										clearable
										required
										singleItemAutoselect
										:disabled="true"
										v-model="model"
										modelName="sdsmdocument"
										:modelFormMapping="{ documentid: 'documentid' }"
										:itemsToSelect="[{ documentid: this.model.documentid }]"
										:itemValue="'documentid'"
										:itemText="
											(item) =>
												`${$t('sdsmjointservice.documentid')} : ${item.documentid} / ${$t('sdsmjointservice.escala')} : ${
													item.callcode
												} / ${$t('sdsmjointservice.portid')} : ${item.portid} / ${$t('sdsmjointservice.ship')} : ${
													item.shipname
												} / ${$t('sdsmjointservice.date')} : ${$moment(item.etaatd).format('DD/MM/YYYY HH:mm')} / ${$t(
													'sdsmjointservice.operator'
												)} :  ${item.operatorid}`
										"
										:order="{ documentid: 'asc' }"
										detailComponentName="sdsmdocument-form"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<!-- OPERATOR ID -->
								<v-col class="12 col-xs-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
									<pui-select
										id="`operatorid-sdsmjointservice`"
										attach="operatorid-sdsmjointservice"
										:label="$t('sdsmjointservice.operatorid')"
										toplabel
										clearable
										required
										singleItemAutoselect
										:disabled="true"
										v-model="model"
										modelName="sdsmoperator"
										:modelFormMapping="{ operatorid: 'operatorid' }"
										:itemsToSelect="[{ operatorid: this.model.operatorid }]"
										:itemValue="'operatorid'"
										:itemText="(item) => `${item.operatorid} - ${item.description}`"
										:order="{ operatorid: 'asc' }"
									></pui-select>
								</v-col>
								<!-- JOINT SERVICE NUMBER -->
								<v-col class="12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
									<pui-number-field
										:id="`jointservicenumber-sdsmjointservice`"
										v-model="model.jointservicenumber"
										:label="$t('sdsmjointservice.jointservicenumber')"
										:disabled="true"
										required
										toplabel
										max="999"
									></pui-number-field>
								</v-col>

								<!-- ORDER NUMBER -->
								<v-col class="12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
									<pui-text-field
										:id="`ordernumber-sdsmjointservice`"
										v-model="model.ordernumber"
										:label="$t('sdsmjointservice.ordernumber')"
										:disabled="true"
										toplabel
										max="999"
									></pui-text-field>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col class="12 col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
									<!-- DOCK ID -->
									<pui-select
										id="`dockid-sdsmjointservice`"
										attach="dockid-sdsmjointservice"
										:label="$t('sdsmjointservice.dockid')"
										toplabel
										clearable
										:disabled="formDisabled"
										v-model="model"
										modelName="mdock"
										:modelFormMapping="{ dockid: 'dockid' }"
										:itemsToSelect="[{ dockid: this.model.dockid }]"
										:itemValue="'dockid'"
										:itemText="(item) => `${item.description}`"
										:order="{ dockid: 'asc' }"
									></pui-select>
								</v-col>
								<v-col class="12 col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
									<!-- DOCK LOCATION -->
									<pui-select
										id="`docklocationid-sdsmjointservice`"
										attach="docklocationid-sdsmjointservice"
										:label="$t('sdsmjointservice.docklocationid')"
										toplabel
										clearable
										:disabled="formDisabled"
										v-model="model"
										modelName="mdockdocklocation"
										:modelFormMapping="{ docklocationid: 'docklocationid' }"
										:itemsToSelect="[{ docklocationid: this.model.docklocationid }]"
										:itemValue="['docklocationid']"
										:itemText="(item) => `${item.docklocation} `"
										:order="{ docklocationid: 'asc' }"
										:fixedFilter="fixedFilterDock"
									></pui-select>
								</v-col>
								<v-col class="12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
									<!-- COM. CODE -->
									<pui-text-field
										:id="`comcode-sdsmjointservice`"
										v-model="model.comcode"
										:label="$t('sdsmjointservice.comcode')"
										:disabled="formDisabled"
										toplabel
										maxlength="3"
									></pui-text-field>
								</v-col>
							</v-row>
						</pui-field-set>
						<pui-field-set :title="$t('sdsmjointservice.simreg')">
							<v-row dense>
								<v-col class="12 col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 pl-2">
									<pui-radio-group
										v-model="model.issimpreg"
										toplabel
										:label="$t('sdsmjointservice.simtran')"
										row
										column
										:radios="[
											{ id: '1', label: $t('general.yes') },
											{ id: '0', label: $t('general.no') }
										]"
									>
									</pui-radio-group>
								</v-col>
								<v-col class="12 col-xs-12 col-sm-7 col-md-7 col-lg-7 col-xl-7">
									<pui-select
										id="`simpregoperatorid-sdsmjointservice`"
										attach="simpregoperatorid-sdsmjointservice"
										:label="$t('sdsmjointservice.trts')"
										toplabel
										clearable
										reactive
										:disabled="!issimpregBool"
										:required="model.issimpreg === '1'"
										v-model="model"
										modelName="sdsmoperator"
										:modelFormMapping="{ operatorid: 'simpregoperatorid' }"
										:itemsToSelect="[{ operatorid: this.model.simpregoperatorid }]"
										:itemValue="'operatorid'"
										:itemText="(item) => `${item.description}`"
										:fixedFilter="filterSimpregoperatorid"
									></pui-select>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-else>
			<v-progress-linear color="primary" indeterminate></v-progress-linear>
		</template>
	</div>
</template>

<script>
import SdsmJointServiceUtils from '../SdsmjointserviceUtils';
import filters from '@/utils/filters.js';
import { EntityStatusUtils } from '../../../utils/entityStates';
export default {
	name: 'sdsmjointservice-form-for-quickedit',
	props: {
		model: { type: Object, default: () => {} }
	},
	data() {
		return {
			formDisabled: false,
			ready: false,
			issimpregBool: false
		};
	},
	methods: {
		async Save() {
			let jointservice = null;
			if (this.ready) {
				// Ready flag
				this.ready = false;
				// Saving
				const valid = SdsmJointServiceUtils.checkBeforeSaving(this, this.model);
				if (valid) {
					jointservice = await SdsmJointServiceUtils.saveJointService(this, this.model);
					if (jointservice) {
						// Notify
						this.$puiNotify.success(this.$t('pui9.save.success'));
					}
				}
				this.ready = true;
			}
			return jointservice;
		}
	},
	watch: {
		'model.issimpreg'(newQuestion) {
			if (newQuestion != null && newQuestion != 0) {
				this.issimpregBool = true;
			} else {
				this.issimpregBool = false;
				this.model.simpregoperatorid = '';
			}
		}
	},
	computed: {
		fixedFilterDock() {
			return SdsmJointServiceUtils.getFixedFilterDock(this.model);
		},
		filterSimpregoperatorid() {
			return filters.expirationDate();
		}
	},
	created() {
		this.model.creauser = this.model.usralta;
		this.model.creadate = this.model.fecalta;
		this.model.moduser = this.model.usrmodif;
		this.model.moddate = this.model.fecmodif;
		this.formDisabled = !EntityStatusUtils.checkEditionAvailable(this.model.blstateid);
		this.ready = true;
	}
};
</script>
