<template>
	<div class="divHeader">
		<pcsum-header :data="HeaderData()" :params="{ entity: 'sdsmbillofloading' }"></pcsum-header>
		<v-card class="PcsumStatsHeaderWrapper" elevation="0" v-if="!params.isCreatingElement">
			<v-expand-transition>
				<v-row dense v-if="!creating && this.showStats" class="mt-1">
					<v-col cols="4">
						<pcsum-stats-header :data="GoodsData" :label="$t('header.sdsmbillofloading.goods')"></pcsum-stats-header>
					</v-col>
					<v-col cols="8">
						<pcsum-stats-header
							:data="EqsData"
							:data2="EqsData2"
							:label="$t('header.sdsmbillofloading.utils')"
							:show-total="true"
						></pcsum-stats-header>
					</v-col>
				</v-row>
			</v-expand-transition>
			<v-chip @click="toggleStats()" class="mt-2">
				{{ $t('general.stats') }}
				<i class="far fa-chevron-down" v-if="!showStats"></i>
				<i class="far fa-chevron-up" v-if="showStats"></i>
			</v-chip>
		</v-card>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';
import PcsumHeader from '../general/PcsumHeader';
import PcsumStatsHeader from '../general/PcsumStatsHeader';
import SdsmbillofloadingUtils from './SdsmbillofloadingUtils';

export default {
	name: 'sdsmbillofloading-form-header',
	mixins: [PuiFormHeaderMixin],
	components: {
		'pcsum-header': PcsumHeader,
		'pcsum-stats-header': PcsumStatsHeader
	},
	props: {
		data: { type: Object, default: () => {} },
		ids: { type: Object, default: () => {} },
		params: { type: Object, default: () => {} },
		alerts: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			goods: null,
			eqs: null,
			eqsState: null,
			modelName: 'sdsmbillofloading',
			showStats: false,
			creating: true
		};
	},
	methods: {
		async toggleStats() {
			this.showStats = !this.showStats;
			if (this.showStats && !this.eqs) {
				this.goods = await SdsmbillofloadingUtils.getGoodsStats(this, this.data);
				this.eqs = await SdsmbillofloadingUtils.getUtisStats(this, this.data);
				this.eqsState = await SdsmbillofloadingUtils.getUtisByBL(this, this.data);
			}
		},
		HeaderData() {
			return [
				{ label: 'header.sdsmbillofloading.documento', value: this.getDocumento() },
				{ label: 'header.sdsmbillofloading.tipo', value: this.getTipo() },
				{
					label: 'header.sdsmbillofloading.consignatariomercancia',
					value: this.getOpDes(),
					showId: true,
					id: this.getConsignatario()
				},
				{ label: 'header.sdsmbillofloading.transhipementcode', value: this.getTranshipement(), pill: true },
				{ label: 'header.sdsmbillofloading.shipname', value: this.getShipname() },
				{ label: 'header.sdsmbillofloading.blnumber', value: this.getOrdernumber() },
				{ label: 'header.sdsmbillofloading.state', value: this.data?.blstate, pill: true, statusId: this.data?.blstateid },
				{ label: 'header.sdsmbillofloading.tramstate', value: this.getEstado(), pill: true, statusId: this.data?.stateid },
				{ label: 'header.sdsmdocument.alert', alert: this.alerts }
			];
		},
		getDocumento() {
			return this.data?.documentnumber || '';
		},
		getTipo() {
			return this.data?.doctype || '';
		},
		getConsignatario() {
			return this.data?.operatorid || '';
		},
		getOpDes() {
			return this.data?.operator || '';
		},
		getState() {
			return this.data?.state || '';
		},
		getTranshipement() {
			return this.data?.callcode || '';
		},
		getShipname() {
			return this.data?.shipname || '';
		},
		getOrdernumber() {
			return this.data?.blordernumber || '';
		},
		getEstado() {
			return this.data?.state || '';
		}
	},
	computed: {
		GoodsData() {
			return this.goods
				? [
						{ label: this.$t('header.sdsmdocument.goodnumber'), value: this.goods.length },
						{ label: this.$t('header.sdsmdocument.goodkg'), value: this.goods.reduce((acc, curr) => acc + curr.weight, 0) },
						{ label: this.$t('header.sdsmdocument.passengers'), value: this.calculatePassengers },
						{ label: this.$t('header.sdsmdocument.vehicles'), value: this.calculateVehicles }
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  ]
				: null;
		},
		EqsData2() {
			return this.eqs
				? [
						{ label: this.$t('header.sdsmdocument.uticont'), value: this.calculateCont },
						{ label: this.$t('header.sdsmdocument.utiroro'), value: this.calculateRoros },
						{ label: this.$t('header.sdsmdocument.utiothers'), value: this.calculateOtros }
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  ]
				: null;
		},
		EqsData() {
			return this.eqsState
				? [
						{ label: this.$t('header.sdsmdocument.utiempty'), value: this.eqsState?.[0] ? this.calculateEmpty : 0 },
						{ label: this.$t('header.sdsmdocument.utifull'), value: this.eqsState?.[0] ? this.calculateFull : 0 }
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  ]
				: null;
		},
		calculateRoros() {
			const roros = this.eqs.filter((item) => item.eqtypegroupid == 2);
			return roros?.[0] ? roros[0].suma : 0;
		},
		calculateCont() {
			const cont = this.eqs.filter((item) => item.eqtypegroupid == 3);
			return cont?.[0] ? cont[0].suma : 0;
		},

		calculateOtros() {
			const others = this.eqs.filter((item) => item.eqtypegroupid == 1);
			return others?.[0] ? others[0].suma : 0;
		},
		calculateEmpty() {
			let n = 0;
			let v = 0;
			this.eqsState.forEach((element) => {
				if (element.utistatusid != 1) {
					if (element.units == null) {
						n = n + 1;
					} else {
						n = n + element.units;
					}
				} else if (element.units == null) {
					v = v + 1;
				} else {
					v = v + element.units;
				}
			});

			return v;
		},
		calculateFull() {
			let n = 0;
			let v = 0;
			this.eqsState.forEach((element) => {
				if (element.utistatusid != 1) {
					if (element.units == null) {
						n = n + 1;
					} else {
						n = n + element.units;
					}
				} else if (element.units == null) {
					v = v + 1;
				} else {
					v = v + element.units;
				}
			});
			return n;
		},
		calculateVehicles() {
			let vehiculos = this.goods.filter((item) => item.tariccod.startsWith('000') && !item.tariccod.startsWith('0001'));
			let suma = vehiculos.reduce((total, item) => total + item.packagesnumber, 0);
			return suma || 0;
		},
		calculatePassengers() {
			let pass = this.goods.filter((item) => item.tariccod.startsWith('0001'));
			let suma = pass.reduce((total, item) => total + item.packagesnumber, 0);

			return suma || 0;
		}
	},
	created() {
		this.creating = this.params.isCreatingElement;
		this.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'puivariable',
				filter: {
					rules: [{ field: 'variable', op: 'eq', data: 'BILLOFLOADING_STATISTICS_AUTOLOAD' }]
				}
			},
			(response) => {
				if (response.data && response.data.totalRecords > 0) {
					if (response.data.data[0].value === 'true') {
						this.toggleStats();
					}
				}
			},
			() => {}
		);
	}
};
</script>
