<template>
	<div class="pui-form">
		<sdsmgood-modals :modelName="modelName"></sdsmgood-modals>
		<sdsmgood-form-header v-if="modelLoaded && headerData" :data="headerData" :alerts="alerts" :params="{}"></sdsmgood-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-tabs v-model="tabmodel" class="ml-4 mb-4 mr-4 mt-4" slider-color="primary" show-arrows>
					<v-tab :key="0" :href="'#maintab'">{{ $t('form.sdsmgood') }}</v-tab>
					<v-tab :key="1" :href="'#vutmessagegood'" v-if="!isCreatingElement">{{ $t('form.sdsmmessage.formtitle') }}</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tabmodel" style="width: 100% !important">
					<v-tab-item :key="0" :value="'maintab'">
						<v-col cols="12">
							<!------------------ GOOODS ------------------>
							<pui-field-set :title="$t('form.sdsmgood')">
								<v-row dense align="center">
									<!-- BLID -->

									<v-col cols="12 col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
										<pui-select
											:id="`blid-sdsmgood`"
											attach="`blid-sdsmgood`"
											:label="$t('sdsmgood.bl')"
											ref="blidselect"
											:disabled="!isCreatingElement || formDisabled"
											toplabel
											clearable
											modelName="vhdgood"
											v-model="model"
											reactive
											outlined
											dense
											singleItemAutoselect
											:itemsToSelect="[{ blid: this.model.blid }]"
											:modelFormMapping="{ blid: 'blid' }"
											:itemValue="['blid']"
											:itemTemplate="true"
											:itemText="''"
											:selectTemplate="true"
											:fixedFilter="filteredBLID"
											:queryFields="[
												'blid',
												'documentnumber',
												'operator',
												'jointservicenumber',
												'ordernumber',
												'blnumber',
												'bltype'
											]"
											:order="{ blid: 'desc' }"
										>
											<template v-slot:selectionTemplate="{ props, item }">
												<div class="SlotOptionWithDetails" v-bind="props">
													{{ $t('sdsmgood.blid') }}:
													<span class="value">{{ item.blid || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.documentnumber') }}:
													<span class="value">{{ item.documentnumber || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.cargooperator') }}:
													<span class="value">{{ item.operator || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.jointservicenumber') }}:
													<span class="value">{{ item.jointservicenumber !== null ? item.jointservicenumber : '-' }} </span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.conocimiento') }}:
													<span class="value">{{ item.ordernumber || '-' }}</span>
													<span class="separator">-</span>
													<span class="value">{{ item.blnumber || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.bltype') }}:
													<span class="value">{{ item.bltype }} </span>
												</div>
											</template>
											<template v-slot:theTemplate="{ props, item }">
												<div class="SlotOptionWithDetails" v-bind="props">
													{{ $t('sdsmgood.blid') }}:
													<span class="value">{{ item.blid || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.documentnumber') }}:
													<span class="value">{{ item.documentnumber || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.cargooperator') }}:
													<span class="value">{{ item.operator || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.jointservicenumber') }}:
													<span class="value">{{ item.jointservicenumber !== null ? item.jointservicenumber : '-' }} </span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.conocimiento') }}:
													<span class="value">{{ item.ordernumber || '-' }}</span>
													<span class="separator">-</span>
													<span class="value">{{ item.blnumber || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmgood.bltype') }}:
													<span class="value">{{ item.bltype }} </span>
												</div>
											</template>
										</pui-select>
									</v-col>

									<!-- ORDERNUMBER -->
									<v-col class="12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
										<pui-text-field
											:id="`ordernumber-sdsmgood`"
											v-model="model.ordernumber"
											:label="$t('sdsmgood.ordernumber')"
											:disabled="true"
											toplabel
										></pui-text-field>
									</v-col>
								</v-row>
								<template v-if="model.blid">
									<v-row dense>
										<!-- PACKAGESNUMBER -->
										<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-1 col-xl-1" v-if="!isPassengers">
											<pui-number-field
												:id="`packagesnumber-sdsmgood`"
												v-model="model.packagesnumber"
												:label="isPassengers ? $t('sdsmgood.passengersnumber') : $t('sdsmgood.packagesnumber')"
												:disabled="formDisabled"
												required
												:rules="[(v) => v >= 0 || $t('requiredMessage')]"
												toplabel
												maxlength="12"
											></pui-number-field>
										</v-col>

										<!-- WEIGHT -->
										<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-1 col-xl-1" v-if="!isPassengers">
											<pui-number-field
												:id="`weight-sdsmgood`"
												v-model="model.weight"
												:label="$t('sdsmgood.weight')"
												:disabled="isPassengers || formDisabled"
												:required="!isPassengers"
												toplabel
												maxlength="18"
											></pui-number-field>
										</v-col>

										<!-- VOLUME -->
										<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-1 col-xl-1" v-if="!isPassengers">
											<pui-number-field
												:id="`volume-sdsmgood`"
												v-model="model.volume"
												:label="$t('sdsmgood.volume')"
												:disabled="isPassengers || formDisabled"
												toplabel
												maxlength="18"
											></pui-number-field>
										</v-col>

										<!-- LOWVALUESENT -->
										<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-1 col-xl-1" v-if="!isPassengers">
											<pui-number-field
												:id="`lowvaluesent-sdsmgood`"
												v-model="model.lowvaluesent"
												:label="$t('sdsmgood.lowvaluesent')"
												:disabled="isPassengers || formDisabled"
												toplabel
												maxlength="18"
											></pui-number-field>
										</v-col>
										<!-- PACKAGETYPEID -->
										<v-col cols="12 col-xs-12 col-sm-8 col-md-8 col-lg-4 col-xl-4" v-if="!isPassengers">
											<pui-select
												:id="`packagetypeid-sdsmgood`"
												:attach="`packagetypeid-sdsmgood`"
												:label="$t('sdsmgood.packagetypeid')"
												:disabled="isPassengers || formDisabled"
												:required="!isPassengers"
												toplabel
												clearable
												modelName="mpackagetype"
												v-model="model"
												reactive
												singleItemAutoselect
												:itemsToSelect="PackageTypeItemsToSelect"
												:modelFormMapping="{ packagetypeid: 'packagetypeid' }"
												:itemValue="['packagetypeid']"
												:queryFields="['edicode', 'description']"
												:itemText="(item) => `${item.edicode} : ${item.description}`"
												:fixedFilter="filterPackagetypeid"
											></pui-select>
										</v-col>

										<!-- CUSSITUATIONID -->
										<v-col
											cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4"
											v-if="!isPassengers"
											:class="{ 'col-sm-1 col-md-11 col-lg-11 col-xl-11': isPassengers }"
										>
											<pui-select
												:id="`cussituationid-sdsmgood`"
												:attach="`cussituationid-sdsmgood`"
												:label="$t('sdsmgood.cussituationid')"
												:disabled="formDisabled"
												toplabel
												clearable
												modelName="mcustomssituation"
												v-model="model"
												reactive
												:required="!isPassengers"
												singleItemAutoselect
												:itemsToSelect="[{ cussituationid: model.cussituationid }]"
												:modelFormMapping="{ cussituationid: 'cussituationid' }"
												:itemValue="['cussituationid']"
												:queryFields="['cussituationid', 'description']"
												:itemText="(item) => `${item.cussituationid} - ${item.description}`"
												:fixedFilter="filterCussituationid"
											></pui-select>
										</v-col>
									</v-row>
									<v-row dense align="center">
										<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-1 col-xl-1" v-if="isPassengers">
											<pui-number-field
												:id="`packagesnumber-sdsmgood`"
												v-model="model.packagesnumber"
												:label="isPassengers ? $t('sdsmgood.passengersnumber') : $t('sdsmgood.packagesnumber')"
												:disabled="formDisabled"
												required
												:rules="[(v) => v >= 0 || $t('requiredMessage')]"
												toplabel
												maxlength="12"
											></pui-number-field>
										</v-col>

										<!-- TARICID -->
										<v-col
											cols="12 col-xs-12 col-sm-12 col-md-12 pl-2 pr-2"
											:class="{ 'col-lg-5 col-xl-5': isPassengers, 'col-lg-10 col-xl-10 ': !isPassengers }"
										>
											<label class="customLabel v-label--required">{{ $t('sdsmgood.taricid') }}</label>
											<v-autocomplete
												class="customSelector"
												outlined
												dense
												v-model="model.taricid"
												:items="filteredTarCodes"
												:disabled="formDisabled"
												@keyup="selectIfOnlyOneOption"
												ref="taricid"
												:readonly="taricSelected"
												clearable
												reactive
												required
												:rules="[(v) => !!v || $t('requiredMessage')]"
												item-value="taricid"
												:item-text="(item) => `${item.tariccod} - ${item.description}`"
											>
												<template v-slot:item="{ props, item }">
													<div class="SlotOptionWithDetails" v-bind="props">
														<span class="value">{{ item.tariccod }}</span>
														<span class="separator"> - </span>
														<span>{{ item.description }}</span>
													</div>
												</template>
											</v-autocomplete>
										</v-col>

										<!-- VEHBRAID -->
										<v-col cols="12 col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2" v-show="!isPassengers">
											<pui-select
												:id="`vehbraid-sdsmgood`"
												:attach="`vehbraid-sdsmgood`"
												:label="$t('sdsmgood.vehbraid')"
												:disabled="formDisabled"
												:required="checkVehBraIDRequired"
												toplabel
												clearable
												modelName="mvehiclebrand"
												v-model="model"
												reactive
												singleItemAutoselect
												:itemsToSelect="[{ vehbraid: model.vehbraid }]"
												:modelFormMapping="{ vehbraid: 'vehbraid' }"
												:itemValue="['vehbraid']"
												:queryFields="['vehbraid', 'description']"
												:itemText="(item) => `${item.vehbraid} -${item.description}`"
												:fixedFilter="filterVehbraid"
											></pui-select>
										</v-col>

										<!-- MARKINGINSTRID -->
										<v-col cols="12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" v-show="!isPassengers">
											<pui-select
												:id="`markinginstrid-sdsmgood`"
												:attach="`markinginstrid-sdsmgood`"
												:label="$t('sdsmgood.markinginstrid')"
												:disabled="isPassengers || formDisabled"
												toplabel
												clearable
												modelName="vutmmarkinginstructiontra"
												v-model="model"
												reactive
												singleItemAutoselect
												:itemsToSelect="[{ markinginstrid: model.markinginstrid }]"
												:modelFormMapping="{ markinginstrid: 'markinginstrid' }"
												:itemValue="['markinginstrid']"
												:queryFields="['edicode', 'description']"
												:itemText="(item) => `${item.edicode} -${item.description}`"
												:fixedFilter="filterMarkinginstrid"
											></pui-select>
										</v-col>

										<!-- PACKAGESMARKING -->
										<v-col cols="12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6" v-show="!isPassengers">
											<pui-text-field
												:id="`packagesmarking-sdsmgood`"
												v-model="model.packagesmarking"
												:label="$t('sdsmgood.packagesmarking')"
												:disabled="isPassengers || formDisabled"
												toplabel
												maxlength="350"
											></pui-text-field>
										</v-col>

										<!-- STEVEDOREID -->
										<v-col
											cols="12 col-xs-12"
											v-if="!isPassengers"
											:class="{ 'col-lg-5 col-xl-5': isPassengers, 'col-lg-6 col-xl-6 ': !isPassengers }"
										>
											<pui-select
												:id="`stevedoreid-sdsmgood`"
												:attach="`stevedoreid-sdsmgood`"
												:label="$t('sdsmgood.stevedoreid')"
												:disabled="isPassengers || formDisabled"
												toplabel
												reactive
												clearable
												modelName="sdsmstevedore"
												v-model="model"
												singleItemAutoselect
												:itemsToSelect="[{ stevedoreid: model.stevedoreid }]"
												:modelFormMapping="{ stevedoreid: 'stevedoreid' }"
												:itemValue="['stevedoreid']"
												:queryFields="['description']"
												:itemText="(item) => `${item.description}`"
												:fixedFilter="filterStevedoreid"
											></pui-select>
										</v-col>

										<!-- ISMANDATORYENS -->
										<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6" v-show="!isPassengers">
											<v-radio-group
												v-model="model.ismandatoryens"
												:disable="formDisabled"
												row
												style="position: relative; top: 4px"
											>
												<v-radio :label="$t('sdsmgood.ismandatoryens.yes')" value="1"></v-radio>
												<v-radio :label="$t('sdsmgood.ismandatoryens.no')" value="0"></v-radio>
											</v-radio-group>
										</v-col>

										<!-- MANIPULATIONTERMINAL -->
										<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4" v-show="!isPassengers">
											<pui-text-field
												:id="`manipulationterminal-sdsmgood`"
												v-model="model.manipulationterminal"
												:label="$t('sdsmgood.manipulationterminal')"
												:disabled="isPassengers || formDisabled"
												toplabel
												maxlength="35"
											></pui-text-field>
										</v-col>

										<!-- SPECIALINSTUSE -->
										<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2" v-show="!isPassengers">
											<pui-text-field
												:id="`specialinstuse-sdsmgood`"
												v-model="model.specialinstuse"
												:label="$t('sdsmgood.specialinstuse')"
												:disabled="isPassengers || formDisabled"
												toplabel
												maxlength="35"
											></pui-text-field>
										</v-col>

										<!-- DSDTNUMBER -->
										<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2" v-show="!isPassengers">
											<pui-text-field
												:id="`dsdtnumber-sdsmgood`"
												v-model="model.dsdtnumber"
												:label="$t('sdsmgood.dsdtnumber')"
												:disabled="isPassengers || formDisabled"
												toplabel
												:required="checkDsdtRequired"
												maxlength="35"
											></pui-text-field>
										</v-col>

										<!-- ORDERNUMBERGOODDSDT -->
										<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2" v-if="!isPassengers">
											<pui-text-field
												:id="`ordernumbergooddsdt-sdsmgood`"
												v-model="model.ordernumbergooddsdt"
												:label="$t('sdsmgood.ordernumbergooddsdt')"
												:disabled="isPassengers || formDisabled"
												toplabel
												:required="checkDsdtRequired"
												maxlength="35"
											></pui-text-field>
										</v-col>

										<!-- COMCODE -->
										<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2">
											<pui-text-field
												:id="`comcode-sdsmgood`"
												v-model="model.comcode"
												:label="$t('sdsmgood.comcode')"
												:disabled="formDisabled"
												toplabel
												maxlength="35"
											></pui-text-field>
										</v-col>
									</v-row>
								</template>
							</pui-field-set>
							<!------------------ DDGG ------------------>
							<pui-field-set :title="$t('sdsmgood.mmpp')" v-if="model.blid && !isPassengers">
								<v-row dense>
									<!-- DDGG CRITERIA -->
									<v-col cols="2" class="pl-2 pr-2">
										<label class="customLabel">{{ $t('sdsmgood.search') }}</label>
										<v-autocomplete
											class="customSelector"
											outlined
											dense
											v-model="ddggcriteria"
											:loading="loading"
											:items="ddgg"
											:disabled="formDisabled"
											validate-on-blur
											clearable
											reactive
											@keyup="selectIfOnlyOneOptionDDGG"
											@focus="focusDDGG"
											item-value="onu"
											ref="ddggRef"
											:item-text="ddggcriteria ? `''` : (item) => `${item.onu} - ${item.denominacion}`"
										>
											<template v-slot:item="{ props, item }">
												<div class="BasicSlotOption" v-bind="props">
													<span> {{ item?.onu }} </span>
													<span> {{ item?.denominacion }} </span>
												</div>
											</template>
											<template v-slot:selection="{}"> </template>
										</v-autocomplete>
									</v-col>

									<!-- IMDGUNONUMBER -->
									<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-1 col-xl-1">
										<pui-text-field
											:id="`imdgunonumber-sdsmgood`"
											v-model="model.imdgunonumber"
											:label="$t('sdsmgood.imdgunonumber')"
											:disabled="true"
											toplabel
											maxlength="4"
										></pui-text-field>
									</v-col>

									<!-- IMDGPAGE -->
									<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-1 col-xl-1">
										<pui-text-field
											:id="`imdgpage-sdsmgood`"
											v-model="model.imdgpage"
											:label="$t('sdsmgood.imdgpage')"
											:disabled="true"
											toplabel
											maxlength="10"
										></pui-text-field>
									</v-col>

									<!-- IMDGVERSION -->
									<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-1 col-xl-1">
										<pui-text-field
											:id="`imdgversion-sdsmgood`"
											v-model="model.imdgversion"
											:label="$t('sdsmgood.imdgversion')"
											:disabled="true"
											toplabel
											maxlength="10"
										></pui-text-field>
									</v-col>
									<!-- IMDGCLASS -->
									<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-1 col-xl-1">
										<pui-text-field
											:id="`imdgclass-sdsmgood`"
											v-model="model.imdgclass"
											:label="$t('sdsmgood.imdgclass')"
											:disabled="true"
											toplabel
											maxlength="10"
										></pui-text-field>
									</v-col>
									<!-- IMDGUNODESC -->
									<v-col cols="12 col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
										<pui-text-field
											:id="`imdgunodesc-sdsmgood`"
											v-model="model.imdgunodesc"
											:label="$t('sdsmgood.imdgunodesc')"
											:disabled="true"
											toplabel
											maxlength="100"
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>

							<template v-if="model.blid">
								<template v-if="!isPassengers">
									<!------------------ EQUIPMENTS ------------------>
									<sdsm-good-equipments
										ref="child1"
										:good="model"
										:params="{ isCreatingElement, formDisabled }"
									></sdsm-good-equipments>
									<!------------------ DOCUMENTS ------------------->
									<sdsm-good-documents
										ref="child2"
										:good="model"
										:params="{ isCreatingElement, formDisabled }"
									></sdsm-good-documents>
								</template>
							</template>
							<!------------------ MINI-AUDIT ------------------>
							<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
						</v-col>
					</v-tab-item>
					<v-tab-item :key="1" :value="'vutmessagegood'">
						<pui-master-detail
							componentName="vutmessagegood-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ goodid: 'goodid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
						></pui-master-detail
					></v-tab-item>
				</v-tabs-items>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveGoodAndNew"
					:saveAndUpdate="saveGoodAndUpdate"
					:save="saveGoodAndBack"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import sdsmgoodActions from './SdsmgoodActions';
import sdsmgoodModals from './SdsmgoodModals.vue';
import sdsmgoodEquipments from './SdsmgoodFormEquipments.vue';
import sdsmgoodDocuments from './SdsmgoodFormDocuments.vue';
import formUtils from '@/utils/form';
import SdsmGoodUtils from './SdsmgoodUtils';
import filters from '@/utils/filters';
import { mapState } from 'vuex';
import { EntityStatusUtils } from '../../utils/entityStates';

export default {
	name: 'sdsmgood-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'sdsmgood-modals': sdsmgoodModals,
		'sdsm-good-equipments': sdsmgoodEquipments,
		'sdsm-good-documents': sdsmgoodDocuments
	},
	data() {
		return {
			ddggcriteria: null,
			ddgg: null,
			billofloadings: [],
			documentSelected: '',
			loading: null,
			taricSelected: false,
			saving: false,
			modelName: 'sdsmgood',
			actions: sdsmgoodActions.formactions,
			blidkey: 0,
			blidDisabled: false,
			blidProgress: false,
			headerData: {},
			selectedblid: true,
			resetTar: false,
			alerts: null,
			filters: {
				packagetypeid: '',
				cussituationid: '',
				vehbraid: '',
				markinginstrid: '',
				stevedoreid: '',
				dockid: '',
				operatorid: ''
			}
		};
	},
	watch: {
		'model.blid'(blid) {
			this.documentSelected = blid != null;
			this.headerData = null;
			this.selectedblid = false;
			if (blid) {
				this.$puiRequests.postRequest(
					'/puisearch',
					{
						model: 'vhdgood',
						rows: null,
						filter: {
							groups: [
								{
									groupOp: 'and',
									rules: [
										{ field: 'blid', op: 'eq', data: blid },
										{ field: 'langtra', op: 'eq', data: this.$store.getters.getUserLanguage }
									]
								}
							],
							groupOp: 'and',
							rules: []
						}
					},
					(responsee) => {
						if (responsee.data?.data && responsee.data?.data?.length > 0) {
							if (this.isCreatingElement) {
								//no hay joint por creacion
								this.headerData = responsee.data.data[0];
								this.selectedblid = true;
								this.headerData.goodstateid = 1;
								this.$puiRequests.postRequest(
									'/puisearch',
									{
										model: 'sdsmgoodstate',
										rows: null,
										filter: {
											groups: [
												{
													groupOp: 'and',
													rules: [
														{ field: 'stateid', op: 'eq', data: this.headerData.goodstateid },
														{ field: 'lang', op: 'eq', data: this.$store.getters.getUserLanguage }
													]
												}
											],
											groupOp: 'and',
											rules: []
										}
									},
									(responseefinal) => {
										if (responseefinal.data?.data && responseefinal.data?.data?.length > 0) {
											this.headerData.goodstate = responseefinal.data.data[0].description;
										}
									}
								);
							} else {
								// puede o no tener el joint asociado
								responsee.data.data.forEach((element) => {
									if (element.blid == this.model.blid) {
										this.headerData = element;
										this.selectedblid = true;
									}
								});
								//si no ha sido asignado, asignarlo
								if (this.headerData == null) {
									this.headerData = responsee.data.data[0];
									this.selectedblid = true;
								}
							}
						}
					}
				);
			} else {
				this.selectedblid = false;
			}
		},
		async ddggcriteria() {
			if (this.ddgg) {
				const DG = this.ddgg.find((e) => e.onu === this.ddggcriteria);
				this.model.imdgunonumber = DG?.onu || null;
				this.model.imdgpage = DG?.pagina || null;
				this.model.imdgversion = DG?.version || null;
				this.model.imdgclass = DG?.clase || null;
				this.model.imdgunodesc = DG?.denominacion || null;
			}
		},
		'model.taricid'(newQuestion) {
			if (newQuestion != null) {
				this.taricSelected = true;
			} else {
				this.taricSelected = false;
				this.resetTar = true;
			}
		}
	},
	methods: {
		async afterGetData() {
			formUtils.updateMiniAudit(this.model);
			this.formDisabled = !EntityStatusUtils.checkEditionAvailable(this.model.stateid);
			SdsmGoodUtils.getTarCodes(this);
			this.$watch('model.blid', this.WatchBLIDCreate);

			if (this.model.ismandatoryens == null) {
				this.model.ismandatoryens = '0';
			}

			this.filters.cussituationid = this.model.cussituationid;
			this.filters.vehbraid = this.model.vehbraid;
			this.filters.markinginstrid = this.model.markinginstrid;
			this.filters.stevedoreid = this.model.stevedoreid;
			this.filters.dockid = this.model.dockid;

			if (!this.isCreatingElement) {
				this.alerts = await SdsmGoodUtils.getAlerts(this, this.model.goodid);
			}
		},
		async focusDDGG() {
			this.loading = true;
			this.ddgg = await SdsmGoodUtils.getDDGG(this);
			this.loading = false;
		},
		selectIfOnlyOneOptionDDGG(event) {
			if (event.key == 'Backspace' && this.ddggcriteria != null) {
				this.ddggcriteria = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.ddgg.filter((item) => {
				return item.onu?.toLowerCase().includes(text.toLowerCase()) || item.descripcion?.toLowerCase().includes(text.toLowerCase());
			});

			if (resultadosFiltrados.length == 1) {
				this.ddggcriteria = resultadosFiltrados[0].onu;

				this.$refs.ddggRef.isMenuActive = false;
			}
		},

		mainSelectorEvent(event) {
			if (event.key == 'Backspace' && this.model.blid != null) {
				this.model.blid = null;
			}
		},
		selectedBlidItem(item) {
			if (!this.selectedblid) {
				return (
					this.$t('sdsmgood.cargooperator') +
					':' +
					` ${item.operator || '0'} / ` +
					this.$t('sdsmgood.documentnumber') +
					':' +
					` ${item.documentnumber} / ` +
					this.$t('sdsmgood.jointservicenumber') +
					':' +
					` ${item.jointservicenumber} / ` +
					this.$t('sdsmgood.blnumber') +
					':' +
					` ${item.ordernumber || '0'} / ` +
					this.$t('sdsmgood.callcode') +
					':' +
					` ${item.callnumber || '0'}`
				);
			} else {
				return '';
			}
		},
		// Wacth BLID (Create Element only)
		async WatchBLIDCreate(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.model.ordernumber = await SdsmGoodUtils.generateNextGoodOrderNumber(
					this,
					this.billOfLoadingHeaderData.jointserviceid,
					this.billOfLoadingHeaderData.jointservicenumber
				);
				if (this.isPassengers) {
					this.model.packagetypeid = null;
					this.model.weight = null;
					this.model.volume = null;
				}
			}
		},
		// Save Good and New
		async saveGoodAndNew() {
			const data = await this.saveGood();
			if (data) {
				this.refreshRouter();
			}
		},
		// Save Good and Keep editing
		async saveGoodAndUpdate() {
			const data = await this.saveGood();
			if (data) {
				if (this.isCreatingElement) {
					this.goToUpdate(data);
				} else {
					this.refreshRouter();
				}
			}
		},
		// Save Good and Back
		async saveGoodAndBack() {
			const data = await this.saveGood();
			if (data) {
				this.back();
			}
		},
		// Save Good
		async saveGood() {
			let good = null;
			const eqs = this.$refs.child1?.equipmentsGood || [];
			const docs = this.$refs.child2?.documentscs || [];
			const valid = await SdsmGoodUtils.checkBeforeSaving(this, this.model, eqs, docs);
			if (valid) {
				this.saving = true;
				this.model.jointservicenumber = this.billOfLoadingHeaderData.jointservicenumber;
				this.model.jointserviceid = this.billOfLoadingHeaderData.jointserviceid;
				good = await SdsmGoodUtils.save(this, this.model, eqs, docs);
				this.saving = false;
			}
			return good;
		},
		selectIfOnlyOneOption(event) {
			if (event.key == 'Backspace' && this.model.taricid != null) {
				this.model.taricid = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.filteredTarCodes.filter((item) => {
				return item.tariccod.toLowerCase().includes(text.toLowerCase()) || item.description.toLowerCase().includes(text.toLowerCase());
			});

			if (resultadosFiltrados.length == 1) {
				this.model.taricid = resultadosFiltrados[0].taricid;

				this.$refs.taricid.isMenuActive = false;
			}
		}
	},
	computed: {
		...mapState({
			tarcodes: (state) => state.PCSUM.tarcodes,
			operatorId: (state) => state.session.properties.GESTAUT_OPERATOR_ID
		}),

		PackageTypeItemsToSelect() {
			return [{ packagetypeid: this.model.packagetypeid }];
		},
		billOfLoadingHeaderData() {
			return this.headerData;
		},
		getTaricInfo() {
			return this.tarcodes.find((t) => t.taricid === this.model.taricid);
		},
		FilterDockid() {
			return filters.expirationDatePlus(this.filters.dockid, 'dockid');
		},
		filteredBLID() {
			// Prepare basic filter
			const filter = {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'operatorid', op: 'eq', data: this.operatorId },
					{ field: 'langtra', op: 'eq', data: this.$store.getters.getUserLanguage },
					{ field: 'isempty', op: 'eq', data: 0 }
				]
			};

			if (!this.$attrs.parentpk && this.isCreatingElement) {
				filter.groups.push({
					groupOp: 'or',
					rules: EntityStatusUtils.editStates.map((stateId) => ({
						field: 'blstateid',
						op: 'eq',
						data: stateId
					}))
				});
			}

			// Filter if parent PK -> Document or Joint Service
			if (this.$attrs.parentpk && this.isCreatingElement) {
				const parentpk = JSON.parse(atob(this.$attrs.parentpk));
				if (parentpk.documentid) {
					filter.rules.push({ field: 'documentid', op: 'eq', data: parentpk.documentid });
				}
				if (parentpk.jointserviceid) {
					filter.rules.push({ field: 'jointserviceid', op: 'eq', data: parentpk.jointserviceid });
				}
				if (parentpk.blid) {
					filter.rules.push({ field: 'blid', op: 'eq', data: parentpk.blid });
				}
			}
			return filter;
		},
		filteredTarCodes() {
			//filtrar tambien por fecha
			return SdsmGoodUtils.filterTarCodes(this.tarcodes, this.isPassengers, this.isCreatingElement, this.resetTar);
		},
		checkDsdtRequired() {
			return this.model.taricid && this.tarcodes.length ? this.getTaricInfo.iscustomsexitmandatory == '1' : false;
		},
		isCargo() {
			return this.billOfLoadingHeaderData?.iscargo === 1;
		},
		isPassengers() {
			return this.billOfLoadingHeaderData?.ispassengers === 1;
		},
		isEmpty() {
			return this.billOfLoadingHeaderData?.isempty === 1;
		},

		checkVehBraIDRequired() {
			return this.model.taricid && this.tarcodes.length ? SdsmGoodUtils.checkVehBrandRequired(this.getTaricInfo.tariccod) : false;
		},
		filterPackagetypeid() {
			return filters.expirationDatePlus(this.filters.packagetypeid, 'packagetypeid');
		},
		filterCussituationid() {
			return filters.expirationDatePlus(this.filters.cussituationid, 'cussituationid');
		},
		filterVehbraid() {
			return filters.expirationDatePlus(this.filters.vehbraid, 'vehbraid');
		},
		filterMarkinginstrid() {
			return filters.expirationDatePlus(this.filters.markinginstrid, 'markinginstrid');
		},
		filterStevedoreid() {
			return filters.expirationDatePlus(this.filters.stevedoreid, 'stevedoreid');
		}
	},
	created() {}
};
</script>
