const accept = {
	id: 'idMessageBlAceptAction',
	selectionType: 'single',
	label: 'action.sdsmmessage.acept.title',
	functionality: 'ACCEPT_MESSAGE',
	showInForm: true,
	checkAvailability: function () {
		return true;
	},
	runAction: async function (action, model, registries) {
		// Get PK for the header
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		await this.$puiRequests.postRequest(
			'/sdsmmessage/aceptMSG',
			registries[0].messageid,
			() => {
				this.$puiNotify.success(this.$t('general.sendsuccess'));
			},
			(e) => {
				this.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
	}
};

const reject = {
	id: 'idMessageBlRejectAction',
	selectionType: 'single',
	label: 'action.sdsmmessage.reject.title',
	functionality: 'REJECT_MESSAGE',
	showInForm: true,
	checkAvailability: function () {
		return true;
	},
	runAction: async function (action, model, registries) {
		// Get PK for the header
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		await this.$puiRequests.postRequest(
			'/sdsmmessage/rejectMSG',
			registries[0].messageid,
			() => {
				this.$puiNotify.success(this.$t('general.sendsuccess'));
			},
			(e) => {
				this.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
	}
};

const resend = {
	id: 'idMessageResendAction',
	selectionType: 'single',
	label: 'action.sdsmmessage.resend.title',
	functionality: 'RESEND_MESSAGE',
	showInForm: true,
	checkAvailability: function () {
		return true;
	},
	runAction: async function (action, model, registries) {
		// Get PK for the header
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		await this.$puiRequests.postRequest(
			'/sdsmmessage/resendMSG',
			registries[0].messageid,
			() => {
				this.$puiNotify.success(this.$t('general.sendsuccess'));
			},
			(e) => {
				this.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
	}
};

const repro = {
	id: 'idMessageRejectAction',
	selectionType: 'single',
	label: 'action.sdsmmessage.repro.title',
	functionality: 'REPRO_MESSAGE',
	showInForm: true,
	checkAvailability: function () {
		return true;
	},
	runAction: async function (action, model, registries) {
		// Get PK for the header
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		await this.$puiRequests.postRequest(
			'/sdsmmessage/reproMSG',
			registries[0].messageid,
			() => {
				this.$puiNotify.success(this.$t('general.sendsuccess'));
			},
			(e) => {
				this.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
	}
};

export default {
	gridactions: [accept, reject, resend, repro],
	formactions: [accept, reject, resend, repro]
};
