<template>
	<div class="pui-form">
		<mequipmenttypegroup-modals :modelName="modelName"></mequipmenttypegroup-modals>

		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<!-- 	<v-row dense>


						<v-col cols="4">
							<pui-text-field
								:id="`eqtypegroupid-mequipmenttypegroup`"
								v-model="model.eqtypegroupid"
								:label="$t('mequipmenttypegroup.eqtypegroupid')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="12"
							></pui-text-field>
						</v-col>
					</v-row> -->
					<v-row dense>
						<!-- CODE -->

						<v-col cols="4">
							<pui-text-field
								:id="`code-mequipmenttypegroup`"
								v-model="model.code"
								:label="$t('mequipmenttypegroup.code')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="3"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DESCRIPCTION -->

						<v-col cols="4">
							<pui-text-field
								:id="`description-mequipmenttypegroup`"
								v-model="model.description"
								:label="$t('mequipmenttypegroup.description')"
								:disabled="formDisabled"
								required
								toplabel
							></pui-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin'
import mequipmenttypegroupActions from './MequipmenttypegroupActions'
import mequipmenttypegroupModals from './MequipmenttypegroupModals.vue'

export default {
	name: 'mequipmenttypegroup-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mequipmenttypegroup-modals': mequipmenttypegroupModals
	},
	data () {
		return {
			modelName: 'mequipmenttypegroup',
			actions: mequipmenttypegroupActions.formActions
		}
	},
	methods: {
		afterGetData () {
			// Do something after get data from server
		}
	},
	computed: {},
	created () {}
}
</script>
