<template>
	<div>
		<pui-datatable v-if="!masterDetail" :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs" :showDeleteBtn="false">
			<template slot="toolbarAdditionalBtns">
				<div class="pui-gridActionsBtn mt-2 pr-2" v-show="deleteSorted">
					<v-btn
						:id="`gridbtn-legend-${modelName}`"
						class="elevation-0 pui-toolbar__actionBtn primary white--text"
						:title="$t('general.confirmremove1title')"
						@click.native.stop="showDialog()"
						icon
					>
						<v-icon>far fa-trash-alt</v-icon>
					</v-btn>
				</div>
			</template>
		</pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:actions="actions"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:modelColumnDefs="modelColumnDefs"
			:key="blidkey"
			:showCreateBtn="showCreateBtn"
			:showUpdateBtn="true"
			:showDeleteBtn="false"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		>
			<template v-if="showDeleteBtn" slot="toolbarAdditionalBtns">
				<div class="pui-gridActionsBtn mt-2 pr-2" v-show="deleteSorted">
					<v-btn
						:id="`gridbtn-legend-${modelName}`"
						class="elevation-0 pui-toolbar__actionBtn primary white--text"
						:title="$t('general.confirmremove1title')"
						@click.native.stop="showDialog()"
						icon
					>
						<v-icon>far fa-trash-alt</v-icon>
					</v-btn>
				</div>
			</template>
		</pui-datatable>
		<sdsmuti-modals :modelName="modelName"></sdsmuti-modals>
		<!-- MODAL START-->
		<v-dialog v-model="openDialogSend" max-width="500">
			<v-card class="pa-2">
				<v-card-title class="pl-3 mb-4 headline">{{ $t('general.confirmremove1title') }}</v-card-title>
				<v-card-subtitle class="pl-3">{{ $t('general.confirmremove1subtitle') }}</v-card-subtitle>
				<v-card-subtitle class="pl-3" v-if="dialogLoading"
					><v-progress-linear color="primary" indeterminate></v-progress-linear
				></v-card-subtitle>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn style="box-shadow: none" @click="cancel()" :disabled="dialogLoading">{{ $t('general.cancel') }}</v-btn>
					<v-btn style="box-shadow: none" color="primary" @click="accept()" :disabled="dialogLoading">{{ $t('general.accept') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- MODAL END -->
	</div>
</template>

<script>
import sdsmutiActions from './SdsmutiActions';
import sdsmutiModals from './SdsmutiModals.vue';
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixinAccess';
import SdsmUtiUtils from './SdsmutiUtils';
import { mapState } from 'vuex';
import filters from '@/utils/filters.js';

export default {
	name: 'sdsmuti-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		'sdsmuti-modals': sdsmutiModals
	},
	props: {
		blid: {
			required: false,
			type: Object
		}
	},
	data() {
		return {
			deleteSorted: false,
			modelName: 'sdsmuti',
			actions: sdsmutiActions.gridactions,
			createBtn: false,
			blidkey: 0,
			modelColumnDefs: {
				fullfilliso: {
					render: (data, type, row) => {
						return row.fullfilliso === '1' ? `<i class="fas fa-check state-check"></i>` : '<i class="fal fa-times state-no"></i>';
					}
				}
			},
			openDialogSend: false,
			dialogLoading: false,
			selectedRows: []
		};
	},
	created() {
		this.externalFilterUpdate();
	},
	mounted() {
		setTimeout(() => {
			this.$puiEvents.$on(`onPui-selectItem-dataTable-${this.modelName}`, (selectedRows) => {
				this.selectedRows = selectedRows.map((row) => {
					return Object.values(row)[0];
				});
			});

			const btns = this.$el.querySelectorAll('.pui-gridActionsBtn');
			const deleteBtn = btns[3];
			btns[3].parentNode.removeChild(btns[3]);
			btns[2].parentNode.insertBefore(deleteBtn, btns[2]);
			this.deleteSorted = true;
		}, 100);
	},
	destroyed() {
		this.$puiEvents.$off(`onPui-selectItem-dataTable-${this.modelName}`);
	},
	computed: {
		...mapState({
			operatorId: (state) => state.session.properties.GESTAUT_OPERATOR_ID
		}),

		showBtnCreate() {
			return this.operatorId;
		},
		CustomExternalFilter() {
			if (this.operatorId) {
				return filters.expirationDateArray([
					{ field: 'shipoperatorid', data: this.operatorId },
					{ field: 'operatorid', data: this.operatorId }
				]);
			} else {
				return filters.expirationDateArray();
			}
		}
	},
	methods: {
		async externalFilterUpdate() {
			if (this.parentPk) {
				let parentpk = JSON.parse(atob(this.parentPk));
				if (parentpk.blid) {
					let newUti = await SdsmUtiUtils.getEquipmentByBlid(this, JSON.parse(atob(this.parentPk)).blid);
					if (newUti && (newUti[0].bltypeid == 3 || newUti[0].bltypeid == 1)) {
						this.createBtn = true;
						this.blidkey++;
					}
				} else if (parentpk.documentid) {
					// Not used --> let newUti = await SdsmUtiUtils.getDocumentByid(this, JSON.parse(atob(this.parentPk)).documentid);
				}
			}
		},
		showDialog() {
			if (this.selectedRows.length != 0) {
				this.openDialogSend = true;
			} else {
				this.$puiNotify.warning(this.$puiI18n.t('errors.warnremove'));
			}
		},
		cancel() {
			this.openDialogSend = false;
		},
		async accept() {
			this.dialogLoading = true;
			let pk = this.selectedRows[0].utiid;
			let params = { data: pk };
			await this.$puiRequests.deleteRequest(
				'/sdsmuti/deleteUti',
				null,
				() => {
					this.openDialogSend = false;
					this.dialogLoading = false;
					document.getElementById('gridbtn-refresh-sdsmuti').click();
					this.selectedRows = [];
				},
				(e) => {
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response?.data) {
						message = e.response.data.message;
					}
					this.$puiNotify.error(message);
					this.openDialogSend = false;
					this.dialogLoading = false;
				},
				params,
				null
			);
		}
	}
};
</script>
