<template>
	<div class="pui-form">
		<sdsmstevedore-modals :modelName="modelName"></sdsmstevedore-modals>
		<!-- <pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<sdsmstevedore-form-header :modelPk="modelPk"></sdsmstevedore-form-header>
		</pui-form-header> -->
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<!-- STEVEDOREID -->
					<v-row dense v-if="!isCreatingElement">
						<v-col cols="1">
							<pui-text-field
								:id="`stevedoreid-sdsmstevedore`"
								v-model="model.stevedoreid"
								:label="$t('sdsmstevedore.stevedoreid')"
								disabled
								toplabel
								maxlength="12"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- CODE -->
						<v-col cols="2">
							<pui-text-field
								:id="`code-sdsmstevedore`"
								v-model="model.code"
								:label="$t('sdsmstevedore.code')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="15"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DESCRIPTION -->
						<v-col cols="5">
							<pui-text-field
								:id="`description-sdsmstevedore`"
								v-model="model.description"
								:label="$t('sdsmstevedore.description')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DATEEXPIRATION -->

						<v-col cols="4" v-if="!isCreatingElement">
							<pui-date-field
								:id="`dateexpiration-mvehiclebrand`"
								v-model="model.dateexpiration"
								:label="$t('mvehiclebrand.dateexpiration')"
								disabled
								readonly
								toplabel
								maxlength="13"
								time
							></pui-date-field>
						</v-col>
					</v-row>

					<!-- MINI AUDIT -->
					<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin'
import sdsmstevedoreActions from './SdsmstevedoreActions'
import sdsmstevedoreModals from './SdsmstevedoreModals.vue'
import formUtils from '@/utils/form'

export default {
	name: 'sdsmstevedore-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'sdsmstevedore-modals': sdsmstevedoreModals
	},
	data () {
		return {
			modelName: 'sdsmstevedore',
			actions: sdsmstevedoreActions.formActions
		}
	},
	methods: {
		afterGetData () {
			formUtils.updateMiniAudit(this.model)
		}
	},
	computed: {},
	created () {}
}
</script>
