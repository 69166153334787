<template>
	<div>
		<!-- Import CSV -->
		<v-dialog v-if="DocumentImportCSV.open" v-model="DocumentImportCSV.open" persistent scrollable>
			<sdsmdocument-import-csv></sdsmdocument-import-csv>
		</v-dialog>

		<!-- Edit Joint Service in a Modal from Document screen -->
		<pui-modal-dialog-form
			scrollable
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.sdsmdocument.editjointservice.title')"
			:modelName="modelName"
			:dialogName="sdsmdocumentEditJSInModal.name"
			:okText="sdsmdocumentEditJSInModal.okText"
			:onOk="onOkEditJSModal"
			:onShow="onShowEditJSModal"
		>
			<template slot="message" slot-scope="data">
				<sdsmjointservice-form-for-quickedit
					:ref="sdsmdocumentEditJSInModal.refForm"
					:model="data.modalData"
				></sdsmjointservice-form-for-quickedit>
			</template>
		</pui-modal-dialog-form>

		<!-- BL Massive Creation from Document Screen -->
		<v-dialog v-if="massiveCreateModal.jointservice" v-model="massiveCreateModal.open" persistent scrollable>
			<sdsmbillofloading-massive-create
				:key="massiveCreateModal.jointservice.jointserviceid"
				:jointservice="massiveCreateModal.jointservice"
				:billofloading="massiveCreateModal.billofloading"
				:modelName="this.modelName"
				:isCreatingElement="true"
			></sdsmbillofloading-massive-create>
		</v-dialog>

		<!-- Copy Document -->
		<pui-modal-dialog-form
			scrollable
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.sdsmdocument.copy.title')"
			:modelName="modelName"
			:dialogName="sdsmdocumentCopyModal.name"
			:okText="sdsmdocumentCopyModal.okText"
			:onOk="onOkCopyModal"
			:onShow="onShowCopyModal"
		>
			<template slot="message" slot-scope="data">
				<sdsmdocument-form-for-copy :ref="sdsmdocumentCopyModal.refForm" :model="data.modalData"></sdsmdocument-form-for-copy>
			</template>
		</pui-modal-dialog-form>

		<!-- MassiveUpdate Action -->
		<pui-modal-dialog-form
			scrollable
			widthDialog="75%"
			:dialogName="sdsmdocumentMassiveUpdateModal.name"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.sdsmdocument.massiveedit.title')"
			:modelName="modelName"
			:onOk="onOkDocumentMassiveUpdateModal"
			:okText="sdsmdocumentMassiveUpdateModal.okText"
			:onShow="onShowDocumentMassiveUpdateModal"
		>
			<template slot="message" slot-scope="data">
				<sdsmdocument-form-for-massive-update
					:ref="sdsmdocumentMassiveUpdateModal.refForm"
					:docs="data.modalData.docs"
				></sdsmdocument-form-for-massive-update>
			</template>
		</pui-modal-dialog-form>

		<!-- document send Action -->
		<pui-modal-dialog-form
			scrollable
			widthDialog="75%"
			:dialogName="sdsmdocumentSendModal.name"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.sdsmdocument.documentsend.title')"
			:modelName="modelName"
			:okText="sdsmdocumentSendModal.okText"
			:onOk="onOkSendModal"
			:onShow="onShowSendModal"
		>
			<template slot="message" slot-scope="data">
				<sdsmdocument-form-for-send :ref="sdsmdocumentSendModal.refForm" :model="data.modalData" :model2="true"></sdsmdocument-form-for-send>
			</template>
		</pui-modal-dialog-form>

		<!-- Warnings modal -->
		<pui-modal-dialog-form
			scrollable
			widthDialog="25%"
			:dialogName="alertsWarningModal.name"
			modelName="sdsmdocument"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.alertmodal.warnings')"
			:okText="alertsWarningModal.okText"
			:onShow="onShowSendModal"
			:disableCancel="true"
		>
			<template slot="message" slot-scope="data">
				<document-warnings :ref="alertsWarningModal.refForm" :alerts="data.modalData.alerts"></document-warnings>
			</template>
		</pui-modal-dialog-form>

		<!-- Errors modal -->
		<pui-modal-dialog-form
			scrollable
			widthDialog="25%"
			:dialogName="alertsErrorModal.name"
			modelName="sdsmdocument"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.alertmodal.errors')"
			:okText="alertsErrorModal.okText"
			:onShow="onShowSendModal"
			:disableCancel="true"
		>
			<template slot="message" slot-scope="data">
				<document-errors :ref="alertsErrorModal.refForm" :alerts="data.modalData.alerts"></document-errors>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
import SdsmjointserviceFormForQuickEdit from '../sdsmjointservice/modalForms/SdsmjointserviceFormForQuickEdit.vue';
import SdsmbillofloadingMassiveCreate from './../sdsmbillofloading/modalForms/SdsmbillofloadingMassiveCreate.vue';
import SdsmdocumentFormForCopy from './modalForms/SdsmdocumentFormForCopy.vue';
import SdsmdocumentSend from './modalForms/SdsmdocumentSend.vue';
import { mapState } from 'vuex';
import SdsmdocumentFormForMassiveUpdate from './modalForms/SdsmdocumentFormForMassiveUpdate.vue';
import alertsWarningModal from '../general/PcsumHeader/alertWarningModal_new.vue';
import alertsErrorModal from '../general/PcsumHeader/alertErrorModal_new.vue';
import SdsmdocumentImportCSVVue from './modalForms/SdsmdocumentImportCSV.vue';

export default {
	components: {
		'sdsmjointservice-form-for-quickedit': SdsmjointserviceFormForQuickEdit,
		'sdsmdocument-form-for-copy': SdsmdocumentFormForCopy,
		'sdsmdocument-import-csv': SdsmdocumentImportCSVVue,
		'sdsmbillofloading-massive-create': SdsmbillofloadingMassiveCreate,
		'sdsmdocument-form-for-massive-update': SdsmdocumentFormForMassiveUpdate,
		'sdsmdocument-form-for-send': SdsmdocumentSend,
		'document-warnings': alertsWarningModal,
		'document-errors': alertsErrorModal
	},
	name: 'sdsmdocument-modals',
	computed: {
		...mapState({
			massiveCreateModal: (state) => state.modal.massiveCreate,
			DocumentImportCSV: (state) => state.modal.DocumentImportCSV
		})
	},
	data() {
		return {
			sdsmdocumentEditJSInModal: {
				refForm: 'refJSForm',
				okText: this.$t('general.saveandfinish'),
				name: 'sdsmdocumentEditJSInModalAction'
			},
			sdsmdocumentBLMassiveCreateModal: {
				refForm: 'refBLForm',
				okText: this.$t('general.saveandfinish'),
				name: 'sdsmdocumentBLMassiveCreateAction'
			},
			sdsmdocumentCopyModal: {
				refForm: 'refCopyForm',
				okText: this.$t('general.saveandfinish'),
				name: 'sdsmdocumentCopyAction'
			},
			sdsmdocumentMassiveUpdateModal: {
				okText: null,
				refForm: 'documentFormMassiveUpdate',
				name: 'idDocumentMassiveUpdateAction'
			},
			sdsmdocumentSendModal: {
				okText: null,
				refForm: 'documentSend',
				name: 'DocumentSendAction'
			},
			alertsWarningModal: {
				okText: this.$t('modal.alertmodal.close'),
				refForm: 'modalWarningAlerts',
				name: 'modalWarningAlerts'
			},
			alertsErrorModal: {
				okText: this.$t('modal.alertmodal.close'),
				refForm: 'modalErrorAlerts',
				name: 'modalErrorAlerts'
			}
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		/***********************/
		/* Edit Joint Service */
		/*********************/
		async onOkEditJSModal() {
			const jointservice = await this.$refs[this.sdsmdocumentEditJSInModal.refForm].Save();
			if (jointservice) {
				return new Promise((resolve) => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					// Refreshing List after updated the row -> reaching Parent
					setTimeout(() => {
						if (this.$parent.$refs.JS) {
							this.$parent.$refs.JS.EmitRefresh();
						}
					}, 1000);
					resolve(true);
				});
			}
		},
		onShowEditJSModal() {},
		/******************/
		/* Copy Document */
		/****************/
		async onOkCopyModal() {
			const document = await this.$refs[this.sdsmdocumentCopyModal.refForm].CopyDocument();
			if (document) {
				return new Promise((resolve) => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					resolve(true);
				});
			}
		},
		onShowCopyModal() {},
		onShowDocumentCopyModal() {},
		/* Massive Edit */
		async onOkDocumentMassiveUpdateModal() {
			const completed = await this.$refs[this.sdsmdocumentMassiveUpdateModal.refForm].SendDocumentMassiveEdit();
			if (completed) {
				return new Promise((resolve) => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					resolve(true);
				});
			}
		},
		onShowDocumentMassiveUpdateModal() {},
		/******************/
		/* Send Document */
		/****************/
		async onOkSendModal() {
			const completed = await this.$refs[this.sdsmdocumentSendModal.refForm].SendDocument();
			if (completed) {
				return new Promise((resolve) => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					resolve(true);
				});
			}
			setTimeout(() => {
				document.getElementById('DocumentSendAction-btn-cancel').disabled = false;
				document.getElementById('DocumentSendAction-btn-cancel').click();
			}, 200);
			if (this.storedData) this.modelDependency.storedData = this.storedData;
			this.$puiEvents.$emit('onPuiRouter-sameModelAndComponent-refreshRouter');
		},
		onOkCloseModal() {
			this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
		},
		onShowSendModal() {}
	}
};
</script>
