<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<sdsmoperator-modals
			:modelName="modelName"
		></sdsmoperator-modals>
	</div>
</template>

<script>
import sdsmoperatorActions from './SdsmoperatorActions';
import sdsmoperatorModals from './SdsmoperatorModals.vue';

export default {
	name: 'sdsmoperator-grid',
	components: {
		'sdsmoperator-modals': sdsmoperatorModals
	},
	data() {
		return {
			modelName: 'sdsmoperator',
			actions: sdsmoperatorActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
