<template>
	<div>
		<pcsum-edition-grid
			ref="BOLS"
			:label="$t('sdsmjointservice.billofloadings')"
			:labelEmpty="$t('sdsmjointservice.nobillofloadings')"
			:rows="billofloadings"
			:fields="fields"
			:params="params"
			:loading="loading"
			:basicDisplay="!formDisabled"
			:allowNew="!formDisabled"
			:allowRemove="!formDisabled"
			:allowLaunch="!formDisabled"
			:allowEdit="editable && !formDisabled"
			:editInModal="!formDisabled"
			launchIcon="fas fa-external-link-square-alt"
			@EmitCreate="EmitCreate"
			@EmitRefresh="EmitRefresh"
			@EmitRemove="EmitRemove"
			@EmitChange="EmitChange"
			@EmitClone="EmitClone"
			@EmitLaunch="EmitLaunch"
			@EmitEditInModal="EmitEditInModal"
		>
		</pcsum-edition-grid>
	</div>
</template>

<script>
import PcsumEditionGrid from '../general/PcsumEditionGrid';

export default {
	name: 'sdsmgood-form-documents',
	components: {
		'pcsum-edition-grid': PcsumEditionGrid
	},
	props: {
		jointservice: { type: Object, default: () => {} },
		params: { type: Object, default: () => {} },
		opMode: { type: Array, default: () => [] }
	},
	data() {
		return {
			loading: false,
			billofloadings: [],
			editable: true,
			formDisabled: false,
			fields: {
				ordernumber: {
					label: 'sdsmbillofloading.ordernumber',
					cols: '12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2'
				},
				blnumber: {
					label: 'sdsmbillofloading.blnumber',
					cols: '12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2'
				},
				billofloadingtype: {
					label: 'sdsmbillofloading.bltypeid',
					cols: '12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3'
				},
				state: {
					label: 'sdsmbillofloading.blstateid',
					cols: '12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2',
					pill: true
				},
				dock: {
					label: 'sdsmbillofloading.dockid',
					cols: '12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3'
				}
			}
		};
	},
	watch: {},
	methods: {
		getBillOfLoadings() {
			if (this.jointservice.jointserviceid) {
				this.loading = true;
				this.$puiRequests.postRequest(
					'/puisearch',
					{
						model: 'sdsmbillofloading',
						rows: null,
						filter: {
							groups: [
								{
									groupOp: 'and',
									rules: [{ field: 'jointserviceid', op: 'eq', data: this.jointservice.jointserviceid }]
								}
							],
							groupOp: 'and',
							rules: []
						},
						order: [
							{
								column: 'ordernumber',
								direction: 'asc'
							}
						]
					},
					(response) => {
						this.loading = false;
						this.billofloadings = [...response.data.data];
						for (let i = 0; i < this.documentscs.length; i++) {
							this.billofloadings[i].isNew = false;
							this.billofloadings[i].isSelected = false;
							this.billofloadings[i].isEdited = false;
							this.billofloadings[i].randomKey = Math.floor(Math.random() * 1000);
						}
					},
					() => {
						this.loading = false;
					}
				);
			}
		},
		/* Create Empty Row */
		EmitCreate() {},
		/* Editing new row */
		EmitChange() {},
		/* Deleting row */
		EmitRemove() {},
		/* Refresh list */
		EmitRefresh() {
			this.getBillOfLoadings();
		},
		EmitClone() {},
		EmitLaunch() {},
		EmitEditInModal(registry) {
			this.$puiEvents.$emit('pui-modalDialogForm-sdsmjointserviceEditBOLInModalAction-sdsmjointservice-show', registry);
		}
	},
	created() {
		this.getBillOfLoadings();
		this.formDisabled = this.params.formDisabled;
		if (this.opMode[0]) {
			this.editable = false;
		}
	}
};
</script>
