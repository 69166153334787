<template>
	<div class="pui-form">
		<template v-if="model">
			<v-form class="mb-4 pb-4" ref="form" lazy-validation @submit.prevent>
				<v-row class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('modal.sdsmjointservice.massiveedit.jointservices')">
							<v-chip-group column>
								<template v-for="(U, index) in jointservices">
									<template v-if="U.jointserviceid">
										<v-tooltip bottom :key="'jointservice-' + index">
											<template v-slot:activator="{ on, attrs }">
												<v-chip label outlined :color="getStatus(U).color" v-bind="attrs" v-on="on">
													<span>{{
														$t('sdsmjointservice.jointservicenumber') +
														': ' +
														(U.jointservicenumber || $t('modal.sdsmuti.massiveedit.noidentification')) +
														' / ' +
														$t('sdsmjointservice.ordernumber') +
														': ' +
														(U.ordernumber || $t('modal.sdsmuti.massiveedit.noidentification'))
													}}</span>
													<template v-if="U.status">
														<v-icon small class="pl-3" :color="getStatus(U).color">{{ getStatus(U).icon }} </v-icon>
													</template>
												</v-chip>
											</template>
											<span class="massiveToolTip">
												<div class="content">
													<div>{{ $t('sdsmdocument.documentnumber') }}: {{ U.documentnumber }}</div>
													<div>{{ $t('sdsmjointservice.jointservicenumber') }}: {{ U.jointservicenumber }}</div>
													<div>{{ $t('sdsmjointservice.state') }}: {{ U.state }}</div>
													<div>{{ $t('sdsmjointservice.ordernumber') }}: {{ U.ordernumber }}</div>
												</div>
												<div v-if="U.error" class="messages">
													<i class="fas fa-exclamation-triangle"></i> <span>{{ U.error }}</span>
												</div>
											</span>
										</v-tooltip>
									</template>
								</template>
							</v-chip-group>
						</pui-field-set>
					</v-col>
					<v-col cols="12">
						<pui-field-set :title="$t('modal.sdsmuti.massiveedit.newvalues')">
							<v-row dense>
								<v-col class="12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6"> </v-col>
							</v-row>
							<!-- <v-row dense>

								<v-col class="consignatarioClass">
									<pui-select
										id="`operatorid-sdsmjointservice`"
										attach="operatorid-sdsmjointservice"
										:label="$t('sdsmjointservice.operatorid')"
										toplabel
										clearable
										required
										v-model="model"
										modelName="sdsmoperator"
										:modelFormMapping="{ operatorid: 'operatorid' }"
										:itemsToSelect="operatorItemsToSelect"
										:itemValue="'operatorid'"
										:itemText="item => `${item.operatorid} - ${item.description}`"
										:order="{ operatorid: 'asc' }"
										:fixedFilter="OperatorFilter"
										singleItemAutoselect
									></pui-select>
								</v-col>

								<v-col class="12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
									<pui-number-field
										:id="`jointservicenumber-sdsmjointservice`"
										v-model="model.jointservicenumber"
										:label="$t('sdsmjointservice.jointservicenumber')"
										required
										toplabel
										:rules="codartRules"
										max="999"
									></pui-number-field>
								</v-col>
							</v-row> -->

							<v-row dense>
								<v-col class="12 col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
									<!-- DOCK ID -->

									<pui-select
										id="`dockid-sdsmjointservice`"
										attach="dockid-sdsmjointservice"
										:label="$t('sdsmjointservice.dockid')"
										toplabel
										clearable
										v-model="model"
										modelName="mdock"
										:modelFormMapping="{ dockid: 'dockid' }"
										:itemsToSelect="dockItemsToSelect"
										:itemValue="'dockid'"
										:itemText="(item) => `${item.description}`"
										:order="{ dockid: 'asc' }"
										:fixedFilter="filterDockid"
										singleItemAutoselect
									></pui-select>
								</v-col>
								<!-- DOCK LOCATION -->
								<v-col class="12 col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
									<pui-select
										id="`docklocationid-sdsmjointservice`"
										attach="docklocationid-sdsmjointservice"
										:label="$t('sdsmjointservice.docklocationid')"
										toplabel
										clearable
										v-model="model"
										reactive
										modelName="mdockdocklocation"
										:modelFormMapping="{ docklocationid: 'docklocationid' }"
										:itemsToSelect="docklocationItemsToSelect"
										:itemValue="['docklocationid']"
										:itemText="(item) => `${item.docklocation} `"
										:order="{ docklocationid: 'asc' }"
										:fixedFilter="filterDocklocationid"
										singleItemAutoselect
									></pui-select>
								</v-col>
								<v-col class="12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
									<!-- ISSIMPREG -->
									<pui-text-field
										:id="`comcode-sdsmjointservice`"
										v-model="model.comcode"
										:label="$t('sdsmjointservice.comcode')"
										toplabel
										maxlength="3"
									></pui-text-field>
								</v-col>
							</v-row>

							<v-row dense>
								<v-col class="12 col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 pl-2">
									<pui-radio-group
										v-model="model.issimpreg"
										toplabel
										:label="$t('sdsmjointservice.simtran')"
										row
										column
										:radios="[
											{ id: '1', label: $t('general.yes') },
											{ id: '0', label: $t('general.no') }
										]"
									>
									</pui-radio-group>
								</v-col>
								<v-col class="12 col-xs-12 col-sm-7 col-md-7 col-lg-7 col-xl-7">
									<pui-select
										id="`simpregoperatorid-sdsmjointservice`"
										attach="simpregoperatorid-sdsmjointservice"
										:label="$t('sdsmjointservice.trts')"
										toplabel
										clearable
										reactive
										:disabled="this.simpregooperatorBool"
										:required="this.issimpregBool"
										v-model="model"
										modelName="sdsmoperator"
										:modelFormMapping="{ operatorid: 'simpregoperatorid' }"
										:itemsToSelect="simpregoperatoridItemsToSelect"
										:itemValue="'operatorid'"
										:itemText="(item) => `${item.description}`"
										:fixedFilter="filterSimpregoperatorid"
									></pui-select>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-else>
			<v-progress-linear color="primary" indeterminate></v-progress-linear>
		</template>
	</div>
</template>

<script>
import MassiveUtils from '../../../utils/massive';
import SdsmjointserviceUtils from '../SdsmjointserviceUtils';
import filters from '@/utils/filters.js';
export default {
	name: 'sdsmjointservice-form-for-massive-update',
	props: {
		jointservices: { type: Array, default: () => [] }
	},
	data() {
		return {
			codartRules: [(v) => this.checkOperator(v) || this.$t('sdsmjointservice.orderinuse')],
			model: null,
			issimpregBool: false,
			simpregooperatorBool: true,
			lastSelectedOption: null,
			jsnumber: [],
			filters: {
				dockid: '',
				docklocationid: '',
				stateid: '',
				operatorid: ''
			}
		};
	},
	methods: {
		afterGetData() {
			this.filters.dockid = this.model.dockid;
			this.filters.docklocationid = this.model.docklocationid;
			this.filters.stateid = this.model.stateid;
			this.filters.operatorid = this.model.operatorid;
		},

		async SendJointserviceMassiveEdit() {
			let everythingupdated = true;
			for (const u in this.jointservices) {
				if (this.jointservices[u].jointserviceid) {
					this.jointservices[u].status = 1;
					let response = await SdsmjointserviceUtils.massiveupdate(this, this.model, this.jointservices[u].jointserviceid);
					if (response.updated) {
						this.jointservices[u].status = 2;
						this.jointservices[u].error = false;
					} else {
						this.jointservices[u].status = 3;
						this.jointservices[u].error = response.error;
						everythingupdated = false;
					}
				}
			}
			if (everythingupdated) {
				this.$puiNotify.success(this.$t('modal.sdsmjointservice.massiveedit.notifySuccess'));
			}
			return everythingupdated;
		},
		checkOperator(u) {
			let b = true;
			if (u == 0 && this.docOpId != this.model.operatorid) {
				return false;
			}

			if (this.jsnumber.length > 0 && this.opNumProv != u) {
				this.jsnumber.forEach((element) => {
					if (element == u) {
						if (!this.isCreatingElement) {
							if (this.model.jointservicenumber == u) {
								b = true;
							}
						} else {
							b = false;
						}
					}
				});
			}
			return b;
		},
		getStatus(jointservice) {
			//get iconos v x !
			return MassiveUtils.getStatus(jointservice);
		}
	},
	async created() {
		for (const u in this.jointservices) {
			if (this.jointservices[u].jointserviceid) {
				this.$set(this.jointservices[u], 'status', null);
				this.$set(this.jointservices[u], 'error', null);
			}
		}
		this.model = await SdsmjointserviceUtils.newJS(this);
	},
	computed: {
		dateHdcal(item) {
			return this.$moment(item.etaatd).format('DD/MM/YYYY HH:mm');
		},
		OperatorFilter() {
			return SdsmjointserviceUtils.getFixedFilterOperator(this.model, this.isCreatingElement);
		},
		fixedDate() {
			return filters.expirationDate();
		},
		fixedDatePlus() {
			return filters.expirationDatePlus(this.model.dockid, 'dockid');
		},
		documentItemsToSelect() {
			return [{ documentid: this.model.documentid }];
		},
		operatorItemsToSelect() {
			return [{ operatorid: this.model.operatorid }];
		},
		dockItemsToSelect() {
			return [{ dockid: this.model.dockid }];
		},
		docklocationItemsToSelect() {
			return [{ docklocationid: this.model.docklocationid }];
		},
		simtranItemsToSelect() {
			return [{ simtranid: this.model.simtranid }];
		},
		simpregoperatoridItemsToSelect() {
			return [{ operatorid: this.model.simpregoperatorid }];
		},
		filterDockid() {
			return filters.expirationDatePlus(this.filters.dockid, 'dockid');
		},
		filterDocklocationid() {
			return filters.getDockDockLockFilter(this.model.dockid);
		},
		filterStateid() {
			return filters.expirationDatePlus(this.filters.stateid, 'stateid');
		},
		filterOperatorid() {
			return filters.expirationDatePlus(this.filters.operatorid, 'operatorid');
		},
		filterSimpregoperatorid() {
			return filters.expirationDatePlus(this.filters.simpregoperatorid, 'simpregoperatorid');
		}
	},
	watch: {
		'model.issimpreg'(newQuestion) {
			if (newQuestion != null && newQuestion != 0) {
				this.issimpregBool = true;
				this.simpregooperatorBool = false;
			} else {
				this.issimpregBool = false;
				this.model.simpregoperatorid = null;
				this.simpregooperatorBool = true;
			}
			if (newQuestion != null && newQuestion == this.lastSelectedOption) {
				this.model.issimpreg = null;
			}
			this.lastSelectedOption = newQuestion;
		}
	}
};
</script>
