<template>
    <div class="pui-login__image--desktop">

	</div>
</template>


<script>
export default {
    name: 'applogindisplayarea'
};
</script>
