<template>
	<v-card class="pui-form" v-if="jointservice" id="ImportCSVJS">
		<v-card-title style="padding-bottom: 0px !important">
			{{ $t('form.sdsmjointservice.formtitle') }}
			<i class="far fa-chevron-right" style="font-size: 10px; opacity: 0.5; margin: 0px 10px"></i>
			{{ $t('modal.importCSV.title') }}
		</v-card-title>
		<!-- Joint Service's basic information -->
		<v-card-title>
			<pcsum-header :data="headerData" style="width: 100%"></pcsum-header>
		</v-card-title>
		<!-- File input -->
		<v-card-title v-if="!loading && !builtData?.progress">
			<v-btn color="primary" outlined @click="triggerFileInput" style="width: 100%">
				<v-icon color="primary"> upload </v-icon>
				{{ $t('modal.importCSV.importFile') }}
			</v-btn>
			<input ref="fileInput" accept=".csv" type="file" @change="handleFile" style="display: none" />
		</v-card-title>
		<v-card-text>
			<!-- Progress bar -->
			<template v-if="builtData?.progress">
				<div id="ImportCSVDOCMessage">{{ $t(builtData?.message) }}</div>
				<v-progress-linear color="primary" :value="builtData?.progress" :buffer-value="100"></v-progress-linear>
			</template>
			<!-- Loading bar-->
			<template v-else-if="loading">
				<div id="ImportCSVDOCMessage">{{ $t(loading) }}</div>
				<v-progress-linear color="primary" indeterminate></v-progress-linear>
			</template>
			<template v-else>
				<!-- Errors -->
				<template v-if="builtData?.errors?.length">
					<v-alert dense text icon="far fa-info-circle" :type="E.type" v-for="(E, index) in builtData.errors" :key="'ICSVE-' + index">
						{{ $t(E.entity) }}
						<i class="far fa-chevron-right" style="font-size: 10px; opacity: 0.5; margin: 0px 10px"></i>
						{{ $t(E.msg, E.params) }}
					</v-alert>
				</template>
				<!-- Report -->
				<template v-else>
					<template v-for="T in builtData?.document?.jointservices">
						<template v-if="T.jointservicenumber === jointservice.jointservicenumber">
							<v-row dense :key="'RB-' + T.jointservicenumber">
								<template v-for="B in T.billofloadings">
									<v-col
										cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
										:key="'JSC-' + T.jointservicenumber + '-' + B.ordernumber"
									>
										<v-card :key="'JS-' + T.jointservicenumber + '-' + B.ordernumber" elevation="0" outlined>
											<!-- Bill of lading ordernumber -->
											<v-card-title>{{ $t('form.sdsmjointservice.sdsmbillofloading') }} {{ B.ordernumber }} </v-card-title>
											<v-card-subtitle>
												<span>{{ B.bltypedescription }}</span> /
												<span
													>{{ $t('sdsmbillofloading.blnumber') }}: <strong>{{ B.blnumber }}</strong></span
												>
												/
												<span
													>{{ $t('sdsmbillofloading.origindes') }}: <strong>{{ B.origindes }}</strong></span
												>
												/
												<span
													>{{ $t('sdsmbillofloading.destinationdes') }}: <strong>{{ B.destinationdes }}</strong></span
												>
											</v-card-subtitle>
											<v-card-text>
												<!-- Goods number -->
												<v-chip label :color="B.goods.length > 0 ? 'primary' : ''" class="mr-3"
													>{{ $t('menu.sdsmgood') }}: {{ B.goods.length }}</v-chip
												>
												<!-- Utis number -->
												<v-chip label :color="getUtisNumber(B) > 0 ? 'primary' : ''" class="mr-3"
													>{{ $t('menu.sdsmuti') }}: {{ getUtisNumber(B) }}</v-chip
												>
												<!-- Docs number -->
												<v-chip label :color="getDocsNumber(B) > 0 ? 'primary' : ''"
													>{{ $t('sdsmgood.documents') }}: {{ getDocsNumber(B) }}</v-chip
												>
											</v-card-text>
										</v-card>
									</v-col>
								</template>
							</v-row>
						</template>
					</template>
				</template>
			</template>
		</v-card-text>
		<!-- Footer -->
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn elevation="0" @click="close()">{{ $t('general.cancel') }}</v-btn>
			<v-btn
				elevation="0"
				@click="accept()"
				color="primary"
				:disabled="loading !== false || !builtData || builtData.errors.length > 0 || builtData.progress !== null"
				>{{ $t('general.accept') }}</v-btn
			>
		</v-card-actions>
	</v-card>
</template>

<script>
import SdsmbillofloadingUtils from '../../sdsmbillofloading/SdsmbillofloadingUtils';
import PcsumHeader from './../../general/PcsumHeader';
import ImportCSV from './../../../utils/importCSV.js';
import { mapState } from 'vuex';
import SdsmjointserviceUtils from '../SdsmjointserviceUtils';
import SdsmDocumentUtils from '../../sdsmdocument/SdsmdocumentUtils';
import SdsmGoodUtils from '../../sdsmgood/SdsmgoodUtils';
import SdsmUtiUtils from '../../sdsmuti/SdsmutiUtils';
export default {
	name: 'sdsmjointservice-import-csv',
	props: {
		jointservice: { type: Object, default: () => {} },
		modelName: { type: String, default: '' }
	},
	components: {
		'pcsum-header': PcsumHeader
	},
	data() {
		return {
			fileData: [],
			builtData: null,
			loading: false,
			templateDocument: null,
			templateJS: null,
			templateBL: null,
			templateGood: null,
			templateUTI: null,
			templateDOC: null
		};
	},
	methods: {
		triggerFileInput() {
			this.$refs.fileInput.click();
		},
		handleFile($event) {
			this.fileData = [];
			const file = $event?.target?.files?.[0];
			if (file?.type === 'text/csv') {
				const reader = new FileReader();
				reader.onload = () => {
					const parsedRawData = reader.result.split(/\r\n|\n/);
					for (let i = 1; i < parsedRawData.length; i++) {
						if (parsedRawData[i]) {
							const rowdata = parsedRawData[i].split(';');
							for (let i in rowdata) {
								rowdata[i] = rowdata[i].trim();
							}
							this.fileData.push(rowdata);
						}
					}
					this.buildDataFromCSV();
				};
				reader.readAsText(file);
			} else {
				this.resetData();
				this.$puiNotify.error(this.$puiI18n.t('modal.importCSV.notValidFile'));
			}
		},
		/**
		 * Main building method
		 */
		async buildDataFromCSV() {
			this.builtData = ImportCSV.buildDocument(this.fileData, this.templateDocument);
			this.builtData = ImportCSV.buildJointServices(this.builtData, this.fileData, this.templateJS);
			this.buildData = ImportCSV.applySystemJointService(this.jointservice, this.builtData);
			this.builtData = ImportCSV.buildBillofLoadings(this.builtData, this.fileData, this.templateBL);
			this.builtData = ImportCSV.buildGoods(this.builtData, this.fileData, this.templateGood);
			this.builtData = ImportCSV.buildUtis(this.builtData, this.fileData, this.templateUTI);
			this.builtData = ImportCSV.buildDocs(this.builtData, this.fileData, this.templateDOC);
			this.builtData = await ImportCSV.retrieveDocumentAdditionalData(this, this.builtData);
			this.builtData = await ImportCSV.retrieveJointServiceAdditionalData(this, this.builtData);
			this.builtData = await ImportCSV.retrieveBillofLoadingAdditionalData(this, this.builtData);
			this.builtData = await ImportCSV.retrieveGoodsAdditionalData(this, this.builtData);
			this.builtData = await ImportCSV.retrieveUtisAdditionalData(this, this.builtData);
			this.builtData = await ImportCSV.retrieveDocsAdditionalData(this, this.builtData);
			await this.validateBuiltData();
		},
		async validateBuiltData() {
			try {
				// Validate Document
				this.builtData = await ImportCSV.validateDocument(this, this.builtData, this.companyLegalCode, this.jointservice);

				// Validate Joint Services
				this.builtData = await ImportCSV.validateJointServices(this, this.builtData, this.companyLegalCode);

				// Validate Bill of ladings
				this.builtData = await ImportCSV.validateBillofLoadings(this, this.builtData);

				// Validate Goods
				this.builtData = await ImportCSV.validateGoods(this, this.builtData);

				// Validate Utis
				this.builtData = await ImportCSV.validateUtis(this, this.builtData);

				// Validate Docs
				this.builtData = await ImportCSV.validateDocs(this, this.builtData);
			} catch (ex) {
				this.resetData();
				this.$puiNotify.error(this.$puiI18n.t(ex.notification));
			} finally {
				this.processingCSV = false;
			}
		},
		resetData() {
			this.fileData = [];
			this.builtData = null;
		},
		close() {
			this.$store.commit('M__JSIMPORTCSV_MODAL', { open: false, jointservice: false });
			return new Promise((resolve) => {
				resolve(true);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
		},
		getUtisNumber(B) {
			let n = 0;
			if (B.bltype_code === 'ZZ3') {
				n = B.utis.length;
			} else {
				B.goods.forEach((i) => {
					n += i.utis.length;
				});
			}
			return n;
		},
		getDocsNumber(B) {
			let n = 0;
			B.goods.forEach((i) => {
				n += i.docs.length;
			});
			return n;
		},
		async accept() {
			this.builtData.message = null;
			this.loading = 'modal.importCSV.saving';
			const response = await ImportCSV.sendJS(this, this.builtData.document.jointservices[0]);
			this.loading = false;
			if (response) {
				this.$puiNotify.success(this.$puiI18n.t('modal.importCSV.success'));
				this.close();
			}
		}
	},
	computed: {
		...mapState({
			companyLegalCode: (state) => state.session.properties.GESTAUT_PROPERTIES_COMPANY_ID_CODE
		}),
		headerData() {
			return [
				{ label: 'header.sdsmjointservice.documento', value: this.jointservice?.documentnumber },
				{ label: 'sdsmjointservice.jointservicenumber', value: this.jointservice?.jointservicenumber },
				{ label: 'sdsmjointservice.operator', value: this.jointservice?.operator, showId: true, id: this.jointservice?.operatorid },
				{ label: 'header.sdsmjointservice.shipname', value: this.jointservice?.shipname },
				{ label: 'header.sdsmjointservice.tramstate', value: this.jointservice?.state, pill: true }
			];
		}
	},
	async created() {
		this.loading = 'modal.importCSV.preparing';
		this.templateDocument = await SdsmDocumentUtils.newDOC(this);
		this.templateJS = await SdsmjointserviceUtils.newJS(this);
		this.templateBL = await SdsmbillofloadingUtils.newBillOfLoading(this);
		this.templateGood = await SdsmGoodUtils.newGood(this);
		this.templateUTI = await SdsmUtiUtils.newUTI(this);
		this.templateDOC = await SdsmGoodUtils.newDOC(this);
		this.loading = false;
	}
};
</script>

<style scoped>
#ImportCSVDOCMessage {
	padding: 20px;
	text-align: center;
}
#ImportCSVJS .v-alert {
	font-size: 1em;
}
#ImportCSVJS .v-alert .v-alert__wrapper .warning--text {
	font-size: 1.25em;
}
</style>
