const reactivateEqSize = {
	id: 'reactivateEqSize',
	selectionType: 'single', // [single|multiple|general]
	label: 'action.mequipmentsize.reactivate',
	functionality: 'MT_EQSIZE', // set the functionality if needed
	showInForm: true,
	checkAvailability: function (registries) {
		// Validation to execute action
		if (registries.length > 0) {
			return registries[0].dateexpiration;
		}
		return false;
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-mequipmentsizeReactivateAction-' + model.name + '-show', row);
	}
};
export default {
	gridactions: [reactivateEqSize],
	formactions: [reactivateEqSize]
};
