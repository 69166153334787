const SdsmUtiUtils = {
	async checkBeforeSaving(ctx, eq, seals) {
		if (ctx.$refs.form?.validate && !ctx.$refs.form.validate()) {
			ctx.$puiNotify.error(ctx.$t('pui9.error.form_errors'));
			ctx.resetAfterSaveActions ? ctx.resetAfterSaveActions() : null;
			return false;
		}

		let valid = true;
		// ISO
		if (eq.fullfilliso === '1' && !eq.utiidentification) {
			valid = false;
			ctx.$puiNotify.error(ctx.$t('errors.eqidentification'));
		}

		// Precintos
		if (seals && seals.length > 0) {
			for (let i = 0; i < seals.length; i++) {
				if (!seals[i].sealnumber) {
					valid = false;
					ctx.$puiNotify.error(ctx.$t('errors.eqsealnumber'));
				}
			}
		}
		return valid;
	},
	async getEquipmentByBlid(ctx, blid) {
		let eqs = [];
		if (blid) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmbillofloading',
					rows: null,
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'blid', op: 'eq', data: blid }]
					}
				},
				(response) => {
					eqs = response.data.data;
				},
				() => {}
			);
		}
		return eqs;
	},
	async getDocumentByid(ctx, documentid) {
		let doc = [];
		if (documentid) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmdocument',
					rows: null,
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'documentid', op: 'eq', data: documentid }]
					}
				},
				(response) => {
					doc = response.data.data;
				},
				() => {}
			);
		}

		return doc;
	},
	async getBLByJsid(ctx, jsid) {
		let doc = [];
		if (jsid) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmbillofloading',
					rows: null,
					filter: {
						groups: [
							{
								rules: [
									{ field: 'jointserviceid', op: 'eq', data: jsid },
									{ field: 'bltypeid', op: 'eq', data: 1 }
								]
							},
							{
								rules: [
									{ field: 'jointserviceid', op: 'eq', data: jsid },
									{ field: 'bltypeid', op: 'eq', data: 3 }
								]
							}
						],
						groupOp: 'or',
						rules: []
					}
				},
				(response) => {
					doc = response.data.data;
				},
				() => {}
			);
		}
		return doc;
	},

	async getUtiSeals(ctx, utiid) {
		let seals = [];
		if (utiid) {
			await ctx.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmutiseal',
					rows: null,
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'utiid', op: 'eq', data: utiid }]
					}
				},
				(response) => {
					seals = response.data.data;
					for (let i = 0; i < seals.length; i++) {
						seals[i].isNew = false;
						seals[i].isSelected = false;
						seals[i].isEdited = false;
						seals[i].randomKey = Math.floor(Math.random() * 1000);
					}
				},
				() => {}
			);
		}
		return seals;
	},
	async getEqTypes(ctx) {
		let eqtypes = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'mequipmenttype',
				rows: null
			},
			(response) => {
				eqtypes = response.data.data;
			},
			() => {}
		);
		return eqtypes;
	},
	async getEqSyzes(ctx) {
		let eqsizes = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'mequipmentsize',
				rows: null,
				filter: {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'dateexpiration', op: 'nu' }]
				}
			},
			(response) => {
				eqsizes = response.data.data;
			},
			() => {}
		);
		return eqsizes;
	},
	async getEqStatus(ctx) {
		let eqstatus = [];
		await ctx.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'mutistatus',
				rows: null
			},
			(response) => {
				eqstatus = response.data.data;
			},
			() => {}
		);
		return eqstatus;
	},
	/*************/
	/* Save UTI */
	/***********/
	async saveUTI(ctx, uti, seals, isCreating) {
		let data = null;
		uti.seals = seals;
		let metodo = isCreating ? '/sdsmuti/save' : '/sdsmuti/saveUpd';
		await ctx.$puiRequests.postRequest(
			metodo,
			uti,
			(response) => {
				data = response.data;
				ctx.$puiNotify.success(ctx.$t('general.success'));
			},
			(e) => {
				ctx.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		return data;
	},
	/****************************/
	/* Update UTI from Massive */
	/**************************/
	async massiveupdate(ctx, data, utiid) {
		let updated = false;
		let error = null;
		await ctx.$puiRequests.postRequest(
			'/sdsmuti/massiveupdate',
			{ data, utiid },
			() => {
				updated = true;
			},
			(e) => {
				error = e.response.data.message;
			},
			null,
			null
		);
		return { updated, error };
	},
	/************/
	/* New UTI */
	/**********/
	async newUTI(ctx, data) {
		let eq = null;
		await ctx.$puiRequests.getRequest(
			'/sdsmuti/template',
			data,
			(response) => {
				eq = response.data;
			},
			() => {},
			null,
			null
		);
		return eq;
	},
	/********************/
	// Delete UTI Seal */
	/******************/
	async deleteUTISeal(ctx, data) {
		let deleted = true;
		if (data.sealid) {
			deleted = false;
			const params = {
				sealid: data.sealid
			};
			await ctx.$puiRequests.deleteRequest(
				'/sdsmutiseal/delete',
				null,
				() => {
					deleted = true;
					ctx.$puiNotify.success(ctx.$t('general.remove'));
				},
				(e) => {
					ctx.$puiNotify.error(e.response.data.message);
				},
				params,
				null
			);
		}
		return deleted;
	}
};

export default SdsmUtiUtils;
