import { EntityStatusUtils } from '../../utils/entityStates';

const ImportCSV = {
	id: 'idJointserviceImportCSVAction',
	selectionType: 'single',
	label: 'action.sdsmjointservice.importCSV.title',
	functionality: 'IMP_CSV_JS',
	showInForm: true,
	checkAvailability: function (reg) {
		return reg?.[0] && reg[0].operatorid == this.$store.state.session.properties.GESTAUT_OPERATOR_ID && reg[0].stateid === 1;
	},
	runAction: function (action, model, registries) {
		this.$puiRequests.postRequest(
			'/puisearch',
			{
				model: 'sdsmjointservice',
				filter: {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: 'jointserviceid', op: 'eq', data: registries[0].jointserviceid }]
						}
					],
					groupOp: 'and',
					rules: []
				}
			},
			(response) => {
				this.$store.commit('M__JSIMPORTCSV_MODAL', { open: true, jointservice: response.data.data[0] });
			},
			() => {}
		);
	}
};

const BillOfLoadingMassiveCreate = {
	id: 'idBOLMassiveCreateModal',
	selectionType: 'single',
	label: 'modal.sdsmbillofloading.massivecreate.title',
	functionality: 'MAS_CREA_BL',
	description: 'action.sdsmjointservice.massivecreate.description',
	showInForm: true,
	checkAvailability: function (reg) {
		if (typeof reg[0] === 'undefined') {
			return true;
		}
		return (
			reg[0].operatorid == this.$store.state.session.properties.GESTAUT_OPERATOR_ID &&
			(reg[0].stateid == 1 || reg[0].stateid == 3 || reg[0].stateid == 4 || reg[0].stateid == 5 || reg[0].stateid == 7 || reg[0].stateid == 8)
		);
	},
	runAction: function (action, model, registries) {
		const jointservice = registries[0];
		this.$store.commit('M__MASSIVE_CREATE_MODAL', { open: true, jointservice, billofloading: null });
	}
};

const MassiveUpdate = {
	id: 'idJointserviceMassiveUpdateAction',
	selectionType: 'multiple',
	label: 'action.sdsmjointservice.massiveEditAction.title',
	description: 'action.sdsmjointservice.massiveEditAction.description',
	functionality: 'MAS_UPD_JSERVICE',
	showInForm: true,
	checkAvailability: function (registries) {
		return registries?.operatorid == this.$store.state.session.properties.GESTAUT_OPERATOR_ID;
	},
	runAction: function (action, model, registries) {
		const data = {
			jointservices: registries,
			isAction: true
		};
		this.$puiEvents.$emit('pui-modalDialogForm-idJointserviceMassiveUpdateAction-' + model.name + '-show', data);
	}
};

const ExportCSV = {
	id: 'idJointserviceExportCsvAction',
	selectionType: 'single',
	label: 'action.sdsmjointservice.exportCSVAction.title',
	functionality: 'EXP_CSV_JS',
	//functionality: 'MAS_UPD_JSERVICE',
	showInForm: true,
	checkAvailability: function (registries) {
		return registries?.[0]?.operatorid == this.$store.state.session.properties.GESTAUT_OPERATOR_ID;
	},
	runAction: async function (action, model, registries) {
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		const pk = registries[0].jointserviceid;
		const fechaActual = new Date();

		const dia = fechaActual.getDate(); // Día del mes (1-31)
		const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0'); // Mes (01-12)
		const anio = fechaActual.getFullYear(); // Año (ejemplo: 2024)
		const horas = fechaActual.getHours().toString().padStart(2, '0'); // Mes (01-12)
		const minutos = fechaActual.getMinutes().toString().padStart(2, '0'); // Mes (01-12)
		const segundos = fechaActual.getSeconds().toString().padStart(2, '0'); // Mes (01-12)
		const fechaFormateada = `${dia}${mes}${anio}${horas}${minutos}${segundos}`;

		let docnum;
		if (registries[0].documentnumber) {
			docnum = registries[0].documentnumber;
		} else {
			await this.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmdocument',
					rows: null,
					filter: {
						groups: [
							{
								groupOp: 'and',
								rules: [{ field: 'documentid', op: 'eq', data: registries[0].documentid }]
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(responsee) => {
					if (responsee?.data?.data && responsee.data.data.length > 0) {
						docnum = responsee.data.data[0].documentnumber;
					}
				}
			);
		}

		const nameCsv = 'pcsum_' + docnum + '_' + registries[0].jointservicenumber + '_' + fechaFormateada + '.csv';

		await this.$puiRequests.getRequest(
			'/sdsmjointservice/exportCSVJS',
			{ pk },
			(response) => {
				const blob = new Blob([response.data], { type: 'application/octet-stream' });

				// Crea una URL para el Blob
				const url = window.URL.createObjectURL(blob);

				// Crea un enlace y configúralo para descargar automáticamente
				const link = document.createElement('a');
				link.href = url;
				link.download = nameCsv; // Nombre del archivo que se descargará

				// Simula un clic en el enlace para iniciar la descarga
				link.click();

				// Libera la URL creada
				window.URL.revokeObjectURL(url);

				this.$puiNotify.success(this.$t('general.exportsucces'));
			},
			(e) => {
				this.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
	}
};

const JointserviceSend = {
	id: 'JointserviceSendAction',
	selectionType: 'single',
	label: 'action.sdsmjointservice.jointserviceSendAction.title',
	functionality: 'SEND_JSERVICE',
	//functionality: null,
	showInForm: true,
	checkAvailability: function (reg) {
		return (
			reg?.[0] &&
			reg[0].operatorid == this.$store.state.session.properties.GESTAUT_OPERATOR_ID &&
			EntityStatusUtils.checkSendAvailable(reg[0].stateid)
		);
	},
	runAction: async function (action, model, registries) {
		// Get PK for the header
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		await this.$puiRequests.postRequest(
			'/sdsmjointservice/send',
			registries,
			() => {
				this.$puiNotify.success(this.$t('general.sendsuccess'));
			},
			(e) => {
				this.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
	}
};

const JointserviceUndoDelete = {
	id: 'JointserviceUndoDelete',
	selectionType: 'single',
	label: 'action.sdsmjointservice.JointserviceUndoDeleteAction.title',
	functionality: 'UNDO_DEL_JS',
	//functionality: null,
	showInForm: true,
	checkAvailability: function (reg) {
		return reg?.[0]?.stateid == 9;
	},
	runAction: async function (action, model, registries) {
		// Get PK for the header
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		await this.$puiRequests.postRequest(
			'/sdsmjointservice/undoDel',
			registries[0].jointserviceid,
			() => {
				this.$puiNotify.success(this.$t('general.success'));
			},
			(e) => {
				this.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
	}
};

export default {
	gridactions: [BillOfLoadingMassiveCreate, MassiveUpdate, JointserviceSend, JointserviceUndoDelete, ExportCSV, ImportCSV /* , formGridModal */],
	formactions: [JointserviceSend, JointserviceUndoDelete, ExportCSV, ImportCSV /*, formGridModal */]
};
