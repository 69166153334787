<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<mequipmentsize-modals :modelName="modelName"></mequipmentsize-modals>
	</div>
</template>

<script>
import mequipmentsizeActions from './MequipmentsizeActions';
import mequipmentsizeModals from './MequipmentsizeModals.vue';

export default {
	name: 'mequipmentsize-grid',
	components: {
		'mequipmentsize-modals': mequipmentsizeModals
	},
	data() {
		return {
			modelName: 'mequipmentsize',
			actions: mequipmentsizeActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
