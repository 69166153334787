import Vue from 'vue';

//GRIDS
import SdsmdocumentGrid from '@/components/sdsmdocument/SdsmdocumentGrid.vue';
import SdsmgoodGrid from '@/components/sdsmgood/SdsmgoodGrid';
import SdsmjointserviceGrid from '@/components/sdsmjointservice/SdsmjointserviceGrid';
import SdsmbillofloadingGrid from '@/components/sdsmbillofloading/SdsmbillofloadingGrid';
import SdsmutiGrid from '@/components/sdsmuti/SdsmutiGrid';
import SdsmmessageGrid from '@/components/sdsmmessage/SdsmmessageGrid';
import VutmessagedocGrid from '@/components/vutmessagedoc/VutmessagedocGrid';
import VutmessagejsGrid from '@/components/vutmessagejs/VutmessagejsGrid';
import VutmessageblGrid from '@/components/vutmessagebl/VutmessageblGrid';
import VutmessagegoodGrid from '@/components/vutmessagegood/VutmessagegoodGrid';
import VutmessageutiGrid from '@/components/vutmessageuti/VutmessageutiGrid';
import SdsmmessageelementGrid from '@/components/sdsmmessageelement/SdsmmessageelementGrid';
import SdsmmessageauditGrid from '@/components/sdsmmessageaudit/SdsmmessageauditGrid';
import SdsmmessageerrorGrid from '@/components/sdsmmessageerror/SdsmmessageerrorGrid';

//FORMS
import SdsmdocumentForm from '@/components/sdsmdocument/SdsmdocumentForm.vue';
import SdsmgoodForm from '@/components/sdsmgood/SdsmgoodForm';
import SdsmjointserviceForm from '@/components/sdsmjointservice/SdsmjointserviceForm';
import SdsmbillofloadingForm from '@/components/sdsmbillofloading/SdsmbillofloadingForm';
import SdsmutiForm from '@/components/sdsmuti/SdsmutiForm';
import SdsmmessageForm from '@/components/sdsmmessage/SdsmmessageForm';

//HEADERS
import SdsmgoodFormHeader from '@/components/sdsmgood/SdsmgoodFormHeader';
import SdsmjointserviceFormHeader from '@/components/sdsmjointservice/SdsmjointserviceFormHeader';
import SdsmbillofloadingFormHeader from '@/components/sdsmbillofloading/SdsmbillofloadingFormHeader';
import SdsmutiFormHeader from '@/components/sdsmuti/SdsmutiFormHeader';
import SdsmmessageFormHeader from '@/components/sdsmmessage/SdsmmessageFormHeader';

//GRIDS
Vue.component(SdsmdocumentGrid.name, SdsmdocumentGrid);
Vue.component(SdsmgoodGrid.name, SdsmgoodGrid);
Vue.component(SdsmjointserviceGrid.name, SdsmjointserviceGrid);
Vue.component(SdsmbillofloadingGrid.name, SdsmbillofloadingGrid);
Vue.component(SdsmutiGrid.name, SdsmutiGrid);
Vue.component(SdsmmessageGrid.name, SdsmmessageGrid);
Vue.component(VutmessagedocGrid.name, VutmessagedocGrid);
Vue.component(VutmessagejsGrid.name, VutmessagejsGrid);
Vue.component(VutmessageblGrid.name, VutmessageblGrid);
Vue.component(VutmessagegoodGrid.name, VutmessagegoodGrid);
Vue.component(VutmessageutiGrid.name, VutmessageutiGrid);
Vue.component(SdsmmessageelementGrid.name, SdsmmessageelementGrid);
Vue.component(SdsmmessageauditGrid.name, SdsmmessageauditGrid);
Vue.component(SdsmmessageerrorGrid.name, SdsmmessageerrorGrid);

//FORMS
Vue.component(SdsmdocumentForm.name, SdsmdocumentForm);
Vue.component(SdsmgoodForm.name, SdsmgoodForm);
Vue.component(SdsmjointserviceForm.name, SdsmjointserviceForm);
Vue.component(SdsmbillofloadingForm.name, SdsmbillofloadingForm);
Vue.component(SdsmutiForm.name, SdsmutiForm);
Vue.component(SdsmmessageForm.name, SdsmmessageForm);

//HEADERS
Vue.component(SdsmgoodFormHeader.name, SdsmgoodFormHeader);
Vue.component(SdsmjointserviceFormHeader.name, SdsmjointserviceFormHeader);
Vue.component(SdsmbillofloadingFormHeader.name, SdsmbillofloadingFormHeader);
Vue.component(SdsmutiFormHeader.name, SdsmutiFormHeader);
Vue.component(SdsmmessageFormHeader.name, SdsmmessageFormHeader);

const declaracionesRouter = [
	{
		path: 'sdsmdocument',
		component: SdsmdocumentGrid
	},
	{
		path: '(.*)sdsmdocument/:method/:pk',
		props: true,
		component: SdsmdocumentForm
	},
	{
		path: 'sdsmgood',
		component: SdsmgoodGrid
	},
	{
		path: '(.*)sdsmjointservice/:method/:parentpk/sdsmgood/:method/:pk',
		props: true,
		component: SdsmgoodForm
	},
	{
		path: '(.*)sdsmbillofloading/:method/:parentpk/sdsmgood/:method/:pk',
		props: true,
		component: SdsmgoodForm
	},
	{
		path: '(.*)sdsmdocument/:method/:parentpk/sdsmgood/:method/:pk',
		props: true,
		component: SdsmgoodForm
	},
	{
		path: '(.*)sdsmgood/:method/:pk',
		props: true,
		component: SdsmgoodForm
	},
	{
		path: 'sdsmjointservice',
		component: SdsmjointserviceGrid
	},
	{
		path: '(.*)sdsmdocument/:method/:parentpk/sdsmjointservice/:method/:pk',
		props: true,
		component: SdsmjointserviceForm
	},
	{
		path: '(.*)sdsmjointservice/:method/:pk',
		props: true,
		component: SdsmjointserviceForm
	},
	{
		path: 'sdsmbillofloading',
		component: SdsmbillofloadingGrid
	},
	{
		path: '(.*)sdsmdocument/:method/:parentpk/sdsmbillofloading/:method/:pk',
		props: true,
		component: SdsmbillofloadingForm
	},
	{
		path: '(.*)sdsmjointservice/:method/:parentpk/sdsmbillofloading/:method/:pk',
		props: true,
		component: SdsmbillofloadingForm
	},
	{
		path: '(.*)sdsmbillofloading/:method/:pk',
		props: true,
		component: SdsmbillofloadingForm
	},
	{
		path: 'sdsmuti',
		component: SdsmutiGrid
	},
	{
		path: '(.*)sdsmjointservice/:method/:parentpk/sdsmuti/:method/:pk',
		props: true,
		component: SdsmutiForm
	},
	{
		path: '(.*)sdsmbillofloading/:method/:parentpk/sdsmuti/:method/:pk',
		props: true,
		component: SdsmutiForm
	},
	{
		path: '(.*)sdsmdocument/:method/:parentpk/sdsmuti/:method/:pk',
		props: true,
		component: SdsmutiForm
	},
	{
		path: '(.*)sdsmuti/:method/:pk',
		props: true,
		component: SdsmutiForm
	},
	{
		path: 'sdsmmessage',
		component: SdsmmessageGrid
	},
	{
		path: '(.*)sdsmmessage/:method/:pk',
		props: true,
		component: SdsmmessageForm
	},
	{
		path: '(.*)sdsmjointservice/:method/:parentpk/sdsmmessage/:method/:pk',
		props: true,
		component: SdsmmessageForm
	},
	{
		path: '(.*)sdsmbillofloading/:method/:parentpk/sdsmmessage/:method/:pk',
		props: true,
		component: SdsmmessageForm
	},
	{
		path: '(.*)sdsmdocument/:method/:parentpk/sdsmmessage/:method/:pk',
		props: true,
		component: SdsmmessageForm
	},
	{
		path: '(.*)sdsmuti/:method/:parentpk/sdsmmessage/:method/:pk',
		props: true,
		component: SdsmmessageForm
	},
	{
		path: '(.*)sdsmgood/:method/:parentpk/sdsmmessage/:method/:pk',
		props: true,
		component: SdsmmessageForm
	},
	{
		path: '(.*)sdsmdocument/:method/:parentpk/vutmessagedoc/:method/:pk',
		props: true,
		component: SdsmmessageForm
	},
	{
		path: '(.*)sdsmjointservice/:method/:parentpk/vutmessagejs/:method/:pk',
		props: true,
		component: SdsmmessageForm
	},
	{
		path: '(.*)sdsmbillofloading/:method/:parentpk/vutmessagebl/:method/:pk',
		props: true,
		component: SdsmmessageForm
	},
	{
		path: '(.*)sdsmgood/:method/:parentpk/vutmessagegood/:method/:pk',
		props: true,
		component: SdsmmessageForm
	},
	{
		path: '(.*)sdsmuti/:method/:parentpk/vutmessageuti/:method/:pk',
		props: true,
		component: SdsmmessageForm
	},
	{
		path: 'sdsmmessageelement',
		component: SdsmmessageelementGrid
	}
];

export default {
	routes: declaracionesRouter
};
