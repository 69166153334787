<template>
	<div class="pui-form">
		<sdsmbillofloading-modals :modelName="modelName"></sdsmbillofloading-modals>
		<sdsmbillofloading-form-header
			v-if="modelLoaded && headerData"
			:data="headerData"
			:params="{ isCreatingElement }"
			:alerts="alerts"
		></sdsmbillofloading-form-header>

		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-tabs v-model="tabmodel" class="ml-4 mb-4 mr-4 mt-4" slider-color="primary" show-arrows>
					<v-tab :key="0" :href="'#maintab'">{{ $t('form.sdsmbillofloading.formtitle') }}</v-tab>
					<v-tab :key="1" :href="'#sdsmuti'" v-if="!isCreatingElement && !isPassengers">{{ $t('form.sdsmbillofloading.sdsmuti') }}</v-tab>
					<v-tab :key="2" :href="'#sdsmgoods'" v-if="!isCreatingElement && !isEmpty">{{ $t('form.sdsmbillofloading.sdsmgood') }}</v-tab>
					<v-tab :key="3" :href="'#vutmessagebl'" v-if="!isCreatingElement">{{ $t('form.sdsmmessage.formtitle') }}</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tabmodel" style="width: 100% !important">
					<v-tab-item :key="0" :value="'maintab'">
						<v-col cols="12">
							<pui-field-set :title="$t('sdsmbillofloading.billofloading')">
								<v-row dense>
									<v-col cols="12 col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
										<pui-select
											:id="`jointserviceid-billofloading`"
											:attach="`blid-sdsmgood`"
											:label="$t('sdsmbillofloading.jointservicenumber')"
											:disabled="jointidDisabled || formDisabled || !isCreatingElement"
											toplabel
											modelName="sdsmjointservice"
											v-model="model"
											reactive
											clearable
											:itemsToSelect="[{ jointserviceid: this.model.jointserviceid }]"
											:modelFormMapping="{ jointserviceid: 'jointserviceid' }"
											:loading="loadingJS"
											:itemValue="['jointserviceid']"
											:itemTemplate="true"
											:itemText="''"
											singleItemAutoselect
											:selectTemplate="true"
											:fixedFilter="filteredJSID"
											:queryFields="['jointserviceid', 'documentnumber', 'jointservicenumber', 'operator', 'callcode']"
											:order="{ jointserviceid: 'desc' }"
										>
											<template v-slot:selectionTemplate="{ props, item }">
												<div class="SlotOptionWithDetails" v-bind="props">
													{{ $t('sdsmbillofloading.jointserviceid') }}:
													<span class="value">{{ item.jointserviceid || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmjointservice.document') }}:
													<span class="value">{{ item.documentnumber || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmbillofloading.jointservicenumber') }}:
													<span class="value">{{ item.jointservicenumber || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmbillofloading.cargooperator') }}:
													<span class="value">{{ item.operator || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmbillofloading.callcode') }}:
													<span class="separator">:</span>
													<span class="value">{{ item.callcode || '-' }} </span>
												</div>
											</template>
											<template v-slot:theTemplate="{ props, item }">
												<div class="SlotOptionWithDetails" v-bind="props">
													{{ $t('sdsmbillofloading.jointserviceid') }}:
													<span class="value">{{ item.jointserviceid || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmjointservice.document') }}:
													<span class="value">{{ item.documentnumber || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmbillofloading.jointservicenumber') }}:
													<span class="value">{{ item.jointservicenumber || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmbillofloading.cargooperator') }}:
													<span class="value">{{ item.operator || '-' }}</span>
													<span class="separator">/</span>

													{{ $t('sdsmbillofloading.callcode') }}:
													<span class="separator">:</span>
													<span class="value">{{ item.callcode || '-' }} </span>
												</div>
											</template>
										</pui-select>
									</v-col>
									<v-col class="12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
										<pui-text-field
											:id="`blnumber-sdsmbillofloading`"
											v-model="model.blnumber"
											:label="$t('sdsmbillofloading.blnumber')"
											:disabled="disableByDoc() || formDisabled"
											:required="isCargo"
											toplabel
										></pui-text-field>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pl-2 pr-2">
										<label class="customLabel v-label--required">{{ $t('sdsmbillofloading.origindes') }}</label>
										<v-autocomplete
											class="customSelector"
											outlined
											dense
											v-model="model.origincode"
											ref="origincodeRef"
											:loading="loading"
											:items="locinfo"
											:disabled="formDisabled"
											clearable
											:readonly="originSelected"
											:rules="[(v) => !!v || $t('requiredMessage')]"
											item-value="locode"
											:item-text="(item) => `${item.locode} - ${item.descripcion}`"
											@keyup="selectIfOnlyOneOptionorigincode"
										>
											<template v-slot:selection="{ props, item }">
												<div class="BasicSlotOption" v-bind="props">
													<span> {{ item?.locode }} </span>
													<span> {{ item?.descripcion }} </span>
												</div>
											</template>
											<template v-slot:item="{ props, item }">
												<div class="BasicSlotOption" v-bind="props">
													<span> {{ item?.locode }} </span>
													<span> {{ item?.descripcion }} </span>
												</div>
											</template>
										</v-autocomplete>
									</v-col>
									<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pl-2 pr-2">
										<label class="customLabel v-label--required">{{ $t('sdsmbillofloading.loaddes') }}</label>
										<v-autocomplete
											class="customSelector"
											outlined
											dense
											validate-on-blur
											v-model="model.loadcode"
											ref="loadcodeRef"
											:loading="loading"
											:items="locinfo"
											:disabled="formDisabled"
											:readonly="loadcodeSelected"
											clearable
											:rules="[(v) => !!v || $t('requiredMessage')]"
											item-value="locode"
											:item-text="model.loadcode ? `''` : (item) => `${item.locode} - ${item.descripcion}`"
											@keyup="selectIfOnlyOneOptionloadcode"
										>
											<template v-slot:selection="{ props, item }">
												<div class="BasicSlotOption" v-bind="props">
													<span> {{ item?.locode }} </span>
													<span> {{ item?.descripcion }} </span>
												</div>
											</template>
											<template v-slot:item="{ props, item }">
												<div class="BasicSlotOption" v-bind="props">
													<span> {{ item?.locode }} </span>
													<span> {{ item?.descripcion }} </span>
												</div>
											</template>
										</v-autocomplete>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pl-2 pr-2">
										<label class="customLabel">{{ $t('sdsmbillofloading.transhipmentdes') }}</label>
										<v-autocomplete
											class="customSelector"
											outlined
											dense
											v-model="model.transhipmentcode"
											:loading="loading"
											:items="locinfo"
											:disabled="formDisabled"
											:readonly="transhipmentcodeSelected"
											clearable
											validate-on-blur
											item-value="locode"
											:item-text="model.transhipmentcode ? `''` : (item) => `${item.locode} - ${item.descripcion}`"
											ref="transhipmentcode"
											@keyup="selectIfOnlyOneOptiontranshipmentcode"
										>
											<template v-slot:selection="{ props, item }">
												<div class="BasicSlotOption" v-bind="props">
													<span> {{ item?.locode }} </span>
													<span> {{ item?.descripcion }} </span>
												</div>
											</template>
											<template v-slot:item="{ props, item }">
												<div class="BasicSlotOption" v-bind="props">
													<span> {{ item?.locode }} </span>
													<span> {{ item?.descripcion }} </span>
												</div>
											</template>
										</v-autocomplete>
									</v-col>
									<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 pl-2 pr-2">
										<label class="customLabel v-label--required">{{ $t('sdsmbillofloading.destinationdes') }}</label>
										<v-autocomplete
											class="customSelector"
											outlined
											dense
											v-model="model.destinationcode"
											:loading="loading"
											:items="locinfo"
											:disabled="formDisabled"
											clearable
											:readonly="destinationcodeSelected"
											:rules="[(v) => !!v || $t('requiredMessage')]"
											item-value="locode"
											:item-text="(item) => `${item.locode} - ${item.descripcion}`"
											ref="destinationcode"
											@keyup="selectIfOnlyOneOptiondestinationcode"
										>
											<template v-slot:selection="{ props, item }">
												<div class="BasicSlotOption" v-bind="props">
													<span> {{ item?.locode }} </span>
													<span> {{ item?.descripcion }} </span>
												</div>
											</template>
											<template v-slot:item="{ props, item }">
												<div class="BasicSlotOption" v-bind="props">
													<span> {{ item?.locode }} </span>
													<span> {{ item?.descripcion }} </span>
												</div>
											</template>
										</v-autocomplete>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
										<pui-select
											id="`bltypeid-sdsmbillofloading`"
											attach="bltypeid-sdsmbillofloading"
											:label="$t('sdsmbillofloading.bltypeid')"
											toplabel
											clearable
											required
											reactive
											:disabled="!isCreatingElement || formDisabled"
											v-model="model"
											modelName="vutbillofloadingtype"
											:modelFormMapping="{ bltypeid: 'bltypeid' }"
											:itemsToSelect="billtypeItemsToSelect"
											:itemValue="'bltypeid'"
											:queryFields="['description']"
											:itemText="(item) => `${item.description}`"
											:order="{ documentid: 'asc' }"
											detailComponentName="vutbillofloadingtype-form"
											singleItemAutoselect
										></pui-select>
									</v-col>
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
										<pui-select
											id="`goodtypeid-vutgoodtype`"
											attach="goodtypeid-sdsmbillofloading"
											:label="$t('sdsmbillofloading.goodtypeid')"
											toplabel
											clearable
											required
											reactive
											v-model="model"
											:disabled="formDisabled"
											modelName="vutgoodtype"
											:modelFormMapping="{ goodtypeid: 'goodtypeid' }"
											:itemsToSelect="goodtypeItemsToSelect"
											:itemValue="'goodtypeid'"
											:queryFields="['description']"
											:itemText="(item) => `${item.description}`"
											:order="{ documentid: 'asc' }"
											detailComponentName="vutgoodtype-form"
											singleItemAutoselect
										></pui-select>
									</v-col>
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
										<pui-select
											id="`frontreartransid-mfrontreartransport`"
											attach="frontreartransid-sdsmbillofloading"
											:label="$t('sdsmbillofloading.frontreartransid')"
											toplabel
											clearable
											required
											reactive
											:disabled="formDisabled"
											v-model="model"
											modelName="vutmfrontreartransporttra"
											:modelFormMapping="{ frontreartransid: 'frontreartransid' }"
											:itemsToSelect="frontreartransidItemsToSelect"
											:itemValue="'frontreartransid'"
											:queryFields="['description']"
											:itemText="(item) => `${item.description}`"
											:order="{ documentid: 'asc' }"
											detailComponentName="mfrontreartransport-form"
											:fixedFilter="FilterTransport"
											singleItemAutoselect
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
										<pui-select
											id="`stevedoreid-sdsmstevedore`"
											attach="stevedoreid-sdsmbillofloading"
											:label="$t('sdsmbillofloading.stevedoreid')"
											toplabel
											clearable
											v-model="model"
											reactive
											:disabled="formDisabled"
											modelName="sdsmstevedore"
											:modelFormMapping="{ stevedoreid: 'stevedoreid' }"
											:itemsToSelect="stevedoreItemsToSelect"
											:itemValue="'stevedoreid'"
											:queryFields="['description']"
											:itemText="(item) => `${item.description}`"
											:order="{ documentid: 'asc' }"
											detailComponentName="sdsmstevedore-form"
											:fixedFilter="FilterStevedoreid"
											singleItemAutoselect
										></pui-select>
									</v-col>
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
										<pui-select
											id="`dockid-sdsmjointservice`"
											attach="dockid-sdsmjointservice"
											:label="$t('sdsmbillofloading.dockid')"
											toplabel
											clearable
											reactive
											:disabled="disableByDoc() || formDisabled"
											v-model="model"
											modelName="mdock"
											:modelFormMapping="{ dockid: 'dockid' }"
											:itemsToSelect="dockItemsToSelect"
											:itemValue="'dockid'"
											:queryFields="['description']"
											:itemText="(item) => `${item.description}`"
											:order="{ dockid: 'asc' }"
											:fixedFilter="FilterDockid"
											singleItemAutoselect
										></pui-select>
									</v-col>
									<!-- COMCODE -->
									<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
										<pui-select
											id="`docklocationid-sdsmjointservice`"
											attach="docklocationid-sdsmjointservice"
											:label="$t('sdsmbillofloading.docklocationid')"
											toplabel
											clearable
											reactive
											:required="dockSelected"
											:disabled="disableByDoc() || formDisabled"
											v-model="model"
											modelName="mdockdocklocation"
											:modelFormMapping="{ docklocationid: 'docklocationid' }"
											:itemsToSelect="docklocationItemsToSelect"
											:itemValue="['docklocationid']"
											:queryFields="['docklocation']"
											:itemText="(item) => `${item.docklocation}`"
											:order="{ docklocationid: 'asc' }"
											:fixedFilter="fixedFilterDockLocation"
											singleItemAutoselect
										></pui-select>
									</v-col>
								</v-row>
								<v-row dense>
									<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`goodservice-sdsmbillofloading`"
											v-model="model.goodservice"
											:label="$t('sdsmbillofloading.goodservice')"
											:disabled="formDisabled"
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-col>
									<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`comcode-sdsmbillofloading`"
											v-model="model.comcode"
											:label="$t('sdsmbillofloading.comcode')"
											:disabled="formDisabled"
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-col>
									<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`shipimo-sdsmbillofloading`"
											v-model="model.shipimo"
											:label="$t('sdsmbillofloading.shipimo')"
											:disabled="formDisabled"
											:required="shipmoR"
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-col>
									<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`manipulationterminal-sdsmbillofloading`"
											v-model="model.manipulationterminal"
											:label="$t('sdsmbillofloading.manipulationterminal')"
											:disabled="formDisabled"
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>
							<pui-field-set :title="$t('sdsmbillofloading.table')">
								<v-row dense>
									<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`dsmcfrontrear-sdsmbillofloading`"
											v-model="model.dsmcfrontrear"
											:label="$t('sdsmbillofloading.dsmcfrontrear')"
											:disabled="formDisabled"
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-col>
									<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
										<pui-text-field
											:id="`ordernumberfrontrear-sdsmbillofloading`"
											v-model="model.ordernumberfrontrear"
											:label="$t('sdsmbillofloading.ordernumberfrontrear')"
											:disabled="formDisabled"
											toplabel
											maxlength="35"
										></pui-text-field>
									</v-col>
								</v-row>
							</pui-field-set>
							<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
						</v-col>
					</v-tab-item>
					<v-tab-item :key="1" :value="'sdsmuti'">
						<pui-master-detail
							componentName="sdsmuti-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ blid: 'blid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
						></pui-master-detail>
					</v-tab-item>
					<v-tab-item :key="2" :value="'sdsmgoods'">
						<pui-master-detail
							componentName="sdsmgood-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ blid: 'blid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
						></pui-master-detail
					></v-tab-item>
					<v-tab-item :key="3" :value="'vutmessagebl'">
						<pui-master-detail
							componentName="vutmessagebl-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ blid: 'blid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
						></pui-master-detail
					></v-tab-item>
				</v-tabs-items>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveBLAndNew"
					:saveAndUpdate="saveBLAndUpdate"
					:save="saveBLAndBack"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import sdsmbillofloadingActions from './SdsmbillofloadingActions';
import sdsmbillofloadingModals from './SdsmbillofloadingModals.vue';
import formUtils from '@/utils/form';
import filters from '@/utils/filters';
import SdsmbillofloadingUtils from './SdsmbillofloadingUtils';
import { mapState } from 'vuex';
import { EntityStatusUtils } from '../../utils/entityStates';
export default {
	name: 'sdsmbillofloading-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'sdsmbillofloading-modals': sdsmbillofloadingModals
	},
	props: {
		modelFromModal: { type: Object, default: () => {} }
	},
	data() {
		return {
			loading: false,
			loadingJS: false,
			modelName: 'sdsmbillofloading',
			actions: sdsmbillofloadingActions.formactions,
			documentSelected: '',
			originSelected: false,
			loadcodeSelected: false,
			transhipmentcodeSelected: false,
			destinationcodeSelected: false,
			GoodsFromDoc: null,
			dockSelected: false,
			EQsState: null,
			EQsFromDoc: null,
			jointidDisabled: false,
			jointidkey: 0,
			CNFromDoc: null,
			shipmoR: false,
			cargoOp: false,
			shipOp: false,
			loadcodeVar: false,
			headerData: null,
			blnumTemp: [],
			jointservices: [],
			orderNumber: '',
			tabmodel: 'tab',
			search: null,
			alerts: null,
			filters: {
				stevedoreid: '',
				documentid: '',
				jointserviceid: ''
			}
		};
	},
	methods: {
		async afterGetData() {
			formUtils.updateMiniAudit(this.model);
			this.formDisabled = !EntityStatusUtils.checkEditionAvailable(this.model.blstateid);
			this.loading = true;
			await SdsmbillofloadingUtils.getLocInfo(this);
			this.$watch('model.origincode', this.WatchOriginCode);
			this.$watch('model.loadcode', this.WatchLoadCode);
			this.$watch('model.transhipmentcode', this.WatchTranshipmentCode);
			this.$watch('model.destinationcode', this.WatchDestinationCode);
			this.loading = false;

			this.filters.stevedoreid = this.model.stevedoreid;

			if (!this.isCreatingElement) {
				this.AUTMod();
				this.shipmoRequired();
				this.consignatarioClass = '12 col-xs-12 col-sm-8 col-md-8 col-lg-8 col-xl-8';
				this.alerts = await SdsmbillofloadingUtils.getAlerts(this, this.model.blid);
			}
		},
		async AUTMod() {
			if (this.operatorId) {
				if (this.operatorId == this.model.cargooperatorid || this.model.stateid != 1) {
					this.cargoOp = true;
				} else if (this.operatorId == this.model.shipoperatorid) {
					this.shipOp = true;
					this.formDisabled = true;
				}
			}
		},
		mainSelectorEvent(event) {
			if (event.key == 'Backspace' && this.model.jointserviceid != null) {
				this.model.jointserviceid = null;
			}
		},
		selectIfOnlyOneOptionorigincode(event) {
			if (event.key == 'Backspace' && this.model.origincode != null) {
				this.model.origincode = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.locinfo.filter((item) => {
				return item.locode.toLowerCase().includes(text.toLowerCase());
			});
			if (resultadosFiltrados.length == 1) {
				this.model.origincode = resultadosFiltrados[0].locode;

				this.$refs.origincodeRef.isMenuActive = false;
			}
		},
		selectIfOnlyOneOptionloadcode(event) {
			if (event.key == 'Backspace' && this.model.loadcode != null) {
				this.model.loadcode = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.locinfo.filter((item) => {
				return item.locode.toLowerCase().includes(text.toLowerCase());
			});
			if (resultadosFiltrados.length == 1) {
				this.model.loadcode = resultadosFiltrados[0].locode;

				this.$refs.loadcodeRef.isMenuActive = false;
			}
		},
		selectIfOnlyOneOptiontranshipmentcode(event) {
			if (event.key == 'Backspace' && this.model.transhipmentcode != null) {
				this.model.transhipmentcode = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.locinfo.filter((item) => {
				return item.locode.toLowerCase().includes(text.toLowerCase());
			});
			if (resultadosFiltrados.length == 1) {
				this.model.transhipmentcode = resultadosFiltrados[0].locode;

				this.$refs.transhipmentcode.isMenuActive = false;
			}
		},
		selectIfOnlyOneOptiondestinationcode(event) {
			if (event.key == 'Backspace' && this.model.destinationcode != null) {
				this.model.destinationcode = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.locinfo.filter((item) => {
				return item.locode.toLowerCase().includes(text.toLowerCase());
			});
			if (resultadosFiltrados.length == 1) {
				this.model.destinationcode = resultadosFiltrados[0].locode;

				this.$refs.destinationcode.isMenuActive = false;
			}
		},
		WatchOriginCode(newValue) {
			this.model.origindes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo);
		},
		WatchTranshipmentCode(newValue) {
			this.model.transhipmentdes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo);
		},
		WatchLoadCode(newValue) {
			this.model.loaddes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo);
		},
		WatchDestinationCode(newValue) {
			this.model.destinationdes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo);
		},
		disableByDoc() {
			return false;
		},
		async getOrdernumberProv() {
			return await SdsmbillofloadingUtils.getOrdernumberProv(this, this.model.jointserviceid);
		},
		// Save BL and New
		async saveBLAndNew() {
			let data = await this.saveBL();
			if (data) {
				this.refreshRouter();
			}
		},
		// Save BL and Keep editing
		async saveBLAndUpdate() {
			const data = await this.saveBL();
			if (data) {
				if (this.isCreatingElement) {
					this.goToUpdate(data);
				} else {
					this.refreshRouter();
				}
			}
		},
		// Save BL and Back
		async saveBLAndBack() {
			const data = await this.saveBL();
			if (data) {
				this.back();
			}
		},
		// Save BL
		async saveBL() {
			let bl = null;
			this.saving = true;
			bl = await SdsmbillofloadingUtils.save(this, this.model, this.isCreatingElement);
			this.saving = false;
			return bl;
		},
		async shipmoRequired() {
			this.shipmoR = await SdsmbillofloadingUtils.shipmoRequiered(this, this.model);
		}
	},
	watch: {
		'model.dockid'(newQuestion) {
			if (newQuestion != null) {
				this.dockSelected = true;
			} else {
				this.dockSelected = false;
			}
		},
		'model.origincode'(newQuestion) {
			if (newQuestion != null) {
				this.originSelected = true;
			} else {
				this.originSelected = false;
			}
		},
		'model.loadcode'(newQuestion) {
			if (newQuestion != null) {
				this.loadcodeSelected = true;
			} else {
				this.loadcodeSelected = false;
			}
		},
		'model.transhipmentcode'(newQuestion) {
			if (newQuestion != null) {
				this.transhipmentcodeSelected = true;
			} else {
				this.transhipmentcodeSelected = false;
			}
		},
		'model.destinationcode'(newQuestion) {
			if (newQuestion != null) {
				this.destinationcodeSelected = true;
			} else {
				this.destinationcodeSelected = false;
			}
		},

		async 'model.jointserviceid'(newQuestion) {
			this.documentSelected = newQuestion != null;
			this.headerData = null;
			await this.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'vhdbillofloading',
					filter: {
						groups: [
							{
								groupOp: 'and',
								rules: [
									{ field: 'jointserviceid', op: 'eq', data: newQuestion },
									{ field: 'langtra', op: 'eq', data: this.$store.getters.getUserLanguage }
								]
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				(response) => {
					if (response?.data?.data?.length > 0) {
						if (this.isCreatingElement) {
							//no hay joint por creacion
							this.headerData = response.data.data[0];

							this.headerData.blstate = this.headerData.pending;
							this.headerData.blstateid = 1;

							if (this.headerData.blid) {
								let max_value = 0;
								response.data.data.forEach((element) => {
									if (max_value === null || parseInt(element.blordernumber, 10) > max_value) {
										max_value = parseInt(element.blordernumber, 10);
									}
								});

								this.headerData.blordernumber = String(max_value + 1).padStart(4, '0');
							} else {
								this.headerData.blordernumber = '0000';
							}
						} else {
							// puede o no tener el joint asociado
							response.data.data.forEach((element) => {
								if (element.blid == this.model.blid) {
									this.headerData = element;
								}
							});
							//si no ha sido asignado, asignarlo
							if (this.headerData == null) {
								this.headerData = response.data.data[0];
							}
						}
					}
				}
			);
			this.orderNumber = await this.getOrdernumberProv();
		}
	},
	computed: {
		...mapState({
			locinfo: (state) => state.PCS.locinfo,
			operatorId: (state) => state.session.properties.GESTAUT_OPERATOR_ID
		}),
		fixedFilterDockLocation() {
			return filters.getDockDockLockFilter(this.model.dockid);
		},
		filteredJSID() {
			const filter = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'operatorid', op: 'eq', data: this.operatorId }]
			};

			if (!this.$attrs.parentpk && this.isCreatingElement) {
				filter.groups.push({
					groupOp: 'or',
					rules: EntityStatusUtils.editStates.map((stateId) => ({
						field: 'stateid',
						op: 'eq',
						data: stateId
					}))
				});
			}

			if (this.$attrs.parentpk && this.isCreatingElement) {
				const parentpk = JSON.parse(atob(this.$attrs.parentpk));
				if (parentpk.documentid) {
					filter.rules.push({ field: 'documentid', op: 'eq', data: parentpk.documentid });
				}
				if (parentpk.jointserviceid) {
					filter.rules.push({ field: 'jointserviceid', op: 'eq', data: parentpk.jointserviceid });
				}
			}
			return filter;
		},
		FilterStevedoreid() {
			return filters.expirationDatePlus(this.filters.stevedoreid, 'stevedoreid');
		},
		FilterDockid() {
			if (this.headerData) {
				return filters.expirationDateArray([
					{ data: this.filters.dockid, field: 'dockid' },
					{ field: 'portid', data: this.headerData.portid }
				]);
			} else {
				return filters.expirationDatePlus(this.filters.dockid, 'dockid');
			}
		},
		jointserviceItemsToSelect() {
			return [{ jointserviceid: this.model.jointserviceid }];
		},
		origincodeItemsToSelect() {
			return [{ origincode: this.model.origincode }];
		},
		stevedoreItemsToSelect() {
			return [{ stevedoreid: this.model.stevedoreid }];
		},
		billtypeItemsToSelect() {
			return [{ bltypeid: this.model.bltypeid }];
		},
		goodtypeItemsToSelect() {
			return [{ goodtypeid: this.model.goodtypeid }];
		},
		frontreartransidItemsToSelect() {
			return [{ frontreartransid: this.model.frontreartransid }];
		},
		dockItemsToSelect() {
			return [{ dockid: this.model.dockid }];
		},
		docklocationItemsToSelect() {
			return [{ docklocationid: this.model.docklocationid }];
		},
		fixedFilterDock() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'dockid', op: 'eq', data: this.model.dockid },
							{ field: 'dateexpiration', op: 'nu' }
						]
					}
				],
				groupOp: 'and',
				rules: []
			};
		},
		FilterTransport() {
			const filter = {
				groups: [
					{
						rules: [{ field: 'edicode', op: 'eq', data: 'ZZ1' }]
					},
					{
						rules: [{ field: 'edicode', op: 'eq', data: 'ZZ2' }]
					},
					{
						rules: [{ field: 'edicode', op: 'eq', data: 'ZZ6' }]
					}
				],
				groupOp: 'or',
				rules: []
			};
			return this.isPassengers ? filter : null;
		},
		isCargo() {
			return this.model.bltypeid === 1;
		},
		isPassengers() {
			return this.model.bltypeid === 2;
		},
		isEmpty() {
			return this.model.bltypeid === 3;
		}
	}
};
</script>
