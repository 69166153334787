<template>
	<div class="PcsumEditionGridRow" :class="{ basicDisplayRow: basicDisplay }">
		<v-row dense align="center">
			<template v-for="(value, field) in localRow">
				<template v-if="fields[field]">
					<!-------------->
					<!-- IS TABLE -->
					<!-------------->
					<template v-if="basicDisplay">
						<v-col :key="'RGF-' + field + '-' + localRow.rowIndex + '-' + localRow.randomKey" :class="fields[field].cols" class="pb-0">
							<label class="customLabel" v-if="localRow.rowIndex === 0">{{ $t(fields[field].label) }}</label>
							<div class="basicDisplayCell">
								<div :class="getTableClasses(localRow, field)">
									<span v-if="localRow[fields[field].withId]" class="state isId">{{ localRow[fields[field].withId] }}</span>
									{{ localRow[field] }}
								</div>
							</div>
						</v-col>
					</template>

					<!---------------->
					<!-- PUI Select -->
					<!---------------->
					<template v-else-if="fields[field].modelName">
						<v-col
							:key="'RGF-' + field + '0' + localRow.rowIndex + '0' + localRow.randomKey"
							:class="fields[field].cols"
							class="pb-0 pl-2 pr-2"
						>
							<pui-select
								class="customSelector"
								:label="localRow.rowIndex === 0 ? $t(fields[field].label) : null"
								toplabel
								outlined
								height="32"
								dense
								:required="localRow.rowIndex === 0 ? true : false"
								:reactive="localRow[field] ? true : false"
								:rules="fields[field].rules"
								v-model="localRow"
								:disabled="checkDisableField(field)"
								:modelFormMapping="{ [`${field}`]: `${field}` }"
								:itemsToSelect="[{ [`${field}`]: localRow[field] }]"
								:modelName="fields[field].modelName"
								:queryFields="fields[field].itemText"
								clearable
								:itemValue="[`${field}`]"
								:itemText="(item) => buildItemText(fields[field].itemText, item)"
								@change="EmitChange"
								singleItemAutoselect
								:fixedFilter="fields[field].filter"
							>
							</pui-select>
						</v-col>
					</template>

					<!---------------->
					<!-- V Select -->
					<!---------------->
					<template v-else-if="fields[field].items">
						<v-col
							:key="'RGF-' + field + '0' + localRow.rowIndex + '0' + localRow.randomKey"
							:class="fields[field].cols"
							class="pb-0 pl-2 pr-2"
						>
							<label class="customLabel" v-if="localRow.rowIndex === 0">{{ $t(fields[field].label) }}</label>
							<v-select
								class="customSelector"
								outlined
								height="32"
								dense
								required
								:rules="fields[field].rules"
								v-model="localRow[field]"
								:disabled="checkDisableField(field)"
								:items="fields[field].items"
								:item-value="[`${field}`]"
								:item-text="(item) => buildItemText(fields[field].itemText, item)"
								@change="EmitChange"
							>
							</v-select>
						</v-col>
					</template>

					<!-------------->
					<!-- PUI Date -->
					<!-------------->
					<template v-else-if="fields[field].date">
						<v-col :key="'RGF-' + field + '0' + localRow.rowIndex + '0' + localRow.randomKey" :class="fields[field].cols" class="pb-0">
							<pui-date-field
								:disabled="checkDisableField(field)"
								:id="field + '0' + localRow.rowIndex + '0' + localRow.isNew + '0' + localRow.isSelected + '0' + localRow.isEdited"
								:label="localRow.rowIndex === 0 ? $t(fields[field].label) : null"
								toplabel
								dense
								:rules="fields[field].rules"
								v-model="localRow[field]"
								@input="EmitChange"
							></pui-date-field>
						</v-col>
					</template>

					<!-------------->
					<!-- PUI Text -->
					<!-------------->
					<template v-else>
						<v-col :key="'RGF-' + field + '0' + localRow.rowIndex + '0' + localRow.randomKey" :class="fields[field].cols" class="pb-0">
							<pui-text-field
								:disabled="checkDisableField(field)"
								@keyup="(localRow.isNew || localRow.isSelected) && isNewSearchField === field ? fillFields() : null"
								:id="field + '0' + localRow.rowIndex + '0' + localRow.isNew + '0' + localRow.isSelected + '0' + localRow.isEdited"
								v-model="localRow[field]"
								:label="localRow.rowIndex === 0 ? $t(fields[field].label) : null"
								toplabel
								:rules="fields[field].rules"
								@change="EmitChange"
							></pui-text-field>
						</v-col>
					</template>
				</template>
			</template>
		</v-row>

		<div v-if="(localRow.editable && userEditable) || !userEditable" class="GridRowActions pl-2 pr-1">
			<button v-if="allowRemove" @click="EmitShowRemoveDialog()" class="remove">
				<i class="fas fa-trash-alt"></i>
			</button>
			<button v-if="allowEdit && !localRow.isNew && !localRow.isSelected" @click="editInModal ? EmitEditInModal() : SetEdit()">
				<i class="fas fa-edit"></i>
			</button>
			<button v-if="allowClone && !localRow.isNew && !localRow.isSelected" @click="EmitClone()">
				<i class="far fa-copy"></i>
			</button>
			<button v-if="allowLaunch && !localRow.isNew && !localRow.isSelected && localRow.launch" @click="EmitLaunch()">
				<i :class="launchIcon ? launchIcon : 'fas fa-paper-plane'"></i>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'pcsum-edition-grid-row',
	props: {
		row: { type: Object, default: () => {} },
		fields: { type: Object, default: () => [] },
		basicDisplay: { type: Boolean, default: () => false },
		allowCreate: { type: Boolean, default: () => false },
		allowEdit: { type: Boolean, default: () => false },
		allowRemove: { type: Boolean, default: () => false },
		allowLaunch: { type: Boolean, default: () => false },
		allowClone: { type: Boolean, default: () => false },
		isNewSearchField: { type: String, default: '' },
		isSelectedEditableFields: { type: Array, default: () => [] },
		isEditedEditableFields: { type: Array, default: () => [] },
		editInModal: { type: Boolean, default: false },
		launchIcon: { type: String, default: '' },
		userEditable: { type: Boolean, default: () => false }
	},
	data() {
		return {
			localRow: this.row
		};
	},
	methods: {
		buildItemText(fields, item) {
			let t = '';
			for (let i = 0; i < fields.length; i++) {
				t += item[fields[i]] + (i < fields.length - 1 ? ' - ' : '');
			}
			return t;
		},
		checkDisableField(field) {
			let d = null;
			switch (true) {
				// Search Row
				case this.localRow.isSelected:
					d = !this.isSelectedEditableFields.includes(field);
					break;
				// New Row
				case this.localRow.isNew:
					d = false;
					break;
				// Edit Row
				case this.localRow.isEdited:
					d = !this.isEditedEditableFields.includes(field);
					break;
				// Old Rows
				default:
					d = true;
			}
			return d;
		},
		fillFields() {
			const f = this.localRow.items.find((r) => r[this.isNewSearchField] === this.localRow[this.isNewSearchField]);
			if (f) {
				for (const field in f) {
					this.localRow[field] = f[field];
				}
				this.localRow.isNew = false;
				this.localRow.isSelected = true;
				this.localRow.isEdited = false;
			} else {
				this.localRow.isNew = true;
				this.localRow.isSelected = false;
				this.localRow.isEdited = false;
			}
			this.EmitChange();
		},
		SetEdit() {
			this.localRow.isNew = false;
			this.localRow.isSelected = false;
			this.localRow.isEdited = true;
			this.EmitChange();
		},
		getTableClasses(localRow, field) {
			let classes = '';
			switch (true) {
				case this.fields[field].stateField !== null && typeof this.fields[field].stateField !== 'undefined':
					classes += 'state state-' + localRow[this.fields[field].stateField];
					break;
				case this.fields[field].pill:
					classes += 'state';
					break;
			}
			return classes;
		},
		EmitClone() {
			this.$emit('EmitClone', this.localRow);
		},
		EmitShowRemoveDialog() {
			this.$emit('EmitShowRemoveDialog', this.localRow);
		},
		EmitChange() {
			this.$emit('EmitChange', this.localRow);
		},
		EmitEditInModal() {
			this.$emit('EmitEditInModal', this.localRow);
		},
		EmitLaunch() {
			this.$emit('EmitLaunch', this.localRow);
		}
	},
	computed: {
		checkActionAvailableRow() {
			let valid = true;
			for (const f in this.localRow) {
				if (this.localRow[f] === null || typeof this.localRow[f] === 'undefined') {
					valid = false;
				}
			}
			return valid;
		}
	}
};
</script>

<style scoped>
.PcsumEditionGridRow {
	display: grid;
	align-items: center;
	grid-template-columns: auto 100px;
}
.PcsumEditionGridRow .GridRowActions {
	position: relative;
	bottom: 14px;
	display: flex;
	align-content: center;
	justify-content: flex-start;
	min-height: 36px !important;
}

.PcsumEditionGridRow:nth-child(1) .GridRowActions {
	bottom: 4px;
}
.PcsumEditionGridRow .GridRowActions button {
	opacity: 0.75;
	line-height: 21px;
	border-radius: 3px;
	font-size: 1.25em;
	cursor: pointer;
	color: var(--N-300);
	margin-inline: 5px;
}

.PcsumEditionGridRow .GridRowActions button:hover {
	color: var(--primarycolor);
	opacity: 1;
}
.PcsumEditionGridRow .GridRowActions button.remove:hover {
	color: var(--warningcolor);
}

/* basicDisplay */
.PcsumEditionGridRow.basicDisplayRow {
	padding: 0px 10px;
	border-bottom: 1px solid #ddd;
}
.PcsumEditionGridRow.basicDisplayRow:nth-child(even) {
	background: #f6f6f6;
}
.PcsumEditionGridRow.basicDisplayRow:last-child {
	margin-bottom: 14px;
}
.PcsumEditionGridRow.basicDisplayRow .basicDisplayCell {
	height: 45px;
	display: flex;
	align-items: center;
	border-right: 1px solid #ddd;
}
.PcsumEditionGridRow.basicDisplayRow .GridRowActions {
	bottom: 0px;
}
.PcsumEditionGridRow.basicDisplayRow:nth-child(1) .GridRowActions {
	bottom: -10px;
}
</style>
