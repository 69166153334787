<template>
	<div>
		<pui-datatable v-if="!masterDetail" :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs" :showDeleteBtn="false">
			<template slot="toolbarAdditionalBtns">
				<div class="pui-gridActionsBtn mt-2 pr-2" v-show="deleteSorted">
					<v-btn
						:id="`gridbtn-legend-${modelName}`"
						class="elevation-0 pui-toolbar__actionBtn primary white--text"
						:title="$t('general.confirmremove1title')"
						@click.native.stop="showDialog()"
						icon
						:disabled="deleteDisabled"
					>
						<v-icon>far fa-trash-alt</v-icon>
					</v-btn>
				</div>
			</template>
		</pui-datatable>

		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:modelColumnDefs="modelColumnDefs"
			:key="blidkey"
			:showCreateBtn="showCreateBtn"
			:showUpdateBtn="showCreateBtn"
			:showDeleteBtn="false"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		>
			<template v-if="showCreateBtn" slot="toolbarAdditionalBtns">
				<div class="pui-gridActionsBtn mt-2 pr-2" v-show="deleteSorted">
					<v-btn
						:id="`gridbtn-legend-${modelName}`"
						class="elevation-0 pui-toolbar__actionBtn primary white--text"
						:title="$t('general.confirmremove1title')"
						@click.native.stop="showDialog()"
						icon
						:disabled="deleteDisabled"
					>
						<v-icon>far fa-trash-alt</v-icon>
					</v-btn>
				</div>
			</template>
		</pui-datatable>
		<sdsmgood-modals :modelName="modelName"></sdsmgood-modals>
		<!-- MODAL START-->
		<v-dialog v-model="openDialogSend" max-width="500">
			<v-card class="pa-2">
				<v-card-title class="pl-3 mb-4 headline">{{ $t('general.confirmremove1title') }}</v-card-title>
				<v-card-subtitle class="pl-3">{{ $t('general.confirmremove1subtitle') }}</v-card-subtitle>
				<v-card-subtitle class="pl-3" v-if="dialogLoading"
					><v-progress-linear color="primary" indeterminate></v-progress-linear
				></v-card-subtitle>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn style="box-shadow: none" @click="cancel()" :disabled="dialogLoading">{{ $t('general.cancel') }}</v-btn>
					<v-btn style="box-shadow: none" color="primary" @click="accept()" :disabled="dialogLoading">{{ $t('general.accept') }}</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- MODAL END -->
	</div>
</template>

<script>
import sdsmgoodActions from './SdsmgoodActions';
import sdsmgoodModals from './SdsmgoodModals.vue';
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixinAccess';
import SdsmGoodUtils from './SdsmgoodUtils';
import { mapState } from 'vuex';
import { EntityStatusUtils } from '../../utils/entityStates';
export default {
	name: 'sdsmgood-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		'sdsmgood-modals': sdsmgoodModals
	},
	props: {
		blid: {
			required: false,
			type: Object
		}
	},
	data() {
		return {
			deleteSorted: false,
			modelName: 'sdsmgood',
			actions: sdsmgoodActions.gridactions,
			createBtn: false,
			blidkey: 0,
			modelColumnDefs: {
				packagesnumber: {
					render: (data, type, row) => {
						return !row.weight
							? '<span style="opacity: 0.25; margin-right: 3px"><i class="fas fa-walking"></i></span> ' + row.packagesnumber
							: row.packagesnumber;
					}
				},
				weight: {
					render: (data, type, row) => {
						return row.weight ? row.weight + ' <span style="opacity: 0.5">Kg</span>' : '';
					}
				},
				state: {
					render: (data, type, row) => {
						return `<span class="state isGrid state-${this.modelName}-${row.stateid}">${data}</span>`;
					}
				}
			},
			openDialogSend: false,
			dialogLoading: false,
			deleteDisabled: false,
			selectedRows: []
		};
	},
	mounted() {
		setTimeout(() => {
			this.$puiEvents.$on(`onPui-selectItem-dataTable-${this.modelName}`, (selectedRows) => {
				this.selectedRows = selectedRows.map((row) => {
					const rowValue = Object.values(row)[0];
					this.deleteDisabled = !EntityStatusUtils.checkEditionAvailable(rowValue.stateid);
					return rowValue;
				});
			});

			const btns = this.$el.querySelectorAll('.pui-gridActionsBtn');
			const deleteBtn = btns[3];
			btns[3].parentNode.removeChild(btns[3]);
			btns[2].parentNode.insertBefore(deleteBtn, btns[2]);
			this.deleteSorted = true;
		}, 100);
	},
	destroyed() {
		this.$puiEvents.$off(`onPui-selectItem-dataTable-${this.modelName}`);
	},
	computed: {
		...mapState({
			operatorId: (state) => state.session.properties.GESTAUT_OPERATOR_ID
		}),

		showBtnCreate() {
			return this.operatorId;
		}
	},
	methods: {
		async externalFilterUpdate() {
			if (this.parentPk) {
				let newGood = await SdsmGoodUtils.getGoodByBlid(this, JSON.parse(atob(this.parentPk)).blid);
				if (newGood[0].bltypeid == 2 || newGood[0].bltypeid == 1) {
					this.createBtn = true;
					this.blidkey++;
				}
			}
		},
		showDialog() {
			if (this.selectedRows.length != 0) {
				this.openDialogSend = true;
			} else {
				this.$puiNotify.warning(this.$puiI18n.t('errors.warnremove'));
			}
		},
		cancel() {
			this.openDialogSend = false;
		},
		async accept() {
			this.dialogLoading = true;
			let pk = this.selectedRows[0].goodid;
			let params = { data: pk };
			await this.$puiRequests.deleteRequest(
				'/sdsmgood/deleteGood',
				null,
				() => {
					this.openDialogSend = false;
					this.dialogLoading = false;
					document.getElementById('gridbtn-refresh-sdsmgood').click();
					this.selectedRows = [];
				},
				(e) => {
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response?.data) {
						message = e.response.data.message;
					}
					this.$puiNotify.error(message);
					this.openDialogSend = false;
					this.dialogLoading = false;
				},
				params,
				null
			);
		}
	}
};
</script>
