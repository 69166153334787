<template>
	<div class="pui-form">
		<mcountry-modals :modelName="modelName"></mcountry-modals>
		<!-- <pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<mcountry-form-header :modelPk="modelPk"></mcountry-form-header>
		</pui-form-header> -->
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<!-- COUNTRYCODE -->
						<v-col cols="1">
							<pui-text-field
								:id="`countrycode-mcountry`"
								v-model="model.countrycode"
								:label="$t('mcountry.countrycode')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="2"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DESCRIPTION -->
						<v-col cols="6">
							<pui-text-field
								:id="`description-mcountry`"
								v-model="model.description"
								:label="$t('mcountry.description')"
								:disabled="formDisabled"
								toplabel
								required
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- ISEU -->
						<v-col cols="5" class="pt-7" align="center">
							<pui-checkbox
								:id="`iseu-mcountry`"
								:label="$t('mcountry.iseu')"
								v-model="model.iseu"
								true-value="1"
								false-value="0"
							></pui-checkbox>
							<!-- ISACTIVE -->
							<pui-checkbox
								:id="`isactive-mcountry`"
								:label="$t('mcountry.isactive')"
								v-model="model.isactive"
								true-value="1"
								false-value="0"
							></pui-checkbox>
						</v-col>
					</v-row>
					<!-- MINI AUDIT -->
					<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import mcountryActions from './McountryActions';
import mcountryModals from './McountryModals.vue';
import formUtils from '@/utils/form';

export default {
	name: 'mcountry-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mcountry-modals': mcountryModals
	},
	data() {
		return {
			modelName: 'mcountry',
			actions: mcountryActions.formActions
		};
	},
	methods: {
		afterGetData() {
			formUtils.updateMiniAudit(this.model)
			if (this.isCreatingElement) {
				this.model.isactive = '1';
			}
		},
		beforeSave() {
			this.dtohash = null;
		}
	},
	computed: {},
	created() {},
	watch: {
		'model.countrycode'() {
			if (this.model.countrycode != undefined && this.model.countrycode != null) {
				this.model.countrycode = this.model.countrycode.toUpperCase();
			}
		}
	}
};
</script>
