<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<sdsmcustoms-modals
			:modelName="modelName"
		></sdsmcustoms-modals>
	</div>
</template>

<script>
import sdsmcustomsActions from './SdsmcustomsActions';
import sdsmcustomsModals from './SdsmcustomsModals.vue';

export default {
	name: 'sdsmcustoms-grid',
	components: {
		'sdsmcustoms-modals': sdsmcustomsModals
	},
	data() {
		return {
			modelName: 'sdsmcustoms',
			actions: sdsmcustomsActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
