<template>
	<pcsum-edition-grid
		:label="$t('sdsmuti.seals')"
		:labelEmpty="$t('sdsmuti.noseals')"
		:rows="seals"
		:fields="fields"
		:limit="9"
		:loading="loading"
		:allowCreate="!formDisabled"
		:allowRemove="!formDisabled"
		:params="params"
		@EmitCreate="EmitCreate"
		@EmitRefresh="EmitRefresh"
		@EmitRemove="EmitRemove"
		@EmitChange="EmitChange"
	></pcsum-edition-grid>
</template>

<script>
import PcsumEditionGrid from '../general/PcsumEditionGrid'
import SdsmUtiUtils from './SdsmutiUtils.js'
export default {
	name: 'sdsmuti-form-seals',
	components: {
		'pcsum-edition-grid': PcsumEditionGrid
	},
	props: {
		uti: { type: Object, default: () => {} },
		classes: { type: String, default: () => '' },
		params: { type: Object, default: () => {} }
	},
	data () {
		return {
			loading: false,
			seals: [],
			formDisabled: false,
			emptyRegistry: {
				sealnumber: null
			},
			fields: {
				sealnumber: {
					label: 'sdsmuti.seal',
					rules: [value => !!value || this.$t('requiredMessage')],
					cols: '12'
				}
			}
		}
	},
	watch: {},
	methods: {
		async getUtiSeals () {
			this.loading = true
			this.seals = await SdsmUtiUtils.getUtiSeals(this, this.uti.utiid)
			this.loading = false
		},
		/* Create Empty Row */
		EmitCreate () {
			this.seals.push({
				...this.emptyRegistry,
				...{
					isNew: true,
					isSelected: false,
					isEdited: false
				}
			})
		},
		/* Editing new row */
		EmitChange (registry) {
			const index = registry.rowIndex
			this.seals[index] = { ...this.seals[index], ...registry }
		},
		/* Deleting row */
		async EmitRemove (registry) {
			const deleted = await SdsmUtiUtils.deleteUTISeal(this, registry)
			if (deleted) {
				this.seals.splice(registry.rowIndex, 1)
			}
		},
		/* Refresh list */
		EmitRefresh () {
			this.getUtiSeals()
		}
	},
	created () {
		this.getUtiSeals()
		this.formDisabled = this.params.formDisabled
	}
}
</script>
