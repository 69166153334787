<template>
	<div class="pui-form">
		<mlocalportmodals :modelName="modelName"></mlocalportmodals>
		<v-form class="pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="6">
					<!-- {{ model }} -->
					<v-row dense v-if="!isCreatingElement">
						<!-- PORTID -->
						<v-col cols="2">
							<pui-text-field
								:id="`portid-mlocalport`"
								v-model="model.portid"
								:label="$t('mlocalport.portid')"
								disabled
								required
								toplabel
								maxlength="12"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- COUNTRYCODE -->

						<v-col cols="12">
							<pui-select
								id="`countrycode-mlocalport`"
								attach="countrycode"
								:label="$t('mlocalport.countrycode')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="mcountry"
								:modelFormMapping="{ countrycode: 'countrycode' }"
								:itemsToSelect="[{ countrycode: model.countrycode }]"
								:itemValue="['countrycode']"
								:itemText="item => `${item.countrycode} - ${item.description}`"
								:order="{ countrycode: 'asc' }"
								detailModelName="mcountry"
								detailComponentName="mcountry-form"
								:fixedFilter="fixedDate"
								singleItemAutoselect
							></pui-select>
						</v-col>
						<!-- PACODE -->
					</v-row>
					<v-row dense>
						<!-- PORTCODE -->
						<v-col cols="12">
							<pui-text-field
								:id="`portcode-mlocalport`"
								v-model="model.portcode"
								:label="$t('mlocalport.portcode')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="3"
							></pui-text-field>
						</v-col>
					</v-row>
				</v-col>
				<v-col cols="6">
					<v-row dense>
						<!-- CUSTOMSID -->
						<v-col cols="12">
							<pui-select
								id="customsid"
								attach="customsid"
								v-model="model"
								modelName="sdsmcustoms"
								:modelFormMapping="{ customsid: 'customsid' }"
								item-value="customsid"
								:itemsToSelect="customsidItemsToSelect"
								:item-text="item => `${item.customsid} - ${item.description}`"
								toplabel
								clearable
								required
								:label="$t('mlocalport.customsid')"
								:fixedFilter="fixedAduana"
							></pui-select>

							<!-- 	<pui-select
								id="customsid"
								attach="customsid"
								:label="$t('mlocalport.customsid')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="sdsmcustoms"
								:modelFormMapping="{ customsid: 'customsid' }"
								:itemsToSelect="aduanaItemsToSelect"
								:itemValue="['customsid']"
								:itemText="item => `${item.customsid} - ${item.description}`"
								:order="{ customsid: 'asc' }"
								detailModelName="sdsmcustoms"
								detailComponentName="sdsmcustoms-form"
								:fixedFilter="fixedAduana"
							></pui-select> -->
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="12">
							<pui-text-field
								:id="`description-mlocalport`"
								v-model="model.description"
								:label="$t('mlocalport.description')"
								toplabel
								required
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DATEEXPIRATION -->
						<v-col cols="12" v-if="!isCreatingElement">
							<pui-date-field
								:id="`dateexpiration-mlocalport`"
								:label="$t('mlocalport.dateexpiration')"
								toplabel
								time
								v-model="model.dateexpiration"
								disabled
							></pui-date-field>
						</v-col>
					</v-row>
					<!-- MINI AUDIT -->
				</v-col>
				<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin'
import mlocalportActions from './MlocalportActions'
import mlocalportModals from './MlocalportModals.vue'
import formUtils from '@/utils/form'
import filters from '@/utils/filters'

export default {
	name: 'mlocalport-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		mlocalportmodals: mlocalportModals
	},
	data () {
		return {
			modelName: 'mlocalport',
			actions: mlocalportActions.formactions,
			aduanaIni: ''
		}
	},
	watch: {
		/* 	'model.customsid' (newQuestion) {
			if (newQuestion.customsid) {
				this.model.customsid = newQuestion.customsid
			}
		} */
	},
	methods: {
		beforeSave () {
			this.dtohash = null
		},
		afterGetData () {
			formUtils.updateMiniAudit(this.model)

			this.aduanaIni = this.model.customsid
		}
	},
	computed: {
		fixedDate () {
			return filters.expirationDate()
		},
		customsidItemsToSelect () {
			return [{ customsid: this.model.customsid }]
		},
		fixedAduana () {
			return filters.expirationDatePlus(this.aduanaIni, 'customsid')
		}
	},
	created () {}
}
</script>
