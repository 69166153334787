<template>
	<v-card class="pui-form" id="ImportCSVDOC">
		<v-card-title style="padding-bottom: 0px !important">
			{{ $t('form.sdsmdocument.formtitle') }}
			<i class="far fa-chevron-right" style="font-size: 10px; opacity: 0.5; margin: 0px 10px"></i>
			{{ $t('modal.importCSV.title') }}
		</v-card-title>
		<!-- File input -->
		<v-card-title v-if="!loading && !builtData?.progress">
			<v-btn color="primary" outlined @click="triggerFileInput" style="width: 100%">
				<v-icon color="primary"> upload </v-icon>
				{{ $t('modal.importCSV.importFile') }}
			</v-btn>
			<input ref="fileInput" accept=".csv" type="file" @change="handleFile" style="display: none" />
		</v-card-title>
		<v-card-text>
			<!-- Progress bar -->
			<template v-if="builtData?.progress">
				<div id="ImportCSVDOCMessage">{{ $t(builtData?.message) }}</div>
				<v-progress-linear color="primary" :value="builtData?.progress" :buffer-value="100"></v-progress-linear>
			</template>
			<!-- Loading bar-->
			<template v-else-if="loading">
				<div id="ImportCSVDOCMessage">{{ $t(loading) }}</div>
				<v-progress-linear color="primary" indeterminate></v-progress-linear>
			</template>
			<template v-else>
				<!-- Errors -->
				<template v-if="builtData?.errors?.length">
					<v-alert dense text icon="far fa-info-circle" :type="E.type" v-for="(E, index) in builtData.errors" :key="'ICSVE-' + index">
						{{ $t(E.entity) }}
						<i class="far fa-chevron-right" style="font-size: 10px; opacity: 0.5; margin: 0px 10px"></i>
						{{ $t(E.msg, E.params) }}
					</v-alert>
				</template>
				<!-- Report -->
				<template v-else>
					<!-- Inputs -->
					<v-form ref="form" class="mt-4" lazy-validation @submit.prevent v-if="builtData?.document">
						<v-row dense align="center">
							<!-- DOCUMENTNUMBER -->
							<v-col cols="2">
								<pui-text-field
									:id="`documentnumber-sdsmdocument`"
									v-model="builtData.document.documentnumber"
									:label="
										$t('sdsmdocument.documentnumber') +
										' (' +
										$t(
											builtData.document.doctypeid === 1
												? 'sdsmdocument.documenttype.upload'
												: 'sdsmdocument.documenttype.download'
										) +
										')'
									"
									disabled
									toplabel
									maxlength="15"
								></pui-text-field>
							</v-col>
							<!-- PORTID -->
							<v-col cols="3">
								<pui-select
									id="portid-sdsmdocument"
									attach="portid-sdsmdocument"
									:label="$t('sdsmdocument.port')"
									toplabel
									disabled
									clearable
									required
									reactive
									singleItemAutoselect
									v-model="builtData.document"
									modelName="mlocalport"
									:modelFormMapping="{ portid: 'portid' }"
									:itemsToSelect="portItemsToSelect"
									:itemValue="['portid']"
									:queryFields="['description']"
									:itemText="(item) => `${item.description}`"
									:order="{ description: 'asc' }"
								></pui-select>
							</v-col>
							<!-- SHIPIMO -->
							<v-col cols="2">
								<pui-text-field
									:id="`shipimo-sdsmdocument`"
									v-model="builtData.document.shipimo"
									:label="$t('sdsmdocument.shipimo')"
									disabled
									toplabel
									maxlength="15"
								></pui-text-field>
							</v-col>
							<!-- shipname -->
							<v-col cols="2">
								<pui-text-field
									:id="`shipimo-sdsmdocument`"
									v-model="builtData.document.shipname"
									:label="$t('sdsmdocument.shipname')"
									disabled
									toplabel
									maxlength="15"
								></pui-text-field>
							</v-col>
							<!-- CALLYEAR -->
							<v-col cols="1">
								<pui-number-field
									:id="`callyear-sdsmdocument`"
									v-model="builtData.document.callyear"
									:label="$t('sdsmdocument.callyear')"
									required
									toplabel
									integer
									min="0"
									max="9999"
								></pui-number-field>
							</v-col>

							<!-- CALLNUMBER -->
							<v-col cols="2">
								<template v-if="builtData.document.callnumber || loadingEscalas">
									<div class="customInputWithIcon">
										<pui-text-field
											:id="`callnumber-sdsmdocument`"
											v-model="builtData.document.callnumber"
											:label="$t('sdsmdocument.callnumber')"
											toplabel
											disabled
											required
											:loading="loadingEscalas"
											maxlength="120"
										>
										</pui-text-field>
										<v-btn text plain @click="cleanCallNumber()"><i class="fas fa-edit"></i></v-btn>
									</div>
								</template>
								<template v-else>
									<div style="padding: 0px 5px">
										<label class="customLabel v-label--required">{{ $t('sdsmdocument.callnumber') }}</label>
										<v-autocomplete
											class="customSelector"
											outlined
											required
											dense
											:rules="[(v) => !!v || $t('requiredMessage')]"
											:key="callnumberKey"
											v-model="builtData.document.callnumber"
											:loading="loadingEscalas"
											:items="escalas"
											:disabled="loadingEscalas || !builtData.document.callyear || !builtData.document.portid"
											clearable
											item-value="numescala"
											:item-text="(item) => `${item.numescala}`"
											height="39px"
										>
										</v-autocomplete>
									</div>
								</template>
							</v-col>
						</v-row>
					</v-form>
					<!-- Report -->
					<template v-for="(T, index) in builtData?.document?.jointservices">
						<v-divider :key="'RBS-' + T.jointservicenumber" class="mb-4 mt-4 primary" v-if="index > 0"></v-divider>
						<v-row dense :key="'RB-' + T.jointservicenumber">
							<template v-for="B in T.billofloadings">
								<v-col
									cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6"
									:key="'JSC-' + T.jointservicenumber + '-' + B.ordernumber"
								>
									<v-card :key="'JS-' + T.jointservicenumber + '-' + B.ordernumber" elevation="0" outlined>
										<!-- Joint Service number + Bill of lading ordernumber -->
										<v-card-title>
											{{ $t('form.sdsmjointservice.formtitle') }} {{ T.jointservicenumber }} /
											{{ $t('form.sdsmjointservice.sdsmbillofloading') }} {{ B.ordernumber }}
										</v-card-title>
										<v-card-subtitle>
											<span>{{ B.bltypedescription }}</span> /
											<span
												>{{ $t('sdsmbillofloading.blnumber') }}: <strong>{{ B.blnumber }}</strong></span
											>
											/
											<span
												>{{ $t('sdsmbillofloading.origindes') }}: <strong>{{ B.origindes }}</strong></span
											>
											/
											<span
												>{{ $t('sdsmbillofloading.destinationdes') }}: <strong>{{ B.destinationdes }}</strong></span
											>
										</v-card-subtitle>
										<v-card-text>
											<!-- Goods number -->
											<v-chip label :color="B.goods.length > 0 ? 'primary' : ''" class="mr-3"
												>{{ $t('menu.sdsmgood') }}: {{ B.goods.length }}</v-chip
											>
											<!-- Utis number -->
											<v-chip label :color="getUtisNumber(B) > 0 ? 'primary' : ''" class="mr-3"
												>{{ $t('menu.sdsmuti') }}: {{ getUtisNumber(B) }}</v-chip
											>
											<!-- Docs number -->
											<v-chip label :color="getDocsNumber(B) > 0 ? 'primary' : ''"
												>{{ $t('sdsmgood.documents') }}: {{ getDocsNumber(B) }}</v-chip
											>
										</v-card-text>
									</v-card>
								</v-col>
							</template>
						</v-row>
					</template>
				</template>
			</template>
		</v-card-text>
		<!-- Footer -->
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn elevation="0" @click="close()">{{ $t('general.cancel') }}</v-btn>
			<v-btn
				elevation="0"
				@click="accept()"
				color="primary"
				:disabled="
					!builtData?.document?.callnumber || loading !== false || !builtData || builtData.errors.length > 0 || builtData.progress !== null
				"
				>{{ $t('general.accept') }}</v-btn
			>
		</v-card-actions>
	</v-card>
</template>

<script>
import SdsmbillofloadingUtils from '../../sdsmbillofloading/SdsmbillofloadingUtils';
import ImportCSV from './../../../utils/importCSV.js';
import { mapState } from 'vuex';
import SdsmjointserviceUtils from '../../sdsmjointservice/SdsmjointserviceUtils';
import SdsmDocumentUtils from '../../sdsmdocument/SdsmdocumentUtils';
import SdsmGoodUtils from '../../sdsmgood/SdsmgoodUtils';
import SdsmUtiUtils from '../../sdsmuti/SdsmutiUtils';
export default {
	name: 'sdsmdocument-import-csv',
	data() {
		return {
			escalas: [],
			loadingEscalas: false,
			callnumberKey: 0,
			fileData: [],
			builtData: null,
			loading: false,
			templateDocument: null,
			templateJS: null,
			templateBL: null,
			templateGood: null,
			templateUTI: null,
			templateDOC: null
		};
	},
	methods: {
		triggerFileInput() {
			this.$refs.fileInput.click();
		},
		handleFile($event) {
			this.fileData = [];
			const file = $event?.target?.files?.[0];
			if (file?.type === 'text/csv') {
				const reader = new FileReader();
				reader.onload = () => {
					const parsedRawData = reader.result.split(/\r\n|\n/);
					for (let i = 1; i < parsedRawData.length; i++) {
						if (parsedRawData[i]) {
							const rowdata = parsedRawData[i].split(';');
							for (let i in rowdata) {
								rowdata[i] = rowdata[i].trim();
							}
							this.fileData.push(rowdata);
						}
					}
					this.buildDataFromCSV();
				};
				reader.readAsText(file);
			} else {
				this.resetData();
				this.$puiNotify.error(this.$puiI18n.t('modal.importCSV.notValidFile'));
			}
		},
		setEscalaFields() {
			const call = this.escalas.find((e) => e.numescala == this.builtData.document.callnumber);
			if (call) {
				this.builtData.document.calllastport = call.locodeorigen;
				this.builtData.document.callnextport = call.locodedestino;
				this.builtData.document.shipflag = call.bandera;
				this.builtData.document.callcode = call.codescala;
				this.builtData.document.shipname = call.nombuque;
				this.builtData.document.shipimo = call.imo;
			}
		},
		cleanCallNumber() {
			this.builtData.document.callnumber = null;
			this.getEscalasPCS();
		},
		async getEscalasPCS() {
			if (this.builtData.document?.locode && this.builtData.document?.callyear && this.companyLegalCode) {
				this.callnumberKey++;
				this.loadingEscalas = true;
				const response = await SdsmDocumentUtils.getEscalasPCS(
					this,
					this.builtData.document,
					this.builtData.document.locode,
					this.builtData.document.callyear,
					this.companyLegalCode
				);
				this.escalas = [];
				for (const r in response) {
					if (response[r].imo === this.builtData.document.shipimo) {
						this.escalas.push(response[r]);
					}
				}
				this.setEscalaFields();
				this.loadingEscalas = false;
			}
		},
		changePortID() {
			this.$puiEvents.$on(`onPuiSelect_selectedItems-portid-sdsmdocument`, (newValPort) => {
				if (this.builtData?.document && newValPort?.model) {
					const lupamodel = newValPort.model;
					this.builtData.document.customsid = lupamodel.customsid;
					this.builtData.document.customscode = lupamodel.customscode;
					this.builtData.document.locode = lupamodel.countrycode + lupamodel.portcode;
				}
			});
		},
		WatchCallYear() {
			if (this.builtData?.document) {
				this.builtData.document.callnumber = null;
				this.getEscalasPCS();
			}
		},
		WatchPortID() {
			if (this.builtData?.document) {
				this.builtData.document.callnumber = null;
				this.changePortID();
				this.getEscalasPCS();
			}
		},
		WatchCallNumber() {
			this.setEscalaFields();
		},
		/**
		 * Main building method
		 */
		async buildDataFromCSV() {
			this.builtData = ImportCSV.buildDocument(this.fileData, this.templateDocument);
			this.builtData = ImportCSV.buildJointServices(this.builtData, this.fileData, this.templateJS);
			this.builtData = ImportCSV.buildBillofLoadings(this.builtData, this.fileData, this.templateBL);
			this.builtData = ImportCSV.buildGoods(this.builtData, this.fileData, this.templateGood);
			this.builtData = ImportCSV.buildUtis(this.builtData, this.fileData, this.templateUTI);
			this.builtData = ImportCSV.buildDocs(this.builtData, this.fileData, this.templateDOC);
			ImportCSV.sort(this.builtData);
			this.builtData = await ImportCSV.retrieveDocumentAdditionalData(this, this.builtData);
			this.builtData = await ImportCSV.retrieveJointServiceAdditionalData(this, this.builtData);
			this.builtData = await ImportCSV.retrieveBillofLoadingAdditionalData(this, this.builtData);
			this.builtData = await ImportCSV.retrieveGoodsAdditionalData(this, this.builtData);
			this.builtData = await ImportCSV.retrieveUtisAdditionalData(this, this.builtData);
			this.builtData = await ImportCSV.retrieveDocsAdditionalData(this, this.builtData);
			await this.validateBuiltData();
		},
		async validateBuiltData() {
			try {
				// Validate Joint Services
				this.builtData = await ImportCSV.validateDocument(this, this.builtData, this.companyLegalCode, null);
				// Validate Joint Services
				this.builtData = await ImportCSV.validateJointServices(this, this.builtData, this.companyLegalCode);

				// Validate Bill of ladings
				this.builtData = await ImportCSV.validateBillofLoadings(this, this.builtData);

				// Validate Goods
				this.builtData = await ImportCSV.validateGoods(this, this.builtData);

				// Validate Utis
				this.builtData = await ImportCSV.validateUtis(this, this.builtData);

				// Validate Docs
				this.builtData = await ImportCSV.validateDocs(this, this.builtData);
			} catch (ex) {
				this.resetData();
				this.$puiNotify.error(this.$puiI18n.t(ex.notification));
			} finally {
				this.processingCSV = false;
			}
		},
		resetData() {
			this.fileData = [];
			this.builtData = null;
		},
		close() {
			this.$store.commit('M__DOCUMENTIMPORTCSV_MODAL', { open: false });
			return new Promise((resolve) => {
				resolve(true);
				this.$puiEvents.$emit('onPui-action-running-ended-sdsmdocument');
			});
		},
		getUtisNumber(B) {
			let n = 0;
			if (B.bltype_code === 'ZZ3') {
				n = B.utis.length;
			} else {
				B.goods.forEach((i) => {
					n += i.utis.length;
				});
			}
			return n;
		},
		getDocsNumber(B) {
			let n = 0;
			B.goods.forEach((i) => {
				n += i.docs.length;
			});
			return n;
		},
		async accept() {
			this.builtData.message = null;
			this.loading = 'modal.importCSV.saving';
			const response = await ImportCSV.sendDocument(this, this.builtData.document);
			this.loading = false;
			if (response) {
				this.$puiNotify.success(this.$puiI18n.t('modal.importCSV.success'));
				this.close();
			}
		}
	},
	computed: {
		...mapState({
			companyLegalCode: (state) => state.session.properties.GESTAUT_PROPERTIES_COMPANY_ID_CODE,
			operatorId: (state) => state.session.properties.GESTAUT_OPERATOR_ID,
			portsList: (state) => state.session.properties.GESTAUT_PROPERTIES_PORTS
		}),
		portItemsToSelect() {
			return [{ portid: this.builtData.document.portid }];
		}
	},
	async created() {
		this.loading = 'modal.importCSV.preparing';
		this.templateDocument = await SdsmDocumentUtils.newDOC(this);
		this.templateJS = await SdsmjointserviceUtils.newJS(this);
		this.templateBL = await SdsmbillofloadingUtils.newBillOfLoading(this);
		this.templateGood = await SdsmGoodUtils.newGood(this);
		this.templateUTI = await SdsmUtiUtils.newUTI(this);
		this.templateDOC = await SdsmGoodUtils.newDOC(this);
		this.$watch('builtData.document.callnumber', this.WatchCallNumber);
		this.$watch('builtData.document.portid', this.WatchPortID);
		this.$watch('builtData.document.callyear', this.WatchCallYear);
		this.loading = false;
	}
};
</script>

<style scoped>
#ImportCSVDOCMessage {
	padding: 20px;
	text-align: center;
}
#ImportCSVDOC .v-alert {
	font-size: 1em;
}
#ImportCSVDOC .v-alert .v-alert__wrapper .warning--text {
	font-size: 1.25em;
}
</style>
