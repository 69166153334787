import { EntityStatusUtils } from '../../utils/entityStates';

const copyAction = {
	id: 'idSampleAction',
	selectionType: 'single', // [single|multiple|general]
	label: 'action.sdsmdocument.copyAction.title',
	functionality: 'COPY_DOCUMENT', // set the functionality if needed
	showInForm: true,
	checkAvailability: function (/*registries*/) {
		// Validation to execute action
		return true;
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-sdsmdocumentCopyAction-' + model.name + '-show', row);
	}
};

const ExportCSV = {
	id: 'idDocumentExportCsvAction',
	selectionType: 'single',
	label: 'action.sdsmdocument.exportCSVAction.title',
	functionality: 'EXP_CSV_DOC',
	//functionality: 'MAS_UPD_JSERVICE',
	showInForm: true,
	checkAvailability: function (registries) {
		return registries[0]?.operatorid == this.$store.state.session.properties.GESTAUT_OPERATOR_ID;
	},
	runAction: async function (action, model, registries) {
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		const pk = registries[0].documentid;
		const fechaActual = new Date();

		const dia = fechaActual.getDate(); // Día del mes (1-31)
		const mes = (fechaActual.getMonth() + 1).toString().padStart(2, '0'); // Mes (01-12)
		const anio = fechaActual.getFullYear(); // Año (ejemplo: 2024)
		const horas = fechaActual.getHours().toString().padStart(2, '0'); // Mes (01-12)
		const minutos = fechaActual.getMinutes().toString().padStart(2, '0'); // Mes (01-12)
		const segundos = fechaActual.getSeconds().toString().padStart(2, '0'); // Mes (01-12)
		const fechaFormateada = `${dia}${mes}${anio}${horas}${minutos}${segundos}`;

		let docnum;

		docnum = registries[0].documentnumber;

		const nameCsv = 'pcsum_' + docnum + '_' + fechaFormateada + '.csv';

		await this.$puiRequests.getRequest(
			'/sdsmdocument/exportCSVJS',
			{ pk },
			(response) => {
				const blob = new Blob([response.data], { type: 'application/octet-stream' });

				// Crea una URL para el Blob
				const url = window.URL.createObjectURL(blob);

				// Crea un enlace y configúralo para descargar automáticamente
				const link = document.createElement('a');
				link.href = url;
				link.download = nameCsv; // Nombre del archivo que se descargará

				// Simula un clic en el enlace para iniciar la descarga
				link.click();

				// Libera la URL creada
				window.URL.revokeObjectURL(url);

				this.$puiNotify.success(this.$t('general.exportsucces'));
			},
			(e) => {
				this.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
	}
};

const ImportCSV = {
	id: 'idDocumentImportCSVAction',
	selectionType: 'general',
	label: 'action.sdsmdocument.importCSV.title',
	functionality: 'IMP_CSV_DOC',
	showInForm: true,
	checkAvailability: function () {
		return true;
	},
	runAction: function () {
		this.$store.commit('M__DOCUMENTIMPORTCSV_MODAL', { open: true });
	}
};

const MassiveUpdate = {
	id: 'idDocumentMassiveUpdateAction',
	selectionType: 'multiple',
	label: 'action.sdsmdocument.massiveEditAction.title',
	functionality: 'MAS_UPD_DOC',
	description: 'action.sdsmdocument.massiveEditAction.description',
	showInForm: true,
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		const data = {
			docs: registries,
			isAction: true
		};

		this.$puiEvents.$emit('pui-modalDialogForm-idDocumentMassiveUpdateAction-' + model.name + '-show', data);
	}
};

const DocumentSend = {
	id: 'DocumentSendAction',
	selectionType: 'single',
	label: 'action.sdsmdocument.DocumentSendAction.title',
	functionality: 'SEND_DOCUMENT',
	//functionality: null,
	showInForm: true,
	checkAvailability: function (reg) {
		if (reg?.[0]) {
			return EntityStatusUtils.checkSendAvailable(reg[0].documentstateid);
		}
	},
	runAction: async function (action, model, registries) {
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;

		if (registries[0].documentstateid == 5 || registries[0].documentstateid == 9) {
			let metodo = '/sdsmdocument/send';

			let documento = {
				documentid: registries[0].documentid,
				jointserviceid: null
			};

			await this.$puiRequests.postRequest(
				metodo,
				documento,
				() => {
					this.$puiNotify.success(this.$t('general.sendsuccess'));
				},
				(e) => {
					this.$puiNotify.error(e.response.data.message);
				},
				null,
				null
			);
		} else {
			this.$puiEvents.$emit('pui-modalDialogForm-DocumentSendAction-' + model.name + '-show', row);
		}
		this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
	}
};

const DocumentUndoDelete = {
	id: 'DocumentUndoDelete',
	selectionType: 'single',
	label: 'action.sdsmdocument.DocumentUndoDeleteAction.title',
	functionality: 'UNDO_DEL_DOC',
	//functionality: null,
	showInForm: true,
	checkAvailability: function (reg) {
		return reg?.[0] && reg[0].documentstateid == 9;
	},
	runAction: async function (action, model, registries) {
		// Get PK for the header
		const documentid = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				documentid[column.name] = registries[0][column.name];
			}
		}
		await this.$puiRequests.postRequest(
			'/sdsmdocument/undoDel',
			registries[0].documentid,
			() => {
				this.$puiNotify.success(this.$t('general.success'));
			},
			(e) => {
				this.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
	}
};

export default {
	gridactions: [MassiveUpdate, copyAction, DocumentSend, DocumentUndoDelete, ExportCSV, ImportCSV],
	formactions: [copyAction, DocumentSend, DocumentUndoDelete, ExportCSV, ImportCSV]
};
