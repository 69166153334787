<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<mvehiclebrand-modals :modelName="modelName"></mvehiclebrand-modals>
	</div>
</template>

<script>
import mvehiclebrandActions from './MvehiclebrandActions';
import mvehiclebrandModals from './MvehiclebrandModals.vue';

export default {
	name: 'mvehiclebrandgrid',
	components: {
		'mvehiclebrand-modals': mvehiclebrandModals
	},
	data() {
		return {
			modelName: 'mvehiclebrand',
			actions: mvehiclebrandActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
