<template>
	<div class="pui-form">
		<template v-if="model">
			<v-form class="mb-4 pb-4" ref="form" lazy-validation @submit.prevent>
				<v-row class="pui-form-layout">
					<v-col cols="12">
						<pui-field-set :title="$t('modal.sdsmdocument.massiveedit.docs')">
							<v-chip-group column>
								<template v-for="(U, index) in docs">
									<template v-if="U.documentid">
										<v-tooltip bottom :key="'UTI-' + index">
											<template v-slot:activator="{ on, attrs }">
												<v-chip label outlined :color="getStatus(U).color" v-bind="attrs" v-on="on">
													<span>{{ U.documentnumber || $t('modal.sdsmdocument.massiveedit.noidentification') }}</span>
													<template v-if="U.status">
														<v-icon small class="pl-3" :color="getStatus(U).color">{{ getStatus(U).icon }} </v-icon>
													</template>
												</v-chip>
											</template>
											<span class="massiveToolTip">
												<div class="content">
													<div>{{ $t('sdsmdocument.documenttype.definition') }}: {{ U.documenttype }}</div>
													<div>{{ $t('sdsmdocument.documentnumber') }}: {{ U.documentnumber }}</div>
													<div>{{ $t('sdsmdocument.state') }}: {{ U.state }}</div>
													<div>{{ $t('sdsmdocument.port') }}: {{ U.port }}</div>
													<div>{{ $t('sdsmdocument.stevedore') }}: {{ U.stevedore }}</div>
													<div>{{ $t('sdsmdocument.callnumber') }}: {{ U.callnumber }}</div>
													<div>
														{{ $t('sdsmdocument.etaatd') }}:
														{{ dateHdcal(U.etaatd) }}
													</div>
												</div>
												<div v-if="U.error" class="messages">
													<i class="fas fa-exclamation-triangle"></i> <span>{{ U.error }}</span>
												</div>
											</span>
										</v-tooltip>
									</template>
								</template>
							</v-chip-group>
						</pui-field-set>
					</v-col>
					<v-col cols="12">
						<pui-field-set :title="$t('modal.sdsmdocument.massiveedit.header')">
							<v-row dense>
								<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2">
									<pui-select
										id="callfirstentry"
										attach="callfirstentry"
										:label="$t('sdsmdocument.callfirstentry')"
										toplabel
										v-model="model"
										singleItemAutoselect
										:queryFields="['countrycode', 'description']"
										modelName="mcountry"
										:modelFormMapping="{ countrycode: 'callfirstentry' }"
										:itemsToSelect="[{ countrycode: model.callfirstentry }]"
										:itemValue="['countrycode']"
										:itemText="(item) => `${item.countrycode} - ${item.description}`"
										:order="{ countrycode: 'asc' }"
										:fixedFilter="getISEUActiveFilter"
									></pui-select>
								</v-col>
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
									<pui-date-field
										:id="`eta-sdsmdocument`"
										v-model="model.etaatd"
										:label="$t('sdsmdocument.etaatd')"
										:time="true"
										toplabel
										maxlength="13"
									></pui-date-field>
								</v-col>
								<v-col cols="12 col-xs-12 col-sm-1 col-md-1 col-lg-1 col-xl-1">
									<pui-number-field
										:id="`servicenumber-sdsmdocument`"
										v-model.number="model.servicenumber"
										:label="$t('sdsmdocument.servicenumber')"
										toplabel
										min="0"
										max="999"
									></pui-number-field>
								</v-col>
								<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
									<pui-select
										id="stevedoreid"
										attach="stevedoreid"
										:label="$t('sdsmdocument.stevedore')"
										toplabel
										clearable
										v-model="model"
										singleItemAutoselect
										modelName="sdsmstevedore"
										:modelFormMapping="{ stevedoreid: 'stevedoreid' }"
										:itemsToSelect="[{ stevedoreid: model.stevedoreid }]"
										:itemValue="['stevedoreid']"
										:queryFields="['description']"
										:itemText="(item) => `${item.description}`"
										:order="{ stevedoreid: 'asc' }"
										:fixedFilter="filterStevedoreid"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
									<pui-select
										id="dockid"
										attach="dockid"
										:label="$t('sdsmdocument.dock')"
										toplabel
										clearable
										v-model="model"
										modelName="mdock"
										singleItemAutoselect
										:modelFormMapping="{ dockid: 'dockid' }"
										:itemsToSelect="[{ dockid: model.dockid }]"
										:itemValue="['dockid']"
										:queryFields="['description']"
										:itemText="(item) => `${item.description}`"
										:order="{ dockid: 'asc' }"
										:fixedFilter="filterDockid"
									></pui-select>
								</v-col>
								<!-- DOCKLOCATIONID -->
								<v-col cols="12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
									<pui-select
										id="docklocationid"
										attach="docklocationid"
										:label="$t('sdsmdocument.docklocation')"
										toplabel
										clearable
										singleItemAutoselect
										v-model="model"
										modelName="mdockdocklocation"
										:modelFormMapping="{ docklocationid: 'docklocationid' }"
										:itemsToSelect="[{ docklocationid: model.docklocationid }]"
										:itemValue="['docklocationid']"
										:itemText="(item) => `${item.docklocation} `"
										:order="{ docklocationid: 'asc' }"
										:fixedFilter="getDockFilter"
									></pui-select>
								</v-col>
							</v-row>
						</pui-field-set>
						<pui-field-set :title="$t('modal.sdsmdocument.massiveedit.service')">
							<v-row dense>
								<v-col cols="12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
									<pui-select
										id="movgoodid"
										attach="movgoodid"
										:label="$t('sdsmdocument.movgood')"
										toplabel
										v-model="model"
										reactive
										singleItemAutoselect
										:queryFields="['edicode', 'description']"
										clearable
										modelName="vutmmovementgoodtra"
										:modelFormMapping="{ movgoodid: 'movgoodid' }"
										:itemsToSelect="[{ movgoodid: model.movgoodid }]"
										:itemValue="'movgoodid'"
										:itemText="(item) => `${item.edicode} - ${item.description}`"
										:fixedFilter="filterDoctypeid"
										:order="{ description: 'asc' }"
									></pui-select>
								</v-col>
								<v-col cols="12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
									<pui-select
										id="regserid"
										attach="regserid"
										:label="$t('sdsmdocument.regularservice')"
										toplabel
										clearable
										v-model="model"
										singleItemAutoselect
										modelName="vutregularservicetypetra"
										:modelFormMapping="{ regserid: 'regserid' }"
										:itemsToSelect="[{ regserid: model.regserid }]"
										:itemValue="['regserid']"
										:queryFields="['description']"
										:itemText="(item) => `${item.description}`"
										:order="{ description: 'asc' }"
										:fixedFilter="filterRegserid"
									></pui-select>
								</v-col>
								<v-col cols="12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
									<pui-number-field
										:id="`voyagenumber-sdsmdocument`"
										v-model.number="model.voyagenumber"
										:label="$t('sdsmdocument.voyagenumber')"
										:disabled="!model.regserid || model.regserid === 2"
										toplabel
										maxlength="17"
										min="0"
									></pui-number-field>
								</v-col>
								<v-col cols="12 col-xs-12 col-sm-2 col-md-2 col-lg-2 col-xl-2">
									<pui-select
										id="simtranid"
										attach="simtranid"
										:label="$t('sdsmdocument.simplifiedtransit')"
										toplabel
										clearable
										v-model="model"
										singleItemAutoselect
										modelName="msimplifiedtransit"
										:modelFormMapping="{ simtranid: 'simtranid' }"
										:itemsToSelect="[{ simtranid: model.simtranid }]"
										:itemValue="['simtranid']"
										:queryFields="['description']"
										:itemText="(item) => `${item.description}`"
										:order="{ description: 'asc' }"
										:fixedFilter="filterSimtranid"
									></pui-select>
								</v-col>
								<!-- SIMTRANOPERATORID -->
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
									<pui-select
										id="simtranoperatorid"
										attach="simtranoperatorid"
										:label="$t('sdsmdocument.simtranoperator')"
										toplabel
										clearable
										singleItemAutoselect
										reactive
										:disabled="!model.simtranid || model.simtranid == 3"
										:required="model.simtranid && model.simtranid !== 3"
										v-model="model"
										modelName="sdsmoperator"
										:modelFormMapping="{ operatorid: 'simtranoperatorid' }"
										:itemsToSelect="[{ operatorid: model.simtranoperatorid }]"
										:itemValue="['operatorid']"
										:queryFields="['description']"
										:itemText="(item) => `${item.description}`"
										:order="{ operatorid: 'asc' }"
										:fixedFilter="filterSimtranoperatorid"
									></pui-select>
								</v-col>
							</v-row>
							<v-row dense>
								<!-- GOODSERVICE -->
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
									<pui-text-field
										:id="`goodservice-sdsmdocument`"
										v-model="model.goodservice"
										:label="$t('sdsmdocument.goodservice')"
										toplabel
										maxlength="35"
									></pui-text-field>
								</v-col>
								<!-- MANIPULATIONTERMINAL -->
								<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
									<pui-text-field
										:id="`manipulationterminal-sdsmdocument`"
										v-model="model.manipulationterminal"
										:label="$t('sdsmdocument.manipulationterminal')"
										toplabel
										maxlength="35"
									></pui-text-field>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template v-else>
			<v-progress-linear color="primary" indeterminate></v-progress-linear>
		</template>
	</div>
</template>

<script>
import SdsmDocumentUtils from '../SdsmdocumentUtils';
import MassiveUtils from '../../../utils/massive';
import filters from '@/utils/filters';

export default {
	name: 'sdsmdocument-form-for-massive-update',
	props: {
		docs: { type: Array, default: () => [] }
	},
	data() {
		return {
			model: null,
			filters: {
				portid: '',
				simtranoperatorid: '',
				simtranid: '',
				regserid: '',
				doctypeid: ''
			}
		};
	},
	watch: {
		'model.regserid'() {
			this.model.voyagenumber = '';
		},
		'model.simtranid'() {
			this.model.simtranoperatorid = '';
		}
	},
	methods: {
		getStatus(doc) {
			return MassiveUtils.getStatus(doc);
		},
		dateHdcal(item) {
			return this.$moment(item.etaatd).format('DD/MM/YYYY HH:mm');
		},
		async afterGetData() {},
		async SendDocumentMassiveEdit() {
			let everythingupdated = true;
			for (const u in this.docs) {
				if (this.docs[u].documentid) {
					this.docs[u].status = 1;
					let response = await SdsmDocumentUtils.massiveupdate(this, this.model, this.docs[u].documentid);
					if (response.updated) {
						this.docs[u].status = 2;
						this.docs[u].error = false;
					} else {
						this.docs[u].status = 3;
						this.docs[u].error = response.error;
						everythingupdated = false;
					}
				}
			}
			if (everythingupdated) {
				this.$puiNotify.success(this.$t('modal.sdsmdocument.massiveedit.notifySuccess'));
			} else {
				//	this.$puiNotify.error(this.$t('modal.sdsmdocument.massiveedit.notifyError'))
			}
			return everythingupdated;
		}
	},
	computed: {
		eqsizeidItemsToSelect() {
			return [{ eqsizeid: this.model.eqsizeid }];
		},
		eqtypeidItemsToSelect() {
			return [{ eqtypeid: this.model.eqtypeid }];
		},
		utistatusidItemsToSelect() {
			return [{ utistatusid: this.model.utistatusid }];
		},
		DateExpirationFilter() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'dateexpiration', op: 'nu' }]
					}
				],
				groupOp: 'and',
				rules: []
			};
		},
		getDockFilter() {
			return filters.getDockDockLockFilter(this.model.dockid);
		},
		getDateExpirationFilter() {
			return SdsmDocumentUtils.getDateExpirationFilter();
		},
		getISEUActiveFilter() {
			return filters.expirationDatePlus(1, 'iseu');
		},
		filterPortid() {
			return filters.expirationDatePlus(this.filters.portid, 'portid');
		},
		filterSimtranoperatorid() {
			return filters.expirationDatePlus(this.filters.simtranoperatorid, 'simtranoperatorid');
		},
		filterSimtranid() {
			return filters.expirationDatePlus(this.filters.simtranid, 'simtranid');
		},
		filterRegserid() {
			return filters.expirationDatePlus(this.filters.regserid, 'regserid');
		},
		filterDoctypeid() {
			return {
				groupOp: 'and',
				rules: [{ field: 'dateexpiration', op: 'nu' }],
				groups: []
			};
		},

		filterCustomsid() {
			return filters.expirationDatePlus(this.filters.customsid, 'customsid');
		},
		filterStevedoreid() {
			return filters.expirationDatePlus(this.filters.stevedoreid, 'stevedoreid');
		},
		filterDockid() {
			return filters.expirationDatePlus(this.filters.dockid, 'dockid');
		},

		fixedDate() {
			return filters.expirationDate();
		},
		fixedDatePlus() {
			return filters.expirationDatePlus(this.model.dockid, 'dockid');
		},
		documentItemsToSelect() {
			return [{ documentid: this.model.documentid }];
		},
		operatorItemsToSelect() {
			return [{ operatorid: this.model.operatorid }];
		},
		dockItemsToSelect() {
			return [{ dockid: this.model.dockid }];
		},
		docklocationItemsToSelect() {
			return [{ docklocationid: this.model.docklocationid }];
		},
		simtranItemsToSelect() {
			return [{ simtranid: this.model.simtranid }];
		},
		simpregoperatoridItemsToSelect() {
			return [{ operatorid: this.model.simpregoperatorid }];
		},
		filterDocklocationid() {
			return filters.getDockDockLockFilter(this.model.dockid);
		},
		filterStateid() {
			return filters.expirationDatePlus(this.filters.stateid, 'stateid');
		},
		filterOperatorid() {
			return filters.expirationDatePlus(this.filters.operatorid, 'operatorid');
		},
		filterSimpregoperatorid() {
			return filters.expirationDatePlus(this.filters.simpregoperatorid, 'simpregoperatorid');
		}
	},
	async created() {
		for (const u in this.docs) {
			if (this.docs[u].documentid) {
				this.$set(this.docs[u], 'status', null);
				this.$set(this.docs[u], 'error', null);
			}
		}
		this.model = await SdsmDocumentUtils.newDOC(this);
		this.$watch('model.doctypeid', this.WatchDocTypeID);
		this.filters.portid = this.model.portid;
		this.filters.simtranoperatorid = this.model.simtranoperatorid;
		this.filters.simtranid = this.model.simtranid;
		this.filters.regserid = this.model.regserid;
		this.filters.doctypeid = this.model.doctypeid;
		this.filters.stevedoreid = this.model.stevedoreid;
	}
};
</script>
