import Vue from 'vue';
import VueI18n from 'vue-i18n';
import * as deepmerge from 'deepmerge';

import enPui from 'pui9-translations/translations/en.json';
import esPui from 'pui9-translations/translations/es.json';
import caPui from 'pui9-translations/translations/ca.json';

import enApp from './en.json';
import esApp from './es.json';
import caApp from './ca.json';

import caForms from './forms/ca.json';
import esForms from './forms/es.json';
import enForms from './forms/en.json';

import caFormHeaders from './formheaders/ca.json';
import esFormHeaders from './formheaders/es.json';
import enFormHeaders from './formheaders/en.json';

import caGrids from './grids/ca.json';
import esGrids from './grids/es.json';
import enGrids from './grids/en.json';

import caModals from './modals/ca.json';
import esModals from './modals/es.json';
import enModals from './modals/en.json';

import caActions from './actions/ca.json';
import esActions from './actions/es.json';
import enActions from './actions/en.json';

const en = deepmerge.all([enPui, enApp, enForms, enFormHeaders, enGrids, enModals, enActions]);
const es = deepmerge.all([esPui, esApp, esForms, esFormHeaders, esGrids, esModals, esActions]);
const ca = deepmerge.all([caPui, caApp, caForms, caFormHeaders, caGrids, caModals, caActions]);

Vue.use(VueI18n);

export default new VueI18n({
	locale: 'es',
	fallbackLocale: 'es',
	silentFallbackWarn: true,
	messages: { en: en, es: es, ca: ca }
});
