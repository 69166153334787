const MassiveUtils = {
	getStatus(element) {
		let color = null;
		let icon = null;
		switch (element.status) {
			// Saving
			case 1:
				color = 'primary';
				icon = 'fal fa-hourglass';
				break;
			// Success
			case 2:
				color = 'success';
				icon = 'fas fa-check';
				break;
			// Failure
			case 3:
				color = 'warning';
				icon = 'fas fa-exclamation-triangle';
				break;
		}
		return { icon, color };
	}
};

export default MassiveUtils;
