<template>
	<v-card class="PcsumHeader" tile elevation="0" :loading="!data">
		<v-card-text>
			<div v-for="(D, index) in data" :key="'RH-' + index">
				<!-- Label -->
				<label v-if="showAlerts(D)">
					{{ $t(D.label) }}
				</label>

				<div>
					<template v-if="D.alert">
						<v-btn
							small
							class="alert-button"
							:disabled="calculateQuantityWarnings() == 0"
							elevation="0"
							color="warning"
							@click="showModal(D, 'modalWarningAlerts')"
							>{{ calculateQuantityWarnings() + ' ' + $t('header.warnings') }}</v-btn
						>

						<v-btn
							small
							:disabled="calculateQuantityErrors() == 0"
							elevation="0"
							color="error"
							class="alert-button"
							@click="showModal(D, 'modalErrorAlerts')"
						>
							{{ calculateQuantityErrors() + ' ' + $t('header.errors') }}
						</v-btn>
					</template>

					<template v-else>
						<!-- Pill style -->
						<template v-if="D.pill">
							<div class="state" v-if="!D.statusId">{{ getValue(D) }}</div>
							<div class="state" :class="'state-' + params.entity + '-' + D.statusId" v-if="D.statusId">{{ getValue(D) }}</div>
						</template>

						<!-- Regular text style -->
						<template v-else>
							<div v-if="D.showId" class="state isId">{{ D.id }}</div>
							<span>{{ getValue(D) }}</span>
						</template>
					</template>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	props: {
		data: { type: Array, default: () => {} },
		params: { type: Object, default: () => {} }
	},

	methods: {
		getValue(D) {
			return D.value !== null ? D.value : '0';
		},
		showModal(D, typeAlert) {
			let data = null;

			const alertOrder = {
				sdsmdocument: 1,
				sdsmjointservice: 2,
				sdsmbillofloading: 3,
				sdsmgood: 4
			};
			const comparator = (a, b) => {
				return alertOrder[a.entity] - alertOrder[b.entity];
			};
			D.alert = D.alert.slice().sort(comparator);

			if (typeAlert == 'modalWarningAlerts') {
				data = D.alert.filter((item) => item.type == 'alerta');
			} else if (typeAlert == 'modalErrorAlerts') {
				data = D.alert.filter((item) => item.type == 'error');
			}

			this.$puiEvents.$emit('pui-modalDialogForm-' + typeAlert + '-' + this.params.entity + '-show', { alerts: data });
		},

		calculateQuantityErrors() {
			let alerts = this.data.find((obj) => obj.alert);

			return alerts.alert.filter((item) => item.type == 'error').length;
			//return 2
		},

		calculateQuantityWarnings() {
			let alerts = this.data.find((obj) => obj.alert);

			return alerts.alert.filter((item) => item.type == 'alerta').length;
		},
		showAlerts(D) {
			// eslint-disable-next-line no-prototype-builtins
			if (!D.hasOwnProperty('alert')) {
				return true;
			} else if (D.alert != null) {
				return true;
			}

			return false;
		}
	}
};
</script>

<style scoped>
.PcsumHeader {
	background: var(--main-10) !important;
	overflow: auto;
}

.PcsumHeader .v-card__text {
	padding: 16px !important;
	display: flex;
	align-items: center;
}
.PcsumHeader .v-card__text > div {
	font-size: 13px;
	border-right: 1px solid var(--main-25);
	padding: 0px 20px;
}
.PcsumHeader .v-card__text .state,
.PcsumHeader .v-card__text span {
	color: #000;
}
.PcsumHeader .v-card__text > div:first-child {
	padding-left: 0px;
}
.PcsumHeader .v-card__text > div:last-child {
	border-right: 0px;
}
.PcsumHeader label {
	font-weight: bold;
	font-size: 1em;
	color: var(--primarycolor);
}
.PcsumHeader .alert-button {
	height: 23px !important;
	margin-right: 7px;
}
.PcsumHeader .alert-button.warning {
	background: #fcd37f !important;
	color: #333 !important;
}
.PcsumHeader .alert-button.error {
	background: #f49c90 !important;
	color: #333 !important;
}
.PcsumHeader .alert-button:last-child {
	margin-right: 0px;
}
</style>
