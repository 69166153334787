<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:actions="actions"
			:showDeleteBtn="false"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
		<vutmessagebl-modals :modelName="modelName"></vutmessagebl-modals>
	</div>
</template>

<script>
import vutmessageblActions from './VutmessageblActions';
import vutmessageblModals from './VutmessageblModals.vue';
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixinAccess';

export default {
	name: 'vutmessagebl-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		'vutmessagebl-modals': vutmessageblModals
	},
	data() {
		return {
			modelName: 'vutmessagebl',
			actions: vutmessageblActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
