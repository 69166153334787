<template>
	<div>
		<pcsum-edition-grid
			ref="JS"
			:label="$t('sdsmdocument.jointservices')"
			:labelEmpty="$t('sdsmdocument.nojointservices')"
			:rows="jointservices"
			:fields="fields"
			:params="params"
			:loading="loading"
			:basicDisplay="true"
			:allowNew="false"
			:allowRemove="!formDisabled"
			:allowLaunch="!formDisabled"
			:allowEdit="!formDisabled"
			:editInModal="!formDisabled"
			:userEditable="!formDisabled"
			launchIcon="fas fa-boxes"
			@EmitCreate="EmitCreate"
			@EmitRefresh="EmitRefresh"
			@EmitRemove="EmitRemove"
			@EmitChange="EmitChange"
			@EmitClone="EmitClone"
			@EmitLaunch="EmitLaunch"
			@EmitEditInModal="EmitEditInModal"
		>
		</pcsum-edition-grid>
	</div>
</template>

<script>
import PcsumEditionGrid from './../general/PcsumEditionGrid';
import SdsmjointserviceUtils from '../sdsmjointservice/SdsmjointserviceUtils';
import { mapState } from 'vuex';

export default {
	name: 'sdsmdocument-jointservices-edit',
	components: {
		'pcsum-edition-grid': PcsumEditionGrid
	},
	props: {
		document: { type: Object, default: () => {} },
		params: { type: Object, default: () => {} }
	},
	data() {
		return {
			loading: false,
			jointservices: [],
			formDisabled: false,
			fields: {
				jointservicenumber: {
					label: 'sdsmjointservice.jointserviceid',
					cols: '12 col-xs-12 col-sm-1 col-md-1 col-lg-1 col-xl-1'
				},
				ordernumber: {
					label: 'sdsmjointservice.ordernumber',
					cols: '12 col-xs-12 col-sm-1 col-md-1 col-lg-1 col-xl-1'
				},
				operator: {
					withId: 'operatorid',
					label: 'sdsmjointservice.operatorid',
					cols: '12 col-xs-12 col-sm-3 col-md-4 col-lg-4 col-xl-4'
				},
				state: {
					stateField: 'stateid',
					label: 'sdsmjointservice.stateid',
					cols: '12 col-xs-12 col-sm-2 col-md-4 col-lg-3 col-xl-3'
				}
			}
		};
	},
	methods: {
		getJointServices() {
			this.loading = true;
			this.$puiRequests.postRequest(
				'/puisearch',
				{
					model: 'sdsmjointservice',
					filter: {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'documentid', op: 'eq', data: this.document.documentid }]
					}
				},
				(response) => {
					this.loading = false;
					this.jointservices = response.data.data;
					this.jointservices.sort((a, b) => {
						return a.jointservicenumber - b.jointservicenumber;
					});
					for (const element of this.jointservices) {
						element.editable = true;
						element.launch = this.operatorId == element.operatorid;
						element.randomKey = Math.floor(Math.random() * 1000);
					}
				},
				() => {
					this.loading = false;
				}
			);
		},
		/* Emits */
		EmitCreate() {},
		EmitChange() {},
		EmitEditInModal(registry) {
			this.$puiEvents.$emit('pui-modalDialogForm-sdsmdocumentEditJSInModalAction-sdsmdocument-show', registry);
		},
		async EmitRemove(registry) {
			const deleted = await SdsmjointserviceUtils.deleteJointService(this, registry);
			if (deleted) {
				this.jointservices.splice(registry.rowIndex, 1);
			}
		},
		EmitRefresh() {
			this.getJointServices();
		},
		EmitClone() {},
		EmitLaunch(registry) {
			this.$store.commit('M__MASSIVE_CREATE_MODAL', { open: true, jointservice: registry, billofloading: null });
		}
	},
	created() {
		this.getJointServices();
		this.formDisabled = this.params.formDisabled;
	},
	computed: {
		...mapState({
			operatorId: (state) => state.session.properties.GESTAUT_OPERATOR_ID
		})
	}
};
</script>
