<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:actions="actions"
			:modalDialog="modalDialog"
			:showDeleteBtn="false"
			:showMasterDetailFilterListBtn="true"
			:showMasterDetailFilterBtn="true"
			:showMasterDetailSortBtn="true"
			:showMasterDetailConfBtn="true"
		></pui-datatable>
		<vutmessagejs-modals :modelName="modelName"></vutmessagejs-modals>
	</div>
</template>

<script>
import vutmessagejsActions from './VutmessagejsActions';
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixinAccess';
import vutmessagejsModals from './VutmessagejsModals.vue';

export default {
	name: 'vutmessagejs-grid',
	mixins: [PuiGridDetailMixin],
	components: {
		'vutmessagejs-modals': vutmessagejsModals
	},
	data() {
		return {
			modelName: 'vutmessagejs',
			actions: vutmessagejsActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
