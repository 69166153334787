<template>
	<div class="mequipmenttypeFormHeader puiformheader">
		<v-row>
			<v-col cols="3">
				<pui-field :label="$t('header.mequipmenttype.edicode')" :value="getEdiCode"></pui-field>
			</v-col>
			<v-col cols="9">
				<pui-field :label="$t('header.mequipmenttype.description')" :value="getDescription"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mequipmenttype-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mequipmenttype'
		};
	},
	computed: {
		getDescription() {
			return this.model && this.model.description ? this.model.description : '0';
		},
		getEdiCode() {
			return this.model && this.model.edicode ? this.model.edicode : '0';
		}
	}
};
</script>
