import { EntityStatusUtils } from '../../utils/entityStates';

const copyAction = {
	id: 'idGoodCopyAction',
	selectionType: 'single',
	label: 'action.sdsmgood.copyAction.title',
	functionality: 'COPY_GOOD',
	showInForm: true,
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		row.goodid = null;
		row.ordernumber = null;
		row.stateid = 1;
		this.$puiEvents.$emit('pui-modalDialogForm-sdsmgoodGoodCopyAction-' + model.name + '-show', row);
	}
};

const massiveUpdateAction = {
	id: 'idGoodMassiveUpdateAction',
	selectionType: 'multiple',
	label: 'action.sdsmgood.massiveEditAction.title',
	description: 'action.sdsmgood.massiveEditAction.description',
	functionality: 'MAS_UPD_GOOD',
	showInForm: true,
	checkAvailability: function (registries) {
		return registries.cargooperatorid == this.$store.state.session.properties.GESTAUT_OPERATOR_ID;
	},
	runAction: function (action, model, registries) {
		const data = {
			goods: registries,
			isAction: true
		};
		this.$puiEvents.$emit('pui-modalDialogForm-sdsmgoodGoodMassiveUpdateAction-' + model.name + '-show', data);
	}
};

const GoodSend = {
	id: 'GoodSendAction',
	selectionType: 'multiple',
	label: 'action.sdsmgood.GoodSendAction.title',
	functionality: 'SEND_GOOD',
	//functionality: null,
	showInForm: true,
	checkAvailability: function (reg) {
		if (reg?.stateid) {
			return EntityStatusUtils.checkSendAvailable(reg.stateid);
		}
	},
	runAction: async function (action, model, registries) {
		// Get PK for the header
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		await this.$puiRequests.postRequest(
			'/sdsmgood/send',
			registries,
			() => {
				this.$puiNotify.success(this.$t('general.sendsuccess'));
			},
			(e) => {
				this.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
	}
};

const GoodUndoDelete = {
	id: 'GoodUndoDelete',
	selectionType: 'single',
	label: 'action.sdsmgood.GoodUndoDeleteAction.title',
	functionality: 'UNDO_DEL_DOC',
	//functionality: null,
	showInForm: true,
	checkAvailability: function (reg) {
		return reg?.[0]?.stateid == 9;
	},
	runAction: async function (action, model, registries) {
		// Get PK for the header
		const objectPk = {};
		for (const index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		await this.$puiRequests.postRequest(
			'/sdsmgood/undoDel',
			registries[0].goodid,
			() => {
				this.$puiNotify.success(this.$t('general.success'));
			},
			(e) => {
				this.$puiNotify.error(e.response.data.message);
			},
			null,
			null
		);
		this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
	}
};

export default {
	gridactions: [massiveUpdateAction, copyAction, GoodSend, GoodUndoDelete],
	formactions: [copyAction, GoodSend, GoodUndoDelete]
};
