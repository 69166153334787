<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:externalFilter="CustomExternalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:modelColumnDefs="columnDefs"
			:showDeleteBtn="false"
		></pui-datatable>

		<sdsmmessage-modals :modelName="modelName"></sdsmmessage-modals>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import sdsmmessageActions from './SdsmmessageActions';
import sdsmmessageModals from './SdsmmessageModals.vue';
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixinAccess';

export default {
	name: 'sdsmmessage-grid',
	components: {
		'sdsmmessage-modals': sdsmmessageModals
	},
	mixins: [PuiGridDetailMixin],
	params: {
		blid: {
			required: false,
			type: Object
		},
		jsid: {
			required: false,
			type: Object
		}
	},
	data() {
		return {
			modelName: 'sdsmmessage',
			actions: sdsmmessageActions.gridactions,
			columnDefs: {
				registerdate: {
					render: (data) => {
						return this.parseDate(data);
					}
				},
				messagedate: {
					render: (data) => {
						return this.parseDate(data);
					}
				},
				creadate: {
					render: (data) => {
						return this.parseDate(data);
					}
				},
				moddate: {
					render: (data) => {
						return this.parseDate(data);
					}
				}
			}
		};
	},
	methods: {
		parseDate(data) {
			const fecha = new Date(data);
			return fecha.toLocaleString('es-ES', {
				timeZone: 'Europe/Madrid',
				hour12: false
			});
		}
	},
	computed: {
		...mapState({
			operatorId: (state) => state.session.properties?.GESTAUT_OPERATOR_ID,
			aut: (state) => state.session.properties?.GESTAUT_AUT
		}),
		CustomExternalFilter() {
			if (this.aut) {
				return { groupOp: 'and', rules: [], groups: [] };
			} else {
				return {
					groups: [
						{
							groupOp: 'and',
							rules: [{ field: 'operatorid', op: 'eq', data: this.operatorId }]
						}
					],
					groupOp: 'and',
					rules: []
				};
			}
		}
	}
};
</script>
