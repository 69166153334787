<template>
	<div class="divHeader">
		<pcsum-header :data="HeaderData()" :params="{}"></pcsum-header>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';
import PcsumHeader from '../general/PcsumHeader';

export default {
	name: 'sdsmmessage-form-header',
	mixins: [PuiFormHeaderMixin],
	components: {
		'pcsum-header': PcsumHeader
	},
	props: {
		data: { type: Object, default: () => {} }
	},
	data() {
		return {
			modelName: 'sdsmmessage'
		};
	},
	watch: {
		data(newQuestion) {
			if (newQuestion != null) {
				this.HeaderData();
			}
		}
	},

	methods: {
		HeaderData() {
			return [
				{ label: 'header.sdsmmessage.dock', value: this.getDock() },
				{ label: 'header.sdsmmessage.documento', value: this.getDocumento() },
				{ label: 'header.sdsmmessage.tipo', value: this.getTipo() },
				{
					label: 'header.sdsmmessage.consignatariomsg',
					value: this.getOpDes(),
					showId: true,
					id: this.getConsignatario()
				},
				{ label: 'header.sdsmmessage.transhipementcode', value: this.getState(), pill: true },
				{ label: 'header.sdsmmessage.shipname', value: this.getShipname() },
				{ label: 'header.sdsmmessage.shipid', value: this.getShipid() }
			];
		},
		getDock() {
			return this.data?.port || '';
		},
		getDocumento() {
			return this.data?.documentnumber || '';
		},
		getTipo() {
			return this.data?.tipodoc || '';
		},
		getConsignatario() {
			return this.data?.operatorid || '';
		},
		getOpDes() {
			return this.data?.operator || '';
		},
		getState() {
			return this.data?.callcode || '';
		},
		getShipname() {
			return this.data?.shipname || '';
		},
		getShipid() {
			return this.data?.shipimo || '';
		}
	}
};
</script>
