<template>
	<pcsum-edition-grid
		:label="$t('sdsmgood.documents')"
		:labelEmpty="$t('sdsmgood.nodocuments')"
		:rows="documentscs"
		:fields="fields"
		:params="params"
		:loading="loading"
		:allowCreate="!formDisabled"
		:allowEdit="!formDisabled"
		:allowClone="!formDisabled"
		:allowRemove="!formDisabled"
		:isEditedEditableFields="['documentcstypeid', 'documentnumber', 'documentdate', 'documentcsstatusid']"
		@EmitCreate="EmitCreate"
		@EmitRefresh="EmitRefresh"
		@EmitRemove="EmitRemove"
		@EmitChange="EmitChange"
		@EmitClone="EmitClone"
		@EmitLaunch="EmitLaunch"
	></pcsum-edition-grid>
</template>

<script>
import PcsumEditionGrid from '../general/PcsumEditionGrid';
import SdsmGoodUtils from './SdsmgoodUtils';

export default {
	name: 'sdsmgood-form-documents',
	components: {
		'pcsum-edition-grid': PcsumEditionGrid
	},
	props: {
		good: { type: Object, default: () => {} },
		params: { type: Object, default: () => {} }
	},
	data() {
		return {
			documentscs: [],
			loading: false,
			formDisabled: false,
			emptyRegistry: {
				documentcsid: null,
				goodid: this.good.goodid,
				documentcstypeid: null,
				documentcsstatusid: null,
				documentnumber: null,
				documentdate: '',
				creadate: null,
				moddate: null
			},
			fields: {
				documentcstypeid: {
					label: 'sdsmgood.documentcstypeid',
					rules: [(value) => !!value || this.$t('requiredMessage')],
					cols: '12 col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3',
					itemText: ['documentcode', 'description'],
					modelName: 'mdocumentcstype',
					filter: {
						groups: [
							{
								groupOp: 'and',
								rules: [{ field: 'langtra', op: 'eq', data: this.$store.getters.getUserLanguage }]
							}
						],
						groupOp: 'and',
						rules: []
					}
				},
				documentnumber: {
					label: 'sdsmgood.documentnumber',
					rules: [(value) => !!value || this.$t('requiredMessage')],
					cols: '12 col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3'
				},
				documentdate: {
					label: 'sdsmgood.documentdate',
					rules: [(value) => !!value || this.$t('requiredMessage')],
					cols: '12 col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3',
					date: true
				},
				documentcsstatusid: {
					label: 'sdsmgood.documentcsstatusid',
					rules: [(value) => !!value || this.$t('requiredMessage')],
					cols: '12 col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3',
					itemText: ['documentcode', 'description'],
					modelName: 'mdocumentcsstatus',
					filter: {
						groups: [
							{
								groupOp: 'and',
								rules: [{ field: 'langtra', op: 'eq', data: this.$store.getters.getUserLanguage }]
							}
						],
						groupOp: 'and',
						rules: []
					}
				}
			}
		};
	},
	watch: {},
	methods: {
		getDocumentsCS() {
			if (this.good.goodid) {
				this.loading = true;
				this.$puiRequests.postRequest(
					'/puisearch',
					{
						model: 'sdsmdocumentcustoms',
						rows: null,
						filter: {
							groups: [],
							groupOp: 'and',
							rules: [{ field: 'goodid', op: 'eq', data: this.good.goodid }]
						}
					},
					(response) => {
						this.loading = false;
						this.documentscs = [...response.data.data];
						for (let i = 0; i < this.documentscs.length; i++) {
							this.documentscs[i].isNew = false;
							this.documentscs[i].isSelected = false;
							this.documentscs[i].isEdited = false;
							this.documentscs[i].randomKey = Math.floor(Math.random() * 1000);
						}
					},
					() => {
						this.loading = false;
					}
				);
			}
		},
		/* Create Empty Row */
		EmitCreate() {
			this.documentscs.push({
				...this.emptyRegistry,
				...{
					isNew: true,
					isSelected: false,
					isEdited: false
				}
			});
		},
		/* Editing new row */
		EmitChange(registry) {
			const index = registry.rowIndex;
			this.documentscs[index] = { ...this.documentscs[index], ...registry };
		},
		/* Deleting row */
		EmitRemove(registry) {
			this.documentscs.splice(registry.rowIndex, 1);
			SdsmGoodUtils.deleteDOC(this, registry);
		},
		/* Refresh list */
		EmitRefresh() {
			this.getDocumentsCS();
		},
		EmitClone(registry) {
			this.documentscs.push({
				...registry,
				...{
					documentcsid: null,
					documentnumber: null,
					isNew: true,
					isSelected: false,
					isEdited: false
				}
			});
		},
		EmitLaunch() {}
	},
	created() {
		this.getDocumentsCS();
		this.formDisabled = this.params.formDisabled;
	}
};
</script>
