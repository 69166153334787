<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<mequipmenttype-modals :modelName="modelName"></mequipmenttype-modals>
	</div>
</template>

<script>
import mequipmenttypeActions from './MequipmenttypeActions';
import mequipmenttypeModals from './MequipmenttypeModals.vue';

export default {
	name: 'mequipmenttype-grid',
	components: {
		'mequipmenttype-modals': mequipmenttypeModals
	},
	data() {
		return {
			modelName: 'mequipmenttype',
			actions: mequipmenttypeActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
