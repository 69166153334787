<template>
	<div class="mequipmentsizeFormHeader puiformheader">
		<v-row>
			<v-col cols="3">
				<pui-field :label="$t('header.mequipmentsize.isocode')" :value="getIsoCode"></pui-field>
			</v-col>
			<v-col cols="9">
				<pui-field :label="$t('header.mequipmentsize.description')" :value="getDescription"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mequipmentsize-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mequipmentsize'
		};
	},
	computed: {
		getDescription() {
			return this.model && this.model.description ? this.model.description : '0';
		},
		getIsoCode() {
			return this.model && this.model.isocode ? this.model.isocode : '0';
		}
	}
};
</script>
