<template>
	<v-card class="pui-form MassiveCreate">
		<!-- Joint Service Header -->
		<v-card-title v-if="bol">
			<v-form ref="massiveFormHeader" @submit.prevent>
				<legend class="mb-2">
					{{ isCreatingElement ? $t('modal.sdsmbillofloading.massivecreate.title') : $t('modal.sdsmbillofloading.massiveedit.title') }}
				</legend>
				<pcsum-header v-if="ready" :data="headerData"></pcsum-header>
				<!--------------------------------------->
				<!----------- Bill of lading ----------->
				<!--------------------------------------->
				<v-card-text style="padding: 0px" v-if="ready">
					<div class="MassiveCreateRow" style="display: block">
						<v-row dense>
							<v-col class="12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-select
									id="`bltypeid-sdsmbillofloading`"
									attach="bltypeid-sdsmbillofloading"
									:label="$t('sdsmbillofloading.bltypeid')"
									toplabel
									clearable
									required
									:disabled="!isCreatingElement"
									v-model="bol"
									hide-details
									modelName="vutbillofloadingtype"
									:modelFormMapping="{ bltypeid: 'bltypeid' }"
									:itemsToSelect="[{ bltypeid: bol.bltypeid }]"
									:itemValue="'bltypeid'"
									:queryFields="['description']"
									:itemText="(item) => `${item.description}`"
									:order="{ documentid: 'asc' }"
									singleItemAutoselect
								></pui-select>
							</v-col>
							<v-col class="12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-select
									id="`goodtypeid-vutgoodtype`"
									attach="goodtypeid-sdsmbillofloading"
									:label="$t('sdsmbillofloading.goodtypeid')"
									toplabel
									clearable
									required
									hide-details
									validate-on-blur
									reactive
									v-model="bol"
									modelName="vutgoodtype"
									:modelFormMapping="{ goodtypeid: 'goodtypeid' }"
									:itemsToSelect="[{ goodtypeid: bol.goodtypeid }]"
									:itemValue="'goodtypeid'"
									:queryFields="['description']"
									:itemText="(item) => `${item.description}`"
									:order="{ documentid: 'asc' }"
									singleItemAutoselect
								></pui-select>
							</v-col>
							<v-col class="12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-select
									id="`frontreartransid-mfrontreartransport`"
									attach="frontreartransid-sdsmbillofloading"
									:label="$t('sdsmbillofloading.frontreartransid')"
									toplabel
									clearable
									required
									v-model="bol"
									modelName="vutmfrontreartransporttra"
									:modelFormMapping="{ frontreartransid: 'frontreartransid' }"
									:itemsToSelect="[{ frontreartransid: this.bol.frontreartransid }]"
									:itemValue="'frontreartransid'"
									:queryFields="['description']"
									:itemText="(item) => `${item.description}`"
									:order="{ documentid: 'asc' }"
									detailComponentName="mfrontreartransport-form"
									:fixedFilter="FilterTransport"
									singleItemAutoselect
								></pui-select>
							</v-col>
							<v-col class="12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
								<pui-text-field
									:id="`r-doc`"
									v-model="bol.blnumber"
									:label="$t('sdsmbillofloading.blnumber')"
									toplabel
									hide-details
									:required="bol.bltypeid === 1"
								></pui-text-field>
							</v-col>
							<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-2 pr-2">
								<label class="customLabel v-label--required">{{ $t('sdsmbillofloading.origindes') }}</label>
								<v-autocomplete
									class="customSelector"
									outlined
									dense
									v-model="bol.origincode"
									ref="origincodeRef"
									:loading="loading"
									:items="locinfo"
									clearable
									validate-on-blur
									:readonly="originSelected"
									:rules="[(v) => !!v || $t('requiredMessage')]"
									item-value="locode"
									:item-text="bol.origincode ? `''` : (item) => `${item.locode} - ${item.descripcion}`"
									@keyup="selectIfOnlyOneOptionorigincode"
								>
									<template v-slot:selection="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
									<template v-slot:item="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
								</v-autocomplete>
							</v-col>

							<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-2 pr-2">
								<label class="customLabel v-label--required">{{ $t('sdsmbillofloading.loaddes') }}</label>
								<v-autocomplete
									class="customSelector"
									outlined
									dense
									validate-on-blur
									v-model="bol.loadcode"
									ref="loadcodeRef"
									:loading="loading"
									:items="locinfo"
									:readonly="loadcodeSelected"
									clearable
									:rules="[(v) => !!v || $t('requiredMessage')]"
									item-value="locode"
									:item-text="bol.loadcode ? `''` : (item) => `${item.locode} - ${item.descripcion}`"
									@keyup="selectIfOnlyOneOptionloadcode"
								>
									<template v-slot:selection="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
									<template v-slot:item="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-2 pr-2">
								<label class="customLabel">{{ $t('sdsmbillofloading.transhipmentdes') }}</label>
								<v-autocomplete
									class="customSelector"
									outlined
									dense
									v-model="bol.transhipmentcode"
									:loading="loading"
									:items="locinfo"
									:readonly="transhipmentcodeSelected"
									clearable
									validate-on-blur
									item-value="locode"
									:item-text="bol.transhipmentcode ? `''` : (item) => `${item.locode} - ${item.descripcion}`"
									ref="transhipmentcode"
									@keyup="selectIfOnlyOneOptiontranshipmentcode"
								>
									<template v-slot:selection="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
									<template v-slot:item="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 pl-2 pr-2">
								<label class="customLabel v-label--required">{{ $t('sdsmbillofloading.destinationdes') }}</label>
								<v-autocomplete
									class="customSelector"
									outlined
									dense
									validate-on-blur
									v-model="bol.destinationcode"
									:loading="loading"
									:items="locinfo"
									clearable
									:readonly="destinationcodeSelected"
									:rules="[(v) => !!v || $t('requiredMessage')]"
									item-value="locode"
									:item-text="(item) => `${item.locode} - ${item.descripcion}`"
									ref="destinationcode"
									@keyup="selectIfOnlyOneOptiondestinationcode"
								>
									<template v-slot:selection="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
									<template v-slot:item="{ props, item }">
										<div class="BasicSlotOption" v-bind="props">
											<span> {{ item?.locode }} </span>
											<span> {{ item?.descripcion }} </span>
										</div>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>
					</div>
				</v-card-text>
			</v-form>
		</v-card-title>
		<v-card-text v-if="ready">
			<v-form ref="massiveFormContent" @submit.prevent>
				<template v-if="AllowAddGoods">
					<template v-if="bol.goods.length">
						<template v-for="(G, index) in bol.goods">
							<!---------------------------->
							<!----------- Good ----------->
							<!---------------------------->
							<pui-field-set
								:title="
									$t('modal.sdsmbillofloading.massivecreate.good') +
									' ' +
									(index + 1) +
									(G.ordernumber ? ' (' + G.ordernumber + ')' : '')
								"
								:key="'G-' + index + '/' + bol.goods.length"
								style="margin-left: 0px !important; margin-right: 0px !important"
							>
								<div class="MassiveCreateActions">
									<template v-if="isCargo">
										<button @click="newUTI(index)">
											+
											<i class="far fa-truck"></i>
											<span>{{ G.utis.length }}</span>
										</button>
										<button @click="newDOC(index)">
											+
											<i class="far fa-file"></i>
											<span>{{ G.docs.length }}</span>
										</button>
									</template>
									<button @click="cloneGOOD(index)"><i class="far fa-copy"></i></button>
									<button @click="removeGOOD(index)" v-if="!G.goodid" class="remove">
										<i class="fas fa-trash"></i>
									</button>
									<div v-if="G.state" class="state" :class="'state-' + G.stateid">{{ G.state }}</div>
								</div>
								<div class="MassiveCreateRow">
									<v-row dense>
										<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-1 col-xl-1">
											<pui-text-field
												:id="`packagesnumber-sdsmgood`"
												v-model="bol.goods[index].packagesnumber"
												:label="$t(isCargo ? 'sdsmgood.packagesnumber' : 'sdsmgood.passengersnumber')"
												:rules="[(v) => v >= 0 || $t('requiredMessage')]"
												toplabel
												maxlength="12"
											></pui-text-field>
										</v-col>
										<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-1 col-xl-1" v-if="isCargo">
											<pui-text-field
												:id="`weight-sdsmgood`"
												v-model="bol.goods[index].weight"
												:label="$t('sdsmgood.weight')"
												required
												toplabel
												maxlength="18"
											></pui-text-field>
										</v-col>
										<v-col cols="12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3" v-if="isCargo">
											<pui-select
												outlined
												dense
												toplabel
												required
												:disabled="false"
												:label="$t('sdsmgood.packagetypeid')"
												clearable
												modelName="mpackagetype"
												v-model="bol.goods[index]"
												:itemsToSelect="[{ packagetypeid: bol.goods[index].packagetypeid }]"
												:modelFormMapping="{ packagetypeid: 'packagetypeid' }"
												item-value="packagetypeid"
												:queryFields="['edicode', 'description']"
												:itemText="(item) => `${item.edicode} - ${item.description}`"
												singleItemAutoselect
											></pui-select>
										</v-col>
										<v-col
											cols="12 col-xs-12 pl-2 pr-2"
											:class="{
												'col-sm-4 col-md-4 col-lg-4 col-xl-4': isCargo,
												'col-sm-6 col-md-6 col-lg-6 col-xl-6': isPassengers
											}"
										>
											<label class="customLabel v-label--required">{{ $t('sdsmgood.taricid') }}</label>
											<v-autocomplete
												class="customSelector"
												outlined
												dense
												v-model="bol.goods[index].taricid"
												:items="filteredTarCodes"
												:disabled="false"
												:readonly="taricSelected[index]"
												reactive
												required
												clearable
												@keyup="(event) => selectIfOnlyOneOption(event, index)"
												:ref="'taricid' + index"
												@change="setVehBraIdIfRequired(index)"
												:rules="[(v) => !!v || $t('requiredMessage')]"
												item-value="taricid"
												:item-text="taricSelected[index] ? `''` : (item) => `${item.tariccod} - ${item.description}`"
											>
												<template v-slot:item="{ props, item }">
													<div class="SlotOptionWithDetails" v-bind="props">
														<span class="value">{{ item.tariccod }}</span>
														<span class="separator"> - </span>
														<span>{{ item.description }}</span>
													</div>
												</template>
												<template v-slot:selected="{ props, item }">
													<div class="SlotOptionWithDetails" v-bind="props">
														<span class="value">{{ item.tariccod }}</span>
														<span class="separator"> - </span>
														<span>{{ item.description }}</span>
													</div>
												</template>
											</v-autocomplete>
										</v-col>
										<v-col
											v-if="isCargo"
											cols="12 col-xs-12"
											:class="{
												'col-sm-6 col-md-3 col-lg-3 col-xl-3': isCargo,
												'col-sm-5 col-md-5 col-lg-5 col-xl-5': isPassengers
											}"
										>
											<pui-select
												outlined
												dense
												required
												:label="$t('sdsmgood.cussituationid')"
												:disabled="false"
												toplabel
												clearable
												modelName="mcustomssituation"
												v-model="bol.goods[index]"
												:itemsToSelect="[{ cussituationid: bol.goods[index].cussituationid }]"
												:modelFormMapping="{ cussituationid: 'cussituationid' }"
												:itemValue="['cussituationid']"
												:queryFields="['edicode', 'description']"
												:itemText="(item) => `${item.edicode} - ${item.description}`"
												singleItemAutoselect
											></pui-select>
										</v-col>
									</v-row>
								</div>

								<!---------------------------------->
								<!----------- Equipments ----------->
								<!---------------------------------->
								<div class="MassiveCreateRowWrapper" :key="'GUTIW-' + index">
									<template v-if="G.utis.length">
										<template v-for="(UTI, index2) in G.utis">
											<div class="MassiveCreateRow SubRow" :key="'GUTI-' + index2" :ref="'GUTI-' + index2">
												<v-row dense>
													<v-col class="12 col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 SubRowIconCol">
														<v-icon color="primary">fal fa-truck</v-icon>
														<span>{{ index2 + 1 }}</span>
													</v-col>
													<v-col class="12 col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
														<pui-text-field
															:id="`utiidentification-sdsmuti`"
															v-model="bol.goods[index].utis[index2].utiidentification"
															:label="$t('sdsmuti.utiidentification')"
															:disabled="UTI.utiid !== null"
															@keyup="fillUTIFields(index, index2)"
															toplabel
														></pui-text-field>
													</v-col>
													<v-col class="12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
														<pui-select
															id="`eqtypeid-sdsmuti`"
															:label="$t('sdsmuti.eqtypeid')"
															toplabel
															outlined
															required
															:reactive="bol.goods[index].utis[index2].eqtypeid !== null"
															modelName="mequipmenttype"
															:disabled="UTI.utiid !== null"
															v-model="bol.goods[index].utis[index2]"
															:itemValue="['eqtypeid']"
															:modelFormMapping="{ eqtypeid: 'eqtypeid' }"
															:itemsToSelect="[{ eqtypeid: bol.goods[index].utis[index2].eqtypeid }]"
															:queryFields="['edicode', 'description']"
															:itemText="(item) => `${item.edicode} - ${item.description}`"
															:order="{ description: 'asc' }"
															:fixedFilter="DateExpirationFilter"
															singleItemAutoselect
														>
														</pui-select>
													</v-col>
													<v-col class="12 col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
														<pui-select
															id="`eqsizeid-sdsmuti`"
															:label="$t('sdsmuti.eqsizeid')"
															toplabel
															outlined
															required
															:reactive="bol.goods[index].utis[index2].eqsizeid !== null"
															modelName="mequipmentsize"
															:disabled="UTI.utiid !== null"
															v-model="bol.goods[index].utis[index2]"
															:itemValue="['eqsizeid']"
															modelFormP
															:queryFields="['isocode', 'description']"
															:modelFormMapping="{ eqsizeid: 'eqsizeid' }"
															:itemsToSelect="[{ eqsizeid: bol.goods[index].utis[index2].eqsizeid }]"
															:itemText="(item) => `${item.isocode} - ${item.description}`"
															:order="{ description: 'asc' }"
															:fixedFilter="DateExpirationFilter"
															singleItemAutoselect
														></pui-select>
													</v-col>
													<v-col class="12 col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
														<pui-text-field
															:id="`tare-sdsmuti`"
															v-model="bol.goods[index].utis[index2].tare"
															:label="$t('sdsmuti.tare')"
															required
															:disabled="UTI.utiid !== null"
															toplabel
														></pui-text-field>
													</v-col>
													<v-col class="12 col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
														<pui-text-field
															:id="`seal-sdsmuti`"
															required
															v-model="bol.goods[index].utis[index2].packagenumber"
															:label="$t('sdsmgood.packagenumber')"
															toplabel
														></pui-text-field>
													</v-col>
													<v-col class="12 col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
														<pui-text-field
															:id="`seal-sdsmuti`"
															v-model="bol.goods[index].utis[index2].seal.sealnumber"
															:label="$t('sdsmuti.seal')"
															toplabel
															:disabled="UTI.utiid !== null"
														></pui-text-field>
													</v-col>
													<v-col class="12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
														<pui-select
															id="`utistatusid-sdsmuti`"
															:label="$t('sdsmuti.utistatusid')"
															toplabel
															outlined
															dense
															:reactive="bol.goods[index].utis[index2].utistatusid !== null"
															:disabled="UTI.utiid !== null"
															required
															modelName="mutistatus"
															v-model="bol.goods[index].utis[index2]"
															:modelFormMapping="{ utistatusid: 'utistatusid' }"
															:itemsToSelect="[{ utistatusid: bol.goods[index].utis[index2].utistatusid }]"
															:itemValue="['utistatusid']"
															:queryFields="['description']"
															:itemText="(item) => `${item.description}`"
															:order="{ description: 'asc' }"
															singleItemAutoselect
														></pui-select>
													</v-col>
												</v-row>
												<div class="MassiveCreateActions" v-if="UTI.utiid == null">
													<button @click="removeUTI(index, index2)" class="remove">
														<i class="fas fa-trash"></i>
													</button>
												</div>
											</div>
										</template>
									</template>
								</div>
								<!--------------------------------->
								<!----------- Documents ----------->
								<!--------------------------------->
								<div class="MassiveCreateRowWrapper" :key="'GDOCW-' + index">
									<template v-if="G.docs && G.docs.length">
										<template v-for="(DOC, index3) in G.docs">
											<div class="MassiveCreateRow SubRow" :key="'GDOC-' + index3">
												<v-row dense>
													<v-col class="12 col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 SubRowIconCol">
														<v-icon color="primary" style="position: relative; left: -7px">fal fa-file</v-icon>
														<span>{{ index3 + 1 }}</span>
													</v-col>
													<v-col class="12 col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
														<pui-text-field
															:id="`documentnumber-doc`"
															v-model="bol.goods[index].docs[index3].documentnumber"
															:label="$t('sdsmgood.documentnumber')"
															toplabel
															required
														></pui-text-field>
													</v-col>
													<v-col class="12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
														<pui-select
															id="`documentcstypeid-doc`"
															toplabel
															outlined
															dense
															required
															:label="$t('sdsmgood.documentcstypeid')"
															v-model="bol.goods[index].docs[index3]"
															modelName="mdocumentcstype"
															:itemValue="['documentcstypeid']"
															:modelFormMapping="{ documentcstypeid: 'documentcstypeid' }"
															:itemsToSelect="[{ documentcstypeid: bol.goods[index].docs[index3].documentcstypeid }]"
															:queryFields="['description']"
															:itemText="(item) => `${item.description}`"
															singleItemAutoselect
														></pui-select>
													</v-col>
													<v-col class="12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
														<pui-select
															id="`documentcsstatusid-doc`"
															:label="$t('sdsmgood.documentcsstatusid')"
															toplabel
															outlined
															dense
															required
															v-model="bol.goods[index].docs[index3]"
															modelName="mdocumentcsstatus"
															:itemValue="['documentcsstatusid']"
															:modelFormMapping="{ documentcsstatusid: 'documentcsstatusid' }"
															:itemsToSelect="[
																{ documentcsstatusid: bol.goods[index].docs[index3].documentcsstatusid }
															]"
															:queryFields="['description']"
															:item-text="(item) => `${item.description}`"
															singleItemAutoselect
														></pui-select>
													</v-col>
													<v-col class="12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
														<pui-date-field
															:id="`documentdate-doc`"
															:label="$t('sdsmgood.documentdate')"
															toplabel
															dense
															required
															v-model="bol.goods[index].docs[index3].documentdate"
														></pui-date-field>
													</v-col>
												</v-row>
												<div class="MassiveCreateActions" v-if="DOC.documentcsid == null">
													<button @click="removeDOC(index, index3)" class="remove">
														<i class="fas fa-trash"></i>
													</button>
												</div>
											</div>
										</template>
									</template>
								</div>
							</pui-field-set>
						</template>
					</template>
				</template>
				<template v-else>
					<!--------------------------------------->
					<!----------- BL > Equipments ----------->
					<!--------------------------------------->
					<template v-if="bol && bol.utis && bol.utis.length">
						<template v-for="(BLUTI, index) in bol.utis">
							<pui-field-set
								:title="'Equipamiento ' + (index + 1)"
								:key="'BLUTI-' + index + '/' + bol.utis.length"
								style="margin-left: 0px !important; margin-right: 0px !important"
							>
								<div class="MassiveCreateRow SubRow" :key="'BLUTIR-' + index" :ref="'BLUTI-' + index">
									<v-row dense>
										<v-col class="12 col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
											<pui-text-field
												:id="`utiidentification-sdsmuti`"
												v-model="bol.utis[index].utiidentification"
												:label="$t('sdsmuti.utiidentification')"
												:disabled="false"
												:required="bol.frontreartransid === 4 || bol.frontreartransid === 5"
												toplabel
											></pui-text-field>
										</v-col>
										<v-col class="12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-select
												id="`eqtypeid-sdsmuti`"
												:label="$t('sdsmuti.eqtypeid')"
												toplabel
												outlined
												modelName="mequipmenttype"
												v-model="bol.utis[index]"
												:disabled="false"
												:itemValue="['eqtypeid']"
												:queryFields="['edicode', 'description']"
												:modelFormMapping="{ eqtypeid: 'eqtypeid' }"
												:itemsToSelect="[{ eqtypeid: bol.utis[index].eqtypeid }]"
												:itemText="(item) => `${item.edicode} - ${item.description}`"
												:order="{ description: 'asc' }"
												:fixedFilter="DateExpirationFilter"
												singleItemAutoselect
											>
											</pui-select>
										</v-col>
										<v-col class="12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-select
												id="`eqsizeid-sdsmuti`"
												:label="$t('sdsmuti.eqsizeid')"
												outlined
												toplabel
												:disabled="false"
												v-model="bol.utis[index]"
												modelName="mequipmentsize"
												:item-value="'eqsizeid'"
												:queryFields="['isocode', 'description']"
												:itemText="(item) => `${item.isocode} - ${item.description}`"
												:modelFormMapping="{ eqsizeid: 'eqsizeid' }"
												:itemsToSelect="[{ eqsizeid: bol.utis[index].eqsizeid }]"
												:order="{ description: 'asc' }"
												:fixedFilter="DateExpirationFilter"
												singleItemAutoselect
											></pui-select>
										</v-col>
										<v-col class="12 col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
											<pui-text-field
												:id="`tare-sdsmuti`"
												v-model="bol.utis[index].tare"
												:label="$t('sdsmuti.tare')"
												required
												:rules="[(v) => v >= 0 || $t('requiredMessage')]"
												:disabled="false"
												toplabel
											></pui-text-field>
										</v-col>
										<v-col class="12 col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
											<pui-text-field
												:id="`units-sdsmuti`"
												v-model="bol.utis[index].units"
												:label="$t('sdsmuti.units')"
												required
												:rules="[(v) => v >= 0 || $t('requiredMessage')]"
												:disabled="false"
												toplabel
											></pui-text-field>
										</v-col>
										<v-col class="12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
											<pui-select
												id="`utistatusid-sdsmuti`"
												:label="$t('sdsmuti.utistatusid')"
												toplabel
												outlined
												dense
												modelName="mutistatus"
												:disabled="true"
												v-model="bol.utis[index]"
												:modelFormMapping="{ utistatusid: 'utistatusid' }"
												:itemsToSelect="[{ utistatusid: bol.utis[index].utistatusid }]"
												:itemValue="['utistatusid']"
												:queryFields="['description']"
												:itemText="(item) => `${item.description}`"
												:order="{ description: 'asc' }"
												singleItemAutoselect
											></pui-select>
										</v-col>
									</v-row>
									<div class="MassiveCreateActions" v-if="BLUTI.utiid == null">
										<button @click="removeBLUTI(index)" class="remove"><i class="fas fa-trash"></i></button>
									</div>
								</div>
							</pui-field-set>
						</template>
					</template>
				</template>
			</v-form>
		</v-card-text>
		<v-card-text v-else>
			<v-progress-linear color="primary" indeterminate></v-progress-linear>
		</v-card-text>
		<v-card-actions v-if="ready">
			<v-spacer></v-spacer>
			<v-btn elevation="0" @click="CloseMassiveCreate()">{{ $t('general.cancel') }}</v-btn>
			<v-btn v-if="isEmpty" :disabled="!AllowSave" elevation="0" color="primary" @click="newBLUTI()">{{
				$t('modal.sdsmbillofloading.massivecreate.adduti')
			}}</v-btn>
			<v-btn v-if="!isEmpty" :disabled="!AllowAddGoods" elevation="0" color="primary" @click="newGOOD()">{{
				$t('modal.sdsmbillofloading.massivecreate.addgood')
			}}</v-btn>
			<v-btn :disabled="!AllowSave" elevation="0" color="secondary" @click="saveMassive()">{{ $t('general.save') }}</v-btn>
		</v-card-actions>

		<!-- Modal for removing -->
		<v-dialog v-model="removeDialog.open" persistent max-width="800">
			<v-card>
				<v-card-title>{{ removeDialog.text }}</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn elevation="0" @click="resetRemoveDialog()">{{ $t('general.cancel') }}</v-btn>
					<v-btn
						elevation="0"
						color="secondary"
						@click="
							() => {
								removeDialog.confirm();
								resetRemoveDialog();
							}
						"
						>{{ $t('general.remove') }}</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-card>
</template>

<script>
import SdsmGoodUtils from '../../sdsmgood/SdsmgoodUtils';
import SdsmUtiUtils from '../../sdsmuti/SdsmutiUtils';
import SdsmbillofloadingUtils from '../SdsmbillofloadingUtils';
import PcsumHeader from './../../general/PcsumHeader';
import { mapState } from 'vuex';
export default {
	name: 'sdsmbillofloading-massive-create',
	components: {
		'pcsum-header': PcsumHeader
	},
	props: {
		jointservice: { type: Object, default: () => {} },
		billofloading: { type: Object, default: () => {} },
		modelName: { type: String, default: '' },
		isCreatingElement: { type: Boolean, default: false }
	},
	data() {
		return {
			ready: true,
			removeDialog: {
				open: false,
				text: null,
				confirm: null
			},
			originSelected: false,
			loadcodeSelected: false,
			transhipmentcodeSelected: false,
			destinationcodeSelected: false,
			loading: false,
			taricSelected: false,
			equipmentsFromBL: [],
			newUTITemplate: null,
			newDOCTemplate: null,
			bol: {}
		};
	},
	watch: {
		'bol.origincode'(newQuestion) {
			if (newQuestion != null) {
				this.originSelected = true;
			} else {
				this.originSelected = false;
			}
		},
		'bol.loadcode'(newQuestion) {
			if (newQuestion != null) {
				this.loadcodeSelected = true;
			} else {
				this.loadcodeSelected = false;
			}
		},
		'bol.transhipmentcode'(newQuestion) {
			if (newQuestion != null) {
				this.transhipmentcodeSelected = true;
			} else {
				this.transhipmentcodeSelected = false;
			}
		},
		'bol.destinationcode'(newQuestion) {
			if (newQuestion != null) {
				this.destinationcodeSelected = true;
			} else {
				this.destinationcodeSelected = false;
			}
		}
	},
	computed: {
		...mapState({
			locinfo: (state) => state.PCS.locinfo,
			tarcodes: (state) => state.PCSUM.tarcodes
		}),
		AllowSave() {
			return (
				this.bol?.bltypeid &&
				this.bol?.goodtypeid &&
				this.bol?.loadcode &&
				this.bol?.frontreartransid &&
				this.bol?.origincode &&
				this.bol?.destinationcode &&
				((this.isCargo && this.bol?.blnumber) || !this.isCargo)
			);
		},
		AllowAddGoods() {
			return this.AllowSave && !this.isEmpty;
		},
		getTotalGoods() {
			return this.bol?.goods ? this.bol.goods.length.toString() : null;
		},
		getTotalUtis() {
			let t = 0;
			if (this.bol?.goods) {
				for (let g in this.bol.goods) {
					t += this.bol.goods[g].utis.length;
				}
			}
			return t.toString();
		},
		getTotalDocs() {
			let t = 0;
			if (this.bol?.goods) {
				for (let g in this.bol.goods) {
					t += this.bol.goods[g].docs.length;
				}
			}
			return t.toString();
		},
		headerData() {
			return [
				{ label: 'header.sdsmjointservice.documento', value: this.jointservice.documentnumber },
				{ label: 'sdsmjointservice.jointservicenumber', value: this.jointservice.jointservicenumber },
				{ label: 'sdsmjointservice.ordernumber', value: this.jointservice.ordernumber },
				{ label: 'sdsmjointservice.operator', value: this.jointservice.operator, showId: true, id: this.jointservice.operatorid },
				{ label: 'header.sdsmjointservice.shipname', value: this.jointservice.shipname },
				{ label: 'header.sdsmjointservice.tramstate', value: this.jointservice.state, pill: true },
				{ label: 'header.sdsmdocument.goods', value: this.getTotalGoods, pill: true },
				{ label: 'header.sdsmdocument.utils', value: this.getTotalUtis, pill: true },
				{ label: 'sdsmgood.documents', value: this.getTotalDocs, pill: true }
			];
		},
		LangFilter() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'langtra', op: 'eq', data: this.$store.getters.getUserLanguage }]
					}
				],
				groupOp: 'and',
				rules: []
			};
		},
		DateExpirationFilter() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [{ field: 'dateexpiration', op: 'nu' }]
					}
				],
				groupOp: 'and',
				rules: []
			};
		},
		FilterTransport() {
			const filter = {
				groups: [
					{
						rules: [{ field: 'edicode', op: 'eq', data: 'ZZ1' }]
					},
					{
						rules: [{ field: 'edicode', op: 'eq', data: 'ZZ2' }]
					},
					{
						rules: [{ field: 'edicode', op: 'eq', data: 'ZZ6' }]
					}
				],
				groupOp: 'or',
				rules: []
			};
			return this.isPassengers ? filter : null;
		},

		filteredTarCodes() {
			return SdsmGoodUtils.filterTarCodes(this.tarcodes, this.isPassengers);
		},
		isCargo() {
			return this.bol.bltypeid === 1;
		},
		isPassengers() {
			return this.bol.bltypeid === 2;
		},
		isEmpty() {
			return this.bol.bltypeid === 3;
		}
	},
	methods: {
		/* Good */
		async afterGetData() {},
		async newGOOD() {
			const newGood = await SdsmGoodUtils.newGood(this);
			newGood.packagesnumber = 0;
			newGood.utis = [];
			newGood.docs = [];
			this.bol.goods.push(newGood);
		},
		async cloneGOOD(index) {
			const clonedGOOD = JSON.parse(JSON.stringify({ ...this.bol.goods[index] }));
			clonedGOOD.goodid = null;
			clonedGOOD.stateid = 1;
			clonedGOOD.ordernumber = null;
			this.bol.goods.push(clonedGOOD);
		},
		selectIfOnlyOneOption(event, index) {
			if (event.key == 'Backspace' && this.bol.goods[index].taricid != null) {
				this.bol.goods[index].taricid = null;
			}
			let text = event.target.value;
			let resultadosFiltrados = this.filteredTarCodes.filter((item) => {
				return item.tariccod.toLowerCase().includes(text.toLowerCase()) || item.description.toLowerCase().includes(text.toLowerCase());
			});

			if (resultadosFiltrados.length == 1) {
				this.bol.goods[index].taricid = resultadosFiltrados[0].taricid;

				this.$refs['taricid' + index].isMenuActive = false;
			}
		},
		removeGOOD(goodIndex) {
			this.removeDialog.text = this.$puiI18n.t('modal.sdsmbillofloading.massivecreate.removegood');
			this.removeDialog.open = true;
			this.removeDialog.confirm = () => this.bol.goods.splice(goodIndex, 1);
		},
		/* BL > UTI */
		async newBLUTI() {
			const newBLUTI = await { ...this.newUTITemplate };
			newBLUTI.utistatusid = 1; // Default status -> Empty
			this.bol.utis.push(newBLUTI);
		},
		async removeBLUTI(utiIndex) {
			this.removeDialog.text = this.$puiI18n.t('modal.sdsmbillofloading.massivecreate.removeuti');
			this.removeDialog.open = true;
			this.removeDialog.confirm = async () => {
				this.bol.utis.splice(utiIndex, 1);
			};
		},
		/* UTI */
		async newUTI(index) {
			if (this.checkUTIs()) {
				const newUTI = { ...this.newUTITemplate };
				newUTI.seal = { sealnumber: null };
				newUTI.packagenumber = this.calculateRestPackagesNumber(index);
				this.bol.goods[index].utis.push(newUTI);
				this.scanUTIs();
			}
		},
		calculateRestPackagesNumber(goodIndex) {
			let rest = 0;
			let utiTotal = 0;
			let goodPackages = this.bol.goods[goodIndex].packagesnumber;
			if (goodPackages) {
				for (let u = 0; u < this.bol.goods[goodIndex].utis.length; u++) {
					utiTotal += parseInt(this.bol.goods[goodIndex].utis[u].packagenumber);
				}
				rest = goodPackages - utiTotal;
			}
			return rest >= 0 ? rest : 0;
		},
		fillUTIFields(goodIndex, utiIndex) {
			if (this.bol.goods[goodIndex].utis[utiIndex].utiidentification.length >= 3) {
				const f = this.equipmentsFromBL.find((e) => e.utiidentification === this.bol.goods[goodIndex].utis[utiIndex].utiidentification);
				if (f) {
					for (const field in f) {
						if (field !== 'packagenumber') {
							this.bol.goods[goodIndex].utis[utiIndex][field] = f[field];
						}
					}
				}
			}
		},
		async removeUTI(goodIndex, utiIndex) {
			this.removeDialog.text = this.$puiI18n.t('modal.sdsmbillofloading.massivecreate.removeuti');
			this.removeDialog.open = true;
			this.removeDialog.confirm = async () => {
				const deleted = await SdsmGoodUtils.deleteGOODUTI(this, this.bol.goods[goodIndex].utis[utiIndex]);
				if (deleted) {
					this.bol.goods[goodIndex].utis.splice(utiIndex, 1);
				}
			};
		},
		checkUTIs() {
			let addNew = true;
			for (let g = 0; g < this.bol.goods.length; g++) {
				for (let u = 0; u < this.bol.goods[g].utis.length; u++) {
					if (
						!this.bol.goods[g].utis[u].utiidentification ||
						!this.bol.goods[g].utis[u].eqtypeid ||
						!this.bol.goods[g].utis[u].eqsizeid ||
						!this.bol.goods[g].utis[u].tare ||
						!this.bol.goods[g].utis[u].utistatusid
					) {
						this.$puiNotify.error(this.$puiI18n.t('modal.sdsmbillofloading.massivecreate.fillutifields'));
						addNew = false;
					}
				}
			}
			return addNew;
		},
		scanUTIs() {
			for (let g = 0; g < this.bol.goods.length; g++) {
				for (let u = 0; u < this.bol.goods[g].utis.length; u++) {
					if (this.bol.goods[g].utis[u].utiidentification) {
						const existUti = this.equipmentsFromBL.find((e) => e.utiidentification === this.bol.goods[g].utis[u].utiidentification);
						if (!existUti) {
							this.equipmentsFromBL.push(this.bol.goods[g].utis[u]);
						}
					}
				}
			}
		},
		/* DOC */
		async newDOC(index) {
			const newDoc = { ...this.newDOCTemplate };
			this.bol.goods[index].docs.push(newDoc);
		},
		async removeDOC(goodIndex, docIndex) {
			this.removeDialog.text = this.$puiI18n.t('modal.sdsmbillofloading.massivecreate.removedoc');
			this.removeDialog.open = true;
			this.removeDialog.confirm = async () => {
				const deleted = await SdsmGoodUtils.deleteDOC(this, this.bol.goods[goodIndex].docs[docIndex]);
				if (deleted) {
					this.bol.goods[goodIndex].docs.splice(docIndex, 1);
				}
			};
		},
		/* Others */
		resetRemoveDialog() {
			this.removeDialog.text = '';
			this.removeDialog.open = false;
			this.removeDialog.confirm = null;
		},
		WatchBlTypeID(newValue, oldValue) {
			if (newValue != oldValue) {
				if (this.isEmpty || this.isPassengers) {
					this.bol.goodtypeid = 4;
				}
				// Reset
				this.bol.goods = [];
				this.bol.utis = [];
			}
		},
		setVehBraIdIfRequired(index) {
			const taricid = this.bol.goods[index].taricid;
			const tar = this.tarcodes.find((t) => t.taricid === taricid);
			if (SdsmGoodUtils.checkVehBrandRequired(tar.tariccod) && !this.bol.goods[index].vehbraid) {
				this.bol.goods[index].vehbraid = 1; // PUI_VARIABLE
			}
		},
		WatchOriginCode(newValue) {
			this.bol.origindes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo);
		},
		WatchTranshipmentCode(newValue) {
			this.bol.transhipmentdes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo);
		},
		WatchLoadCode(newValue) {
			this.bol.loaddes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo);
		},
		WatchDestinationCode(newValue) {
			this.bol.destinationdes = SdsmbillofloadingUtils.getDesFromLoccode(newValue, this.locinfo);
		},
		getDesFromLoccode(code, field) {
			this.bol[field] = SdsmbillofloadingUtils.getDesFromLoccode(code, this.locinfo);
		},
		CloseMassiveCreate() {
			this.$store.commit('M__MASSIVE_CREATE_MODAL', { open: false, jointservice: false, billofloading: null });
			return new Promise((resolve) => {
				resolve(true);
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
			});
		},
		async saveMassive() {
			SdsmbillofloadingUtils.fillMassiveCreateFields(this, this.jointservice, this.bol);
			const valid = await SdsmbillofloadingUtils.checkMasiveCreateBeforeSaving(this, this.jointservice, this.bol);
			if (valid) {
				this.ready = false;
				const response = await SdsmbillofloadingUtils.saveMassive(this, this.jointservice, this.bol);
				this.ready = true;
				if (response) {
					this.$puiNotify.success(this.$puiI18n.t('modal.sdsmbillofloading.massivecreate.notifySuccess'));
					this.CloseMassiveCreate();
				}
			}
		},
		selectIfOnlyOneOptionorigincode(event) {
			if (event.key == 'Backspace' && this.bol.origincode != null) {
				this.bol.origincode = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.locinfo.filter((item) => {
				return item.locode.toLowerCase().includes(text.toLowerCase());
			});
			if (resultadosFiltrados.length == 1) {
				this.bol.origincode = resultadosFiltrados[0].locode;

				this.$refs.origincodeRef.isMenuActive = false;
			}
		},
		selectIfOnlyOneOptionloadcode(event) {
			if (event.key == 'Backspace' && this.bol.loadcode != null) {
				this.bol.loadcode = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.locinfo.filter((item) => {
				return item.locode.toLowerCase().includes(text.toLowerCase());
			});
			if (resultadosFiltrados.length == 1) {
				this.bol.loadcode = resultadosFiltrados[0].locode;

				this.$refs.loadcodeRef.isMenuActive = false;
			}
		},
		selectIfOnlyOneOptiontranshipmentcode(event) {
			if (event.key == 'Backspace' && this.bol.transhipmentcode != null) {
				this.bol.transhipmentcode = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.locinfo.filter((item) => {
				return item.locode.toLowerCase().includes(text.toLowerCase());
			});
			if (resultadosFiltrados.length == 1) {
				this.bol.transhipmentcode = resultadosFiltrados[0].locode;

				this.$refs.transhipmentcode.isMenuActive = false;
			}
		},
		selectIfOnlyOneOptiondestinationcode(event) {
			if (event.key == 'Backspace' && this.bol.destinationcode != null) {
				this.bol.destinationcode = null;
			}

			let text = event.target.value;
			let resultadosFiltrados = this.locinfo.filter((item) => {
				return item.locode.toLowerCase().includes(text.toLowerCase());
			});
			if (resultadosFiltrados.length == 1) {
				this.bol.destinationcode = resultadosFiltrados[0].locode;

				this.$refs.destinationcode.isMenuActive = false;
			}
		}
	},
	async created() {
		this.loading = true;
		if (this.billofloading) {
			this.ready = false;
			// Equipments from existing Bill of lading
			this.equipmentsFromBL = await SdsmbillofloadingUtils.getEquipmentsFromBOL(this, this.billofloading.blid);
			// Retrieving / building data
			this.bol = await SdsmbillofloadingUtils.getAndPrepareBOLForMassiveEdit2(this, this.billofloading); // Step 1 --> retrieving goods / good-utis / docs
			this.bol = await SdsmbillofloadingUtils.MergeBOLAndEquipmentsFromBOL(this, this.bol, this.equipmentsFromBL); // Step 2 --> merging utis from BL
			this.$set(this.bol, 'utis', this.equipmentsFromBL); // BL -> utis
			this.ready = true;
		} else {
			// New
			const newBillofloading = await SdsmbillofloadingUtils.newBillOfLoading(this);
			newBillofloading.goods = [];
			this.bol = newBillofloading;
			this.$set(this.bol, 'utis', []); // BL -> utis
			this.$watch('bol.bltypeid', this.WatchBlTypeID);
		}
		await SdsmbillofloadingUtils.getLocInfo(this);
		SdsmGoodUtils.getTarCodes(this);
		this.newDOCTemplate = await SdsmGoodUtils.newDOC(this);
		this.newUTITemplate = await SdsmUtiUtils.newUTI(this);
		this.loading = false;

		this.$watch('bol.origincode', this.WatchOriginCode);
		this.$watch('bol.loadcode', this.WatchLoadCode);
		this.$watch('bol.transhipmentcode', this.WatchTranshipmentCode);
		this.$watch('bol.destinationcode', this.WatchDestinationCode);
	}
};
</script>

<style scoped>
#cussituationid-sdsmgood {
	position: relative;
}

.MassiveCreate .v-card__title {
	border-bottom: 1px solid var(--main-25);
	margin-bottom: 5px;
}
.MassiveCreate .PcsumHeader {
	width: 100%;
}
.MassiveCreate .MassiveCreateRow {
	padding-top: 20px;
	display: grid;
	grid-template-columns: auto 50px;
}
.MassiveCreate .MassiveCreateRow:nth-child(2) {
	border-top: 0px;
	padding-top: 0px;
}
.MassiveCreate .MassiveCreateActions {
	margin-top: 20px;
	padding-left: 10px;
	display: flex;
	align-content: center;
	justify-content: flex-start;
}
.MassiveCreate .MassiveCreateActions button {
	opacity: 0.75;
	margin-right: 25px;
	font-size: 1.25em;
	cursor: pointer;
	color: var(--N-300);
	position: relative;
}
.MassiveCreate .MassiveCreateActions button span {
	position: absolute;
	color: var(--primarycolor);
	font-size: 0.75em;
	top: -12px;
}
.MassiveCreate .MassiveCreateActions button:last-child {
	margin-right: 0px;
}
.MassiveCreate .MassiveCreateActions button:hover {
	color: var(--primarycolor);
	opacity: 1;
}
.MassiveCreate .MassiveCreateActions button.remove:hover {
	color: var(--warningcolor);
}
.MassiveCreate .MassiveCreateRow.SubRow {
	border-top: 0px;
	padding-top: 0px;
	padding-right: 0px;
}
.MassiveCreate .MassiveCreateRow.SubRow .SubRowIconCol {
	padding-right: 20px;
	display: flex;
	align-content: center;
	justify-content: flex-end;
	position: relative;
	top: -5px;
}
.MassiveCreate .MassiveCreateRow.SubRow .SubRowIconCol span {
	position: absolute;
	top: 25px;
	right: 15px;
	font-size: 0.85em;
	color: var(--primarycolor);
}
.MassiveCreate .MassiveCreateRow.SubRow .MassiveCreateActions {
	margin-top: 0px;
	padding-left: 0px;
	justify-content: center;
}
.MassiveCreate .MassiveCreateRow.SubRow .MassiveCreateActions button {
	position: relative;
	top: -4px;
}
</style>
