<template>
	<div class="pui-form">
		<sdsmcustoms-modals :modelName="modelName"></sdsmcustoms-modals>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense v-if="!isCreatingElement">
						<!-- CUSTOMSID -->
						<v-col cols="1">
							<pui-number-field
								:id="`customsid-${modelName}`"
								:label="$t('sdsmcustoms.customsid')"
								v-model.number="model.customsid"
								disabled
								min="0"
								max="9999"
								toplabel
							></pui-number-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- CODE -->
						<v-col cols="2">
							<pui-text-field
								:id="`code-sdsmcustoms`"
								v-model="model.code"
								:label="$t('sdsmcustoms.code')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="15"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DESCRIPTION -->
						<v-col cols="5">
							<pui-text-field
								:id="`description-sdsmcustoms`"
								v-model="model.description"
								:label="$t('sdsmcustoms.description')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DATEEXPIRATION -->
						<v-col cols="4" v-if="!isCreatingElement">
							<pui-date-field
								:id="`dateexpiration-sdsmcustoms`"
								:label="$t('sdsmcustoms.dateexpiration')"
								toplabel
								time
								v-model="model.dateexpiration"
								disabled
							></pui-date-field>
						</v-col>
					</v-row>
					<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin'
import sdsmcustomsActions from './SdsmcustomsActions'
import sdsmcustomsModals from './SdsmcustomsModals.vue'
import formUtils from '@/utils/form'

export default {
	name: 'sdsmcustoms-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'sdsmcustoms-modals': sdsmcustomsModals
	},
	data () {
		return {
			modelName: 'sdsmcustoms',
			actions: sdsmcustomsActions.formActions
		}
	},
	methods: {
		afterGetData () {
			formUtils.updateMiniAudit(this.model)
		}
	},
	computed: {},
	created () {}
}
</script>
