<template>
	<div>
		<pui-modal-dialog-form
			:titleText="$t('modal.mequipmentsize.reactivate.title')"
			:modelName="modelName"
			:dialogName="mequipmentsizeReactivateModal"
			:onOk="onOkReactivate"
		>
			<template slot="message">
				<v-row dense>
					<p>{{ $t('modal.mequipmentsize.reactivate.confirm') }}</p>
				</v-row>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'mequipmentsize-modals',
	data() {
		return {
			mequipmentsizeReactivateModal: 'mequipmentsizeReactivateAction'
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onOkReactivate(modalData) {
			const params = {
				eqsizeid: modalData.eqsizeid
			};

			const url = '/mequipmentsize/reactivate';
			return new Promise((resolve) => {
				this.$puiRequests.patchRequest(
					url,
					null,
					() => {
						// Valid response, do code and close modal
						this.$puiNotify.success(this.$puiI18n.t('modal.mequipmentsize.reactivate.notifySuccess'));
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response?.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message);
						resolve(true);
					},
					params,
					null
				);
			});
		}
	}
};
</script>
