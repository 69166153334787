<template>
	<div>
		<!-- Copy Wizard -->
		<pui-modal-dialog-form
			scrollable
			:dialogName="sdsmgoodCopyModal.name"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.sdsmgood.copy.title')"
			:modelName="modelName"
			:onOk="onOKGoodWizardModal"
			:okText="sdsmgoodCopyModal.okText"
			:onShow="null"
		>
			<template slot="message" slot-scope="data">
				<pcsum-copy-wizard :ref="sdsmgoodCopyModal.refWizard" @EmitSetOkText="(t) => (sdsmgoodCopyModal.okText = t)">
					<sdsmgood-form-for-wizard :ref="sdsmgoodCopyModal.refWizardForm" :modeltemplate="data.modalData"></sdsmgood-form-for-wizard>
				</pcsum-copy-wizard>
			</template>
		</pui-modal-dialog-form>

		<!-- Massive Update -->
		<pui-modal-dialog-form
			scrollable
			:dialogName="sdsmgoodMassiveUpdateModal.name"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.sdsmgood.massiveedit.title')"
			:modelName="modelName"
			:onOk="onOkGoodMassiveUpdateModal"
			:okText="sdsmgoodMassiveUpdateModal.okText"
			:onShow="null"
		>
			<template slot="message" slot-scope="data">
				<sdsmgood-form-for-massive-update
					:ref="sdsmgoodMassiveUpdateModal.refForm"
					:goods="data.modalData.goods"
				></sdsmgood-form-for-massive-update>
			</template>
		</pui-modal-dialog-form>

		<!-- Warnings modal -->
		<pui-modal-dialog-form
			scrollable
			widthDialog="25%"
			:dialogName="alertsWarningModal.name"
			modelName="sdsmdocument"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.alertmodal.warnings')"
			:okText="alertsWarningModal.okText"
			:onShow="() => {}"
			:disableCancel="true"
		>
			<template slot="message" slot-scope="data">
				<sdsmgood-warnings :ref="alertsWarningModal.refForm" :alerts="data.modalData.alerts"></sdsmgood-warnings>
			</template>
		</pui-modal-dialog-form>

		<!-- Errors modal -->
		<pui-modal-dialog-form
			scrollable
			widthDialog="25%"
			:dialogName="alertsErrorModal.name"
			modelName="sdsmgood"
			dialogClasses="PcsumFormInModal"
			:titleText="$t('modal.alertmodal.errors')"
			:okText="alertsErrorModal.okText"
			:onShow="() => {}"
			:disableCancel="true"
		>
			<template slot="message" slot-scope="data">
				<sdsmgood-errors :ref="alertsErrorModal.refForm" :alerts="data.modalData.alerts"></sdsmgood-errors>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
import PcsumCopyWizard from './../general/PcsumCopyWizard';
import SdsmgoodFormForWizard from './modalForms/SdsmgoodFormForWizard.vue';
import SdsmgoodFormForMassiveUpdate from './modalForms/SdsmgoodFormForMassiveUpdate.vue';
import alertsWarningModal from '../general/PcsumHeader/alertWarningModal_new.vue';
import alertsErrorModal from '../general/PcsumHeader/alertErrorModal_new.vue';
export default {
	name: 'sdsmgood-modals',
	components: {
		'pcsum-copy-wizard': PcsumCopyWizard,
		'sdsmgood-form-for-wizard': SdsmgoodFormForWizard,
		'sdsmgood-form-for-massive-update': SdsmgoodFormForMassiveUpdate,
		'sdsmgood-warnings': alertsWarningModal,
		'sdsmgood-errors': alertsErrorModal
	},
	data() {
		return {
			sdsmgoodCopyModal: {
				okText: null,
				refWizard: 'goodWizard',
				refWizardForm: 'goodWizardForm',
				name: 'sdsmgoodGoodCopyAction'
			},
			sdsmgoodMassiveUpdateModal: {
				okText: null,
				refForm: 'goodFormMassiveUpdate',
				name: 'sdsmgoodGoodMassiveUpdateAction'
			},
			alertsWarningModal: {
				okText: this.$t('modal.alertmodal.close'),
				refForm: 'modalWarningAlerts',
				name: 'modalWarningAlerts'
			},
			alertsErrorModal: {
				okText: this.$t('modal.alertmodal.close'),
				refForm: 'modalErrorAlerts',
				name: 'modalErrorAlerts'
			}
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		/* Copy Wizard */
		async onOKGoodWizardModal() {
			const saveMethod = this.$refs[this.sdsmgoodCopyModal.refWizardForm] ? this.$refs[this.sdsmgoodCopyModal.refWizardForm].SaveWizard : null;
			const completed = await this.$refs[this.sdsmgoodCopyModal.refWizard].nextStep(saveMethod);
			if (completed) {
				return new Promise((resolve) => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					resolve(true);
				});
			}
		},
		/* Massive Update */
		async onOkGoodMassiveUpdateModal() {
			const completed = await this.$refs[this.sdsmgoodMassiveUpdateModal.refForm].SendGoodMassiveUpdate();
			if (completed) {
				return new Promise((resolve) => {
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					resolve(true);
				});
			}
		}
	}
};
</script>
