<template>
	<div class="pui-form">
		<mequipmenttype-modals :modelName="modelName"></mequipmenttype-modals>
		<!-- <pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<mequipmenttype-form-header :modelPk="modelPk"></mequipmenttype-form-header>
		</pui-form-header> -->
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense v-if="!isCreatingElement">
						<!-- EQTYPEID -->
						<v-col cols="1">
							<pui-text-field
								:id="`eqtypeid-mequipmenttype`"
								v-model="model.eqtypeid"
								:label="$t('mequipmenttype.eqtypeid')"
								disabled
								toplabel
								maxlength="12"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- EDICODE -->
						<v-col cols="1">
							<pui-text-field
								:id="`edicode-mequipmenttype`"
								v-model="model.edicode"
								:label="$t('mequipmenttype.edicode')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="3"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DESCRIPTION -->
						<v-col cols="6">
							<pui-text-field
								:id="`description-mequipmenttype`"
								v-model="model.description"
								:label="$t('mequipmenttype.description')"
								:disabled="formDisabled"
								toplabel
								required
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- DATEEXPIRATION -->
						<v-col cols="4" v-if="!isCreatingElement">
							<pui-date-field
								:id="`dateexpiration-mequipmenttype`"
								v-model="model.dateexpiration"
								:label="$t('mequipmenttype.dateexpiration')"
								disabled
								readonly
								toplabel
								maxlength="13"
								time
							></pui-date-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12 col-xs-12 col-sm-4 col-md-4 col-lg-2 col-xl-2">
							<pui-select
								id="callfirstentry"
								attach="callfirstentry"
								:label="$t('mequipmenttype.eqtypegroup')"
								toplabel
								v-model="model"
								modelName="mequipmenttypegroup"
								:modelFormMapping="{ eqtypegroupid: 'eqtypegroupid' }"
								:itemsToSelect="[{ eqtypegroupid: model.eqtypegroupid }]"
								:itemValue="['eqtypegroupid']"
								:itemText="item => `${item.description}`"
								:order="{ countrycode: 'asc' }"
							></pui-select>
						</v-col>
					</v-row>
					<!-- MINI AUDIT -->
					<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin'
import mequipmenttypeActions from './MequipmenttypeActions'
import mequipmenttypeModals from './MequipmenttypeModals.vue'
import formUtils from '@/utils/form'

export default {
	name: 'mequipmenttype-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mequipmenttype-modals': mequipmenttypeModals
	},
	data () {
		return {
			modelName: 'mequipmenttype',
			actions: mequipmenttypeActions.formActions
		}
	},
	methods: {
		afterGetData () {
			formUtils.updateMiniAudit(this.model)
		},
		beforeSave () {
			this.dtohash = null
		}
	},
	computed: {},
	created () {}
}
</script>
